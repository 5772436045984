import { IFriend, IUserFriend } from '../../models/friend.model';
import { friendActions } from '../actions';

export interface FriendState {
  isLoading: boolean;
  users: IUserFriend[];
  friends: IFriend[];
  message: string | null;
}

const initialState: FriendState = {
  isLoading: false,
  users: [],
  friends: [],
  message: null,
};

export const friendReducer = (
  state: FriendState = initialState,
  action: friendActions.Action
): FriendState => {
  switch (action.type) {
    case friendActions.FriendActionTypes.FRIEND_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case friendActions.FriendActionTypes.FRIEND_USERS_LOADED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.payload,
      };

    case friendActions.FriendActionTypes.FRIEND_LOADED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        friends: action.payload,
      };

    case friendActions.FriendActionTypes.FRIEND_FOLLOW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: state.users.map((u) => {
          let newData = u;

          if (u._id === action.payload.following.userId) {
            newData['followings'] = [
              ...u['followings'],
              action.payload.following,
            ];
          }

          if (u._id === action.payload.follower.followerId) {
            newData['followers'] = [...u['followers'], action.payload.follower];
          }

          return (u = newData);
        }),
        friends: [...state.friends, action.payload.following],
      };

    case friendActions.FriendActionTypes.FRIEND_UNFOLLOW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: state.users.map((u) => {
          let newData = u;
          if (u._id === action.payload.following.userId) {
            newData['followings'] = u.followings.filter(
              (f) => f.userId !== u._id
            );
          }

          if (u._id === action.payload.follower.followerId) {
            newData['followers'] = u.followers.filter(
              (f) => f.followerId !== u._id
            );
          }

          return (u = newData);
        }),
        friends: state.friends.filter((f) => {
          if (f.userId === action.payload.following.userId) {
            return false;
          }
          return true;
        }),
      };

    case friendActions.FriendActionTypes.FRIEND_USERS_LOADED_FAIL:
    case friendActions.FriendActionTypes.FRIEND_LOADED_FAIL:
    case friendActions.FriendActionTypes.FRIEND_FOLLOW_FAIL:
    case friendActions.FriendActionTypes.FRIEND_UNFOLLOW_FAIL:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
