import React from 'react';
import Spinner from '../spinner/Spinner';
import './infinite-loading.css';

interface InfiniteLoadingProps {
  absolute?: boolean;
  style?: React.CSSProperties;
}

const InfiniteLoading = ({ absolute, style }: InfiniteLoadingProps) => {
  return (
    <div
      className={absolute ? 'infinite-loading absolute' : 'infinite-loading'}
      style={style}
    >
      <Spinner />
    </div>
  );
};

export default InfiniteLoading;
