import React, { useEffect, useState } from 'react';
import { FaAngleUp } from 'react-icons/fa';
import './scroll-to-top.css';

const ScrollToTop = () => {
  const [showToTopBtn, setShowToTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowToTopBtn(true);
      } else {
        setShowToTopBtn(false);
      }
    });
  }, []);

  const goToTopHandle = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {showToTopBtn && (
        <FaAngleUp className="scroll-to-top icon" onClick={goToTopHandle} />
      )}
    </>
  );
};

export default ScrollToTop;
