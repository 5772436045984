import React, { useEffect, useState } from 'react';
import ReactTimeAgo from 'react-time-ago';
import urlRegex from 'url-regex';
import { Link } from 'react-router-dom';
import { ICommments } from '../../models/comment.model';
import { IPost } from '../../models/post.model';
import { IUser } from '../../models/user.model';
import './blog-comment.css';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { contains } from '../../utils/contains';
import { useDispatch, useSelector } from 'react-redux';
import { commentCreators } from '../../store/creators';
import ConfirmPopup from '../confirm-popup/ConfirmPopup';
import BlogCommentReply from '../blog-comment-reply/BlogCommentReply';
import { AppState } from '../../store/app-state';
import InputBlogCommentReply from '../input-blog-comment-reply/InputBlogCommentReply';
import LinkPreview from '../link-preview/LinkPreview';
import { useMediaQuery } from '../../hooks';

type BlogCommentProps = {
  comments: ICommments;
  parent: ICommments;
  postInfo: IPost;
  currentUser: IUser | null;
};

const BlogComment = ({
  comments,
  parent,
  postInfo,
  currentUser,
}: BlogCommentProps) => {
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [currentComment, setCurrentComment] = useState<ICommments | null>(null);
  const [text, setText] = useState('');
  const [hidden, setHidden] = useState(false);

  const dispatch = useDispatch();

  const isMobile = useMediaQuery('(max-width: 576px)');

  const user = useSelector((state: AppState) => state.auth.user);
  const commentReplyId = useSelector(
    (state: AppState) => state.posts.commentReplyId
  );
  const commentEdit = useSelector((state: AppState) => state.posts.commentEdit);
  const commentCurrent = useSelector(
    (state: AppState) => state.posts.commentCurrent
  );

  useEffect(() => {
    if ((comments.children || []).length > 2) {
      setHidden(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(comments.children || []).length]);

  useEffect(() => {
    if (commentEdit && commentCurrent) {
      setText(commentCurrent.text);
    }
  }, [commentCurrent, commentEdit]);

  const handleLike = (
    postId: string | number,
    commentId: string | number,
    status: boolean
  ) => {
    dispatch(
      commentCreators.commentPostClickLikeOrUnlike(postId, commentId, status)
    );
  };

  const handleDelete = (comment: ICommments) => {
    setShowConfirmPopup(!showConfirmPopup);
    setCurrentComment(comment);
  };

  const handleConfirmation = () => {
    currentComment &&
      dispatch(
        commentCreators.deleteCommentPostById(postInfo, currentComment._id)
      );
  };

  const handleClose = () => {
    setCurrentComment(null);
  };

  const handleReplyAndFocusInput = (commentId: string | number) => {
    if (commentReplyId === commentId) {
      dispatch(commentCreators.clearReplyCommentPost());
      // dispatch(commentCreators.clearClickPostFocusInputComment());
    } else {
      dispatch(commentCreators.setReplyCommentPost(commentId));
      // dispatch(commentCreators.clickPostFocusInputComment(commentId));
    }
  };

  const handleEdit = (comment: ICommments) => {
    dispatch(
      commentCreators.setEditCommentPost(postInfo._id, parent, comment, true)
    );
  };

  const handleUpdate = (commentId: string | number) => {
    dispatch(
      commentCreators.updateCommentPost(postInfo._id, commentId, { text })
    );
  };

  const handleCancel = () => {
    dispatch(commentCreators.clearEditCommentPost());
  };

  const nestedComments = (comments.children || []).map((comment, i) => {
    return (
      <ul key={comment._id} className="blog-comments-child">
        {hidden && i === (comments.children || []).length - 3 ? (
          <div
            className="view-previous-comments"
            onClick={() => setHidden(false)}
          >
            <p>
              View{' '}
              {(comments.children || []).length - 2 <= 0
                ? ''
                : (comments.children || []).length - 2 + ' '}
              previous comments
            </p>
          </div>
        ) : hidden && i < (comments.children || []).length - 3 ? (
          <></>
        ) : (
          <BlogComment
            key={comment._id}
            comments={comment}
            postInfo={postInfo}
            parent={parent}
            currentUser={currentUser}
          />
        )}
      </ul>
    );
  });

  return (
    <>
      <ConfirmPopup
        showConfirmPopup={showConfirmPopup}
        setShowConfirmPopup={setShowConfirmPopup}
        handleConfirmation={handleConfirmation}
        handleClose={handleClose}
      />
      <li className="blog-comment">
        <div className="blog-comment__media">
          <Link
            to={`/users/${comments.user.username}`}
            className="blog-comment__media-title"
          >
            <img src={comments.user.avartar} alt="" />
          </Link>
          <div className="blog-comment__media-body">
            <div className="blog-comment__media-body-title">
              <Link
                to={`/users/${comments.user.username}`}
                className="username"
              >
                {comments.user.username}
              </Link>
            </div>
            {commentEdit && commentCurrent?._id === comments._id ? (
              <InputBlogCommentReply
                style={{ paddingBottom: 0 }}
                value={text}
                onChange={setText}
                onEnter={() => handleUpdate(comments._id)}
                focus={commentEdit && commentCurrent?._id === comments._id}
              />
            ) : (
              <>
                <p
                  className="blog-comment-text"
                  dangerouslySetInnerHTML={{
                    __html: comments.text.replace(
                      urlRegex({ strict: false }),
                      function (url) {
                        return `<a href="${url}" target="_bank">${url}</a>`;
                      }
                    ),
                  }}
                ></p>
                {comments.text.match(urlRegex())?.length && (
                  <div className="blog-comment-text">
                    <LinkPreview
                      largeSize={isMobile ? true : false}
                      url={
                        (comments.text.match(urlRegex()) &&
                          comments.text.match(urlRegex())![0]) ||
                        ''
                      }
                    />
                  </div>
                )}
              </>
            )}
            <div className="blog-comment-actions">
              <p>
                <ReactTimeAgo
                  date={new Date(comments.createdAt)}
                  locale="en-US"
                  timeStyle="twitter"
                />
              </p>
              {commentEdit && commentCurrent?._id === comments._id ? (
                <></>
              ) : (
                <div
                  className={`blog-comment-like ${
                    contains(comments.likes, comments.user._id) ? 'active' : ''
                  }`}
                  onClick={() =>
                    handleLike(
                      postInfo._id,
                      comments._id,
                      contains(comments.likes, comments.user._id)
                    )
                  }
                >
                  {contains(comments.likes, comments.user._id) ? (
                    <FaHeart className="icon" />
                  ) : (
                    <FaRegHeart className="icon" />
                  )}
                  {comments.likes.length > 0 && (
                    <span>{comments.likes.length}</span>
                  )}
                </div>
              )}
              <p
                className={`reply ${
                  comments._id === commentReplyId ? 'active' : ''
                }`}
                onClick={() => handleReplyAndFocusInput(comments._id)}
              >
                Reply
              </p>
              {user?._id === comments.userId && (
                <p
                  className={`edit ${
                    commentEdit && commentCurrent?._id === comments._id
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => handleEdit(comments)}
                >
                  Edit
                </p>
              )}
              {commentEdit && commentCurrent?._id === comments._id && (
                <p className="cancel" onClick={handleCancel}>
                  Cancel
                </p>
              )}
              <p className="delete" onClick={() => handleDelete(comments)}>
                Delete
              </p>
            </div>
          </div>
        </div>
        <BlogCommentReply
          user={user}
          post={postInfo}
          parentId={parent._id}
          commentId={comments._id}
        />
        {nestedComments}
      </li>
    </>
  );
};

export default BlogComment;
