import { ITrade } from '../../models/trade.model';
import { tradeActions } from '../actions';
import {
  TradeStatistic,
  assignPortToTrades,
  getStatisticFromTrades,
} from '../../utils/trade-helper';
import { IWallet } from '../../models/wallet.model';
import { TradeMethodologyJoin } from '../../models/trademedology.model';

export interface TradeState {
  isLoading: boolean;
  trades: ITrade[] | [];
  wallets: IWallet[] | [];
  statistic: TradeStatistic | null;
  showInput: boolean;
  edit: boolean;
  current: TradeMethodologyJoin | null;
  message: string | null;
}

const initialState: TradeState = {
  isLoading: false,
  trades: [],
  wallets: [],
  statistic: null,
  showInput: false,
  edit: false,
  current: null,
  message: null,
};

export const tradeReducer = (
  state: TradeState = initialState,
  action: tradeActions.Action
): TradeState => {
  switch (action.type) {
    case tradeActions.TradeActionTypes.TRADE_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case tradeActions.TradeActionTypes.TRADE_SHOW_INPUT:
      return {
        ...state,
        showInput: action.payload,
      };

    case tradeActions.TradeActionTypes.TRADE_LOADED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        trades: assignPortToTrades(
          action.payload.trades,
          action.payload.wallets
        ),
        statistic: getStatisticFromTrades(
          action.payload.trades,
          action.payload.wallets
        ),
        wallets: action.payload.wallets,
      };

    case tradeActions.TradeActionTypes.TRADE_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        trades: assignPortToTrades(
          [...state.trades, action.payload.trade],
          state.wallets
        ),
        statistic: getStatisticFromTrades(
          [...state.trades, action.payload.trade],
          state.wallets
        ),
        showInput: false,
        message: action.payload.message,
      };

    case tradeActions.TradeActionTypes.TRADE_SET_EDIT:
      return {
        ...state,
        isLoading: false,
        edit: true,
        current: action.payload,
      };

    case tradeActions.TradeActionTypes.TRADE_SET_EDIT_CLEAR:
      return {
        ...state,
        isLoading: false,
        edit: false,
        current: null,
      };

    case tradeActions.TradeActionTypes.TRADE_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        trades: assignPortToTrades(
          state.trades.map((trade) => {
            if (trade._id === action.payload.trade._id) {
              let newTrade = action.payload.trade;
              return (trade = newTrade);
            }
            return trade;
          }),
          state.wallets
        ),
        statistic: getStatisticFromTrades(
          state.trades.map((trade) => {
            if (trade._id === action.payload.trade._id) {
              let newTrade = action.payload.trade;
              return (trade = newTrade);
            }
            return trade;
          }),
          state.wallets
        ),
        edit: false,
        current: null,
        showInput: false,
        message: action.payload.message,
      };

    case tradeActions.TradeActionTypes.TRADE_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        trades: assignPortToTrades(
          state.trades.filter(
            (trade) => trade._id !== action.payload.trade._id
          ),
          state.wallets
        ),
        statistic: getStatisticFromTrades(
          state.trades.filter(
            (trade) => trade._id !== action.payload.trade._id
          ),
          state.wallets
        ),
        message: action.payload.message,
      };

    case tradeActions.TradeActionTypes.TRADE_LOADED_FAIL:
    case tradeActions.TradeActionTypes.TRADE_GET_FAIL:
    case tradeActions.TradeActionTypes.TRADE_CREATE_FAIL:
    case tradeActions.TradeActionTypes.TRADE_UPDATE_FAIL:
    case tradeActions.TradeActionTypes.TRADE_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
