import * as Yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';

Yup.addMethod(Yup.string, 'strongPassword', strongPasswordMethod);

function strongPasswordMethod(this: Yup.StringSchema) {
  return this.test('strongPasswordTest', '', function (value: any) {
    // @ts-ignore
    const { path, createError } = this;
    switch (Boolean(value)) {
      case !/^(?=.*[a-z])/.test(value):
        return createError({
          path,
          message: 'Password must include lowercase letter',
        });
      case !/^(?=.*[A-Z])/.test(value):
        return createError({
          path,
          message: 'Password must include uppercase letter',
        });
      case !/^(?=.*[0-9])/.test(value):
        return createError({ path, message: 'Password must include digit' });
      case !/^(?=.*[!@#\\$%\\^&\\*])/.test(value):
        return createError({
          path,
          message: 'Password must include special character',
        });
      default:
        return true;
    }
  });
}

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    strongPassword(): StringSchema<TType, TContext>;
  }
}

export default Yup;
