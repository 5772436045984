import React, { useEffect, useState } from 'react';
import ReactTimeAgo from 'react-time-ago';
import {
  FaHistory,
  FaPlus,
  FaRegTrashAlt,
  FaUsers,
  FaWallet,
} from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '../../hooks';
import { IUser } from '../../models/user.model';
import { AppState } from '../../store/app-state';
import {
  backupCreators,
  roleCreators,
  userCreators,
  walletCreators,
} from '../../store/creators';
import { hasRole } from '../../utils/role';
import './settings.css';
import { IBackup } from '../../models/backup.model';
import ConfirmPopup from '../../components/confirm-popup/ConfirmPopup';
import { IWallet } from '../../models/wallet.model';
import { BsPencil } from 'react-icons/bs';
import WalletPopup from '../../components/wallet-popup/WalletPopup';

const Settings = () => {
  const [tab, setTab] = useState({ active: 'wallet' });
  const [userRoles, setUserRoles] = useState<string[] | null>(null);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showWalletPopup, setShowWalletPopup] = useState(false);
  const [currentWallet, setCurrentWallet] = useState<IWallet | null>(null);
  const [currentBackup, setCurrentBackup] = useState<IBackup | null>(null);

  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.auth.user);
  const users = useSelector((state: AppState) => state.users.users);
  const roles = useSelector((state: AppState) => state.roles.roles);
  const currentUser = useSelector((state: AppState) => state.users.current);
  const walletsInStore = useSelector((state: AppState) => state.wallets);
  const backups = useSelector((state: AppState) => state.backups.backups);

  const isMobile = useMediaQuery('(max-width: 576px)');

  useEffect(() => {
    if (user && hasRole(user?.roles, 'admin')) {
      dispatch(roleCreators.loadRoles());
      dispatch(userCreators.loadUsers());
      dispatch(walletCreators.loadWallets());
      dispatch(backupCreators.loadBackups());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (currentUser) {
      setUserRoles(currentUser.roles.map((role) => role.name));
    }
  }, [currentUser]);

  const handleEditItem = (user: IUser) => {
    dispatch(userCreators.setEditUser(user));
  };

  const handleClearItem = () => {
    dispatch(userCreators.clearEditUser());
  };

  const handleChangeRole = (
    value: string,
    $event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (userRoles) {
      const index = userRoles.findIndex((v) => v === value);
      const checked = $event.target.checked;

      if (checked && index < 0) userRoles.push($event.target.value);
      if (!checked && index >= 0) userRoles.splice(index, 1);
    }
  };

  const handleSubmitRole = (user: IUser) => {
    if (userRoles) {
      const data = { email: user.email, roles: userRoles };

      dispatch(userCreators.updateUser(user, data));
    }
  };

  const handleOpenWallet = () => {
    setShowWalletPopup(true);
  };

  const handleUpdateWallet = (wallet: IWallet) => {
    dispatch(walletCreators.walletSetEdit(wallet));
    setShowWalletPopup(!showWalletPopup);
  };

  const handleDeleteWallet = (wallet: IWallet) => {
    setShowConfirmPopup(!showConfirmPopup);
    setCurrentWallet(wallet);
  };

  const handleWalletClose = () => {
    dispatch(walletCreators.walletSetEditClear());
  };

  const handleCreateBackup = () => {
    dispatch(backupCreators.createBackup({ remark: '' }));
  };

  const handleDeleteBackup = (backup: IBackup) => {
    setShowConfirmPopup(!showConfirmPopup);
    setCurrentBackup(backup);
  };

  const handleConfirmation = () => {
    if (currentWallet) {
      dispatch(walletCreators.deleteWallet(currentWallet));
    }

    if (currentBackup) {
      dispatch(backupCreators.deleteBackup(currentBackup));
    }
  };

  const handleConfirmationClose = () => {
    if (currentWallet) {
      setCurrentWallet(null);
    }

    if (currentBackup) {
      setCurrentBackup(null);
    }
  };

  return (
    <div className="settings">
      <ConfirmPopup
        showConfirmPopup={showConfirmPopup}
        setShowConfirmPopup={setShowConfirmPopup}
        handleConfirmation={handleConfirmation}
        handleClose={handleConfirmationClose}
      />
      <WalletPopup
        showWalletPopup={showWalletPopup}
        setShowWalletPopup={setShowWalletPopup}
        handleClose={handleWalletClose}
      />
      <div className="settings-container">
        <div className="settings-wrapper">
          <div className="settings-content">
            <div className="settings-content-container">
              <div className="settings-content-left">
                <div className="profile">
                  <img src={user?.avartar} alt="user" width={100} />
                  <h4>{user?.username}</h4>
                </div>
                <div className="settings-menu">
                  <div
                    className={`tab ${tab.active === 'wallet' ? 'active' : ''}`}
                    onClick={() => setTab({ active: 'wallet' })}
                  >
                    <FaWallet className="icon" />
                    <span>Wallet</span>
                  </div>
                  {user && hasRole(user?.roles, 'admin') && (
                    <>
                      <div
                        className={`tab ${
                          tab.active === 'users' ? 'active' : ''
                        }`}
                        onClick={() => setTab({ active: 'users' })}
                      >
                        <FaUsers className="icon" />
                        <span>Users</span>
                      </div>
                      <div
                        className={`tab ${
                          tab.active === 'backup' ? 'active' : ''
                        }`}
                        onClick={() => setTab({ active: 'backup' })}
                      >
                        <FaHistory className="icon" />
                        <span>Backup</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="settings-content-right">
                {tab.active === 'wallet' && (
                  <div className="settings-tab">
                    <h3 className="title">Wallet Settings</h3>
                    <p className="description">Wallet history</p>
                    <div className="create-wallet" onClick={handleOpenWallet}>
                      <FaPlus className="icon" />
                    </div>
                    <div className="content">
                      <div className="wallet">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Date</th>
                              <th scope="col">Amount (B)</th>
                              {isMobile ? (
                                <></>
                              ) : (
                                <th scope="col">Description</th>
                              )}
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {walletsInStore.wallets.length > 0 && (
                              <>
                                {walletsInStore.wallets.map((wallet, i) => (
                                  <tr key={wallet._id}>
                                    <th scope="row">{wallet.order}</th>
                                    <td>
                                      <ReactTimeAgo
                                        date={new Date(wallet.createdAt)}
                                        locale="en-US"
                                        timeStyle={
                                          isMobile ? 'twitter' : undefined
                                        }
                                      />
                                    </td>
                                    <td
                                      className={
                                        wallet.amount > 0
                                          ? 'deposit'
                                          : 'withdrawal'
                                      }
                                    >
                                      {wallet.amount.toFixed(2)}
                                    </td>
                                    {isMobile ? (
                                      <></>
                                    ) : (
                                      <td>{wallet.description}</td>
                                    )}
                                    <td>
                                      <div className="group-btn">
                                        <button
                                          className="update-btn"
                                          onClick={() =>
                                            handleUpdateWallet(wallet)
                                          }
                                        >
                                          <BsPencil className="icon" /> Update
                                        </button>
                                        {!wallet.starter && (
                                          <button
                                            className="delete-btn"
                                            onClick={() =>
                                              handleDeleteWallet(wallet)
                                            }
                                          >
                                            <FaRegTrashAlt className="icon" />{' '}
                                            Delete
                                          </button>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                                <tr>
                                  <th></th>
                                  <td>Balance</td>
                                  <td
                                    className={
                                      walletsInStore.balance > 0
                                        ? 'deposit'
                                        : walletsInStore.balance < 0
                                        ? 'withdrawal'
                                        : undefined
                                    }
                                  >
                                    {walletsInStore.balance.toFixed(2)}
                                  </td>
                                  {isMobile ? <></> : <td></td>}
                                  <td></td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {tab.active === 'users' && (
                  <div className="settings-tab">
                    <h3 className="title">Users Settings</h3>
                    <p className="description">Users detail</p>
                    <div className="content">
                      <div className="backup">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th
                                scope="col"
                                style={isMobile ? { display: 'none' } : {}}
                              >
                                Username
                              </th>
                              <th scope="col">Email</th>
                              <th scope="col">Roles</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users.length > 0 &&
                              users.map((user, i) => (
                                <tr
                                  key={i}
                                  onDoubleClick={() => handleEditItem(user)}
                                >
                                  <th scope="row">{i + 1}</th>
                                  <td
                                    style={isMobile ? { display: 'none' } : {}}
                                  >
                                    {user.username}
                                  </td>
                                  <td>{user.email}</td>
                                  <td>
                                    {currentUser &&
                                    currentUser._id === user._id ? (
                                      <form className="form-roles">
                                        {roles.map((role) => (
                                          <div
                                            className="form-check"
                                            key={role._id}
                                          >
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              value={role.name}
                                              defaultChecked={
                                                !!hasRole(user.roles, role.name)
                                              }
                                              name={role.name}
                                              id={role.name}
                                              onChange={(e) =>
                                                handleChangeRole(role.name, e)
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={role.name}
                                            >
                                              {role.name}
                                            </label>
                                          </div>
                                        ))}
                                        <div className="form-roles-action">
                                          <button
                                            type="button"
                                            onClick={() =>
                                              handleSubmitRole(user)
                                            }
                                          >
                                            Update
                                          </button>
                                          <button
                                            type="button"
                                            onClick={handleClearItem}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </form>
                                    ) : (
                                      <div className="badges-wrapper">
                                        <div className="badges">
                                          {user.roles.map((role) => (
                                            <span
                                              key={role._id}
                                              className={`badge ${
                                                role.name === 'admin'
                                                  ? 'badge-danger'
                                                  : role.name === 'moderator'
                                                  ? 'badge-warning'
                                                  : role.name === 'user'
                                                  ? 'badge-success'
                                                  : 'badge-info'
                                              }`}
                                            >
                                              {role.name}
                                            </span>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {tab.active === 'backup' && (
                  <div className="settings-tab">
                    <h3 className="title">Backup History</h3>
                    <p className="description">Database backup history</p>
                    <div className="create-backup" onClick={handleCreateBackup}>
                      <FaPlus className="icon" />
                    </div>
                    <div className="content">
                      <div className="backup">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Date</th>
                              <th scope="col">Size (MB)</th>
                              {isMobile ? <></> : <th scope="col">Status</th>}
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {backups.length > 0 &&
                              backups.map((backup, i) => (
                                <tr key={backup._id}>
                                  <th scope="row">{i + 1}</th>
                                  <td>
                                    <ReactTimeAgo
                                      date={new Date(backup.createdAt)}
                                      locale="en-US"
                                      timeStyle={
                                        isMobile ? 'twitter' : undefined
                                      }
                                    />
                                  </td>
                                  <td>
                                    {(backup.size / 1024 / 1024).toFixed(2)}
                                  </td>
                                  {isMobile ? (
                                    <></>
                                  ) : (
                                    <td>
                                      <span className="badge badge-success">
                                        Success
                                      </span>
                                    </td>
                                  )}
                                  <td>
                                    <button
                                      className="delete-btn"
                                      onClick={() => handleDeleteBackup(backup)}
                                    >
                                      <FaRegTrashAlt className="icon" /> Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
