import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
  ReactNode,
} from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { modalCreators } from '../../store/creators';
import { AppState } from '../../store/app-state';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { BsBootstrapReboot, BsXLg, BsZoomIn, BsZoomOut } from 'react-icons/bs';

const Background = styled.div`
  top: 0;
  left: 0;
  /* width: 100%;
  height: 100%; */
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: row;
  z-index: 10;
`;

const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  grid-template-columns: 3fr 1fr;
  position: relative;
  z-index: 10;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eee;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ImagContainer = styled.div`
  position: relative;
  background: rgba(0, 0, 0, 0.9);
  max-height: 100vh;

  .img-zoom {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: calc(100vw - 500px);

    @media (max-width: 1024px) {
      width: calc(100vw - 360px);
    }

    @media (max-width: 768px) {
      width: 100vw;
    }
  }

  .img-tools {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 100;
    display: flex;
    flex-direction: row;

    .wrapper-icon {
      height: 36px;
      width: 36px;
      margin-right: 4px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        color: rgb(175, 175, 175);
        font-size: 22px;
      }

      &:hover {
        color: rgb(200, 200, 200);
        background-color: rgba(175, 175, 175, 0.2);
        transition: all 0.3s ease-out 0s;
      }
    }
  }

  .img-close {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 100;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      color: rgb(175, 175, 175);
      font-size: 22px;
    }

    &:hover {
      color: rgb(200, 200, 200);
      background-color: rgba(175, 175, 175, 0.2);
      transition: all 0.3s ease-out 0s;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: 768px) {
    height: auto;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  width: 500px;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  overflow-y: auto;

  @media (max-width: 1024px) {
    width: 360px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const NextButton = styled(FaAngleRight)`
  color: #eee;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
  right: 20px;
  top: 50%;
  margin-top: -24px;
  background: rgba(255, 255, 255, 0.3);

  &:hover {
    color: #aaa;
    background: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease-out;
  }
`;

const PrevButton = styled(FaAngleLeft)`
  color: #eee;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
  left: 20px;
  top: 50%;
  margin-top: -24px;
  background: rgba(255, 255, 255, 0.2);

  &:hover {
    color: #aaa;
    background: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease-out;
  }
`;

export type CustomModalProps = {
  children: ReactNode;
  showModal: boolean;
  setShowModal: (showModal: any) => void;
  mainSrc: string;
  nextSrc: string;
  prevSrc: string;
  onMovePrevRequest: () => void;
  onMoveNextRequest: () => void;
  closeModalAndRedirectTo?: string;
};

const CustomModal = ({
  children,
  showModal,
  setShowModal,
  mainSrc,
  nextSrc,
  prevSrc,
  onMovePrevRequest,
  onMoveNextRequest,
  closeModalAndRedirectTo = '/',
}: CustomModalProps) => {
  const modalRef = useRef(null);
  const [image, setImage] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: showModal ? 1 : 0,
    // transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
      window.history.pushState('', '', closeModalAndRedirectTo);
    }
  };

  const editFile = useSelector((state: AppState) => state.files.edit);
  const showTradeInput = useSelector(
    (state: AppState) => state.trades.showInput
  );

  const requestMove = useCallback(
    (direction: string) => {
      if (direction === 'prev') {
        onMovePrevRequest();
        setImage(prevSrc);
      } else {
        onMoveNextRequest();
        setImage(nextSrc);
      }
    },
    [nextSrc, onMoveNextRequest, onMovePrevRequest, prevSrc]
  );

  const keyPress = useCallback(
    (e: any) => {
      if (e.key === 'Escape' && showModal) {
        // console.log('I pressed');
        setShowModal(false);
        dispatch(modalCreators.modalImageClose());
        // window.history.pushState({}, '', closeModalAndRedirectTo);
        // window.location.replace(closeModalAndRedirectTo);
        navigate(closeModalAndRedirectTo);
      }

      // console.log(e.key);

      if ((e.key === 'ArrowLeft' || e.key === 'ArrowUp') && showModal) {
        // console.log('Arrow Left');
        // onMovePrevRequest();
        // setImage(prevSrc);
        requestMove('prev');
      }

      if ((e.key === 'ArrowRight' || e.key === 'ArrowDown') && showModal) {
        // console.log('Arrow Right');
        // onMoveNextRequest();
        // setImage(nextSrc);
        requestMove('next');
      }
    },
    [
      showModal,
      setShowModal,
      dispatch,
      navigate,
      closeModalAndRedirectTo,
      requestMove,
    ]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    setImage(mainSrc);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress, mainSrc]);

  // Request to transition to the next image
  const requestMoveNext = () => {
    requestMove('next');
  };

  // Request to transition to the previous image
  const requestMovePrev = () => {
    requestMove('prev');
  };

  return (
    <>
      {showModal && (
        <Background ref={modalRef} onClick={closeModal}>
          <animated.div style={animation}>
            <ModalWrapper>
              <ImagContainer>
                {!editFile && !showTradeInput && (
                  <div
                    className="img-close"
                    onClick={() => {
                      setShowModal((prev: boolean) => !prev);
                      // window.location.replace(closeModalAndRedirectTo);
                      navigate(closeModalAndRedirectTo);
                      dispatch(modalCreators.modalImageClose());
                    }}
                  >
                    <BsXLg className="icon" />
                  </div>
                )}
                <TransformWrapper initialScale={1}>
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <React.Fragment>
                      <div className="img-tools">
                        <div onClick={() => zoomIn()} className="wrapper-icon">
                          <BsZoomIn className="icon" />
                        </div>
                        <div onClick={() => zoomOut()} className="wrapper-icon">
                          <BsZoomOut className="icon" />
                        </div>
                        <div
                          onClick={() => resetTransform()}
                          className="wrapper-icon"
                        >
                          <BsBootstrapReboot className="icon" />
                        </div>
                      </div>
                      <TransformComponent contentClass="img-zoom">
                        <img src={image} alt="" />
                      </TransformComponent>
                    </React.Fragment>
                  )}
                </TransformWrapper>
                <NextButton onClick={requestMoveNext} />
                <PrevButton onClick={requestMovePrev} />
              </ImagContainer>
              <ContentContainer>{children}</ContentContainer>
            </ModalWrapper>
          </animated.div>
        </Background>
      )}
    </>
  );
};

export default CustomModal;
