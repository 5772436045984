import { passwordActions } from '../actions';

export interface PasswordState {
  isLoading: boolean;
  isRedirect: boolean;
  message: string | null;
}

const initialState: PasswordState = {
  isLoading: false,
  isRedirect: false,
  message: null,
};

export const passwordReducer = (
  state: PasswordState = initialState,
  action: passwordActions.Action
): PasswordState => {
  switch (action.type) {
    case passwordActions.PasswordActionTypes.PASSWORD_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case passwordActions.PasswordActionTypes.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isRedirect: true,
        message: action.payload.message,
      };
    case passwordActions.PasswordActionTypes.PASSWORD_FORGOT_SUCCESS:
    case passwordActions.PasswordActionTypes.PASSWORD_FORGOT_FAIL:
    case passwordActions.PasswordActionTypes.PASSWORD_RESET_FAIL:
      return {
        ...state,
        isLoading: false,
        isRedirect: false,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
