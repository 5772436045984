import React from 'react';
import { AiOutlineTags } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { config } from '../../config';
import { IFile } from '../../models/file.model';
import { AppState } from '../../store/app-state';
import './cards.css';

type CardsProps = {
  images: IFile[] | [];
};

const Cards = ({ images }: CardsProps) => {
  const navigate = useNavigate();

  const filter = useSelector((state: AppState) => state.files.filter);
  const token = useSelector((state: AppState) => state.auth.accessToken);

  return (
    <div className="cards-container">
      <div className="cards-wrapper">
        <div className="cards">
          {images.map((image) => (
            <div
              className="card"
              key={image._id}
              onClick={() =>
                navigate(`/images/${image._id}?tags=${filter.slug}`)
              }
            >
              <div className="card__image-container">
                <img
                  src={`${config.serverUrl}/api/files/${image._id}?token=${token}`}
                  alt=""
                />
                <p className="card__tag">
                  <AiOutlineTags />{' '}
                  {image.tags.length > 0
                    ? image.tags[image.tags.length - 1].name
                    : 'Unknow'}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Cards;
