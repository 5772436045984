import React from 'react';
import { useDispatch } from 'react-redux';
import {
  FaCheckCircle,
  FaTimesCircle,
  FaInfoCircle,
  FaExclamationCircle,
} from 'react-icons/fa';
import './toast.css';
import { toastCreators } from '../../store/creators';

type ToastProps = {
  id: string;
  type: string;
  message: string;
  position?: string;
  duration?: number;
};

const Toast = ({
  id,
  type,
  message,
  duration = 3,
  position = 'top-right',
}: ToastProps) => {
  const dispatch = useDispatch();

  const handleDelete = (id: string) => {
    dispatch(toastCreators.deleteToast(id));
  };

  return (
    <>
      {type === 'success' && (
        <div className={`toast toast-success ${position}`}>
          <div className="toast-container-top">
            <FaCheckCircle className="icon" />
          </div>
          <div className="toast-container-buttom">
            <p>Success</p>
            <p>{message}</p>
          </div>
          <button className="toast-btn" onClick={() => handleDelete(id)}>
            ×
          </button>
          <div
            className="toast-progress success"
            style={{ animation: `progress ${duration}s linear forwards` }}
          ></div>
        </div>
      )}
      {type === 'error' && (
        <div className={`toast toast-error ${position}`}>
          <div className="toast-container-top">
            <FaTimesCircle className="icon" />
          </div>
          <div className="toast-container-buttom">
            <p>Error</p>
            <p>{message}</p>
          </div>
          <button className="toast-btn" onClick={() => handleDelete(id)}>
            ×
          </button>
          <div
            className="toast-progress error"
            style={{ animation: `progress ${duration}s linear forwards` }}
          ></div>
        </div>
      )}
      {type === 'info' && (
        <div className={`toast toast-info ${position}`}>
          <div className="toast-container-top">
            <FaInfoCircle className="icon" />
          </div>
          <div className="toast-container-buttom">
            <p>Info</p>
            <p>{message}</p>
          </div>
          <button className="toast-btn" onClick={() => handleDelete(id)}>
            ×
          </button>
          <div
            className="toast-progress info"
            style={{ animation: `progress ${duration}s linear forwards` }}
          ></div>
        </div>
      )}
      {type === 'warning' && (
        <div className={`toast toast-warning ${position}`}>
          <div className="toast-container-top">
            <FaExclamationCircle className="icon" />
          </div>
          <div className="toast-container-buttom">
            <p>Warning</p>
            <p>{message}</p>
          </div>
          <button className="toast-btn" onClick={() => handleDelete(id)}>
            ×
          </button>
          <div
            className="toast-progress warning"
            style={{ animation: `progress ${duration}s linear forwards` }}
          ></div>
        </div>
      )}
    </>
  );
};

export default Toast;
