export interface Config {
  appName: string;
  appUrl: string;
  serverUrl: string;
}

export const config: Config = {
  appName: process.env.REACT_APP_APP_NAME || 'TradeS',
  appUrl: process.env.REACT_APP_APP_URL || 'http://localhost:3000',
  serverUrl: process.env.REACT_APP_SERVER_URL || 'http://localhost:5000',
};
