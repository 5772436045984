import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import './App.scss';
import ToastContainer from './components/toast-container/ToastContainer';
import 'react-datepicker/dist/react-datepicker.css';

function App() {
  return (
    <Router>
      <ToastContainer />
      <Routes />
    </Router>
  );
}

export default App;
