import React, {
  Dispatch,
  FocusEventHandler,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { BsEmojiSmile } from 'react-icons/bs';
// emoji-mart v3
// import { Picker } from 'emoji-mart';
// import 'emoji-mart/css/emoji-mart.css';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import './input-emoji.css';

type Props = {
  value: string;
  setValue: Dispatch<React.SetStateAction<string>>;
  onEnter?: () => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  closeOnSeletEmoji?: boolean;
  closeOnClickOutside?: boolean;
  closeOnPressEsc?: boolean;
  cleanOnEnter?: boolean;
};

const InputEmojiForwardRef = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const [showEmojis, setShowEmojis] = useState(false);
    const div = useRef() as React.MutableRefObject<HTMLDivElement>;

    const addEmoji = (e: any) => {
      let sym = e.unified.split('-');
      let codesArray: any[] = [];
      sym.forEach((el: any) => codesArray.push('0x' + el));
      let emoji = String.fromCodePoint(...codesArray);
      props.setValue(props.value + emoji);

      if (props.closeOnSeletEmoji) {
        setShowEmojis(false);
      }
    };

    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        setShowEmojis(false);
        props.onEnter && props.onEnter();
        if (props.cleanOnEnter) {
          props.setValue('');
        }
      }
    };

    useEffect(() => {
      const close = (e: any) => {
        if (e.keyCode === 27) {
          if (showEmojis) {
            if (props.closeOnPressEsc) {
              setShowEmojis(false);
            }
          }
        }
      };
      window.addEventListener('keydown', close);
      return () => window.removeEventListener('keydown', close);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showEmojis]);

    useEffect(() => {
      const onClickOutside = (e: any) => {
        const element = e.target;
        if (ref && showEmojis && !div.current?.contains(element)) {
          if (props.closeOnClickOutside) {
            setShowEmojis(false);
          }
        }
      };

      document.body.addEventListener('click', onClickOutside);
      return () => {
        document.removeEventListener('click', onClickOutside);
      };
    }, [props, ref, showEmojis]);

    return (
      <div className="input-emoji" ref={div}>
        <input
          ref={ref}
          value={props.value}
          onKeyDown={handleKeyDown}
          onChange={(e) => props.setValue(e.target.value)}
          onBlur={props.onBlur}
          type="text"
          className="input-emoji--input"
          placeholder={props.placeholder}
        />
        <div className="input-emoji-picker--container">
          {showEmojis && (
            <div className="input-emoji-picker--wrapper">
              <Picker
                data={data}
                onEmojiSelect={addEmoji}
                maxFrequentRows={1}
                previewPosition="none"
              />
            </div>
          )}
        </div>
        <button
          type="button"
          className="input-emoji--button"
          onClick={() => setShowEmojis(!showEmojis)}
        >
          <BsEmojiSmile
            className={
              showEmojis
                ? 'input-emoji--button-icon active'
                : 'input-emoji--button-icon'
            }
          />
        </button>
      </div>
    );
  }
);

InputEmojiForwardRef.defaultProps = {
  placeholder: 'Write a comment...',
  closeOnSeletEmoji: false,
  closeOnClickOutside: false,
  closeOnPressEsc: false,
  cleanOnEnter: false,
};

export default InputEmojiForwardRef;
