import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { FcLock } from 'react-icons/fc';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import './otp-login.css';

type Props = {
  showRecoveryCode: boolean;
  setShowRecoveryCode: React.Dispatch<React.SetStateAction<boolean>>;
  formData: (otp: string) => void;
};

const OtpLogin = ({
  showRecoveryCode,
  setShowRecoveryCode,
  formData,
}: Props) => {
  const [otpInput, setOtpInput] = useState('');
  const isMobile = useMediaQuery('(max-width: 500px)');

  const handleOtpInput = (otp: string) => {
    setOtpInput(otp);
  };

  const handleOtpSubmit = () => {
    formData(otpInput);
    setOtpInput('');
  };
  return (
    <form className="sign-in-form">
      <div className="otp-input-icon">
        <FcLock />
      </div>
      <h2 className="title">Verification Code</h2>
      <h4 className="description">
        Open your authentication app and enter the code for TradeSetup.
      </h4>
      <div className="otp-input">
        <OtpInput
          numInputs={6}
          value={otpInput}
          onChange={handleOtpInput}
          renderSeparator={
            <span>
              <strong>&middot;</strong>
            </span>
          }
          renderInput={(props) => <input {...props} />}
          inputStyle={styles.container(isMobile)}
        />
      </div>
      <input
        type="button"
        onClick={handleOtpSubmit}
        className="btn solid"
        defaultValue="Verify Code"
        disabled={otpInput === '' || otpInput.length !== 6 ? true : false}
      />
      <p className="social-text">
        Can't access your authentication app?{' '}
        <span
          className="use-recovery"
          onClick={() => setShowRecoveryCode(!showRecoveryCode)}
        >
          Enter a recovery code
        </span>
      </p>
    </form>
  );
};

const styles = {
  container: (isMobile: boolean) => ({
    width: isMobile ? '2rem' : '2.5rem',
    height: isMobile ? '2rem' : '2.5rem',
    margin: isMobile ? '0 0.1rem' : '0 0.5rem',
    fontSize: isMobile ? '1rem' : '1.5rem',
    borderRadius: isMobile ? 2 : 4,
    border: '1px solid rgba(0,0,0,0.3)',
  }),
};

export default OtpLogin;
