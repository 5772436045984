import React from 'react';
import { Routes as Switch, Route } from 'react-router-dom';
import PrivateRoute from './guards/PrivateRoute';
import Blog from './pages/blog/Blog';
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import Home from './pages/home/Home';
import Image from './pages/image/Image';
import Images from './pages/images/Images';
// import Message from './pages/message/Message';
import Profile from './pages/profile/Profile';
import ReOrder from './pages/re-order/ReOrder';
import ResetPassword from './pages/reset-password/ResetPassword';
import Settings from './pages/settings/Settings';
import Upload from './pages/upload/Upload';
import Welcome from './pages/welcome/Welcome';
import TradeHome from './pages/trades/trade-home/TradeHome';
import MainLayout from './layouts/main-layout/MainLayout';
import AppLayout from './layouts/app-layout/AppLayout';
import TradeList from './pages/trades/trade-list/TradeList';
import TradePositionSize from './pages/trades/trade-position-size/TradePositionSize';
// import BlankLayout from './layouts/blank-layout/BlankLayout';
// import Friends from './pages/friends/Friends';

const Routes = () => {
  return (
    <Switch>
      <Route path="/" element={<MainLayout />}>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Home />} index />
          <Route path="/blog" element={<Blog />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/images" element={<Images />} />
          <Route path="/images/:id" element={<Image />} />
          <Route path="/re-order" element={<ReOrder />} />
          {/* <Route path="/friends" element={<Friends />} /> */}
        </Route>
      </Route>
      {/* <Route path="/" element={<BlankLayout />}>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/message" element={<Message />} />
        </Route>
      </Route> */}
      <Route path="/" element={<AppLayout />}>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/trades" element={<TradeHome />} />
          <Route path="/trades/list" element={<TradeList />} />
          <Route path="/trades/position-size" element={<TradePositionSize />} />
        </Route>
      </Route>
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
    </Switch>
  );
};

export default Routes;
