import { createContext, ReactNode } from 'react';
import { io } from 'socket.io-client';
import { config } from '../config';

const socket = io(config.serverUrl, { withCredentials: true });

export const SocketContext = createContext(socket);

type SocketProviderProps = {
  children: ReactNode;
};

export const SocketProvider = ({ children }: SocketProviderProps) => {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
