import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import OtpLogin from '../../components/otp-login/OtpLogin';
import OtpRegister from '../../components/otp-register/OtpRegister';
import RecoveryCode from '../../components/recovery-code/RecoveryCode';
import SigninForm from '../../components/signin-form/SigninForm';
import SignupForm from '../../components/signup-form/SignupForm';
import signinImg from '../../images/undraw_maker_launch.svg';
import signupImg from '../../images/undraw_remotely.svg';
import { AppState } from '../../store/app-state';
import { authCreators } from '../../store/creators';
import './welcome.css';

const Welcome = () => {
  const [isActive, setIsActive] = useState(false);
  const [showRecoveryCode, setShowRecoveryCode] = useState(false);

  const dispatch = useDispatch();

  const auth = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    if (auth.message === 'You have successfully registered. Please login') {
      setIsActive(!isActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.message]);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const handleSignupCallback = (data: {
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
  }) => {
    // console.log(data);
    dispatch(authCreators.registerUser(data));
  };

  const handleSigninCallback = (data: { email: string; password: string }) => {
    // console.log(data);
    dispatch(authCreators.loginUser(data));
  };

  const handleOtpLoginCallback = (otp: string) => {
    dispatch(
      authCreators.verifyOtpLogin({
        token: otp,
        pendingId: auth.pendingId as string,
      })
    );
  };

  const handleOtpRegisterCallback = (otp: string) => {
    dispatch(
      authCreators.verifyOtpRegister({
        token: otp,
        pendingId: auth.pendingId as string,
      })
    );
  };

  const handleRecoveryCallback = (data: { recoveryCode: string }) => {
    dispatch(
      authCreators.verifyUseRecoveryCodeLogin({
        pendingId: auth.pendingId as string,
        recoveryCode: data.recoveryCode,
      })
    );
  };

  if (auth.isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div className="welcome">
      <div className={`container ${isActive ? 'sign-up-mode' : ''}`}>
        <div className="forms-container">
          <div className="signin-signup">
            {auth.subject === 'login' ? (
              <>
                {showRecoveryCode ? (
                  <RecoveryCode
                    showRecoveryCode={showRecoveryCode}
                    setShowRecoveryCode={setShowRecoveryCode}
                    formData={handleRecoveryCallback}
                  />
                ) : (
                  <OtpLogin
                    formData={handleOtpLoginCallback}
                    showRecoveryCode={showRecoveryCode}
                    setShowRecoveryCode={setShowRecoveryCode}
                  />
                )}
              </>
            ) : (
              <SigninForm formData={handleSigninCallback} />
            )}
            {auth.subject === 'register' ? (
              <OtpRegister formData={handleOtpRegisterCallback} />
            ) : (
              <SignupForm formData={handleSignupCallback} />
            )}
          </div>
        </div>
        <div className="panels-container">
          <div className="panel left-panel">
            <div className="content">
              <h3>New here ?</h3>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Debitis, ex ratione. Aliquid!
              </p>
              <button
                className="btn transparent"
                id="sign-up-btn"
                onClick={handleToggle}
              >
                Sign up
              </button>
            </div>
            <img src={signinImg} className="image" alt="" />
          </div>
          <div className="panel right-panel">
            <div className="content">
              <h3>One of us ?</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
                laboriosam ad deleniti.
              </p>
              <button
                className="btn transparent"
                id="sign-in-btn"
                onClick={handleToggle}
              >
                Sign in
              </button>
            </div>
            <img src={signupImg} className="image" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
