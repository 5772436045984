import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { AppState } from '../store/app-state';

const PrivateRoute = () => {
  const isLoggedIn = useSelector(
    (state: AppState) => state.auth.isAuthenticated
  );

  return isLoggedIn ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/welcome" />
  );
};

export default PrivateRoute;

// Use
// <Route exact path='/' element={<PrivateRoute/>}>
//       <Route exact path='/' element={<Home/>}/>
// </Route>
// https://stackoverflow.com/a/69869761
