import React, { useEffect, useRef, useState } from 'react';
import { FaHashtag, FaHeart } from 'react-icons/fa';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import useDraggableScroll from '../../hooks/useDraggableScroll';
import { AppState } from '../../store/app-state';
import { postCreators, tagCreators } from '../../store/creators';
import './blog-tags.css';

const BlogTags = () => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const tagsRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.auth.user);
  const tags = useSelector((state: AppState) => state.tags.postTags);
  const posts = useSelector((state: AppState) => state.posts);
  const active = useSelector((state: AppState) => state.posts.filter);

  useEffect(() => {
    dispatch(tagCreators.loadPostsTags());
  }, [dispatch, posts.all]);

  useEffect(() => {
    if (tagsRef.current?.scrollHeight > tagsRef.current?.offsetHeight) {
      setIsOverflowing(true);
    } else {
      setIsOverflowing(false);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (tagsRef.current?.scrollHeight > tagsRef.current?.offsetHeight) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    };

    window.addEventListener('resize', handleResize, false);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (tagsRef.current?.scrollHeight > tagsRef.current?.offsetHeight) {
      setIsOverflowing(true);
    } else {
      setIsOverflowing(false);
    }
  }, [scrollPosition]);

  const handleScrollButton = (direction: 'top' | 'bottom') => {
    const { current } = tagsRef;
    // console.log(current);
    if (current) {
      const scrollAmount = direction === 'bottom' ? 200 : -200;
      current.scrollBy({ top: scrollAmount, behavior: 'smooth' });
      // console.log(scrollAmount);
    }
  };

  const { onMouseDown } = useDraggableScroll(tagsRef, {
    direction: 'vertical',
  });

  const onScroll = () => {
    setScrollPosition(tagsRef.current?.scrollTop);
  };

  const handleTagName = (tag: string) => {
    dispatch(postCreators.postFilter(tag, user));
  };

  const isStartScrollPosition = scrollPosition < 5 ? true : false;

  const isEndScrollPosition =
    tagsRef.current?.offsetHeight + scrollPosition + 5 >
    tagsRef.current?.scrollHeight
      ? true
      : false;

  return (
    <div className="post-tags">
      {isOverflowing && !isStartScrollPosition && (
        <div
          className="post-tags-move"
          onClick={() => handleScrollButton('top')}
        >
          <HiChevronUp className="icon" />
        </div>
      )}
      <div
        className="post-tag"
        ref={tagsRef}
        onScroll={onScroll}
        onMouseDown={onMouseDown}
      >
        <div
          className={`post-tag-name ${active === 'All' ? 'active' : ''}`}
          onClick={() => handleTagName('All')}
        >
          <FaHashtag className="icon" />
          <p>All</p>
        </div>
        <div
          className={`post-tag-name ${active === 'Love' ? 'active' : ''}`}
          onClick={() => handleTagName('Love')}
        >
          <FaHashtag className="icon" />
          <p>
            <FaHeart className="icon" />
          </p>
        </div>
        {tags.length > 0 &&
          tags.map((tag) => (
            <div
              key={tag._id}
              className={`post-tag-name ${active === tag.name ? 'active' : ''}`}
              onClick={() => handleTagName(tag.name)}
            >
              <FaHashtag className="icon" />
              <p>{tag.name}</p>
            </div>
          ))}
      </div>
      {isOverflowing && !isEndScrollPosition && (
        <div
          className="post-tags-move"
          onClick={() => handleScrollButton('bottom')}
        >
          <HiChevronDown className="icon" />
        </div>
      )}
    </div>
  );
};

export default BlogTags;
