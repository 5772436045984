import { ITrade } from '../models/trade.model';
import { IWallet } from '../models/wallet.model';
import { getAverage } from './math';

export interface TradeStatistic {
  netProfit: number;
  netProfitPercent: number;
  balance: number;
  averageProfitPerTrade: number;
  maxProfitPerTrade: number;
  minProfitPerTrade: number;
  averageProfitPerTradePercent: number;
  maxProfitPerTradePercent: number;
  minProfitPerTradePercent: number;
  averagePositionSizePercent: number;
  maxPositionSizePercent: number;
  minPositionSizePercent: number;
  totalTrade: number;
  winTrade: number;
  lossTrade: number;
  winRatio: number;
  lossRatio: number;
  payoff: number;
}

export const assignPortToTrades = (trades: ITrade[], wallets: IWallet[]) => {
  let port = 0;
  let portRealize = 0;

  const result = trades.map((trade, i) => {
    trade['id'] = trade.order;

    if (trade.completed) {
      wallets.map((w, j) => {
        if (i === 0) {
          port = port + wallets[j].amount;
        }
        return w;
      });

      port = port + trade.result;

      trade['portGain'] = parseFloat(
        Number((trade.result / port) * 100).toFixed(2)
      );

      portRealize = portRealize + trade.portGain;

      trade['portRealize'] = portRealize;
      trade['balance'] = port;
      trade['realize'] = parseFloat(
        Number(
          (trade.result / (trade.entryPrice * trade.volume)) * 100
        ).toFixed(2)
      );
      trade['positionSize'] = parseFloat(
        Number(((trade.entryPrice * trade.volume) / port) * 100).toFixed(2)
      );

      return trade;
    }
    return trade;
  });

  return result;
};

export const getStatisticFromTrades = (
  trades: ITrade[],
  wallets: IWallet[]
): TradeStatistic => {
  let port = 0;
  let portRealize = 0;

  let profit = 0;

  let profits: number[] = [];
  let maxProfit = -1000000;
  let minProfit = 1000000;

  let gains: number[] = [];
  let maxGain = -1000000;
  let minGain = 1000000;

  let positionSizes: number[] = [];
  let maxPositionSize = -1000000;
  let minPositionSize = 1000000;

  trades.map((trade, i) => {
    trade['id'] = trade.order;

    if (trade.completed) {
      wallets.map((w, j) => {
        if (i === 0) {
          port = port + wallets[j].amount;
        }
        return w;
      });

      port = port + trade.result;

      trade['portGain'] = parseFloat(
        Number((trade.result / port) * 100).toFixed(2)
      );

      portRealize = portRealize + trade.portGain;

      trade['portRealize'] = portRealize;
      trade['balance'] = port;
      trade['realize'] = parseFloat(
        Number(
          (trade.result / (trade.entryPrice * trade.volume)) * 100
        ).toFixed(2)
      );
      trade['positionSize'] = parseFloat(
        Number(((trade.entryPrice * trade.volume) / port) * 100).toFixed(2)
      );

      profit = profit + trade.result;

      profits.push(trade.result);

      if (trade.result > maxProfit) {
        maxProfit = trade.result;
      }

      if (trade.result < minProfit) {
        minProfit = trade.result;
      }

      gains.push(trade['realize']);

      if (trade['realize'] > maxGain) {
        maxGain = trade['realize'];
      }

      if (trade['realize'] < minGain) {
        minGain = trade['realize'];
      }

      positionSizes.push(trade['positionSize']);

      if (trade['positionSize'] > maxPositionSize) {
        maxPositionSize = trade['positionSize'];
      }

      if (trade['positionSize'] < minPositionSize) {
        minPositionSize = trade['positionSize'];
      }

      return trade;
    }
    return trade;
  });

  return {
    netProfit: profit,
    netProfitPercent: parseFloat(Number((profit / port) * 100).toFixed(2)),
    balance: port,
    averageProfitPerTrade: getAverage(profits),
    maxProfitPerTrade: maxProfit,
    minProfitPerTrade: minProfit,
    averageProfitPerTradePercent: getAverage(gains),
    maxProfitPerTradePercent: maxGain,
    minProfitPerTradePercent: minGain,
    averagePositionSizePercent: getAverage(positionSizes),
    maxPositionSizePercent: maxPositionSize,
    minPositionSizePercent: minPositionSize,
    totalTrade: trades.length,
    winTrade: profits.filter((p) => p > 0).length,
    lossTrade: profits.filter((p) => p < 0).length,
    winRatio: profits.filter((p) => p > 0).length / trades.length,
    lossRatio: profits.filter((p) => p < 0).length / trades.length,
    payoff:
      getAverage(profits.filter((p) => p > 0)) /
      getAverage(
        profits.filter((p) => p < 0),
        true
      ),
  };
};
