import { Dispatch } from 'redux';
import { useToastInCreator } from '../../hooks';
import { httpClient } from '../../utils/http-client';
import { tradeMethodologyActions } from '../actions';
import {
  ITradeMethodology,
  TradeMethodologyGroup,
  TradeMethodologyType,
} from '../../models/trademedology.model';

export const loadTradeMethodologies =
  () => async (dispatch: Dispatch<tradeMethodologyActions.Action>) => {
    dispatch({
      type: tradeMethodologyActions.TradeMethodologyActionTypes
        .TRADE_METHODOLOGY_LOADING,
    });

    try {
      const res = await httpClient.get('/api/trade-methodologies');

      dispatch({
        type: tradeMethodologyActions.TradeMethodologyActionTypes
          .TRADE_METHODOLOGY_LOADED_SUCCESS,
        payload: res.data.methodologies,
      });
    } catch (err: any) {
      dispatch({
        type: tradeMethodologyActions.TradeMethodologyActionTypes
          .TRADE_METHODOLOGY_LOADED_FAIL,
        payload: err.response.data,
      });
    }
  };

export const loadTradeMethodologiesGroup =
  (group: TradeMethodologyGroup) =>
  async (dispatch: Dispatch<tradeMethodologyActions.Action>) => {
    dispatch({
      type: tradeMethodologyActions.TradeMethodologyActionTypes
        .TRADE_METHODOLOGY_LOADING,
    });

    try {
      const res = await httpClient.get(
        `/api/trade-methodologies/groups/${group}`
      );

      dispatch({
        type: tradeMethodologyActions.TradeMethodologyActionTypes
          .TRADE_METHODOLOGY_GROUP_LOADED_SUCCESS,
        payload: res.data.methodologies,
      });
    } catch (err: any) {
      dispatch({
        type: tradeMethodologyActions.TradeMethodologyActionTypes
          .TRADE_METHODOLOGY_GROUP_LOADED_FAIL,
        payload: err.response.data,
      });
    }
  };

export const loadTradeMethodologiesType =
  (type: TradeMethodologyType) =>
  async (dispatch: Dispatch<tradeMethodologyActions.Action>) => {
    dispatch({
      type: tradeMethodologyActions.TradeMethodologyActionTypes
        .TRADE_METHODOLOGY_LOADING,
    });

    try {
      const res = await httpClient.get(
        `/api/trade-methodologies/types/${type}`
      );

      dispatch({
        type: tradeMethodologyActions.TradeMethodologyActionTypes
          .TRADE_METHODOLOGY_TYPE_LOADED_SUCCESS,
        payload: res.data.methodologies,
      });
    } catch (err: any) {
      dispatch({
        type: tradeMethodologyActions.TradeMethodologyActionTypes
          .TRADE_METHODOLOGY_TYPE_LOADED_FAIL,
        payload: err.response.data,
      });
    }
  };

export const loadTradeMethodologiesTypeInstrumentUnique =
  () => async (dispatch: Dispatch<tradeMethodologyActions.Action>) => {
    dispatch({
      type: tradeMethodologyActions.TradeMethodologyActionTypes
        .TRADE_METHODOLOGY_LOADING,
    });

    try {
      const res = await httpClient.get(
        `/api/trade-methodologies/types/${TradeMethodologyType.instrument}/unique`
      );

      dispatch({
        type: tradeMethodologyActions.TradeMethodologyActionTypes
          .TRADE_METHODOLOGY_TYPE_INSTRUMENT_UNIQUE_LOADED_SUCCESS,
        payload: res.data.methodologies,
      });
    } catch (err: any) {
      dispatch({
        type: tradeMethodologyActions.TradeMethodologyActionTypes
          .TRADE_METHODOLOGY_TYPE_INSTRUMENT_UNIQUE_LOADED_FAIL,
        payload: err.response.data,
      });
    }
  };

export const loadTradeMethodologiesTypeSetupUnique =
  () => async (dispatch: Dispatch<tradeMethodologyActions.Action>) => {
    dispatch({
      type: tradeMethodologyActions.TradeMethodologyActionTypes
        .TRADE_METHODOLOGY_LOADING,
    });

    try {
      const res = await httpClient.get(
        `/api/trade-methodologies/types/${TradeMethodologyType.setup}/unique`
      );

      dispatch({
        type: tradeMethodologyActions.TradeMethodologyActionTypes
          .TRADE_METHODOLOGY_TYPE_SETUP_UNIQUE_LOADED_SUCCESS,
        payload: res.data.methodologies,
      });
    } catch (err: any) {
      dispatch({
        type: tradeMethodologyActions.TradeMethodologyActionTypes
          .TRADE_METHODOLOGY_TYPE_SETUP_UNIQUE_LOADED_FAIL,
        payload: err.response.data,
      });
    }
  };

export const createTradeMethodology =
  (data: { amount: string | number; description: string }) =>
  async (dispatch: Dispatch<tradeMethodologyActions.Action>) => {
    dispatch({
      type: tradeMethodologyActions.TradeMethodologyActionTypes
        .TRADE_METHODOLOGY_LOADING,
    });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.post(`/api/trade-methodologies`, data);

      dispatch({
        type: tradeMethodologyActions.TradeMethodologyActionTypes
          .TRADE_METHODOLOGY_CREATE_SUCCESS,
        payload: {
          methodology: res.data.methodology,
          message: res.data.message,
        },
      });
      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: tradeMethodologyActions.TradeMethodologyActionTypes
          .TRADE_METHODOLOGY_CREATE_FAIL,
        payload: err.response.data,
      });
      toast.error(err.response.data.message);
    }
  };

export const tradeMethodologySetEdit =
  (methodology: ITradeMethodology) =>
  async (dispatch: Dispatch<tradeMethodologyActions.Action>) => {
    dispatch({
      type: tradeMethodologyActions.TradeMethodologyActionTypes
        .TRADE_METHODOLOGY_SET_EDIT,
      payload: methodology,
    });
  };

export const updateTradeMethodology =
  (
    methodology: ITradeMethodology,
    data: { amount: string | number; description: string }
  ) =>
  async (dispatch: Dispatch<tradeMethodologyActions.Action>) => {
    dispatch({
      type: tradeMethodologyActions.TradeMethodologyActionTypes
        .TRADE_METHODOLOGY_LOADING,
    });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.put(
        `/api/trade-methodologies/${methodology._id}`,
        data
      );

      dispatch({
        type: tradeMethodologyActions.TradeMethodologyActionTypes
          .TRADE_METHODOLOGY_UPDATE_SUCCESS,
        payload: {
          methodology: res.data.methodology,
          message: res.data.message,
        },
      });
      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: tradeMethodologyActions.TradeMethodologyActionTypes
          .TRADE_METHODOLOGY_UPDATE_FAIL,
        payload: err.response.data,
      });
      toast.error(err.response.data.message);
    }
  };

export const TradeMethodologySetEditClear =
  () => async (dispatch: Dispatch<tradeMethodologyActions.Action>) => {
    dispatch({
      type: tradeMethodologyActions.TradeMethodologyActionTypes
        .TRADE_METHODOLOGY_SET_EDIT_CLEAR,
    });
  };

export const deleteTradeMethodology =
  (methodology: ITradeMethodology) =>
  async (dispatch: Dispatch<tradeMethodologyActions.Action>) => {
    dispatch({
      type: tradeMethodologyActions.TradeMethodologyActionTypes
        .TRADE_METHODOLOGY_LOADING,
    });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.delete(
        `/api/trade-methodologies/${methodology._id}`
      );

      dispatch({
        type: tradeMethodologyActions.TradeMethodologyActionTypes
          .TRADE_METHODOLOGY_DELETE_SUCCESS,
        payload: { methodology: methodology, message: res.data.message },
      });
      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: tradeMethodologyActions.TradeMethodologyActionTypes
          .TRADE_METHODOLOGY_DELETE_FAIL,
        payload: err.response.data,
      });
      toast.error(err.response.data.message);
    }
  };
