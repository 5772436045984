import { ITag } from '../models/tag.model';

export const mockTagAll = (userId?: string, type = 'file'): ITag => ({
  _id: userId + `all`,
  name: 'All',
  userId: userId ? userId : 'anonymous',
  postId: null,
  fileId: null,
  type: type,
  createdAt: '2022-09-26T14:13:27.918Z',
  updatedAt: '2022-09-26T14:13:27.918Z',
  slug: 'all',
});

export const mockTagLove = (userId?: string, type = 'file'): ITag => ({
  _id: userId + `love`,
  name: 'Love',
  userId: userId ? userId : 'anonymous',
  postId: null,
  fileId: null,
  type: type,
  createdAt: '2022-09-26T14:13:27.918Z',
  updatedAt: '2022-09-26T14:13:27.918Z',
  slug: 'love',
});
