import { Dispatch } from 'redux';
import { useToastInCreator } from '../../hooks';
import { httpClient } from '../../utils/http-client';
import { sessionActions } from '../actions';

export const loadSessions =
  () => async (dispatch: Dispatch<sessionActions.Action>) => {
    dispatch({ type: sessionActions.SessionActionTypes.SESSION_LOADING });

    try {
      const res = await httpClient.get('/api/sessions');

      dispatch({
        type: sessionActions.SessionActionTypes.SESSION_LOAD_SESSIONS_SUCCESS,
        payload: res.data.sessions,
      });
    } catch (err: any) {
      dispatch({
        type: sessionActions.SessionActionTypes.SESSION_LOAD_SESSIONS_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const loadCurrentSession =
  () => async (dispatch: Dispatch<sessionActions.Action>) => {
    dispatch({ type: sessionActions.SessionActionTypes.SESSION_LOADING });

    try {
      const res = await httpClient.get('/api/sessions/me');

      dispatch({
        type: sessionActions.SessionActionTypes
          .SESSION_LOAD_CURRENT_SESSION_SUCCESS,
        payload: res.data.session,
      });
    } catch (err: any) {
      dispatch({
        type: sessionActions.SessionActionTypes
          .SESSION_LOAD_CURRENT_SESSION_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const terminateSessions =
  () => async (dispatch: Dispatch<sessionActions.Action>) => {
    dispatch({ type: sessionActions.SessionActionTypes.SESSION_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.get('/api/sessions/destroy/all');

      dispatch({
        type: sessionActions.SessionActionTypes
          .SESSION_TERMINATE_SESSIONS_SUCCESS,
        payload: res.data.message,
      });
      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: sessionActions.SessionActionTypes.SESSION_TERMINATE_SESSIONS_FAIL,
        payload: err.response.data.message,
      });
      toast.error(err.response.data.message);
    }
  };
