import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Action, Dispatch } from 'redux';

export const useOnceDispatchEffect = (
  effect: (dispatch: Dispatch<Action>) => Promise<void>,
  ...params: any
) => {
  const initialRef = useRef(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!initialRef.current) {
      return;
    }
    initialRef.current = false;
    effect(dispatch);
  }, [dispatch, effect, params]);
};
