export enum ModalActionTypes {
  MODAL_IMAGE_OPEN = 'MODAL_IMAGE_OPEN',
  MODAL_IMAGE_CLOSE = 'MODAL_IMAGE_CLOSE',
}

export interface ModalImageOpenAction {
  readonly type: ModalActionTypes.MODAL_IMAGE_OPEN;
  payload: boolean;
}

export interface ModalImageCloseAction {
  readonly type: ModalActionTypes.MODAL_IMAGE_CLOSE;
}

export type Action = ModalImageOpenAction | ModalImageCloseAction;
