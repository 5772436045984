import React, { useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/app-state';
import { postCreators } from '../../store/creators';
import * as Yup from 'yup';
import { FieldArray, Form, Formik, getIn } from 'formik';
import ActivityEmbed, { IActivityEmbed } from '../activity-embed/ActivityEmbed';
import './activity-embed-preview.css';

interface AutoSubmitProps {
  values: IActivityEmbed[];
  submitForm: () => void;
}

const AutoSubmit: React.FC<AutoSubmitProps> = ({ values, submitForm }) => {
  useEffect(() => {
    submitForm();
  }, [values, submitForm]);

  return null;
};

const validationSchema = Yup.object().shape({
  activities: Yup.array().of(
    Yup.object().shape({
      activity: Yup.string().required('Activity is required'),
      score: Yup.number().required('Score is required'),
    })
  ),
});

type ActivityEmbedPreviewProps = {
  showActivity: boolean;
  setShowActivity: React.Dispatch<React.SetStateAction<boolean>>;
  activites: IActivityEmbed[];
  setActivites: React.Dispatch<React.SetStateAction<IActivityEmbed[]>>;
};

const ActivityEmbedPreview = ({
  showActivity,
  setShowActivity,
  activites,
  setActivites,
}: ActivityEmbedPreviewProps) => {
  const dispatch = useDispatch();

  const postInStore = useSelector((state: AppState) => state.posts);

  const handleClose = () => {
    dispatch(postCreators.showActivity(!postInStore.showActivity));
    setActivites([
      {
        id: uuid(),
        activity: '',
        score: 0,
      },
    ]);
  };

  return (
    <>
      {showActivity && (
        <div className="activity-embed-preview">
          <div className="activity-embed-preview__close" onClick={handleClose}>
            <MdClose />
          </div>
          <div className="activity-embed-preview__body">
            <Formik
              initialValues={{
                activities: activites,
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                setActivites(values.activities);
              }}
            >
              {({
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                isValid,
                submitForm,
              }) => (
                <>
                  <Form noValidate autoComplete="off">
                    <AutoSubmit
                      values={values.activities}
                      submitForm={submitForm}
                    />
                    <FieldArray name="activities">
                      {({ push, remove }) => (
                        <div className="activity-list">
                          {values.activities.map((p, index) => {
                            const activity = `activities[${index}].activity`;
                            const touchedActivity = getIn(touched, activity);
                            const errorActivity = getIn(errors, activity);

                            const score = `activities[${index}].score`;
                            const touchedScore = getIn(touched, score);
                            const errorScore = getIn(errors, score);

                            return (
                              <div key={p.id} className="activity-list-item">
                                <div className="input-activity">
                                  <input
                                    className="activity"
                                    placeholder="Activity"
                                    name={activity}
                                    type="text"
                                    value={p.activity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {touchedActivity && errorActivity && (
                                    <span className="activity-invalid-feedback">
                                      {errorActivity}
                                    </span>
                                  )}
                                </div>
                                <div className="input-score">
                                  <input
                                    className="score"
                                    placeholder="Score"
                                    name={score}
                                    type="number"
                                    max={100}
                                    min={0}
                                    value={p.score}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {touchedScore && errorScore && (
                                    <span className="activity-invalid-feedback">
                                      {errorScore}
                                    </span>
                                  )}
                                </div>
                                <button
                                  className="btn-remove"
                                  type="button"
                                  onClick={() => remove(index)}
                                >
                                  x
                                </button>
                              </div>
                            );
                          })}
                          <button
                            className="btn-add"
                            type="button"
                            onClick={() =>
                              push({
                                id: uuid(),
                                activity: '',
                                score: 0,
                              })
                            }
                          >
                            Add
                          </button>
                        </div>
                      )}
                    </FieldArray>
                    <div className="activity-embed-preview__activity">
                      <ActivityEmbed activities={values.activities} />
                    </div>
                    {/* <button
                      className="activity-btn-submit"
                      type="submit"
                      color="primary"
                      disabled={!isValid || values.activities.length === 0}
                    >
                      submit
                    </button> */}
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default ActivityEmbedPreview;
