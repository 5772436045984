import { Dispatch } from 'redux';
import { httpClient } from '../../utils/http-client';
import { roleActions } from '../actions';

export const loadRoles =
  () => async (dispatch: Dispatch<roleActions.Action>) => {
    dispatch({ type: roleActions.RoleActionTypes.ROLE_LOADING });

    try {
      const res = await httpClient.get('/api/roles');

      dispatch({
        type: roleActions.RoleActionTypes.ROLE_LOADED_SUCCESS,
        payload: res.data.roles,
      });
    } catch (err: any) {
      dispatch({
        type: roleActions.RoleActionTypes.ROLE_LOADED_FAIL,
        payload: err.response.data.message,
      });
    }
  };
