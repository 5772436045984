import { IErrorMessage } from '../../models/errormessage.model';
import { IFriend, IUserFriend } from '../../models/friend.model';

export enum FriendActionTypes {
  FRIEND_LOADING = 'FRIEND_LOADING',
  FRIEND_USERS_LOADED_SUCCESS = 'FRIEND_USERS_LOADED_SUCCESS',
  FRIEND_USERS_LOADED_FAIL = 'FRIEND_USERS_LOADED_FAIL',
  FRIEND_LOADED_SUCCESS = 'FRIEND_LOADED_SUCCESS',
  FRIEND_LOADED_FAIL = 'FRIEND_LOADED_FAIL',
  FRIEND_FOLLOW_SUCCESS = 'FRIEND_FOLLOW_SUCCESS',
  FRIEND_FOLLOW_FAIL = 'FRIEND_FOLLOW_FAIL',
  FRIEND_UNFOLLOW_SUCCESS = 'FRIEND_UNFOLLOW_SUCCESS',
  FRIEND_UNFOLLOW_FAIL = 'FRIEND_UNFOLLOW_FAIL',
}

export interface FriendLoadingAction {
  readonly type: FriendActionTypes.FRIEND_LOADING;
}

export interface FriendUsersLoadedSuccessAction {
  readonly type: FriendActionTypes.FRIEND_USERS_LOADED_SUCCESS;
  payload: IUserFriend[];
}

export interface FriendUsersLoadedFailAction {
  readonly type: FriendActionTypes.FRIEND_USERS_LOADED_FAIL;
  payload: IErrorMessage;
}

export interface FriendLoadedSuccessAction {
  readonly type: FriendActionTypes.FRIEND_LOADED_SUCCESS;
  payload: IFriend[];
}

export interface FriendLoadedFailAction {
  readonly type: FriendActionTypes.FRIEND_LOADED_FAIL;
  payload: IErrorMessage;
}

export interface FriendFollowSuccessAction {
  readonly type: FriendActionTypes.FRIEND_FOLLOW_SUCCESS;
  payload: { following: IFriend; follower: IFriend };
}

export interface FriendFollowFailAction {
  readonly type: FriendActionTypes.FRIEND_FOLLOW_FAIL;
  payload: IErrorMessage;
}

export interface FriendUnfollowSuccessAction {
  readonly type: FriendActionTypes.FRIEND_UNFOLLOW_SUCCESS;
  payload: { following: IFriend; follower: IFriend };
}

export interface FriendUnfollowFailAction {
  readonly type: FriendActionTypes.FRIEND_UNFOLLOW_FAIL;
  payload: IErrorMessage;
}

export type Action =
  | FriendLoadingAction
  | FriendUsersLoadedSuccessAction
  | FriendUsersLoadedFailAction
  | FriendLoadedSuccessAction
  | FriendLoadedFailAction
  | FriendFollowSuccessAction
  | FriendFollowFailAction
  | FriendUnfollowSuccessAction
  | FriendUnfollowFailAction;
