import { IErrorMessage } from '../../models/errormessage.model';
import { IChatMessage } from '../../models/chatmessage.model';

export enum MessageActionTypes {
  MESSAGE_LOADING = 'MESSAGE_LOADING',
  MESSAGE_LOADED_SUCCESS = 'MESSAGE_LOADED_SUCCESS',
  MESSAGE_LOAD_LOADING = 'MESSAGE_LOAD_LOADING',
  MESSAGE_LOADED_FAIL = 'MESSAGE_LOADED_FAIL',
  MESSAGE_CREATE_SUCCESS = 'MESSAGE_CREATE_SUCCESS',
  MESSAGE_CREATE_FAIL = 'MESSAGE_CREATE_FAIL',
  MESSAGE_RECEIVED = 'MESSAGE_RECEIVED',
}

export interface MessageLoadingAction {
  readonly type: MessageActionTypes.MESSAGE_LOADING;
}

export interface MessageLoadLoadingAction {
  readonly type: MessageActionTypes.MESSAGE_LOAD_LOADING;
}

export interface MessageLoadedSuccessAction {
  readonly type: MessageActionTypes.MESSAGE_LOADED_SUCCESS;
  payload: IChatMessage[];
}

export interface MessageLoadedFailAction {
  readonly type: MessageActionTypes.MESSAGE_LOADED_FAIL;
  payload: IErrorMessage;
}

export interface MessageCreateSuccessAction {
  readonly type: MessageActionTypes.MESSAGE_CREATE_SUCCESS;
  payload: { newMessage: IChatMessage; message: string };
}

export interface MessageCreateFailAction {
  readonly type: MessageActionTypes.MESSAGE_CREATE_FAIL;
  payload: IErrorMessage;
}

export interface MessageReceivedAction {
  readonly type: MessageActionTypes.MESSAGE_RECEIVED;
  payload: IChatMessage;
}

export type Action =
  | MessageLoadingAction
  | MessageLoadLoadingAction
  | MessageLoadedSuccessAction
  | MessageLoadedFailAction
  | MessageCreateSuccessAction
  | MessageCreateFailAction
  | MessageReceivedAction;
