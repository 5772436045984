import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  FaRegPaperPlane,
  FaRegCommentDots,
  FaCloudUploadAlt,
  FaUser,
  FaCog,
  FaSignOutAlt,
  FaSearch,
  FaImages,
} from 'react-icons/fa';
import { CgReorder } from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../images/logo.png';
import logoSmall from '../../images/logo-sm.png';
import './navbar.css';
import { AppState } from '../../store/app-state';
import { authCreators } from '../../store/creators';
import { BiBookBookmark } from 'react-icons/bi';
import { TbDeviceAnalytics } from 'react-icons/tb';

const Navbar = () => {
  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.auth.user);

  const logout = () => {
    dispatch(authCreators.logoutUser());
  };

  return (
    <div className="navbar">
      <div className="navbar__wrapper">
        <NavLink className="navbar__logo" to="/">
          <img src={logo} className="logo" alt="" />
          <img src={logoSmall} className="logo-sm" alt="" />
        </NavLink>
        <div className="search-bar-wrapper">
          <form className="search-bar">
            <input
              type="search"
              placeholder="Search accounts and images"
              name="search"
              autoComplete="off"
              className="search-input"
            />
            <span className="search-line" />
            <button type="submit" className="search-button">
              <FaSearch className="icon" />
            </button>
          </form>
        </div>
        <div className="navbar__menu">
          {user ? (
            <>
              <div className="navbar__menu--icon">
                <NavLink to="/upload">
                  <FaCloudUploadAlt className="icon" />
                </NavLink>
                <span className="tooltiptext tooltip-bottom">
                  Upload images
                </span>
              </div>
              <div className="navbar__menu--icon">
                <NavLink to="/blog">
                  <BiBookBookmark className="icon" />
                </NavLink>
                <span className="tooltiptext tooltip-bottom">Blog</span>
              </div>
              <div className="navbar__menu--icon">
                <NavLink to="/images">
                  <FaImages className="icon" />
                </NavLink>
                <span className="tooltiptext tooltip-bottom">Images</span>
              </div>
              <div className="navbar__menu--icon">
                <NavLink to="/trades">
                  <TbDeviceAnalytics className="icon" />
                </NavLink>
                <span className="tooltiptext tooltip-bottom">Trade</span>
              </div>
              <div className="navbar__menu--icon">
                <NavLink to="/message">
                  <FaRegPaperPlane className="icon" />
                </NavLink>
                <span className="tooltiptext tooltip-bottom">Message</span>
              </div>
              <div className="navbar__menu--icon">
                <NavLink to="/inbox">
                  <FaRegCommentDots className="icon" />
                </NavLink>
                <span className="tooltiptext tooltip-bottom">Inbox</span>
              </div>

              <div className="navbar__menu--icon">
                <NavLink to="/profile">
                  <img src={user?.avartar} alt={user?.username} />
                </NavLink>
                <span className="tooltiptext-profile tooltiptext-profile-bottom">
                  <div className="tooltiptext-profile-list">
                    <Link
                      to="/profile"
                      className="tooltiptext-profile-list-item"
                    >
                      <FaUser className="icon" /> View profile
                    </Link>
                    <Link
                      to="/re-order"
                      className="tooltiptext-profile-list-item"
                    >
                      <CgReorder className="icon" /> Re Order
                    </Link>
                    <Link
                      to="/settings"
                      className="tooltiptext-profile-list-item"
                    >
                      <FaCog className="icon" /> Settings
                    </Link>
                    <Link
                      to="/"
                      className="tooltiptext-profile-list-item"
                      onClick={logout}
                    >
                      <FaSignOutAlt className="icon" /> Log out
                    </Link>
                  </div>
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="navbar__menu--text">
                <NavLink to="/upload">Upload</NavLink>
              </div>
              <div className="navbar__menu--text">
                <NavLink to="/welcome" className="btn-nav">
                  Login
                </NavLink>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
