import { ITradeMethodology } from '../../models/trademedology.model';
import { tradeMethodologyActions } from '../actions';

export interface TradeMethodologyState {
  isLoading: boolean;
  methodologies: ITradeMethodology[] | [];
  groups: ITradeMethodology[] | [];
  types: ITradeMethodology[] | [];
  instruments: ITradeMethodology[] | [];
  setups: ITradeMethodology[] | [];
  edit: boolean;
  current: ITradeMethodology | null;
  message: string | null;
}

const initialState: TradeMethodologyState = {
  isLoading: false,
  methodologies: [],
  groups: [],
  types: [],
  instruments: [],
  setups: [],
  edit: false,
  current: null,
  message: null,
};

export const tradeMethodologyReducer = (
  state: TradeMethodologyState = initialState,
  action: tradeMethodologyActions.Action
): TradeMethodologyState => {
  switch (action.type) {
    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_LOADED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        methodologies: action.payload,
      };

    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_GROUP_LOADED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groups: action.payload,
      };

    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_TYPE_LOADED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        types: action.payload,
      };

    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_TYPE_INSTRUMENT_UNIQUE_LOADED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        instruments: action.payload,
      };

    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_TYPE_SETUP_UNIQUE_LOADED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        setups: action.payload,
      };

    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        methodologies: [...state.methodologies, action.payload.methodology],
        message: action.payload.message,
      };

    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_SET_EDIT:
      return {
        ...state,
        isLoading: false,
        edit: true,
        current: action.payload,
      };

    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_SET_EDIT_CLEAR:
      return {
        ...state,
        isLoading: false,
        edit: false,
        current: null,
      };

    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        methodologies: state.methodologies.map((m) => {
          if (m._id === action.payload.methodology._id) {
            let newMethodology = action.payload.methodology;
            return (m = newMethodology);
          }
          return m;
        }),
        edit: false,
        current: null,
        message: action.payload.message,
      };

    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        methodologies: state.methodologies.filter(
          (m) => m._id !== action.payload.methodology._id
        ),
        message: action.payload.message,
      };

    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_LOADED_FAIL:
    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_GROUP_LOADED_FAIL:
    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_TYPE_LOADED_FAIL:
    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_TYPE_INSTRUMENT_UNIQUE_LOADED_FAIL:
    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_TYPE_SETUP_UNIQUE_LOADED_FAIL:
    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_CREATE_FAIL:
    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_UPDATE_FAIL:
    case tradeMethodologyActions.TradeMethodologyActionTypes
      .TRADE_METHODOLOGY_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
