const set = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const get = (key: string) => {
  if (localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key) || '{}');
  }
  return localStorage.getItem(key);
};

const remove = (key: string) => {
  localStorage.removeItem(key);
};

const clear = () => {
  localStorage.clear();
};

export const customeLocalStorage = { set, get, remove, clear };
