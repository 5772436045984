import React from 'react';
import './text-card.css';

export interface ITextCard {
  text: string;
  color: string;
}

type TextCardProps = {
  textCard: ITextCard;
  style?: React.CSSProperties;
};

const TextCard = ({ textCard, style }: TextCardProps) => {
  return (
    <div className="text-card-responsive">
      <div className="text-card" style={{ backgroundColor: textCard.color }}>
        <h1 style={style}>{textCard.text}</h1>
      </div>
    </div>
  );
};

export default TextCard;
