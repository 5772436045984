import { IErrorMessage } from '../../models/errormessage.model';
import { IRole } from '../../models/role.model';

export enum RoleActionTypes {
  ROLE_LOADING = 'ROLE_LOADING',
  ROLE_LOADED_SUCCESS = 'ROLE_LOADED_SUCCESS',
  ROLE_LOADED_FAIL = 'ROLE_LOADED_FAIL',
}

export interface RoleLoadingAction {
  readonly type: RoleActionTypes.ROLE_LOADING;
}

export interface RoleLoadedSuccessAction {
  readonly type: RoleActionTypes.ROLE_LOADED_SUCCESS;
  payload: IRole[];
}

export interface RoleLoadedFailAction {
  readonly type: RoleActionTypes.ROLE_LOADED_FAIL;
  payload: IErrorMessage;
}

export type Action =
  | RoleLoadingAction
  | RoleLoadedSuccessAction
  | RoleLoadedFailAction;
