import { Dispatch } from 'redux';
import { modalActions } from '../actions';

export const modalImageOpen =
  (open: boolean = true) =>
  async (dispatch: Dispatch<modalActions.Action>) => {
    dispatch({
      type: modalActions.ModalActionTypes.MODAL_IMAGE_OPEN,
      payload: open,
    });
  };

export const modalImageClose =
  () => async (dispatch: Dispatch<modalActions.Action>) => {
    dispatch({
      type: modalActions.ModalActionTypes.MODAL_IMAGE_CLOSE,
    });
  };
