import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BlogCreatePost from '../../components/blog-create-post/BlogCreatePost';
import BlogPost from '../../components/blog-post/BlogPost';
import BlogMenu from '../../components/blog-menu/BlogMenu';
import BlogTags from '../../components/blog-tags/BlogTags';
import BlogInfo from '../../components/blog-info/BlogInfo';
import './blog.css';
import { useOnceDispatchEffect, useWindowScrollPositions } from '../../hooks';
import {
  postCreators,
  routeCreators,
  scrollbarCreators,
  tagCreators,
} from '../../store/creators';
import InfiniteLoading from '../../components/infinite-loading/InfiniteLoading';
import { AppState } from '../../store/app-state';

const Blog = () => {
  const dispatch = useDispatch();

  const posts = useSelector((state: AppState) => state.posts.posts);
  const isLoading = useSelector((state: AppState) => state.posts.isLoading);
  const page = useSelector((state: AppState) => state.srollbar.pageBlog);
  const routeGoBack = useSelector(
    (state: AppState) => state.routes.routeGoBack
  );
  const scrollPosition = useSelector((state: AppState) => state.srollbar);

  useOnceDispatchEffect(postCreators.loadPosts());
  useOnceDispatchEffect(tagCreators.loadPostsTags());

  useOnceDispatchEffect(routeCreators.routeSet('/blog'));
  useOnceDispatchEffect(
    scrollbarCreators.setPositionScollbar(
      scrollPosition.positionX,
      scrollPosition.positionY
    )
  );

  const { scrollX, scrollY } = useWindowScrollPositions();

  useEffect(() => {
    // if (blogPath === 'blog') {
    dispatch(scrollbarCreators.trackingScollbar(scrollX, scrollY));
    // }
  }, [dispatch, scrollX, scrollY]);

  useEffect(() => {
    if (page === 1 && routeGoBack !== '/blog') {
      dispatch(scrollbarCreators.setPositionScollbar(0, 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop <
        Math.floor(document.documentElement.offsetHeight * 0.8) ||
      page * 12 > posts.length ||
      isLoading
    ) {
      return;
    }
    dispatch(scrollbarCreators.setPage('blog', page + 1));
  }, [dispatch, isLoading, page, posts.length]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const visiblePosts = useMemo(() => posts.slice(0, page * 5), [page, posts]);

  return (
    <div className="blog">
      <div className="blog-container">
        <div className="blog-wrapper">
          <div className="blog-content__left">
            <BlogMenu />
          </div>
          <div className="blog-content__center">
            <div className="blog-content__center-container">
              <BlogCreatePost />
              <div className="posts">
                {isLoading && posts.length === 0 && page === 1 ? (
                  <InfiniteLoading absolute />
                ) : (
                  visiblePosts.map((post) => (
                    <BlogPost post={post} key={post._id} />
                  ))
                )}
                {isLoading && page !== 1 && <InfiniteLoading />}
              </div>
            </div>
          </div>
          <div className="blog-content__right">
            <div className="blog-content__right-container">
              <h3>Discover</h3>
              <BlogTags />
              <h3>Info</h3>
              <BlogInfo />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
