import styled from 'styled-components';

export const EditContainer = styled.div`
  padding: 0px 32px 0px;
  position: relative;

  .content {
    padding: 22px 0px 15px;

    .image-upload-container {
      width: 100%;
      margin-bottom: 50px;
    }

    .input-container {
      width: 100%;
      height: 50px;
      margin-bottom: 20px;
    }

    .select-container {
      width: 100%;
      height: 50px;
    }

    .btn-update-container {
      width: 100%;
      height: 20px;
      padding-top: 50px;

      @media (max-width: 768px) {
        padding-top: 25px;
      }

      .btn-update,
      .btn-cancel {
        padding: 10px 20px;
        float: right;
        margin-left: 5px;
        cursor: pointer;
        border-radius: 5px;
        border-color: transparent;
      }

      .btn-update {
        background-color: #ffe08a;
        color: rgba(0, 0, 0, 0.7);

        &:hover {
          background-color: #ffdc7d;
          transition: background-color 0.3s ease-out;
        }
      }

      .btn-success {
        background-color: #48c78e;
        color: #fff;

        &:hover {
          background-color: #3ec487;
          transition: background-color 0.3s ease-out;
        }
      }

      .btn-cancel {
        background-color: #f5f5f5;
        color: rgba(0, 0, 0, 0.7);

        &:hover {
          background-color: #eee;
          transition: background-color 0.3s ease-out;
        }
      }
    }
  }
`;
