import { Dispatch } from 'redux';
import { httpClient } from '../../utils/http-client';
import { tagActions } from '../actions';

export const loadPostsTags =
  () => async (dispatch: Dispatch<tagActions.Action>) => {
    dispatch({ type: tagActions.TagActionTypes.TAG_LOADING });

    try {
      const res = await httpClient.get('/api/tags/unique/posts/tags');

      dispatch({
        type: tagActions.TagActionTypes.TAG_POSTS_LOADED_SUCCESS,
        payload: res.data.tags,
      });
    } catch (err: any) {
      dispatch({
        type: tagActions.TagActionTypes.TAG_POSTS_LOADED_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const loadFilesTags =
  () => async (dispatch: Dispatch<tagActions.Action>) => {
    dispatch({ type: tagActions.TagActionTypes.TAG_LOADING });

    try {
      const res = await httpClient.get('/api/tags/unique/files/tags');

      dispatch({
        type: tagActions.TagActionTypes.TAG_FILES_LOADED_SUCCESS,
        payload: res.data.tags,
      });
    } catch (err: any) {
      dispatch({
        type: tagActions.TagActionTypes.TAG_FILES_LOADED_FAIL,
        payload: err.response.data.message,
      });
    }
  };
