import { Dispatch } from 'redux';
import { IPost } from '../../models/post.model';
import { IUser } from '../../models/user.model';
import { httpClient } from '../../utils/http-client';
import { postActions } from '../actions';

export const loadPosts =
  () => async (dispatch: Dispatch<postActions.Action>) => {
    dispatch({ type: postActions.PostActionTypes.POST_LOADING });

    try {
      const res = await httpClient.get('/api/posts');

      dispatch({
        type: postActions.PostActionTypes.POST_LOADED_SUCCESS,
        payload: { posts: res.data.posts, available: res.data.available },
      });
    } catch (err: any) {
      dispatch({
        type: postActions.PostActionTypes.POST_LOADED_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const toggleModal =
  () => async (dispatch: Dispatch<postActions.Action>) => {
    dispatch({
      type: postActions.PostActionTypes.POST_MODAL_TOGGLE,
    });
  };

export const showUpload =
  (state: boolean) => async (dispatch: Dispatch<postActions.Action>) => {
    dispatch({
      type: postActions.PostActionTypes.POST_UPLOAD_SHOW_OR_HIDE,
      payload: state,
    });
  };

export const showVideo =
  (state: boolean) => async (dispatch: Dispatch<postActions.Action>) => {
    dispatch({
      type: postActions.PostActionTypes.POST_VIDEO_SHOW_OR_HIDE,
      payload: state,
    });
  };

export const showActivity =
  (state: boolean) => async (dispatch: Dispatch<postActions.Action>) => {
    dispatch({
      type: postActions.PostActionTypes.POST_ACTIVITY_SHOW_OR_HIDE,
      payload: state,
    });
  };

export const createPost =
  (data: FormData) => async (dispatch: Dispatch<postActions.Action>) => {
    dispatch({ type: postActions.PostActionTypes.POST_LOADING });

    try {
      const res = await httpClient.post('/api/posts', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({
        type: postActions.PostActionTypes.POST_CREATE_SUCCESS,
        payload: { post: res.data.post, message: res.data.message },
      });
    } catch (err: any) {
      dispatch({
        type: postActions.PostActionTypes.POST_CREATE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const setEditPost =
  (current: IPost, edit: boolean = true) =>
  async (dispatch: Dispatch<postActions.Action>) => {
    dispatch({
      type: postActions.PostActionTypes.POST_SET_EDIT,
      payload: { current: current, edit: edit },
    });
  };

export const updatePost =
  (postId: string | number, data: FormData) =>
  async (dispatch: Dispatch<postActions.Action>) => {
    dispatch({ type: postActions.PostActionTypes.POST_LOADING });

    try {
      const res = await httpClient.put(`/api/posts/${postId}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({
        type: postActions.PostActionTypes.POST_UPDATE_SUCCESS,
        payload: { post: res.data.post, message: res.data.message },
      });
    } catch (err: any) {
      dispatch({
        type: postActions.PostActionTypes.POST_UPDATE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const clearEditPost =
  () => async (dispatch: Dispatch<postActions.Action>) => {
    dispatch({
      type: postActions.PostActionTypes.POST_CLEAR_EDIT,
    });
  };

export const deletePost =
  (post: IPost) => async (dispatch: Dispatch<postActions.Action>) => {
    dispatch({ type: postActions.PostActionTypes.POST_LOADING });

    try {
      const res = await httpClient.delete(`/api/posts/${post._id}`);

      dispatch({
        type: postActions.PostActionTypes.POST_DELETE_SUCCESS,
        payload: { post: post, message: res.data.message },
      });
    } catch (err: any) {
      dispatch({
        type: postActions.PostActionTypes.POST_DELETE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const deleteFileFromPost =
  (post: IPost, fileId: string) =>
  async (dispatch: Dispatch<postActions.Action>) => {
    dispatch({ type: postActions.PostActionTypes.POST_LOADING });

    try {
      const res = await httpClient.delete(`/api/files/${fileId}`);

      dispatch({
        type: postActions.PostActionTypes.POST_DELETE_FILE_SUCCESS,
        payload: {
          post: post,
          fileId: fileId,
          message: res.data.message,
        },
      });
    } catch (err: any) {
      dispatch({
        type: postActions.PostActionTypes.POST_DELETE_FILE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const postFilter =
  (tag: string, user: IUser | null) =>
  async (dispatch: Dispatch<postActions.Action>) => {
    dispatch({
      type: postActions.PostActionTypes.POST_FILTER,
      payload: { tag, user },
    });
  };

export const postClickLikeOrUnlike =
  (post: IPost, user: IUser | null, status: boolean) =>
  async (dispatch: Dispatch<postActions.Action>) => {
    dispatch({ type: postActions.PostActionTypes.POST_LOADING });

    try {
      const res = await httpClient.get(`/api/posts/${post._id}/like`);

      if (status) {
        dispatch({
          type: postActions.PostActionTypes.POST_CLICK_UNLIKE,
          payload: { post, unlike: res.data.unlike, message: res.data.message },
        });
      } else {
        dispatch({
          type: postActions.PostActionTypes.POST_CLICK_LIKE,
          payload: { post, like: res.data.like, message: res.data.message },
        });
      }
    } catch (err: any) {
      dispatch({
        type: postActions.PostActionTypes.POST_CLICK_LIKE_OR_UNLIKE_ERROR,
        payload: err.response.data.message,
      });
    }
  };

export const postClickPinedOrUnPind =
  (post: IPost) => async (dispatch: Dispatch<postActions.Action>) => {
    dispatch({ type: postActions.PostActionTypes.POST_LOADING });

    try {
      const res = await httpClient.get(`/api/posts/${post._id}/pin`);

      dispatch({
        type: postActions.PostActionTypes.POST_CLICK_PINED_OR_UNPINED_SUCCESS,
        payload: { post: res.data.post, message: res.data.message },
      });
    } catch (err: any) {
      dispatch({
        type: postActions.PostActionTypes.POST_CLICK_PINED_OR_UNPINED_FAIL,
        payload: err.response.data,
      });
    }
  };
