import { IErrorMessage } from '../../models/errormessage.model';
import { IUser } from '../../models/user.model';

export enum UserActionTypes {
  USER_LOADING = 'USER_LOADING',
  USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS',
  USER_LOADED_FAIL = 'USER_LOADED_FAIL',
  USER_SET_EDIT = 'USER_SET_EDIT',
  USER_CLEAR_EDIT = 'USER_CLEAR_EDIT',
  USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS',
  USER_UPDATE_FAIL = 'USER_UPDATE_FAIL',
}

export interface UserLoadingAction {
  readonly type: UserActionTypes.USER_LOADING;
}

export interface UserLoadedSuccessAction {
  readonly type: UserActionTypes.USER_LOADED_SUCCESS;
  payload: IUser[];
}

export interface UserLoadedFailAction {
  readonly type: UserActionTypes.USER_LOADED_FAIL;
  payload: IErrorMessage;
}

export interface UserSetEditAction {
  readonly type: UserActionTypes.USER_SET_EDIT;
  payload: { current: IUser; edit: boolean };
}

export interface PostClearEditAction {
  readonly type: UserActionTypes.USER_CLEAR_EDIT;
}

export interface UserUpdateSuccessAction {
  readonly type: UserActionTypes.USER_UPDATE_SUCCESS;
  payload: { user: IUser; message: string };
}

export interface UserUpdateFailAction {
  readonly type: UserActionTypes.USER_UPDATE_FAIL;
  payload: IErrorMessage;
}

export type Action =
  | UserLoadingAction
  | UserLoadedSuccessAction
  | UserLoadedFailAction
  | UserSetEditAction
  | PostClearEditAction
  | UserUpdateSuccessAction
  | UserUpdateFailAction;
