export function numToColor(a: number) {
  switch (a) {
    case 0:
      return '#00B8D9';
    case 1:
      return '#0052CC';
    case 2:
      return '#5243AA';
    case 3:
      return '#FF5630';
    case 4:
      return '#FF8B00';
    case 5:
      return '#FFC400';
    case 6:
      return '#36B37E';
    case 7:
      return '#00875A';
    case 8:
      return '#253858';
    case 9:
      return '#FF0000';
    case 10:
      return '#00FF00';
    case 11:
      return '#0000FF';
    case 12:
      return '#FFFF00';
    case 13:
      return '#FF00FF';
    case 14:
      return '#00FFFF';
    case 15:
      return '#000000';
    case 16:
      return '#FFA500';
    case 17:
      return '#800080';
    case 18:
      return '#008000';
    case 19:
      return '#FFC0CB';
    case 20:
      return '#FFD700';
    case 21:
      return '#008080';
    case 22:
      return '#800000';
    case 23:
      return '#808080';
    case 24:
      return '#FF4500';
    case 25:
      return '#4B0082';
    case 26:
      return '#2E8B57';
    case 27:
      return '#FF6347';
    case 28:
      return '#6A5ACD';
    case 29:
      return '#20B2AA';
    case 30:
      return '#00CED1';
    case 31:
      return '#32CD32';
    case 32:
      return '#FF8C00';
    case 33:
      return '#ADFF2F';
    case 34:
      return '#FF69B4';
    case 35:
      return '#7B68EE';
    case 36:
      return '#9370DB';
    case 37:
      return '#DB7093';
    case 38:
      return '#8A2BE2';
    case 39:
      return '#00FA9A';
    case 40:
      return '#66CDAA';
    case 41:
      return '#FF1493';
    case 42:
      return '#1E90FF';
    case 43:
      return '#8B008B';
    case 44:
      return '#556B2F';
    case 45:
      return '#FFDAB9';
    case 46:
      return '#FF4500';
    case 47:
      return '#8B4513';
    case 48:
      return '#8B0000';
    case 49:
      return '#483D8B';
    case 50:
      return '#228B22';
    case 51:
      return '#7FFF00';
    case 52:
      return '#A0522D';
    case 53:
      return '#B8860B';
    case 54:
      return '#32CD32';
    case 55:
      return '#2F4F4F';
    case 56:
      return '#8B0000';
    case 57:
      return '#191970';
    case 58:
      return '#7CFC00';
    case 59:
      return '#FF00FF';
    case 60:
      return '#FF8C00';
    case 61:
      return '#6B8E23';
    case 62:
      return '#F08080';
    case 63:
      return '#4682B4';
    case 64:
      return '#D2691E';
    case 65:
      return '#8FBC8F';
    case 66:
      return '#9932CC';
    case 67:
      return '#DC143C';
    case 68:
      return '#40E0D0';
    case 69:
      return '#2E8B57';
    case 70:
      return '#A52A2A';
    case 71:
      return '#DB7093';
    case 72:
      return '#BC8F8F';
    case 73:
      return '#ADFF2F';
    case 74:
      return '#4B0082';
    case 75:
      return '#BA55D3';
    case 76:
      return '#228B22';
    case 77:
      return '#9400D3';
    case 78:
      return '#8B4513';
    case 79:
      return '#8A2BE2';
    case 80:
      return '#FF69B4';
    case 81:
      return '#20B2AA';
    case 82:
      return '#D2B48C';
    case 83:
      return '#FFD700';
    case 84:
      return '#FF6347';
    case 85:
      return '#66CDAA';
    case 86:
      return '#483D8B';
    case 87:
      return '#3CB371';
    case 88:
      return '#FF7F50';
    case 89:
      return '#CD5C5C';
    case 90:
      return '#48D1CC';
    case 91:
      return '#800080';
    case 92:
      return '#9370DB';
    case 93:
      return '#ADFF2F';
    case 94:
      return '#32CD32';
    case 95:
      return '#FF4500';
    case 96:
      return '#6A5ACD';
    case 97:
      return '#2E8B57';
    case 98:
      return '#FF6347';
    case 99:
      return '#4B0082';
    case 100:
      return '#20B2AA';
    case 101:
      return '#00CED1';
    case 102:
      return '#32CD32';
    case 103:
      return '#FF8C00';
    case 104:
      return '#ADFF2F';
    case 105:
      return '#FF69B4';
    case 106:
      return '#7B68EE';
    case 107:
      return '#9370DB';
    default:
      return '#666666';
  }
}
