import { IErrorMessage } from '../../models/errormessage.model';
import { IBackup } from '../../models/backup.model';

export enum BackupActionTypes {
  BACKUP_LOADING = 'BACKUP_LOADING',
  BACKUP_LOADED_SUCCESS = 'BACKUP_LOADED_SUCCESS',
  BACKUP_LOADED_FAIL = 'BACKUP_LOADED_FAIL',
  BACKUP_CREATE_SUCCESS = 'BACKUP_CREATE_SUCCESS',
  BACKUP_CREATE_FAIL = 'BACKUP_CREATE_FAIL',
  BACKUP_DELETE_SUCCESS = 'BACKUP_DELETE_SUCCESS',
  BACKUP_DELETE_FAIL = 'BACKUP_DELETE_FAIL',
}

export interface BackupLoadingAction {
  readonly type: BackupActionTypes.BACKUP_LOADING;
}

export interface BackupLoadedSuccessAction {
  readonly type: BackupActionTypes.BACKUP_LOADED_SUCCESS;
  payload: IBackup[];
}

export interface BackupLoadedFailAction {
  readonly type: BackupActionTypes.BACKUP_LOADED_FAIL;
  payload: IErrorMessage;
}

export interface BackupCreateSuccessAction {
  readonly type: BackupActionTypes.BACKUP_CREATE_SUCCESS;
  payload: { backup: IBackup; message: string };
}

export interface BackupCreateFailAction {
  readonly type: BackupActionTypes.BACKUP_CREATE_FAIL;
  payload: IErrorMessage;
}

export interface BackupDeleteSuccessAction {
  readonly type: BackupActionTypes.BACKUP_DELETE_SUCCESS;
  payload: { backup: IBackup; message: string };
}

export interface BackupDeleteFailAction {
  readonly type: BackupActionTypes.BACKUP_DELETE_FAIL;
  payload: IErrorMessage;
}

export type Action =
  | BackupLoadingAction
  | BackupLoadedSuccessAction
  | BackupLoadedFailAction
  | BackupCreateSuccessAction
  | BackupCreateFailAction
  | BackupDeleteSuccessAction
  | BackupDeleteFailAction;
