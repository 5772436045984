import React from 'react';
import { FaExclamation } from 'react-icons/fa';
import Popup from '../popup/Popup';
import './confirm-popup.css';

type ConfirmPopupProps = {
  showConfirmPopup: boolean;
  setShowConfirmPopup: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirmation: () => void;
  handleClose?: () => void;
  title?: string;
  description?: string;
  submitBtnName?: string;
  cancelBtnName?: string;
};

const ConfirmPopup = ({
  showConfirmPopup,
  setShowConfirmPopup,
  handleConfirmation,
  handleClose,
  title = 'Are you sure?',
  description = "You won't be able to revert this!",
  submitBtnName = 'Yes, delete it!',
  cancelBtnName = 'Cancel',
}: ConfirmPopupProps) => {
  return (
    <Popup
      showPopup={showConfirmPopup}
      setShowPopup={setShowConfirmPopup}
      handleClose={handleClose}
    >
      <div className="confirm-popup">
        <div className="icon-container">
          <FaExclamation className="icon" />
        </div>
        <h2>{title}</h2>
        <h4>{description}</h4>
        <div className="btns">
          <button
            className="btn-delete"
            onClick={() => {
              handleConfirmation();
              setShowConfirmPopup(!showConfirmPopup);
              handleClose && handleClose();
            }}
          >
            {submitBtnName}
          </button>
          <button
            className="btn-cancel"
            onClick={() => {
              setShowConfirmPopup(!showConfirmPopup);
              handleClose && handleClose();
            }}
          >
            {cancelBtnName}
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default ConfirmPopup;
