import React, { forwardRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import Select, {
  ActionMeta,
  OnChangeValue,
  SelectInstance,
  SingleValue,
  StylesConfig,
  Theme,
} from 'react-select';
import { ITag, TagOption } from '../../models/tag.model';
import Color from 'color';
import { numToColor } from '../../utils/color';
import { ITradeMethodology } from '../../models/trademedology.model';

type CreatableAndDefaultValueProps = {
  name?: string;
  id?: string;
  options: ITradeMethodology[];
  defaultValue: ITag[];
  value?: SingleValue<TagOption>;
  onChange?: (
    newValue: OnChangeValue<TagOption, false>,
    actionMeta: ActionMeta<TagOption>
  ) => void;
  onBlur?: () => void;
  placeholder?: React.ReactNode;
  noOptionsMessage?:
    | ((obj: { inputValue: string }) => React.ReactNode)
    | undefined;
  selectOnly?: boolean;
};

export type DiffHandleProps = {
  start: () => void;
};

const customTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 5,
  borderSize: 5,
  colors: {
    ...theme.colors,
    // primary25: 'hotpink',
    primary: '#bbb',
  },
});

const colourStyles: StylesConfig<TagOption, false> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, props) => {
    const color = Color(props.data.color ? props.data.color : '#ddd');
    return {
      ...styles,
      backgroundColor: props.isDisabled
        ? undefined
        : props.isSelected
        ? props.data.color
        : props.isFocused
        ? color.alpha(0.1).lighten(0.5).hsl().string()
        : undefined,
      color: props.isDisabled
        ? '#ccc'
        : props.isSelected
        ? color.contrast(Color('white')) > 2
          ? 'white'
          : 'black'
        : props.data.color,
      cursor: props.isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !props.isDisabled
          ? props.isSelected
            ? props.data.color
            : color.alpha(0.3).lighten(0.5).hsl().string()
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = Color(data.color ? data.color : '#ddd');
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).lighten(0.5).hsl().string(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: Color(data.color ? data.color : '#ddd')
        .lighten(0.5)
        .hex(),
      color: '#eee',
    },
  }),
};

const SelectAndCreateTag = forwardRef<
  SelectInstance<TagOption, false>,
  CreatableAndDefaultValueProps
>(
  (
    {
      name,
      id,
      options,
      value,
      defaultValue,
      onChange,
      onBlur,
      placeholder,
      noOptionsMessage,
      selectOnly,
    },
    ref
  ) => {
    const tagOptions: readonly TagOption[] = defaultValue.map(function (
      tag: ITag,
      i: number
    ) {
      return {
        value: tag.name,
        label: tag.name,
        color: numToColor(i),
        score: tag.score,
      };
    });

    const systemTagsOptions: readonly TagOption[] = options.map(function (
      tag: ITradeMethodology,
      i: number
    ) {
      return {
        value: tag.name,
        label: tag.name,
        color: numToColor(i),
        score: tag.score,
      };
    });

    return (
      <>
        {selectOnly ? (
          <Select<TagOption, false>
            name={name}
            id={id}
            isClearable={true}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={tagOptions}
            value={value}
            options={systemTagsOptions}
            placeholder={placeholder}
            noOptionsMessage={noOptionsMessage}
            theme={customTheme}
            styles={colourStyles}
            isSearchable
            autoFocus
            ref={ref}
          />
        ) : (
          <CreatableSelect<TagOption, false>
            name={name}
            id={id}
            isClearable={true}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={tagOptions}
            value={value}
            options={systemTagsOptions}
            placeholder={placeholder}
            noOptionsMessage={noOptionsMessage}
            theme={customTheme}
            styles={colourStyles}
            isSearchable
            autoFocus
            ref={ref}
          />
        )}
      </>
    );
  }
);

export default SelectAndCreateTag;
