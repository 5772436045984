import { ILike } from '../../models/like.model';
import { IErrorMessage } from '../../models/errormessage.model';
import { IPost } from '../../models/post.model';
import { IUser } from '../../models/user.model';

export enum PostActionTypes {
  POST_LOADING = 'POST_LOADING',
  POST_LOADED_SUCCESS = 'POST_LOADED_SUCCESS',
  POST_LOADED_FAIL = 'POST_LOADED_FAIL',
  POST_MODAL_TOGGLE = 'POST_MODAL_TOGGLE',
  POST_UPLOAD_SHOW_OR_HIDE = 'POST_UPLOAD_SHOW/HIDE',
  POST_VIDEO_SHOW_OR_HIDE = 'POST_VIDEO_SHOW/HIDE',
  POST_ACTIVITY_SHOW_OR_HIDE = 'POST_ACTIVITY_SHOW/HIDE',
  POST_CREATE_SUCCESS = 'POST_CREATE_SUCCESS',
  POST_CREATE_FAIL = 'POST_CREATE_FAIL',
  POST_DELETE_SUCCESS = 'POST_DELETE_SUCCESS',
  POST_DELETE_FAIL = 'POST_DELETE_FAIL',
  POST_SET_EDIT = 'POST_SET_EDIT',
  POST_CLEAR_EDIT = 'POST_CLEAR_EDIT',
  POST_UPDATE_SUCCESS = 'POST_UPDATE_SUCCESS',
  POST_UPDATE_FAIL = 'POST_UPDATE_FAIL',
  POST_DELETE_FILE_SUCCESS = 'POST_DELETE_FILE_SUCCESS',
  POST_DELETE_FILE_FAIL = 'POST_DELETE_FILE_FAIL',
  POST_FILTER = 'POST_FILTER',
  POST_CLICK_LIKE = 'POST_CLICK_LIKE',
  POST_CLICK_UNLIKE = 'POST_CLICK_UNLIKE',
  POST_CLICK_LIKE_OR_UNLIKE_ERROR = 'POST_CLICK_LIKE_OR_UNLIKE_ERROR',
  POST_CLICK_PIN = 'POST_CLICK_LIKE',
  POST_CLICK_PINED_OR_UNPINED_SUCCESS = 'POST_CLICK_PINED_OR_UNPINED_SUCCESS',
  POST_CLICK_PINED_OR_UNPINED_FAIL = 'POST_CLICK_PINED_OR_UNPINED_FAIL',
}

export interface PostLoadingAction {
  readonly type: PostActionTypes.POST_LOADING;
}

export interface PostLoadedSuccessAction {
  readonly type: PostActionTypes.POST_LOADED_SUCCESS;
  payload: { posts: IPost[]; available: number };
}

export interface PostModalToggleAction {
  readonly type: PostActionTypes.POST_MODAL_TOGGLE;
}

export interface PostUploadShowOrHideAction {
  readonly type: PostActionTypes.POST_UPLOAD_SHOW_OR_HIDE;
  payload: boolean;
}

export interface PostVideoShowOrHideAction {
  readonly type: PostActionTypes.POST_VIDEO_SHOW_OR_HIDE;
  payload: boolean;
}

export interface PostActivityShowOrHideAction {
  readonly type: PostActionTypes.POST_ACTIVITY_SHOW_OR_HIDE;
  payload: boolean;
}

export interface PostCreateSuccessAction {
  readonly type: PostActionTypes.POST_CREATE_SUCCESS;
  payload: { post: IPost; message: string };
}

export interface PostDeleteSuccessAction {
  readonly type: PostActionTypes.POST_DELETE_SUCCESS;
  payload: { post: IPost; message: string };
}

export interface PostSetEditAction {
  readonly type: PostActionTypes.POST_SET_EDIT;
  payload: { current: IPost; edit: boolean };
}

export interface PostClearEditAction {
  readonly type: PostActionTypes.POST_CLEAR_EDIT;
}

export interface PostUpdateSuccessAction {
  readonly type: PostActionTypes.POST_UPDATE_SUCCESS;
  payload: { post: IPost; message: string };
}

export interface PostDeleteFileSuccessAction {
  readonly type: PostActionTypes.POST_DELETE_FILE_SUCCESS;
  payload: { post: IPost; fileId: string; message: string };
}

export interface PostFilterAction {
  readonly type: PostActionTypes.POST_FILTER;
  payload: { tag: string; user: IUser | null };
}

export interface PostClickLikeAction {
  readonly type: PostActionTypes.POST_CLICK_LIKE;
  payload: { post: IPost; like: ILike; message: string };
}

export interface PostClickUnlikeAction {
  readonly type: PostActionTypes.POST_CLICK_UNLIKE;
  payload: { post: IPost; unlike: ILike; message: string };
}

export interface PostClickLikeOrUnlikeFailAction {
  readonly type: PostActionTypes.POST_CLICK_LIKE_OR_UNLIKE_ERROR;
  payload: IErrorMessage;
}

export interface PostClickPinedOrUnpindSuccessAction {
  readonly type: PostActionTypes.POST_CLICK_PINED_OR_UNPINED_SUCCESS;
  payload: { post: IPost; message: string };
}

export interface PostClickPinedOrUnpindFailAction {
  readonly type: PostActionTypes.POST_CLICK_PINED_OR_UNPINED_FAIL;
  payload: IErrorMessage;
}

export interface PostLoadedFailAction {
  readonly type: PostActionTypes.POST_LOADED_FAIL;
  payload: IErrorMessage;
}

export interface PostCreateFailAction {
  readonly type: PostActionTypes.POST_CREATE_FAIL;
  payload: IErrorMessage;
}

export interface PostUpdateFailAction {
  readonly type: PostActionTypes.POST_UPDATE_FAIL;
  payload: IErrorMessage;
}

export interface PostDeleteFailAction {
  readonly type: PostActionTypes.POST_DELETE_FAIL;
  payload: IErrorMessage;
}

export interface PostDeleteFileFailAction {
  readonly type: PostActionTypes.POST_DELETE_FILE_FAIL;
  payload: IErrorMessage;
}

export type Action =
  | PostLoadingAction
  | PostLoadedSuccessAction
  | PostModalToggleAction
  | PostUploadShowOrHideAction
  | PostVideoShowOrHideAction
  | PostActivityShowOrHideAction
  | PostLoadedFailAction
  | PostCreateSuccessAction
  | PostCreateFailAction
  | PostSetEditAction
  | PostClearEditAction
  | PostUpdateSuccessAction
  | PostClickLikeAction
  | PostClickUnlikeAction
  | PostClickPinedOrUnpindSuccessAction
  | PostClickPinedOrUnpindFailAction
  | PostFilterAction
  | PostUpdateFailAction
  | PostDeleteSuccessAction
  | PostDeleteFailAction
  | PostDeleteFileSuccessAction
  | PostDeleteFileFailAction
  | PostClickLikeOrUnlikeFailAction;
