import { IToast } from '../../models/toast.model';
import { toastActions } from '../actions';

export interface ToastState {
  toasts: IToast[] | [];
  position: string;
}

const initialState: ToastState = {
  toasts: [],
  position: 'top-right',
};

export const toastReducer = (
  state: ToastState = initialState,
  action: toastActions.Action
): ToastState => {
  switch (action.type) {
    case toastActions.ToastActionTypes.TOAST_ADD:
      return {
        ...state,
        toasts: [action.payload, ...state.toasts],
        position: action.payload.position,
      };
    case toastActions.ToastActionTypes.TOAST_DELETE:
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== action.payload.id),
      };

    default:
      return state;
  }
};
