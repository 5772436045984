import React, { forwardRef, useImperativeHandle, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue, StylesConfig, Theme } from 'react-select';
import { ITag, TagOption } from '../../models/tag.model';
import Color from 'color';
import { numToColor } from '../../utils/color';
import { safUrl } from '../../utils/string';

type CreatableMultiAndDefaultValueProps = {
  systemTags: ITag | any;
  tags: ITag | any;
  postId: string | null;
  fileId: string | null;
  diff: (data: Object[]) => void;
  selectTags: readonly TagOption[];
  setSelectTags: React.Dispatch<React.SetStateAction<readonly TagOption[]>>;
};

export type DiffHandleProps = {
  start: () => void;
};

const customTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 5,
  borderSize: 5,
  colors: {
    ...theme.colors,
    // primary25: 'hotpink',
    primary: '#bbb',
  },
});

const colourStyles: StylesConfig<TagOption, true> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, props) => {
    const color = Color(props.data.color ? props.data.color : '#ddd');
    return {
      ...styles,
      backgroundColor: props.isDisabled
        ? undefined
        : props.isSelected
        ? props.data.color
        : props.isFocused
        ? color.alpha(0.1).lighten(0.5).hsl().string()
        : undefined,
      color: props.isDisabled
        ? '#ccc'
        : props.isSelected
        ? color.contrast(Color('white')) > 2
          ? 'white'
          : 'black'
        : props.data.color,
      cursor: props.isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !props.isDisabled
          ? props.isSelected
            ? props.data.color
            : color.alpha(0.3).lighten(0.5).hsl().string()
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = Color(data.color ? data.color : '#ddd');
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).lighten(0.5).hsl().string(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: Color(data.color ? data.color : '#ddd')
        .lighten(0.5)
        .hex(),
      color: '#eee',
    },
  }),
};

const SelectAndCreateTags = forwardRef<
  DiffHandleProps,
  CreatableMultiAndDefaultValueProps
>(
  (
    { systemTags, tags, postId, fileId, diff, selectTags, setSelectTags },
    ref
  ) => {
    const [selected, setSelected] = useState(false);

    // let selectRef = useRef() as
    //   | React.Ref<React.MutableRefObject<HTMLInputElement>>
    //   | Select<TagOption, true, GroupBase<TagOption>>
    //   | null;

    // const edit = useSelector((state: AppState) => state.comments.current);

    const tagOptions: readonly TagOption[] = tags.map(function (
      tag: ITag,
      i: number
    ) {
      return {
        value: tag.name,
        label: tag.name,
        color:
          tag.name === 'Trading Win'
            ? '#36B37E'
            : tag.name === 'Trading Loss'
            ? '#DC143C'
            : numToColor(i),
      };
    });

    // let defaultValue = tagOptions;

    // if (edit) {
    //   // selectRef && selectRef.setValue([], 'clear');
    //   // selectRef && selectRef.clearValue()
    //   // @ts-ignore
    //   // selectRef && selectRef.current.select.clearValue();
    //   // console.log(selectRef.setValue([], 'clear'));
    //   // ref.setValue()
    //   // setValue(null);
    //   // @ts-ignore
    //   defaultValue = null;
    //   // selectRef && selectRef.current?.select.clearValue();
    // } else {
    //   defaultValue = tagOptions;
    // }

    const systemTagsOptions: readonly TagOption[] = systemTags.map(function (
      tag: ITag,
      i: number
    ) {
      return {
        value: tag.name,
        label: tag.name,
        color:
          tag.name === 'Trading Win'
            ? '#36B37E'
            : tag.name === 'Trading Loss'
            ? '#DC143C'
            : numToColor(i),
      };
    });

    // console.log(selectTags);
    const handleChange = (
      newValue: OnChangeValue<TagOption, true>,
      actionMeta: ActionMeta<TagOption>
    ) => {
      // console.group('Value Changed');
      // console.log(newValue);
      setSelectTags(newValue);
      setSelected(true);
      // console.log(`action: ${actionMeta.action}`);
      // console.groupEnd();
    };

    const valuesA: { [key: string]: string } = tagOptions.reduce(
      (tagOptions, { value }) => Object.assign(tagOptions, { [value]: value }),
      {}
    );

    const valuesB: { [key: string]: string } = selectTags.reduce(
      (selectTags, { value }) => Object.assign(selectTags, { [value]: value }),
      {}
    );

    const dift = [
      ...tagOptions.filter(({ value }) => !valuesB[value]),
      ...selectTags.filter(({ value }) => !valuesA[value]),
    ];

    const dataToServer = dift.map(function (tag) {
      return {
        name: tag.label,
        slug: safUrl(tag.label),
        postId: postId,
        fileId: fileId,
        isNew: tag.__isNew__
          ? tag.__isNew__
          : !tagOptions.includes(tag)
          ? true
          : false,
        method: tag.__isNew__
          ? 'POST'
          : !tagOptions.includes(tag)
          ? 'POST'
          : 'DELETE',
      };
    });

    useImperativeHandle(ref, () => ({
      start() {
        diff(selected ? dataToServer : []);
      },
    }));

    // console.log('dataToServe', dataToServer);
    // console.log('selectTags', selectTags);

    return (
      <CreatableSelect
        isClearable={true}
        isMulti
        // ref={(ref) => (selectRef = ref)}
        onChange={handleChange}
        defaultValue={tagOptions}
        options={systemTagsOptions}
        placeholder="Select tags or type something..."
        noOptionsMessage={() => 'No other tags :('}
        theme={customTheme}
        styles={colourStyles}
        isSearchable
        autoFocus
      />
    );
  }
);

export default SelectAndCreateTags;
