import { Dispatch } from 'redux';
import { useToastInCreator } from '../../hooks';
import { IUser } from '../../models/user.model';
import { httpClient } from '../../utils/http-client';
import { userActions } from '../actions';

export const loadUsers =
  () => async (dispatch: Dispatch<userActions.Action>) => {
    dispatch({ type: userActions.UserActionTypes.USER_LOADING });

    try {
      const res = await httpClient.get('/api/users');

      dispatch({
        type: userActions.UserActionTypes.USER_LOADED_SUCCESS,
        payload: res.data.users,
      });
    } catch (err: any) {
      dispatch({
        type: userActions.UserActionTypes.USER_LOADED_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const setEditUser =
  (current: IUser, edit: boolean = true) =>
  async (dispatch: Dispatch<userActions.Action>) => {
    dispatch({
      type: userActions.UserActionTypes.USER_SET_EDIT,
      payload: { current: current, edit: edit },
    });
  };

export const updateUser =
  (user: IUser, data: { email: string; roles: string[] }) =>
  async (dispatch: Dispatch<userActions.Action>) => {
    dispatch({ type: userActions.UserActionTypes.USER_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.put(`/api/roles/${user._id}`, data);

      dispatch({
        type: userActions.UserActionTypes.USER_UPDATE_SUCCESS,
        payload: { user: res.data.user, message: res.data.message },
      });
      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: userActions.UserActionTypes.USER_UPDATE_FAIL,
        payload: err.response.data.message,
      });
      toast.error(err.response.data.message);
    }
  };

export const clearEditUser =
  () => async (dispatch: Dispatch<userActions.Action>) => {
    dispatch({
      type: userActions.UserActionTypes.USER_CLEAR_EDIT,
    });
  };
