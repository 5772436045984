import { Dispatch } from 'redux';
import { IToast } from '../../models/toast.model';
import { toastActions } from '../actions';

export const addToast =
  (toast: IToast) => async (dispatch: Dispatch<toastActions.Action>) => {
    dispatch({ type: toastActions.ToastActionTypes.TOAST_ADD, payload: toast });
  };

export const deleteToast =
  (id: string) => async (dispatch: Dispatch<toastActions.Action>) => {
    dispatch({
      type: toastActions.ToastActionTypes.TOAST_DELETE,
      payload: { id },
    });
  };
