import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import TradeNavbar from '../../components/trade-navbar/TradeNavbar';
import TradeMenu from '../../components/trade-menu/TradeMenu';
import { useDispatch } from 'react-redux';
import { tradeCreators, tradeMethodologyCreators } from '../../store/creators';
import { TradeMethodologyGroup } from '../../models/trademedology.model';
import './app-layout.scss';

const AppLayout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(tradeCreators.loadTrades());
    dispatch(
      tradeMethodologyCreators.loadTradeMethodologiesGroup(
        TradeMethodologyGroup.methodology
      )
    );
    dispatch(
      tradeMethodologyCreators.loadTradeMethodologiesTypeInstrumentUnique()
    );
    dispatch(tradeMethodologyCreators.loadTradeMethodologiesTypeSetupUnique());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handle = useFullScreenHandle();

  return (
    <FullScreen handle={handle}>
      <div className="app-layout">
        <div className="sidebar-container">
          <TradeMenu />
        </div>
        <div className="app-container">
          <TradeNavbar fullscreen={handle} />
          <div className="content-container">
            <Outlet />
          </div>
        </div>
      </div>
    </FullScreen>
  );
};

export default AppLayout;
