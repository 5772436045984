import { Dispatch } from 'redux';
import { useToastInCreator } from '../../hooks';
import { httpClient } from '../../utils/http-client';
import { walletActions } from '../actions';
import { IWallet } from '../../models/wallet.model';

export const loadWallets =
  () => async (dispatch: Dispatch<walletActions.Action>) => {
    dispatch({ type: walletActions.WalletActionTypes.WALLET_LOADING });

    try {
      const res = await httpClient.get('/api/wallets');

      dispatch({
        type: walletActions.WalletActionTypes.WALLET_LOADED_SUCCESS,
        payload: res.data.wallets,
      });
    } catch (err: any) {
      dispatch({
        type: walletActions.WalletActionTypes.WALLET_LOADED_FAIL,
        payload: err.response.data,
      });
    }
  };

export const createWallet =
  (data: { amount: string | number; description: string }) =>
  async (dispatch: Dispatch<walletActions.Action>) => {
    dispatch({ type: walletActions.WalletActionTypes.WALLET_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.post(`/api/wallets`, data);

      dispatch({
        type: walletActions.WalletActionTypes.WALLET_CREATE_SUCCESS,
        payload: { wallet: res.data.wallet, message: res.data.message },
      });
      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: walletActions.WalletActionTypes.WALLET_CREATE_FAIL,
        payload: err.response.data,
      });
      toast.error(err.response.data.message);
    }
  };

export const walletSetEdit =
  (wallet: IWallet) => async (dispatch: Dispatch<walletActions.Action>) => {
    dispatch({
      type: walletActions.WalletActionTypes.WALLET_SET_EDIT,
      payload: wallet,
    });
  };

export const updateWallet =
  (wallet: IWallet, data: { amount: string | number; description: string }) =>
  async (dispatch: Dispatch<walletActions.Action>) => {
    dispatch({ type: walletActions.WalletActionTypes.WALLET_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.put(`/api/wallets/${wallet._id}`, data);

      dispatch({
        type: walletActions.WalletActionTypes.WALLET_UPDATE_SUCCESS,
        payload: { wallet: res.data.wallet, message: res.data.message },
      });
      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: walletActions.WalletActionTypes.WALLET_UPDATE_FAIL,
        payload: err.response.data,
      });
      toast.error(err.response.data.message);
    }
  };

export const walletSetEditClear =
  () => async (dispatch: Dispatch<walletActions.Action>) => {
    dispatch({
      type: walletActions.WalletActionTypes.WALLET_SET_EDIT_CLEAR,
    });
  };

export const deleteWallet =
  (wallet: IWallet) => async (dispatch: Dispatch<walletActions.Action>) => {
    dispatch({ type: walletActions.WalletActionTypes.WALLET_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.delete(`/api/wallets/${wallet._id}`);

      dispatch({
        type: walletActions.WalletActionTypes.WALLET_DELETE_SUCCESS,
        payload: { wallet: wallet, message: res.data.message },
      });
      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: walletActions.WalletActionTypes.WALLET_DELETE_FAIL,
        payload: err.response.data,
      });
      toast.error(err.response.data.message);
    }
  };
