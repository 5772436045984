import { IChatMessage } from '../../models/chatmessage.model';
import { messageActions } from '../actions';

export interface MessageState {
  isLoading: boolean;
  isLoadingMessage: boolean;
  messages: IChatMessage[] | [];
  currentMessage: IChatMessage | null;
  message: string | null;
}

const initialState: MessageState = {
  isLoading: false,
  isLoadingMessage: false,
  messages: [],
  currentMessage: null,
  message: null,
};

export const messageReducer = (
  state: MessageState = initialState,
  action: messageActions.Action
): MessageState => {
  switch (action.type) {
    case messageActions.MessageActionTypes.MESSAGE_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case messageActions.MessageActionTypes.MESSAGE_LOAD_LOADING:
      return {
        ...state,
        isLoadingMessage: true,
      };

    case messageActions.MessageActionTypes.MESSAGE_LOADED_SUCCESS:
      return {
        ...state,
        isLoadingMessage: false,
        messages: action.payload,
      };

    case messageActions.MessageActionTypes.MESSAGE_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentMessage: action.payload.newMessage,
        messages: [...state.messages, action.payload.newMessage],
        message: action.payload.message,
      };

    case messageActions.MessageActionTypes.MESSAGE_RECEIVED:
      return {
        ...state,
        currentMessage: action.payload,
        messages:
          state.currentMessage?._id !== action.payload._id
            ? [...state.messages, action.payload]
            : state.messages,
      };

    case messageActions.MessageActionTypes.MESSAGE_LOADED_FAIL:
    case messageActions.MessageActionTypes.MESSAGE_CREATE_FAIL:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
