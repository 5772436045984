import { mockTagAll } from '../../data';
import { IFile } from '../../models/file.model';
import { ITag } from '../../models/tag.model';
import { customeLocalStorage } from '../../utils/local-storage';
import { sort } from '../../utils/sort';
import { fileActions } from '../actions';

export interface FileState {
  isLoading: boolean;
  files: IFile[] | [];
  images: IFile[] | [];
  available: number;
  filter: ITag;
  message: string | null;
  edit: boolean;
  current: IFile | null;
}

const initialState: FileState = {
  isLoading: false,
  files: [],
  images: [],
  available: 0,
  filter: customeLocalStorage.get('fileFilter') || mockTagAll('anonymous'),
  message: null,
  edit: false,
  current: null,
};

export const fileReducer = (
  state: FileState = initialState,
  action: fileActions.Action
): FileState => {
  switch (action.type) {
    case fileActions.FileActionTypes.FILE_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    // case fileActions.FileActionTypes.FILE_LOADED:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     files: action.payload,
    //     images: action.payload.filter(
    //       (file) => file.filetype === 'image' && file.like === true
    //     ),
    //   };

    // case fileActions.FileActionTypes.FILE_LOADED:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     files: action.payload.files,
    //     images: action.payload.files.filter(action.payload.predicate),
    //   };

    case fileActions.FileActionTypes.FILE_LOADED:
      return {
        ...state,
        isLoading: false,
        files: action.payload.files,
        available: action.payload.available,
      };

    case fileActions.FileActionTypes.FILE_LOADED_IMAGES:
      return {
        ...state,
        isLoading: false,
        files: action.payload.images,
        // images: action.payload,
        images:
          state.filter.name === 'All'
            ? action.payload.images
            : state.filter.name === 'Love'
            ? state.files.filter((image) => {
                if (image.likes.length > 0) {
                  return true;
                }
                return false;
              })
            : action.payload.images.filter((image) => {
                if (
                  image.tags.filter((tag) => tag.name === state.filter.name)
                    .length > 0
                ) {
                  return true;
                }
                return false;
              }),
        available: action.payload.available,
      };

    case fileActions.FileActionTypes.FILE_LOADED_IMAGES_AND_LIKE:
      return {
        ...state,
        isLoading: false,
        files: action.payload.images,
        images: action.payload.images,
        available: action.payload.available,
      };

    case fileActions.FileActionTypes.FILE_LOADED_IMAGES_FROM_POST:
      return {
        ...state,
        isLoading: false,
        files: action.payload,
        images: action.payload,
      };

    case fileActions.FileActionTypes.FILE_CLICK_LIKE:
      return {
        ...state,
        isLoading: false,
        images: state.images.map((file) => {
          if (file._id === action.payload.file._id) {
            const fileLike = file.likes as any;
            fileLike.push(action.payload.like);
            return file;
          }
          return file;
        }),
        message: action.payload.message,
      };

    case fileActions.FileActionTypes.FILE_CLICK_UNLIKE:
      return {
        ...state,
        isLoading: false,
        images: state.images.filter((file) => {
          if (file._id === action.payload.file._id) {
            let userLike: any;
            userLike = file.likes.filter(
              (like) => like.userId !== action.payload.unlike.userId
            );
            return (file.likes = userLike);
          }
          return file;
        }),
        message: action.payload.message,
      };

    case fileActions.FileActionTypes.FILE_SET_EDIT:
      return {
        ...state,
        isLoading: false,
        edit: true,
        current: action.payload,
      };

    case fileActions.FileActionTypes.FILE_SET_EDIT_CLEAR:
      return {
        ...state,
        isLoading: false,
        edit: false,
        current: null,
      };

    case fileActions.FileActionTypes.FILE_UPDATE_SUCCESS:
      if (action.payload.uploadFile) {
        window.location.reload();
      }
      return {
        ...state,
        isLoading: false,
        images: sort(
          state.images.map((file) => {
            if (file._id === action.payload.file._id) {
              // let newFile = action.payload.newFile;
              // return (file = newFile)
              return action.payload.newFile;
            }
            return file;
          }),
          'order'
        ),
        message: action.payload.message,
      };

    case fileActions.FileActionTypes.FILE_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        images: state.images.filter(
          (file) => file._id !== action.payload.file._id
        ),
        message: action.payload.message.message,
        available: state.available - 1,
      };

    case fileActions.FileActionTypes.FILE_IMAGES_FILTER:
      customeLocalStorage.set('fileFilter', action.payload.tag);
      return {
        ...state,
        isLoading: false,
        images:
          action.payload.tag.name === 'All'
            ? state.files
            : action.payload.tag.name === 'Love'
            ? sort(
                state.files.filter((image) => {
                  if (
                    image.likes.filter(
                      (like) => like.userId === action.payload.user?._id
                    ).length > 0
                  ) {
                    return true;
                  }
                  return false;
                }),
                'order'
              )
            : state.files.filter((image) => {
                if (
                  image.tags.filter(
                    (tag) => tag.name === action.payload.tag.name
                  ).length > 0
                ) {
                  return true;
                }
                return false;
              }),
        filter: action.payload.tag,
      };

    case fileActions.FileActionTypes.FILE_ADSIGN_TRADE:
      return {
        ...state,
        isLoading: false,
        files: state.files.map((file) => {
          if (file._id === action.payload.tradeMethodology.fileId) {
            file['tradeMethodology'] = action.payload.tradeMethodology;
            return file;
          }
          return file;
        }),
        images: state.images.map((file) => {
          if (file._id === action.payload.tradeMethodology.fileId) {
            file['tradeMethodology'] = action.payload.tradeMethodology;
            return file;
          }
          return file;
        }),
      };

    case fileActions.FileActionTypes.FILE_UPDATE_FAIL:
    case fileActions.FileActionTypes.FILE_DELETE_FAIL:
    case fileActions.FileActionTypes.FILE_ERROR:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
