import React from 'react';
import { IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import EditIcon from '@mui/icons-material/Edit';
import { alpha } from '@mui/material/styles';
import { AddCircleOutline } from '@mui/icons-material';

interface EnhancedTableToolbarProps {
  numSelected: number;
  handleAdd?: () => void;
  handleEdit?: () => void;
  handleDelete?: () => void;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Trade List
        </Typography>
      )}
      {numSelected > 0 ? (
        <>
          {numSelected === 1 && (
            <Tooltip
              title="Edit"
              style={{ marginRight: '8px' }}
              onClick={props.handleEdit}
            >
              <IconButton>
                <EditIcon sx={{ color: '#ffc107' }} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Delete" onClick={props.handleDelete}>
            <IconButton>
              <DeleteIcon sx={{ color: '#dc3545' }} />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip
            title="Add trade"
            style={{ marginRight: '8px' }}
            onClick={props.handleAdd}
          >
            <IconButton>
              <AddCircleOutline
                style={{ fontSize: '1.75rem', color: 'teal' }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
