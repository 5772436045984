import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDraggableScroll from './../../hooks/useDraggableScroll';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import './tags-bar.css';
import { AppState } from '../../store/app-state';
import { fileCreators, scrollbarCreators } from '../../store/creators';
import { ITag } from '../../models/tag.model';
import { mockTagAll, mockTagLove } from '../../data';
import { FaHeart } from 'react-icons/fa';

const Tagsbar = () => {
  // const [active, setActive] = useState('All');
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const tagsRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.auth.user);
  const files = useSelector((state: AppState) => state.files);
  const images = useSelector((state: AppState) => state.files.files);
  const tags = useSelector((state: AppState) => state.tags.fileTags);
  const currentTag = useSelector((state: AppState) => state.files.filter);

  useEffect(() => {
    if (tagsRef.current?.scrollWidth > tagsRef.current?.offsetWidth) {
      setIsOverflowing(true);
    } else {
      setIsOverflowing(false);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (tagsRef.current?.scrollWidth > tagsRef.current?.offsetWidth) {
        setIsOverflowing(true);
      } else {
        setIsOverflowing(false);
      }
    };

    window.addEventListener('resize', handleResize, false);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (tagsRef.current?.scrollWidth > tagsRef.current?.offsetWidth) {
      setIsOverflowing(true);
    } else {
      setIsOverflowing(false);
    }
  }, [scrollPosition]);

  useEffect(() => {
    window.history.pushState({}, '', `/images/?tags=${files.filter.slug}`);
  }, [files.filter]);

  const handleScrollButton = (direction: 'left' | 'right') => {
    const { current } = tagsRef;
    // console.log(current);
    if (current) {
      const scrollAmount = direction === 'right' ? 200 : -200;
      current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      // console.log(scrollAmount);
    }
  };

  const { onMouseDown } = useDraggableScroll(tagsRef, {
    direction: 'horizontal',
  });

  const onScroll = () => {
    setScrollPosition(tagsRef.current?.scrollLeft);
  };

  const handleTag = (tag: ITag) => {
    // setActive(tag);
    dispatch(scrollbarCreators.setPage('images', 1));
    dispatch(fileCreators.fileImagesFilter(tag, user));
  };

  const isStartScrollPosition = scrollPosition < 5 ? true : false;

  const isEndScrollPosition =
    tagsRef.current?.offsetWidth + scrollPosition + 5 >
    tagsRef.current?.scrollWidth
      ? true
      : false;

  // console.log(active);

  return (
    <>
      {images.length > 0 && (
        <div className="tags-container">
          <div className="tags-wrapper">
            <div className="tags-section">
              {isOverflowing && !isStartScrollPosition && (
                <div
                  className="tags-move"
                  onClick={() => handleScrollButton('left')}
                >
                  <HiOutlineChevronLeft className="icon" />
                </div>
              )}
              <div
                className="tags"
                ref={tagsRef}
                onScroll={onScroll}
                onMouseDown={onMouseDown}
              >
                <div
                  className={`tag icon ${
                    currentTag.name === 'Love' ? 'active' : ''
                  }`}
                  onClick={() => handleTag(mockTagLove(user?._id))}
                >
                  <FaHeart className="icon" />
                </div>
                <div
                  className={`tag ${currentTag.name === 'All' ? 'active' : ''}`}
                  onClick={() => handleTag(mockTagAll(user?._id))}
                >
                  All
                </div>
                {tags.map((tag, i) => (
                  <button
                    className={`tag ${
                      currentTag.name === `${tag.name}` ? 'active' : ''
                    }`}
                    key={tag._id}
                    onClick={() => handleTag(tag)}
                  >
                    {tag.name}
                  </button>
                ))}
              </div>
              {isOverflowing && !isEndScrollPosition && (
                <button
                  className="tags-move"
                  onClick={() => handleScrollButton('right')}
                >
                  <HiOutlineChevronRight className="icon" />
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Tagsbar;
