import { ITag } from '../../models/tag.model';
import { tagActions } from '../actions';

export interface TagState {
  fileTags: ITag[] | [];
  postTags: ITag[] | [];
  isLoading: boolean;
  message: string | null;
}

const initialState: TagState = {
  fileTags: [],
  postTags: [],
  isLoading: false,
  message: null,
};

export const tagReducer = (
  state: TagState = initialState,
  action: tagActions.Action
): TagState => {
  switch (action.type) {
    case tagActions.TagActionTypes.TAG_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case tagActions.TagActionTypes.TAG_POSTS_LOADED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        postTags: action.payload,
      };

    case tagActions.TagActionTypes.TAG_FILES_LOADED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fileTags: action.payload,
      };

    case tagActions.TagActionTypes.TAG_POSTS_LOADED_FAIL:
      return {
        ...state,
        isLoading: false,
        postTags: [],
        message: action.payload.message,
      };

    case tagActions.TagActionTypes.TAG_FILES_LOADED_FAIL:
      return {
        ...state,
        isLoading: false,
        fileTags: [],
        message: action.payload.message,
      };

    default:
      return state;
  }
};
