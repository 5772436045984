import React, { useCallback, useMemo } from 'react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import {
  Editable,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react';
import { Element, Leaf, withPlugins } from './TextEditor';
import { Descendant } from '../../types/slate';

const TextEditorDisplay = ({ value }: { value: Descendant[] }) => {
  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  );
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );
  const editor = useMemo(
    () => withPlugins(withHistory(withReact(createEditor()))),
    []
  );

  editor.children = value;

  return (
    <Slate editor={editor} value={value}>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        readOnly
      />
    </Slate>
  );
};

export default TextEditorDisplay;
