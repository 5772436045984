import React, { useState } from 'react';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';
import { ICommments } from '../../models/comment.model';
import { IFile } from '../../models/file.model';
import { IUser } from '../../models/user.model';
import { AppState } from '../../store/app-state';
import { commentCreators } from '../../store/creators';
import { contains } from '../../utils/contains';
import ConfirmPopup from '../confirm-popup/ConfirmPopup';
// import Reply from '../reply/Reply';

type CommmentProps = {
  comments: ICommments;
  parent: ICommments;
  fileInfo: IFile;
  user: IUser | null;
  // nest?: boolean;
};

const Comment = ({ comments, parent, fileInfo, user }: CommmentProps) => {
  // const [replying, setReplying] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const dispatch = useDispatch();

  const nestedComments = (comments.children || []).map((comment) => {
    return (
      <div key={comment._id} className="more-comments">
        <Comment
          key={comment._id}
          comments={comment}
          fileInfo={fileInfo}
          parent={parent}
          user={user}
        />
      </div>
    );
  });

  const current = useSelector((state: AppState) => state.comments.current);
  const edit = useSelector((state: AppState) => state.comments.edit);

  const replyHandler = (parent: ICommments, current: ICommments) => {
    // setReplying(!replying);
    dispatch(commentCreators.setReplyCommentFile(parent, current));
    // console.log(current);
  };

  const editHandler = (
    parent: ICommments,
    current: ICommments,
    edit: boolean = true
  ) => {
    dispatch(commentCreators.setEditCommentFile(parent, current, edit));
  };

  const editClearHandler = (id: string | number) => {
    dispatch(commentCreators.clearEditCommentFile());
  };

  const replyClearHandler = (id: string | number) => {
    // setReplying(!replying);
    dispatch(commentCreators.clearReplyCommentFile());
    // console.log('Clear :', comments._id);
  };

  const handleConfirmation = () => {
    dispatch(commentCreators.deleteCommentFileById(comments._id));
    dispatch(commentCreators.clearReplyCommentFile());
  };

  const deleteHandler = (id: string | number) => {
    setShowConfirmPopup(!showConfirmPopup);
    // if (window.confirm('Are you sure you want to delete this item?')) {
    //   dispatch(commentCreators.deleteCommentById(id));
    //   dispatch(commentCreators.clearReplyComment());
    // }
    // console.log('Delete', id);
  };

  const likeHandler = (id: string | number, status: boolean) => {
    dispatch(commentCreators.commentFileClickLikeOrUnlike(id, status));
  };

  return (
    <>
      <ConfirmPopup
        showConfirmPopup={showConfirmPopup}
        setShowConfirmPopup={setShowConfirmPopup}
        handleConfirmation={handleConfirmation}
      />
      <div className="comment-item">
        <div className="comment-content">
          <Link className="user-avatar" to={`/users/${comments.user.username}`}>
            <img src={comments.user.avartar} alt="user" />
          </Link>
          <div className="comment-content-container">
            <Link className="user-name" to={`/users/${comments.user.username}`}>
              {comments.user.username}
            </Link>
            <p className="comment-text">
              {comments.text}
              <span className="comment-buttom-container">
                <span className="comment-date">
                  <ReactTimeAgo
                    date={new Date(comments.createdAt)}
                    locale="en-US"
                    timeStyle="twitter"
                  />
                </span>
                <span
                  className={`comment-reply ${
                    current?._id === comments._id && !edit ? 'active' : ''
                  }`}
                  onClick={() => replyHandler(parent, comments)}
                >
                  Reply
                </span>
                {current?._id === comments._id && !edit && (
                  <span
                    className="comment-cancel"
                    onClick={() => replyClearHandler(comments._id)}
                  >
                    Cancel
                  </span>
                )}
                {user?._id === comments.user._id && (
                  <span
                    className={`comment-edit ${
                      current?._id === comments._id && edit ? 'active' : ''
                    }`}
                    onClick={() => editHandler(parent, comments)}
                  >
                    Edit
                  </span>
                )}
                {current?._id === comments._id && edit && (
                  <span
                    className="comment-edit-cancel"
                    onClick={() => editClearHandler(comments._id)}
                  >
                    Cancel
                  </span>
                )}
                {user?._id === comments.user._id && (
                  <span
                    className="comment-delete"
                    onClick={() => deleteHandler(comments._id)}
                  >
                    Delete
                  </span>
                )}
              </span>
            </p>
          </div>
          <div className="like-container">
            <span
              className="love-comment-icon"
              onClick={() =>
                likeHandler(comments._id, contains(comments.likes, user?._id))
              }
            >
              {contains(comments.likes, user?._id) ? (
                <FaHeart size={20} color={'#fe2c55'} />
              ) : (
                <FaRegHeart size={20} />
              )}
            </span>
            <span className="count">
              {comments.likes.length > 0 ? comments.likes.length : ''}
            </span>
          </div>
        </div>
        {/* <Reply
        user={user}
        fileInfo={fileInfo}
        parentId={comments._id}
        replying={replying}
        setReplying={setReplying}
      /> */}
        {nestedComments}
      </div>
    </>
  );
};

export default Comment;
