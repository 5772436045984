import { ICommment, ICommments } from '../../models/comment.model';
import { IErrorMessage } from '../../models/errormessage.model';
import { commentActions } from '../actions';

export interface CommentState {
  isLoading: boolean;
  comments: ICommment[] | [];
  focus: boolean;
  edit: boolean;
  parent: ICommments | null;
  current: ICommments | null;
  message: IErrorMessage | string | null;
}

const initialState: CommentState = {
  isLoading: false,
  comments: [],
  focus: false,
  edit: false,
  parent: null,
  current: null,
  message: null,
};

export const commentReducer = (
  state: CommentState = initialState,
  action: commentActions.Action
): CommentState => {
  switch (action.type) {
    case commentActions.CommentActionTypes.COMMENT_FILE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case commentActions.CommentActionTypes.COMMENT_FILE_LOADED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        comments: action.payload,
      };
    case commentActions.CommentActionTypes.COMMENT_FILE_CLICK_FOCUS_INPUT:
      return {
        ...state,
        focus: !state.focus,
      };
    case commentActions.CommentActionTypes.COMMENT_FILE_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        focus: false,
        comments: [...state.comments, action.payload.comment],
        message: action.payload.message,
      };
    case commentActions.CommentActionTypes.COMMENT_FILE_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        comments: state.comments.filter((comment) => {
          if (
            comment._id === action.payload.id ||
            comment.parentId === action.payload.id
          ) {
            return false;
          }

          return true;
        }),
        message: action.payload.message,
      };
    case commentActions.CommentActionTypes.COMMENT_FILE_SET_REPLY:
      return {
        ...state,
        focus: true,
        parent: action.payload.parent,
        current: action.payload.current,
      };
    case commentActions.CommentActionTypes.COMMENT_FILE_SET_EDIT:
      return {
        ...state,
        focus: true,
        edit: action.payload.edit,
        parent: action.payload.parent,
        current: action.payload.current,
      };
    case commentActions.CommentActionTypes.COMMENT_FILE_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        focus: false,
        comments: state.comments.map((comment) =>
          comment._id === action.payload.comment._id
            ? action.payload.comment
            : comment
        ),
        message: action.payload.message,
      };
    case commentActions.CommentActionTypes.COMMENT_FILE_CLEAR_REPLY:
    case commentActions.CommentActionTypes.COMMENT_FILE_CLEAR_EDIT:
      return {
        ...state,
        focus: false,
        edit: false,
        parent: null,
        current: null,
      };
    case commentActions.CommentActionTypes.COMMENT_FILE_CLICK_LIKE:
      return {
        ...state,
        isLoading: false,
        comments: state.comments.map((comment) => {
          if (comment._id === action.payload.id) {
            // @ts-ignore
            comment.likes.push(action.payload.like);
            return comment;
          }
          return comment;
        }),
        message: action.payload.message,
      };

    case commentActions.CommentActionTypes.COMMENT_FILE_CLICK_UNLIKE:
      return {
        ...state,
        isLoading: false,
        comments: state.comments.filter((comment) => {
          if (comment._id === action.payload.id) {
            let userLike: any;
            userLike = comment.likes.filter(
              (like) => like.userId !== action.payload.unlike.userId
            );
            return (comment.likes = userLike);
          }
          return comment;
        }),
        message: action.payload.message,
      };

    case commentActions.CommentActionTypes.COMMENT_FILE_LOADED_FAIL:
    case commentActions.CommentActionTypes.COMMENT_FILE_CREATE_FAIL:
    case commentActions.CommentActionTypes.COMMENT_FILE_UPDATE_FAIL:
    case commentActions.CommentActionTypes.COMMENT_FILE_DELETE_FAIL:
    case commentActions.CommentActionTypes
      .COMMENT_FILE_CLICK_LIKE_OR_UNLIKE_FAIL:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
      };

    default:
      return state;
  }
};
