import { ITrade } from '../models/trade.model';

type KeyOf<T extends object, U> = Extract<keyof T, U>;

export const getSummation = (data: number[]) => {
  let total = 0;
  for (var i = 0; i < data.length; i++) {
    total += data[i];
  }

  return total;
};

export const getAverage = (data: number[], preventResultZero = false) => {
  let total = 0;
  let avg = 0;
  for (var i = 0; i < data.length; i++) {
    total += data[i];
  }

  if (data.length > 0) {
    avg = total / data.length;
  }

  if (preventResultZero && data.length === 0) {
    console.error('Average divide by zero.');
    avg = 0.000001;
  }

  return avg;
};

export const getTradeAverage = (
  data: ITrade[],
  field: KeyOf<ITrade, number>
) => {
  let total = 0;
  for (var i = 0; i < data.length; i++) {
    total += data[i][field];
  }
  let avg = total / data.length;

  return avg;
};
