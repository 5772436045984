import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import Comments from '../../components/comments/Comments';
import Loading from '../../components/loading/Loading';
import CustomModal from '../../components/modal/CustomModal';
import { config } from '../../config';
import { IFile } from '../../models/file.model';
import { AppState } from '../../store/app-state';
import {
  commentCreators,
  fileCreators,
  modalCreators,
  tagCreators,
  tradeCreators,
} from '../../store/creators';

const Image = () => {
  const [loading, setLoading] = useState(true);
  const [index, setIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const params = useParams();

  const [searchParams] = useSearchParams();

  const postId = searchParams.get('post');

  const tags = searchParams.get('tags');

  const initialRef = useRef(true);

  // Mistak in production (id repeat loop)
  // const id = window.location.pathname.replace('/images/', '');

  let id = params.id!;

  id = window.location.pathname.replace('/images/', '');

  const user = useSelector((state: AppState) => state.auth.user);

  const token = useSelector((state: AppState) => state.auth.accessToken);

  const files = useSelector((state: AppState) => state.files);
  const images = useSelector((state: AppState) => state.files.images);

  const routeGoBack = useSelector(
    (state: AppState) => state.routes.routeGoBack
  );

  useEffect(() => {
    if (!initialRef.current) {
      return;
    }
    initialRef.current = false;
    setShowModal(true);
    dispatch(modalCreators.modalImageOpen());
    dispatch(tagCreators.loadFilesTags());
    if (routeGoBack === '/') {
      dispatch(fileCreators.loadFilesImagesAndLike());
    } else {
      if (postId) {
        dispatch(fileCreators.loadFilesImagesFromPost(postId));
      } else if (files.filter.name === 'Love') {
        dispatch(fileCreators.loadFilesImagesAndLike());
      } else {
        dispatch(fileCreators.loadFilesImages());
      }
    }
  }, [dispatch, files.filter, images, postId, routeGoBack]);

  useEffect(() => {
    // setShowModal(true);

    const indexOfArray = images.findIndex((img: IFile) => {
      setLoading(false);
      return img._id === id;
    });

    setIndex(indexOfArray);
  }, [dispatch, id, images]);

  // id = window.location.pathname.replace('/images/', '');

  const fileCurrent = useSelector((state: AppState) => state.files.current);

  const commentCurrent = useSelector(
    (state: AppState) => state.comments.current
  );

  const tradeShowInput = useSelector(
    (state: AppState) => state.trades.showInput
  );

  const tradeCurrent = useSelector((state: AppState) => state.trades.current);

  const comments = useSelector((state: AppState) => state.comments.comments);

  useEffect(() => {
    if (images[index]) {
      dispatch(commentCreators.loadCommentByFileId(images[index]._id));
      dispatch(tagCreators.loadFilesTags());
    }

    if (commentCurrent) {
      dispatch(commentCreators.clearReplyCommentFile());
    }

    if (fileCurrent) {
      dispatch(fileCreators.fileSetEditClear());
    }

    if (tradeShowInput) {
      dispatch(tradeCreators.tradeShowInput(false));
    }

    if (tradeCurrent) {
      dispatch(tradeCreators.tradeSetEditClear());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, index]);

  // console.log(id);

  const imageSrc = (imageId: string) =>
    `${config.serverUrl}/api/files/${imageId}?token=${token}`;

  // console.log(comments);

  if (loading || images[index] === undefined) {
    return <Loading />;
  }

  const prevIndex = (index + images.length - 1) % images.length;

  const nextIndex = (index + images.length + 1) % images.length;

  // Fixed mistake page pending
  // if (showModal) {
  //   document.body.style.overflow = 'hidden';
  // } else {
  //   document.body.style.overflow = 'scroll';
  // }

  // console.log(images[index]._id);

  let path = `/images/${images[index]._id}`;

  if (postId) {
    path = `/images/${images[index]._id}?post=${postId}`;
  } else if (tags) {
    path = `/images/${images[index]._id}?tags=${tags}`;
  }

  window.history.pushState({}, '', path);

  // URLs for full width images
  const mainSrc = imageSrc(images[index]._id);
  const nextSrc = imageSrc(images[nextIndex]._id);
  const prevSrc = imageSrc(images[prevIndex]._id);

  return (
    <CustomModal
      showModal={showModal}
      setShowModal={setShowModal}
      mainSrc={mainSrc || ''}
      nextSrc={nextSrc || ''}
      prevSrc={prevSrc || ''}
      onMovePrevRequest={() => setIndex(prevIndex)}
      onMoveNextRequest={() => setIndex(nextIndex)}
      closeModalAndRedirectTo={routeGoBack}
    >
      <Comments comments={comments} fileInfo={images[index]} user={user} />
    </CustomModal>
  );
};

export default Image;
