import { Action, Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { toastActions } from '../store/actions';

export const useToastInCreator = (dispatch: Dispatch<Action>) => {
  const dispatchAction = (
    type: string,
    message: string,
    duration: number,
    position: string
  ) => {
    const id = uuidv4();

    dispatch({
      type: toastActions.ToastActionTypes.TOAST_ADD,
      payload: { id, type, message, duration, position },
    });

    setTimeout(() => {
      dispatch({
        type: toastActions.ToastActionTypes.TOAST_DELETE,
        payload: { id },
      });
    }, duration * 1000);
  };

  const success = (
    message: string,
    duration: number = 3,
    position:
      | string
      | 'top-right'
      | 'bottom-right'
      | 'top-left'
      | 'bottom-left'
      | 'center' = 'top-right'
  ) => {
    dispatchAction('success', message, duration, position);
  };

  const error = (
    message: string,
    duration: number = 3,
    position:
      | string
      | 'top-right'
      | 'bottom-right'
      | 'top-left'
      | 'bottom-left'
      | 'center' = 'top-right'
  ) => {
    dispatchAction('error', message, duration, position);
  };

  const info = (
    message: string,
    duration: number = 3,
    position:
      | string
      | 'top-right'
      | 'bottom-right'
      | 'top-left'
      | 'bottom-left'
      | 'center' = 'top-right'
  ) => {
    dispatchAction('info', message, duration, position);
  };

  const warning = (
    message: string,
    duration: number = 3,
    position:
      | string
      | 'top-right'
      | 'bottom-right'
      | 'top-left'
      | 'bottom-left'
      | 'center' = 'top-right'
  ) => {
    dispatchAction('warning', message, duration, position);
  };

  const toast = { success, error, info, warning };

  return toast;
};
