import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cards from '../../components/cards/Cards';
import { AppState } from '../../store/app-state';
import {
  fileCreators,
  routeCreators,
  scrollbarCreators,
  tagCreators,
} from '../../store/creators';
// import { filterImageAndLike } from '../../utils/file.utils';
import { useOnceDispatchEffect } from '../../hooks';
import { useWindowScrollPositions } from '../../hooks';
import InfiniteLoading from '../../components/infinite-loading/InfiniteLoading';
import './home.css';

const Home = () => {
  // const initialRef = useRef(true);
  const dispatch = useDispatch();

  const images = useSelector((state: AppState) => state.files.images);
  const isLoading = useSelector((state: AppState) => state.files.isLoading);
  const routeGoBack = useSelector(
    (state: AppState) => state.routes.routeGoBack
  );
  const page = useSelector((state: AppState) => state.srollbar.pageHome);
  const scrollPosition = useSelector((state: AppState) => state.srollbar);

  // useEffect(() => {
  //   dispatch(fileCreators.loadFiles());
  //   dispatch(routeCreators.routeSet('/'));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   if (!initialRef.current) {
  //     return;
  //   }
  //   initialRef.current = false;
  //   dispatch(fileCreators.loadFiles());
  //   dispatch(routeCreators.routeSet('/'));
  // }, [dispatch]);

  // useOnceDispatchEffect(fileCreators.loadFiles());
  // useOnceDispatchEffect(
  //   fileCreators.loadFiles(
  //     (file) => file.filetype === 'image' && file.like === true
  //   )
  // );
  // useOnceDispatchEffect(fileCreators.loadFiles(filterImageAndLike), images);
  useOnceDispatchEffect(fileCreators.loadFilesImagesAndLike());
  useOnceDispatchEffect(tagCreators.loadFilesTags());
  useOnceDispatchEffect(routeCreators.routeSet('/'));
  useOnceDispatchEffect(
    scrollbarCreators.setPositionScollbar(
      scrollPosition.positionX,
      scrollPosition.positionY
    )
  );

  const { scrollX, scrollY } = useWindowScrollPositions();

  useEffect(() => {
    dispatch(scrollbarCreators.trackingScollbar(scrollX, scrollY));
  }, [dispatch, scrollX, scrollY]);

  useEffect(() => {
    if (page === 1 && routeGoBack !== '/') {
      dispatch(scrollbarCreators.setPositionScollbar(0, 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop <
        Math.floor(document.documentElement.offsetHeight * 0.8) ||
      page * 12 > images.length ||
      isLoading
    ) {
      return;
    }
    dispatch(scrollbarCreators.setPage('home', page + 1));
  }, [dispatch, images.length, isLoading, page]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const visibleImages = useMemo(
    () => images.slice(0, page * 12),
    [page, images]
  );

  return (
    <>
      <div className="home"></div>
      {isLoading && images.length === 0 && page === 1 ? (
        <InfiniteLoading absolute />
      ) : (
        <Cards images={visibleImages} />
      )}
      {isLoading && page !== 1 && <InfiniteLoading />}
    </>
  );
};

export default Home;
