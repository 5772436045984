import { IErrorMessage } from '../../models/errormessage.model';
import { ITradeMethodology } from '../../models/trademedology.model';

export enum TradeMethodologyActionTypes {
  TRADE_METHODOLOGY_LOADING = 'TRADE_METHODOLOGY_LOADING',
  TRADE_METHODOLOGY_LOADED_SUCCESS = 'TRADE_METHODOLOGY_LOADED_SUCCESS',
  TRADE_METHODOLOGY_LOADED_FAIL = 'TRADE_METHODOLOGY_LOADED_FAIL',
  TRADE_METHODOLOGY_GROUP_LOADED_SUCCESS = 'TRADE_METHODOLOGY_GROUP_LOADED_SUCCESS',
  TRADE_METHODOLOGY_GROUP_LOADED_FAIL = 'TRADE_METHODOLOGY_GROUP_LOADED_FAIL',
  TRADE_METHODOLOGY_TYPE_LOADED_SUCCESS = 'TRADE_METHODOLOGY_TYPE_LOADED_SUCCESS',
  TRADE_METHODOLOGY_TYPE_LOADED_FAIL = 'TRADE_METHODOLOGY_TYPE_LOADED_FAIL',
  TRADE_METHODOLOGY_TYPE_INSTRUMENT_UNIQUE_LOADED_SUCCESS = 'TRADE_METHODOLOGY_TYPE_INSTRUMENT_UNIQUE_LOADED_SUCCESS',
  TRADE_METHODOLOGY_TYPE_INSTRUMENT_UNIQUE_LOADED_FAIL = 'TRADE_METHODOLOGY_TYPE_INSTRUMENT_UNIQUE_LOADED_FAIL',
  TRADE_METHODOLOGY_TYPE_SETUP_UNIQUE_LOADED_SUCCESS = 'TRADE_METHODOLOGY_TYPE_SETUP_UNIQUE_LOADED_SUCCESS',
  TRADE_METHODOLOGY_TYPE_SETUP_UNIQUE_LOADED_FAIL = 'TRADE_METHODOLOGY_TYPE_SETUP_UNIQUE_LOADED_FAIL',
  TRADE_METHODOLOGY_CREATE_SUCCESS = 'TRADE_METHODOLOGY_CREATE_SUCCESS',
  TRADE_METHODOLOGY_CREATE_FAIL = 'TRADE_METHODOLOGY_CREATE_FAIL',
  TRADE_METHODOLOGY_SET_EDIT = 'TRADE_METHODOLOGY_SET_EDIT',
  TRADE_METHODOLOGY_SET_EDIT_CLEAR = 'TRADE_METHODOLOGY_SET_EDIT_CLEAR',
  TRADE_METHODOLOGY_UPDATE_SUCCESS = 'TRADE_METHODOLOGY_UPDATE_SUCCESS',
  TRADE_METHODOLOGY_UPDATE_FAIL = 'TRADE_METHODOLOGY_UPDATE_FAIL',
  TRADE_METHODOLOGY_DELETE_SUCCESS = 'TRADE_METHODOLOGY_DELETE_SUCCESS',
  TRADE_METHODOLOGY_DELETE_FAIL = 'TRADE_METHODOLOGY_DELETE_FAIL',
}

export interface TradeMethodologyLoadingAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_LOADING;
}

export interface TradeMethodologyLoadedSuccessAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_LOADED_SUCCESS;
  payload: ITradeMethodology[];
}

export interface TradeMethodologyLoadedFailAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_LOADED_FAIL;
  payload: IErrorMessage;
}

export interface TradeMethodologyGroupLoadedSuccessAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_GROUP_LOADED_SUCCESS;
  payload: ITradeMethodology[];
}

export interface TradeMethodologyGroupLoadedFailAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_GROUP_LOADED_FAIL;
  payload: IErrorMessage;
}

export interface TradeMethodologyTypeLoadedSuccessAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_TYPE_LOADED_SUCCESS;
  payload: ITradeMethodology[];
}

export interface TradeMethodologyTypeLoadedFailAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_TYPE_LOADED_FAIL;
  payload: IErrorMessage;
}

export interface TradeMethodologyTypeInstrumentUniqueLoadedSuccessAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_TYPE_INSTRUMENT_UNIQUE_LOADED_SUCCESS;
  payload: ITradeMethodology[];
}

export interface TradeMethodologyTypeInstrumentUniqueLoadedFailAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_TYPE_INSTRUMENT_UNIQUE_LOADED_FAIL;
  payload: IErrorMessage;
}

export interface TradeMethodologyTypeSetupUniqueLoadedSuccessAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_TYPE_SETUP_UNIQUE_LOADED_SUCCESS;
  payload: ITradeMethodology[];
}

export interface TradeMethodologyTypeSetupUniqueLoadedFailAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_TYPE_SETUP_UNIQUE_LOADED_FAIL;
  payload: IErrorMessage;
}

export interface TradeMethodologyCreateSuccessAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_CREATE_SUCCESS;
  payload: { methodology: ITradeMethodology; message: string };
}

export interface TradeMethodologyCreateFailAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_CREATE_FAIL;
  payload: IErrorMessage;
}

export interface TradeMethodologySetEditAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_SET_EDIT;
  payload: ITradeMethodology;
}

export interface TradeMethodologySetEditClearAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_SET_EDIT_CLEAR;
}

export interface TradeMethodologyUpdateSuccessAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_UPDATE_SUCCESS;
  payload: { methodology: ITradeMethodology; message: string };
}

export interface TradeMethodologyUpdateFailAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_UPDATE_FAIL;
  payload: IErrorMessage;
}

export interface TradeMethodologyDeleteSuccessAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_DELETE_SUCCESS;
  payload: { methodology: ITradeMethodology; message: string };
}

export interface TradeMethodologyDeleteFailAction {
  readonly type: TradeMethodologyActionTypes.TRADE_METHODOLOGY_DELETE_FAIL;
  payload: IErrorMessage;
}

export type Action =
  | TradeMethodologyLoadingAction
  | TradeMethodologyLoadedSuccessAction
  | TradeMethodologyLoadedFailAction
  | TradeMethodologyGroupLoadedSuccessAction
  | TradeMethodologyGroupLoadedFailAction
  | TradeMethodologyTypeLoadedSuccessAction
  | TradeMethodologyTypeLoadedFailAction
  | TradeMethodologyTypeInstrumentUniqueLoadedSuccessAction
  | TradeMethodologyTypeInstrumentUniqueLoadedFailAction
  | TradeMethodologyTypeSetupUniqueLoadedSuccessAction
  | TradeMethodologyTypeSetupUniqueLoadedFailAction
  | TradeMethodologyCreateSuccessAction
  | TradeMethodologyCreateFailAction
  | TradeMethodologySetEditAction
  | TradeMethodologySetEditClearAction
  | TradeMethodologyUpdateSuccessAction
  | TradeMethodologyUpdateFailAction
  | TradeMethodologyDeleteSuccessAction
  | TradeMethodologyDeleteFailAction;
