import { Dispatch } from 'redux';
import { httpClient } from '../../utils/http-client';
import { authActions } from '../actions';
import { useToastInCreator } from './../../hooks';

export const registerUser =
  (data: {
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
  }) =>
  async (dispatch: Dispatch<authActions.Action>) => {
    dispatch({ type: authActions.AuthActionTypes.USER_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.post('/api/users', data);

      dispatch({
        type: authActions.AuthActionTypes.REGISTER_SUCCESS,
        payload: {
          pendingId: res.data.pendingId,
          subject: res.data.subject,
          message: res.data.message,
        },
      });
      toast.info(res.data.message);
    } catch (err: any) {
      dispatch({
        type: authActions.AuthActionTypes.REGISTER_FAIL,
        payload: { message: err.response.data.message },
      });
      toast.error(err.response.data.message);
    }
  };

export const verifyOtpRegister =
  (data: { pendingId: string; token: string }) =>
  async (dispatch: Dispatch<authActions.Action>) => {
    dispatch({ type: authActions.AuthActionTypes.USER_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.post('/api/otps/validate/register', data);

      dispatch({
        type: authActions.AuthActionTypes.REGISTER_VERIFY_SUCCESS,
        payload: {
          pendingId: res.data.pendingId,
          subject: res.data.subject,
          message: res.data.message,
        },
      });

      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: authActions.AuthActionTypes.REGISTER_VERIFY_FAIL,
        payload: { message: err.response.data.message },
      });
      toast.error(err.response.data.message);
    }
  };

export const loginUser =
  (data: { email: string; password: string }) =>
  async (dispatch: Dispatch<authActions.Action>) => {
    dispatch({ type: authActions.AuthActionTypes.USER_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.post('/api/sessions', data);

      if (res.data.pendingId) {
        dispatch({
          type: authActions.AuthActionTypes.LOGIN_REQUEST_OTP,
          payload: {
            pendingId: res.data.pendingId,
            subject: res.data.subject,
            message: res.data.message,
          },
        });
        toast.info(res.data.message);
      } else {
        dispatch({
          type: authActions.AuthActionTypes.LOGIN_SUCCESS,
          payload: res.data,
        });
        toast.success(res.data.message);
      }
    } catch (err: any) {
      dispatch({
        type: authActions.AuthActionTypes.LOGIN_FAIL,
        payload: { message: err.response.data.message },
      });
      toast.error(err.response.data.message);
    }
  };

export const verifyOtpLogin =
  (data: { pendingId: string; token: string }) =>
  async (dispatch: Dispatch<authActions.Action>) => {
    dispatch({ type: authActions.AuthActionTypes.USER_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.post('/api/otps/validate/login', data);

      dispatch({
        type: authActions.AuthActionTypes.LOGIN_VERIFY_SUCCESS,
        payload: res.data,
      });

      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: authActions.AuthActionTypes.LOGIN_VERIFY_FAIL,
        payload: { message: err.response.data.message },
      });
      toast.error(err.response.data.message);
    }
  };

export const verifyUseRecoveryCodeLogin =
  (data: { pendingId: string; recoveryCode: string }) =>
  async (dispatch: Dispatch<authActions.Action>) => {
    dispatch({ type: authActions.AuthActionTypes.USER_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.post(
        '/api/otps/validate/login/recovery-code',
        data
      );

      dispatch({
        type: authActions.AuthActionTypes.LOGIN_USE_RECOVERY_CODE_SUCCESS,
        payload: res.data,
      });

      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: authActions.AuthActionTypes.LOGIN_USE_RECOVERY_CODE_FAIL,
        payload: { message: err.response.data.message },
      });

      toast.error(err.response.data.message);
    }
  };

export const loadUser =
  () => async (dispatch: Dispatch<authActions.Action>) => {
    dispatch({ type: authActions.AuthActionTypes.USER_LOADING });

    try {
      const res = await httpClient.get('/api/users/me');

      dispatch({
        type: authActions.AuthActionTypes.USER_LOADED,
        payload: res.data.user,
      });
    } catch (err: any) {
      dispatch({
        type: authActions.AuthActionTypes.AUTH_ERROR,
        payload: { message: err.response.data.message },
      });
    }
  };

export const logoutUser =
  () => async (dispatch: Dispatch<authActions.Action>) => {
    dispatch({ type: authActions.AuthActionTypes.USER_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.get('/api/sessions/destroy');

      dispatch({
        type: authActions.AuthActionTypes.LOGOUT_SUCCESS,
        payload: res.data,
      });
      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: authActions.AuthActionTypes.LOGOUT_FAIL,
        payload: { message: err.response.data.message },
      });
      toast.error(err.response.data.message);
    }
  };
