import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/app-state';
import Toast from '../toast/Toast';
import './toast-container.css';

const ToastContainer = () => {
  const toasts = useSelector((state: AppState) => state.toasts.toasts);
  const position = useSelector((state: AppState) => state.toasts.position);

  return (
    <div className={`toast-container ${position}`}>
      {toasts.length > 0 &&
        toasts.map((toast) => (
          <Toast
            key={toast.id}
            id={toast.id}
            type={toast.type}
            message={toast.message}
            duration={toast.duration}
            position={toast.position}
          />
        ))}
    </div>
  );
};

export default ToastContainer;
