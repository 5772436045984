import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import App from './App';
import './index.scss';
import store from './store/store';
import { authCreators /*, fileCreators*/ } from './store/creators';
// import { filterImageAndLike } from './utils/file.utils';

TimeAgo.addDefaultLocale(en);

// Dispatch the loadUser() before our root component renders
store.dispatch<any>(authCreators.loadUser());
// Dispatch the loadFiles() before our root component renders
// store.dispatch<any>(fileCreators.loadImages());
// store.dispatch<any>(fileCreators.loadFiles());

// const routeGoBack = store.getState().routes.routeGoBack;

// if (routeGoBack === '/') {
//   // store.dispatch<any>(fileCreators.loadFiles(filterImageAndLike));
//   store.dispatch<any>(fileCreators.loadFilesImagesAndLike);
// } else {
//   // store.dispatch<any>(fileCreators.loadFiles());
//   store.dispatch<any>(fileCreators.loadFilesImages());
// }

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);
