import { ISession } from '../../models/session.model';
import { sessionActions } from '../actions';

export interface SessionState {
  isLoading: boolean;
  sessions: ISession[] | [];
  current: ISession | null;
  message: string | null;
}

const initialState: SessionState = {
  isLoading: false,
  sessions: [],
  current: null,
  message: null,
};

export const sessionReducer = (
  state: SessionState = initialState,
  action: sessionActions.Action
): SessionState => {
  switch (action.type) {
    case sessionActions.SessionActionTypes.SESSION_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case sessionActions.SessionActionTypes.SESSION_LOAD_SESSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sessions: action.payload,
      };
    case sessionActions.SessionActionTypes.SESSION_LOAD_CURRENT_SESSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        current: action.payload,
      };
    case sessionActions.SessionActionTypes.SESSION_TERMINATE_SESSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sessions: state.sessions.filter(
          (session) => session._id === state.current?._id
        ),
        message: action.payload.message,
      };
    case sessionActions.SessionActionTypes.SESSION_LOAD_SESSIONS_FAIL:
      return {
        ...state,
        isLoading: false,
        sessions: [],
        message: action.payload.message,
      };
    case sessionActions.SessionActionTypes.SESSION_LOAD_CURRENT_SESSION_FAIL:
      return {
        ...state,
        isLoading: false,
        current: null,
        message: action.payload.message,
      };
    case sessionActions.SessionActionTypes.SESSION_TERMINATE_SESSIONS_FAIL:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
