import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IFile } from '../../models/file.model';
import { TagOption } from '../../models/tag.model';
import { AppState } from '../../store/app-state';
import { fileCreators } from '../../store/creators';
import DropzonImagePreview from '../dropzon-image-preview/DropzonImagePreview';
import InputComment from '../input-comment/InputComment';
import SelectAndCreateTags, {
  DiffHandleProps,
} from '../select-and-create-tags/SelectAndCreateTags';
import { EditContainer } from './file-edit-styles';

type FileEditProps = {
  file: IFile;
};

const FileEdit = ({ file }: FileEditProps) => {
  const [textFile, setTextFile] = useState('');
  const [uploadfile, setUploadFile] = useState<File | null>(null);
  const [textDiff, setTextDiff] = useState<Object[] | []>([]);
  const [selectTags, setSelectTags] = useState<readonly TagOption[]>([]);

  const selectRef = useRef<DiffHandleProps>(null);

  const dispatch = useDispatch();

  const editFile = useSelector((state: AppState) => state.files.edit);
  const currentFile = useSelector((state: AppState) => state.files.current);
  const systemTags = useSelector((state: AppState) => state.tags.fileTags);

  useEffect(() => {
    if (editFile) {
      setTextFile(currentFile?.description ? currentFile?.description : '');
    } else {
      setTextFile('');
    }
  }, [currentFile?.description, editFile]);

  useEffect(() => {
    selectRef?.current?.start();
  }, [selectTags]);

  const handleEnterContent = () => {};

  const handleDiff = (data: Object[] | []) => {
    setTextDiff(data);
  };

  const handleSetEditClear = () => {
    dispatch(fileCreators.fileSetEditClear());
  };

  const handleFileCallback = (file: File) => {
    if (file) {
      setUploadFile(file);
    }
  };

  const handleUpdate = () => {
    // console.log('File Id', file._id);
    // console.log('textFile', textFile);
    // console.log('textDiff', textDiff);
    // console.log('uploadfile', uploadfile);
    dispatch(fileCreators.fileUpdate(file, textFile, textDiff, uploadfile));
    dispatch(fileCreators.fileSetEditClear());
  };

  return (
    <EditContainer>
      <div className="content">
        <div className="image-upload-container">
          <DropzonImagePreview file={handleFileCallback} />
        </div>
        <div className="input-container">
          <InputComment
            value={textFile}
            onChange={setTextFile}
            onEnter={handleEnterContent}
          />
        </div>
        <div className="select-container">
          <SelectAndCreateTags
            systemTags={systemTags}
            tags={file.tags}
            postId={null}
            fileId={file._id}
            diff={handleDiff}
            ref={selectRef}
            selectTags={selectTags}
            setSelectTags={setSelectTags}
          />
        </div>
        <div className="btn-update-container">
          <button className="btn-cancel" onClick={handleSetEditClear}>
            Cancel
          </button>
          <button className="btn-update" onClick={handleUpdate}>
            Update
          </button>
        </div>
      </div>
    </EditContainer>
  );
};

export default FileEdit;
