import React, { FC } from 'react';
import { TradeMethodologyJoin } from '../../models/trademedology.model';
import './trade-table.css';

type TradeTableProps = {
  trade: TradeMethodologyJoin;
};

const TradeTable: FC<TradeTableProps> = ({ trade }) => {
  return (
    <table className="trade-table">
      <thead className={trade.trade.result > 0 ? 'win' : 'loss'}>
        <tr>
          <th>{trade.instrument.name}</th>
          <th>{trade.trade.result > 0 ? 'WIN' : 'LOSS'}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Gain/Loss</td>
          <td className={trade.trade.result > 0 ? 'win' : 'loss'}>
            {trade.trade.result.toFixed(2)}
          </td>
        </tr>
        <tr>
          <td>Entry price</td>
          <td>{trade.trade.entryPrice.toFixed(2)}</td>
        </tr>
        <tr>
          <td>Entry date</td>
          <td>{new Date(trade.trade.entryDate).toLocaleString('en-GB')}</td>
        </tr>
        <tr>
          <td>Entry emotion</td>
          <td
            className={
              trade.entryEmotion.score > 0
                ? 'positive'
                : trade.entryEmotion.score < 0
                ? 'negative'
                : 'normal'
            }
          >
            {trade.entryEmotion.name}
          </td>
        </tr>
        <tr>
          <td>Entry comment</td>
          <td
            className={
              trade.entryComment.score > 0
                ? 'positive'
                : trade.entryComment.score < 0
                ? 'negative'
                : 'normal'
            }
          >
            {trade.entryComment.name}
          </td>
        </tr>
        <tr>
          <td>Volume</td>
          <td>{trade.trade.volume}</td>
        </tr>
        <tr>
          <td>Exit price</td>
          <td>{trade.trade.exitPrice.toFixed(2)}</td>
        </tr>
        <tr>
          <td>Exit date</td>
          <td>{new Date(trade.trade.exitDate).toLocaleString('en-GB')}</td>
        </tr>
        <tr>
          <td>Exit emotion</td>
          <td
            className={
              trade.exitEmotion && trade.exitEmotion.score > 0
                ? 'positive'
                : trade.exitEmotion && trade.exitEmotion.score < 0
                ? 'negative'
                : 'normal'
            }
          >
            {trade.exitEmotion && trade.exitEmotion.name}
          </td>
        </tr>
        <tr>
          <td>Exit comment</td>
          <td
            className={
              trade.exitComment && trade.exitComment.score > 0
                ? 'positive'
                : trade.exitComment && trade.exitComment.score < 0
                ? 'negative'
                : 'normal'
            }
          >
            {trade.exitComment && trade.exitComment.name}
          </td>
        </tr>
        <tr>
          <td>Trade setup</td>
          <td>{trade.setup.name}</td>
        </tr>
        <tr>
          <td>Trade direction</td>
          <td>{trade.direction.name}</td>
        </tr>
        <tr>
          <td>Trade Management</td>
          <td
            className={
              trade.tradeManagement && trade.tradeManagement.score > 0
                ? 'positive'
                : trade.tradeManagement && trade.tradeManagement.score < 0
                ? 'negative'
                : 'normal'
            }
          >
            {trade.tradeManagement && trade.tradeManagement.name}
          </td>
        </tr>
        <tr>
          <td>Trade description</td>
          <td>{trade.trade.description}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default TradeTable;
