import { Dispatch } from 'redux';
import { httpClient } from '../../utils/http-client';
import { fileActions } from '../actions';
import { IFile } from '../../models/file.model';
import { IUser } from '../../models/user.model';
import { useToastInCreator } from '../../hooks';
import { ITag } from '../../models/tag.model';
// import { IFile } from '../../models/file.model';

// export const loadFiles =
//   () => async (dispatch: Dispatch<fileActions.Action>) => {
//     dispatch({ type: fileActions.FileActionTypes.FILE_LOADING });

//     try {
//       const res = await httpClient.get('/api/files');

//       dispatch({
//         type: fileActions.FileActionTypes.FILE_LOADED,
//         payload: res.data.files,
//       });
//     } catch (err: any) {
//       dispatch({
//         type: fileActions.FileActionTypes.FILE_ERROR,
//         payload: err.response.data,
//       });
//       toast.error(err.response.data.message);
//     }
//   };

// export const loadFiles =
//   (
//     predicate: (value: IFile, index: number, array: IFile[]) => unknown = (
//       file
//     ) => file.filetype === 'image'
//   ) =>
//   async (dispatch: Dispatch<fileActions.Action>) => {
//     dispatch({ type: fileActions.FileActionTypes.FILE_LOADING });

//     try {
//       const res = await httpClient.get('/api/files');

//       dispatch({
//         type: fileActions.FileActionTypes.FILE_LOADED,
//         payload: { files: res.data.files, predicate: predicate },
//       });
//     } catch (err: any) {
//       dispatch({
//         type: fileActions.FileActionTypes.FILE_ERROR,
//         payload: err.response.data.message,
//       });
//       toast.error(err.response.data.message);
//     }
//   };

export const loadFiles =
  () => async (dispatch: Dispatch<fileActions.Action>) => {
    dispatch({ type: fileActions.FileActionTypes.FILE_LOADING });

    try {
      const res = await httpClient.get('/api/files');

      dispatch({
        type: fileActions.FileActionTypes.FILE_LOADED,
        payload: { files: res.data.files, available: res.data.available },
      });
    } catch (err: any) {
      dispatch({
        type: fileActions.FileActionTypes.FILE_ERROR,
        payload: err.response.data.message,
      });
    }
  };

export const loadFilesImages =
  () => async (dispatch: Dispatch<fileActions.Action>) => {
    dispatch({ type: fileActions.FileActionTypes.FILE_LOADING });

    try {
      const res = await httpClient.get('/api/files/images');

      dispatch({
        type: fileActions.FileActionTypes.FILE_LOADED_IMAGES,
        payload: { images: res.data.files, available: res.data.available },
      });
    } catch (err: any) {
      dispatch({
        type: fileActions.FileActionTypes.FILE_ERROR,
        payload: err.response.data.message,
      });
    }
  };

export const loadFilesImagesAndLike =
  () => async (dispatch: Dispatch<fileActions.Action>) => {
    dispatch({ type: fileActions.FileActionTypes.FILE_LOADING });

    try {
      const res = await httpClient.get('/api/files/images/like');

      dispatch({
        type: fileActions.FileActionTypes.FILE_LOADED_IMAGES_AND_LIKE,
        payload: { images: res.data.files, available: res.data.available },
      });
    } catch (err: any) {
      dispatch({
        type: fileActions.FileActionTypes.FILE_ERROR,
        payload: err.response.data.message,
      });
    }
  };

export const loadFilesImagesFromPost =
  (postId: string | number) =>
  async (dispatch: Dispatch<fileActions.Action>) => {
    dispatch({ type: fileActions.FileActionTypes.FILE_LOADING });

    try {
      const res = await httpClient.get(`/api/files/images/post/${postId}`);

      dispatch({
        type: fileActions.FileActionTypes.FILE_LOADED_IMAGES_FROM_POST,
        payload: res.data.files,
      });
    } catch (err: any) {
      dispatch({
        type: fileActions.FileActionTypes.FILE_ERROR,
        payload: err.response.data.message,
      });
    }
  };

export const fileClickLikeAndUnlike =
  (file: IFile, user: IUser | null, status: boolean) =>
  async (dispatch: Dispatch<fileActions.Action>) => {
    dispatch({ type: fileActions.FileActionTypes.FILE_LOADING });

    try {
      const res = await httpClient.get(`/api/files/${file._id}/like`);

      if (status) {
        dispatch({
          type: fileActions.FileActionTypes.FILE_CLICK_UNLIKE,
          payload: { file, unlike: res.data.unlike, message: res.data.message },
        });
      } else {
        dispatch({
          type: fileActions.FileActionTypes.FILE_CLICK_LIKE,
          payload: { file, like: res.data.like, message: res.data.message },
        });
      }
    } catch (err: any) {
      dispatch({
        type: fileActions.FileActionTypes.FILE_ERROR,
        payload: err.response.data.message,
      });
    }
  };

export const fileSetEdit =
  (file: IFile) => async (dispatch: Dispatch<fileActions.Action>) => {
    dispatch({
      type: fileActions.FileActionTypes.FILE_SET_EDIT,
      payload: file,
    });
  };

export const fileSetEditClear =
  () => async (dispatch: Dispatch<fileActions.Action>) => {
    dispatch({
      type: fileActions.FileActionTypes.FILE_SET_EDIT_CLEAR,
    });
  };

export const fileUpdate =
  (
    file: IFile,
    description: string | null,
    tags: Object[] | [],
    uploadFile: File | null,
    order?: number
  ) =>
  async (dispatch: Dispatch<fileActions.Action>) => {
    dispatch({ type: fileActions.FileActionTypes.FILE_LOADING });
    const formData = new FormData();

    const toast = useToastInCreator(dispatch);

    if (description) {
      formData.append('description', description);
    }

    formData.append('tags', JSON.stringify(tags));

    if (uploadFile) {
      formData.append('file', uploadFile as any);
    }

    if (order) {
      formData.append('order', `${order}`);
    }

    try {
      const res = await httpClient.put(`/api/files/${file._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({
        type: fileActions.FileActionTypes.FILE_UPDATE_SUCCESS,
        payload: {
          file: file,
          newFile: res.data.file,
          uploadFile: uploadFile,
          message: res.data.message,
        },
      });
      if (!order) toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: fileActions.FileActionTypes.FILE_UPDATE_FAIL,
        payload: err.response.data.message,
      });
      toast.error(err.response.data.message);
    }
  };

export const fileDelete =
  (file: IFile) => async (dispatch: Dispatch<fileActions.Action>) => {
    dispatch({ type: fileActions.FileActionTypes.FILE_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.delete(`/api/files/${file._id}`);

      dispatch({
        type: fileActions.FileActionTypes.FILE_DELETE_SUCCESS,
        payload: {
          file: file,
          message: res.data.message,
        },
      });
      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: fileActions.FileActionTypes.FILE_DELETE_FAIL,
        payload: err.response.data.message,
      });
      toast.error(err.response.data.message);
    }
  };

export const fileImagesFilter =
  (tag: ITag, user: IUser | null) =>
  async (dispatch: Dispatch<fileActions.Action>) => {
    dispatch({
      type: fileActions.FileActionTypes.FILE_IMAGES_FILTER,
      payload: { tag, user },
    });
  };
