import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { AppState } from '../../store/app-state';
import { useOnceDispatchEffect } from '../../hooks';
import { fileCreators, scrollbarCreators } from '../../store/creators';
import { IFile } from '../../models/file.model';
import { config } from '../../config';
import { BsThreeDotsVertical } from 'react-icons/bs';
import {
  BiArrowToBottom,
  BiArrowToTop,
  BiChevronUp,
  BiChevronDown,
  BiVerticalCenter,
} from 'react-icons/bi';
import './re-order.css';
import { FaAngleDown } from 'react-icons/fa';

const ReOrder = () => {
  const [list, setList] = useState<IFile[]>([]);
  const [index, setIndex] = useState<null | number>(null);

  const dispatch = useDispatch();

  const images = useSelector((state: AppState) => state.files.images);
  const isLoading = useSelector((state: AppState) => state.files.isLoading);
  const page = useSelector((state: AppState) => state.srollbar.pageReOrder);
  const token = useSelector((state: AppState) => state.auth.accessToken);

  useOnceDispatchEffect(fileCreators.loadFilesImagesAndLike());

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop <
        Math.floor(document.documentElement.offsetHeight * 0.8) ||
      page * 12 > images.length ||
      isLoading
    ) {
      return;
    }
    dispatch(scrollbarCreators.setPage('re-order', page + 1));
  }, [dispatch, images.length, isLoading, page]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const visibleImages = useMemo(
    () => images.slice(0, page * 12),
    [page, images]
  );

  useEffect(() => {
    setList(visibleImages);
  }, [visibleImages]);

  const handleSave = () => {
    list.forEach((image, i) => {
      if (i + 1 !== image.order) {
        dispatch(fileCreators.fileUpdate(image, null, [], null, i + 1));
      }
    });
  };

  const handleCancel = () => {
    setList(visibleImages);
  };

  const handleMoveToTop = (index: number) => {
    const items = Array.from(list);
    const element = items[index];

    items.splice(index, 1);
    items.splice(0, 0, element);

    setList(items);
    setIndex(null);
  };

  const handleMoveToCenter = (index: number) => {
    const items = Array.from(list);
    const element = items[index];

    items.splice(index, 1);
    items.splice(Math.ceil(items.length / 2), 0, element);

    setList(items);
    setIndex(null);
  };

  const handleMoveToBottom = (index: number) => {
    const items = Array.from(list);
    const element = items[index];

    items.splice(index, 1);
    items.splice(items.length, 0, element);

    setList(items);
    setIndex(null);
  };

  const handleMoveUp = (index: number) => {
    const items = Array.from(list);
    const element = items[index];

    items.splice(index, 1);
    items.splice(index - 10 < 0 ? 0 : index - 10, 0, element);

    setList(items);
    setIndex(null);
  };

  const handleMoveDown = (index: number) => {
    const items = Array.from(list);
    const element = items[index];

    items.splice(index, 1);
    items.splice(
      index + 10 > items.length ? items.length : index + 10,
      0,
      element
    );

    setList(items);
    setIndex(null);
  };

  const goToBottomHandle = () => {
    if (page < Math.ceil(images.length / 12)) {
      dispatch(
        scrollbarCreators.setPage('re-order', Math.ceil(images.length / 12))
      );
    }

    if (visibleImages.length === images.length) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <div className="re-order">
        <div className="re-order__wrapper">
          <DragDropContext
            onDragEnd={(param) => {
              const srcI = param.source.index;
              const desI = param.destination?.index;
              if (desI != null) {
                const items = Array.from(list);
                const [newOrder] = items.splice(srcI, 1);
                items.splice(desI, 0, newOrder);
                setList(items);
              }
            }}
          >
            <div className="re-order__list-container">
              <div className="re-order__header">
                <h2>
                  Image List <span>(Drag and drop to reorder items)</span>
                </h2>

                <div className="re-order__header-btn">
                  {list !== visibleImages && (
                    <>
                      <button type="button" onClick={handleSave}>
                        Save
                      </button>
                      <button
                        type="button"
                        className="light"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                  <div className="scroll-to-bottom" onClick={goToBottomHandle}>
                    <FaAngleDown className="icon" />
                  </div>
                </div>
              </div>
              <Droppable droppableId="list">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="re-order__draggable"
                  >
                    {list.map((item, i) => (
                      <Draggable
                        key={item._id}
                        draggableId={`draggable-${item._id}`}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            className="re-order__list-item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              backgroundColor: snapshot.isDragging
                                ? '#f9f9f9'
                                : '#fff',
                              boxShadow: snapshot.isDragging
                                ? '0 0 .4rem #666'
                                : 'none',
                            }}
                          >
                            <div
                              className="re-order__list-drag"
                              {...provided.dragHandleProps}
                            >
                              <img
                                src={`${config.serverUrl}/api/files/${item._id}?token=${token}`}
                                alt=""
                              />
                            </div>
                            <div className="re-order__list-order">
                              <BsThreeDotsVertical
                                className="icon"
                                onClick={() => setIndex(i === index ? null : i)}
                              />
                              {index === i && (
                                <div className="re-order__list-order-actions">
                                  <div
                                    className="re-order__list-order-item"
                                    onClick={() => handleMoveToTop(i)}
                                  >
                                    <BiArrowToTop className="icon" /> Move to
                                    top
                                  </div>
                                  <div
                                    className="re-order__list-order-item"
                                    onClick={() => handleMoveUp(i)}
                                  >
                                    <BiChevronUp className="icon" /> Move up 10x
                                  </div>
                                  <div
                                    className="re-order__list-order-item"
                                    onClick={() => handleMoveToCenter(i)}
                                  >
                                    <BiVerticalCenter className="icon" /> Move
                                    to center
                                  </div>
                                  <div
                                    className="re-order__list-order-item"
                                    onClick={() => handleMoveDown(i)}
                                  >
                                    <BiChevronDown className="icon" /> Move down
                                    10x
                                  </div>
                                  <div
                                    className="re-order__list-order-item"
                                    onClick={() => handleMoveToBottom(i)}
                                  >
                                    <BiArrowToBottom className="icon" /> Move to
                                    bottom
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        </div>
      </div>
    </>
  );
};

export default ReOrder;
