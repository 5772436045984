import { combineReducers } from 'redux';
import { authReducer } from './auth.reducer';
import { postReducer } from './post.reducer';
import { fileReducer } from './file.reducer';
import { routeReducer } from './route.reducer';
import { modalReducer } from './modal.reducer';
import { commentReducer } from './comment.reducer';
import { tagReducer } from './tag.reducer';
import { scrollbarReducer } from './scrollbar.reducer';
import { toastReducer } from './toast.reducer';
import { sessionReducer } from './session.reducer';
import { otpReducer } from './otp.reducer';
import { passwordReducer } from './password.reducer';
import { roleReducer } from './role.reducer';
import { userReducer } from './user.reducer';
import { backupReducer } from './backup.reducer';
import { chatReducer } from './chat.reducer';
import { messageReducer } from './message.reducer';
import { tradeReducer } from './trade.reducer';
import { tradeMethodologyReducer } from './trademethodology.reducer';
import { walletReducer } from './wallet.reducer';
import { friendReducer } from './friend.reducer';

const rootReducers = combineReducers({
  auth: authReducer,
  posts: postReducer,
  files: fileReducer,
  trades: tradeReducer,
  tradeMethodologies: tradeMethodologyReducer,
  wallets: walletReducer,
  routes: routeReducer,
  modals: modalReducer,
  comments: commentReducer,
  tags: tagReducer,
  srollbar: scrollbarReducer,
  toasts: toastReducer,
  sessions: sessionReducer,
  otps: otpReducer,
  passwors: passwordReducer,
  roles: roleReducer,
  users: userReducer,
  friends: friendReducer,
  backups: backupReducer,
  chats: chatReducer,
  messages: messageReducer,
});

export default rootReducers;
