import React, { ChangeEvent, useEffect, useRef } from 'react';
import ContentEditable from 'react-contenteditable';
import { MdClose } from 'react-icons/md';
import TextCard, { ITextCard } from '../text-card/TextCard';
import './text-card-preview.css';

type TextCardPreviewProps = {
  showTextCard: boolean;
  setShowTextCard: React.Dispatch<React.SetStateAction<boolean>>;
  textCard: ITextCard;
  setTextCard: React.Dispatch<React.SetStateAction<ITextCard>>;
};

const TextCardPreview = ({
  showTextCard,
  setShowTextCard,
  textCard,
  setTextCard,
}: TextCardPreviewProps) => {
  const divRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const placeholderRef = useRef() as React.MutableRefObject<HTMLHeadingElement>;

  useEffect(() => {
    if (showTextCard) {
      divRef.current.focus();
    }
    if (showTextCard && textCard.text !== '') {
      placeholderRef.current.style.display = 'none';
    }
  }, [showTextCard, textCard.text]);

  const handleClose = () => {
    setShowTextCard(!showTextCard);
    setTextCard({ text: '', color: '#cfffe3' });
  };

  const handleChange = (e: any) => {
    setTextCard({
      ...textCard,
      text: e.target.value,
    });
  };

  const handleFocus = () => {
    placeholderRef.current.style.color = '#c5ccd3';
  };

  const handleBlur = () => {
    placeholderRef.current.style.color = '#98a5b1';
    if (textCard.text === '') {
      placeholderRef.current.style.display = '';
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLElement>) => {
    let element = e.target as HTMLElement;
    validated(element);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    let element = e.target as HTMLElement;
    validated(element);
    placeholderRef.current.style.display = 'none';
  };

  const handleOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTextCard({
      ...textCard,
      color: e.target.value,
    });
  };

  function validated(element: HTMLElement) {
    let text;
    let maxLength = 100;
    let currentlength = element.innerText.length;

    if (currentlength <= 0) {
      placeholderRef.current.style.display = 'block';
    } else {
      placeholderRef.current.style.display = 'none';
    }

    if (currentlength > maxLength && currentlength < 110) {
      //extracting over texts
      let overText = element.innerText.substr(maxLength);
      //creating new span and passing over texts
      overText = `<span class="highlight">${overText}</span>`;
      //passing overText value in textTag variable
      text = element.innerText.substr(0, maxLength) + overText;
      setTextCard({ ...textCard, text });
    } else {
      if (currentlength > 110) {
        setTextCard({ ...textCard, text: textCard.text.substring(0, 110) });
      }
    }
  }

  const list = [
    '#cfffe3',
    '#b8e9f5',
    '#e9e985',
    '#fef2ff',
    '#fef1aa',
    '#fdfae4',
    '#fff6d6',
    '#daf1e3',
    '#b4eed1',
    '#d8e0ef',
    '#f5f5f5',
  ];

  return (
    <>
      {showTextCard && (
        <div className="text-card-preview">
          <div className="text-card-preview__close" onClick={handleClose}>
            <MdClose />
          </div>
          <div className="text-card-preview__content">
            <h1 ref={placeholderRef}>Write something...</h1>
            <ContentEditable
              innerRef={divRef}
              className="text-card-preview__content-editable"
              html={textCard.text}
              disabled={false}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
            ></ContentEditable>
            <TextCard textCard={textCard} style={{ display: 'none' }} />
          </div>
          <div className="text-card-preview__colors">
            <div className="list">
              {list.map((item, i) => (
                <label
                  key={i}
                  className={`list-item ${
                    textCard.color === item ? 'active' : ''
                  }`}
                  style={{ backgroundColor: item }}
                >
                  <input
                    type="radio"
                    name="radio"
                    value={item}
                    checked={textCard.color === item}
                    onChange={handleOptionChange}
                  />
                  {/* <span className="checkmark"></span> */}
                </label>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// https://stackoverflow.com/a/57112242
// https://stackoverflow.com/a/72195285

export default TextCardPreview;
