import React from 'react';
import {
  validDailymotion,
  validFacebook,
  validIframe,
  validTiktok,
  validUrl,
  validYoutube,
} from '../../utils/video';
import LinkPreview from '../link-preview/LinkPreview';
import './video-embed.css';

export interface IVideoEmbed {
  url: string;
  isPortrait: boolean;
}

type VideoEmbedProps = {
  video: IVideoEmbed;
};

const VideoEmbed = ({ video }: VideoEmbedProps) => {
  const [matchYoutube, youtubeId] = validYoutube(video.url);
  const [matchDailymotion, dailymotionId] = validDailymotion(video.url);
  const [matchTiktok, tiktokId] = validTiktok(video.url);
  const [matchFacebook, facebookUrl] = validFacebook(video.url);
  const [matchIframe, iframe] = validIframe(video.url);
  const isUrl = validUrl(video.url);

  return (
    <>
      {matchIframe ? (
        <div
          className="video-center"
          dangerouslySetInnerHTML={{ __html: iframe }}
        ></div>
      ) : matchYoutube ? (
        <div className="video-responsive">
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${youtubeId}?origin=${process.env.REACT_APP_APP_URL}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube video player"
          />
        </div>
      ) : matchDailymotion ? (
        <div className="video-responsive">
          <iframe
            width="853"
            height="480"
            src={`https://www.dailymotion.com/embed/video/${dailymotionId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Dailymotion video player"
          />
        </div>
      ) : matchTiktok ? (
        <iframe
          src={`https://www.tiktok.com/embed/v2/${tiktokId}?origin=${process.env.REACT_APP_APP_URL}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          width={'100%'}
          height={725}
          title="Tiktok video player"
        />
      ) : matchFacebook ? (
        <div className={video.isPortrait ? 'video-center' : 'video-responsive'}>
          <iframe
            title="Facebook video player"
            src={`https://www.facebook.com/plugins/video.php?href=${facebookUrl}&origin=${process.env.REACT_APP_APP_URL}&show_text=false&t=0`}
            width={video.isPortrait ? '315' : '560'}
            height={video.isPortrait ? '560' : '315'}
            style={{ border: 'none', overflow: 'hidden' }}
            scrolling="no"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          />
        </div>
      ) : isUrl ? (
        <LinkPreview url={video.url} largeSize={true} />
      ) : (
        <></>
      )}
    </>
  );
};

export default VideoEmbed;
