import { IFile } from '../../models/file.model';
import { ILike } from '../../models/like.model';
import { IErrorMessage } from '../../models/errormessage.model';
import { ITag } from '../../models/tag.model';
import { ITrade } from '../../models/trade.model';
import { IUser } from '../../models/user.model';
// import { IUser } from '../../models/user.model';

export enum FileActionTypes {
  FILE_LOADING = 'FILE_LOADING',
  FILE_LOADED = 'FILE_LOADED',
  FILE_LOADED_IMAGES = 'FILE_LOADED_IMAGES',
  FILE_LOADED_IMAGES_AND_LIKE = 'FILE_LOADED_IMAGES_AND_LIKE',
  FILE_LOADED_IMAGES_FROM_POST = 'FILE_LOADED_IMAGES_FROM_POST',
  FILE_CLICK_LIKE = 'FILE_CLICK_LIKE',
  FILE_CLICK_UNLIKE = 'FILE_CLICK_UNLIKE',
  FILE_ERROR = 'FILE_ERROR',
  FILE_SET_EDIT = 'FILE_SET_EDIT',
  FILE_SET_EDIT_CLEAR = 'FILE_SET_EDIT_CLEAR',
  FILE_UPDATE_SUCCESS = 'FILE_UPDATE_SUCCESS',
  FILE_UPDATE_FAIL = 'FILE_UPDATE_FAIL',
  FILE_DELETE_SUCCESS = 'FILE_DELETE_SUCCESS',
  FILE_IMAGES_FILTER = 'FILE_IMAGES_FILTER',
  FILE_DELETE_FAIL = 'FILE_DELETE_FAIL',
  FILE_ADSIGN_TRADE = 'FILE_ADSIGN_TRADE',
}

export interface FileLoadingAction {
  readonly type: FileActionTypes.FILE_LOADING;
}

// export interface FileLoadedAction {
//   readonly type: FileActionTypes.FILE_LOADED;
//   payload: IFile[];
// }

// export interface FileLoadedAction {
//   readonly type: FileActionTypes.FILE_LOADED;
//   payload: {
//     files: IFile[];
//     predicate: (value: IFile, index: number, array: IFile[]) => unknown;
//   };
// }

export interface FileLoadedAction {
  readonly type: FileActionTypes.FILE_LOADED;
  payload: { files: IFile[]; available: number };
}

export interface FileLoadedImagesAction {
  readonly type: FileActionTypes.FILE_LOADED_IMAGES;
  payload: { images: IFile[]; available: number };
}

export interface FileLoadedImagesAndLIkeAction {
  readonly type: FileActionTypes.FILE_LOADED_IMAGES_AND_LIKE;
  payload: { images: IFile[]; available: number };
}

export interface FileLoadedImagesFromPostAction {
  readonly type: FileActionTypes.FILE_LOADED_IMAGES_FROM_POST;
  payload: IFile[];
}

export interface FileClickLikeAction {
  readonly type: FileActionTypes.FILE_CLICK_LIKE;
  payload: { file: IFile; like: ILike; message: string };
}

export interface FileClickUnlikeAction {
  readonly type: FileActionTypes.FILE_CLICK_UNLIKE;
  payload: { file: IFile; unlike: ILike; message: string };
}

export interface FileSetEditAction {
  readonly type: FileActionTypes.FILE_SET_EDIT;
  payload: IFile;
}

export interface FileSetEditClearAction {
  readonly type: FileActionTypes.FILE_SET_EDIT_CLEAR;
}

export interface FileUpdateSuccessAction {
  readonly type: FileActionTypes.FILE_UPDATE_SUCCESS;
  payload: {
    file: IFile;
    newFile: IFile;
    uploadFile: File | null;
    message: string;
  };
}

export interface FileUpdateFailAction {
  readonly type: FileActionTypes.FILE_UPDATE_FAIL;
  payload: IErrorMessage;
}

export interface FileDeleteSuccessAction {
  readonly type: FileActionTypes.FILE_DELETE_SUCCESS;
  payload: { file: IFile; message: IErrorMessage };
}

export interface FileDeleteFailAction {
  readonly type: FileActionTypes.FILE_DELETE_FAIL;
  payload: IErrorMessage;
}

export interface FileImagesFilterAction {
  readonly type: FileActionTypes.FILE_IMAGES_FILTER;
  payload: { tag: ITag; user: IUser | null };
}

export interface FileErrorAction {
  readonly type: FileActionTypes.FILE_ERROR;
  payload: IErrorMessage;
}

export interface FileAdsignTradeAction {
  readonly type: FileActionTypes.FILE_ADSIGN_TRADE;
  payload: ITrade;
}

export type Action =
  | FileLoadingAction
  | FileLoadedAction
  | FileLoadedImagesAction
  | FileLoadedImagesAndLIkeAction
  | FileLoadedImagesFromPostAction
  | FileClickLikeAction
  | FileClickUnlikeAction
  | FileSetEditAction
  | FileSetEditClearAction
  | FileUpdateSuccessAction
  | FileUpdateFailAction
  | FileDeleteSuccessAction
  | FileDeleteFailAction
  | FileImagesFilterAction
  | FileErrorAction
  | FileAdsignTradeAction;
