import { IErrorMessage } from '../../models/errormessage.model';
import { IChatMessage } from '../../models/chatmessage.model';
import { IChat } from '../../models/chat.model';

export enum ChatActionTypes {
  CHAT_LOADING = 'CHAT_LOADING',
  CHAT_LOADED_SUCCESS = 'CHAT_LOADED_SUCCESS',
  CHAT_LOADED_FAIL = 'CHAT_LOADED_FAIL',
  CHAT_CREATE_SUCCESS = 'CHAT_CREATE_SUCCESS',
  CHAT_CREATE_FAIL = 'CHAT_CREATE_FAIL',
  CHAT_SET_CURRENT = 'CHAT_SET_CURRENT',
  CHAT_MESSAGE_RECEIVED_LATEST = 'CHAT_MESSAGE_RECEIVED_LATEST',
}

export interface ChatLoadingAction {
  readonly type: ChatActionTypes.CHAT_LOADING;
}

export interface ChatLoadedSuccessAction {
  readonly type: ChatActionTypes.CHAT_LOADED_SUCCESS;
  payload: IChat[];
}

export interface ChatLoadedFailAction {
  readonly type: ChatActionTypes.CHAT_LOADED_FAIL;
  payload: IErrorMessage;
}

export interface ChatCreateSuccessAction {
  readonly type: ChatActionTypes.CHAT_CREATE_SUCCESS;
  payload: { chat: IChat; message: string };
}

export interface ChatCreateFailAction {
  readonly type: ChatActionTypes.CHAT_CREATE_FAIL;
  payload: IErrorMessage;
}

export interface ChatSetCurrentAction {
  readonly type: ChatActionTypes.CHAT_SET_CURRENT;
  payload: { currentChat: IChat; previousChat: IChat | null };
}

export interface ChatMessageReceivedLatestAction {
  readonly type: ChatActionTypes.CHAT_MESSAGE_RECEIVED_LATEST;
  payload: IChatMessage;
}

export type Action =
  | ChatLoadingAction
  | ChatLoadedSuccessAction
  | ChatLoadedFailAction
  | ChatCreateSuccessAction
  | ChatCreateFailAction
  | ChatSetCurrentAction
  | ChatMessageReceivedLatestAction;
