import { Dispatch } from 'redux';
import { useToastInCreator } from '../../hooks';
import { IBackup } from '../../models/backup.model';
import { httpClient } from '../../utils/http-client';
import { backupActions } from '../actions';

export const loadBackups =
  () => async (dispatch: Dispatch<backupActions.Action>) => {
    dispatch({ type: backupActions.BackupActionTypes.BACKUP_LOADING });

    try {
      const res = await httpClient.get('/api/backups');

      dispatch({
        type: backupActions.BackupActionTypes.BACKUP_LOADED_SUCCESS,
        payload: res.data.backups,
      });
    } catch (err: any) {
      dispatch({
        type: backupActions.BackupActionTypes.BACKUP_LOADED_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const createBackup =
  (data: { remark?: string }) =>
  async (dispatch: Dispatch<backupActions.Action>) => {
    dispatch({ type: backupActions.BackupActionTypes.BACKUP_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.post(`/api/backups`, data);

      dispatch({
        type: backupActions.BackupActionTypes.BACKUP_CREATE_SUCCESS,
        payload: { backup: res.data.backup, message: res.data.message },
      });
      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: backupActions.BackupActionTypes.BACKUP_CREATE_FAIL,
        payload: err.response.data.message,
      });
      toast.error(err.response.data.message);
    }
  };

export const deleteBackup =
  (backup: IBackup) => async (dispatch: Dispatch<backupActions.Action>) => {
    dispatch({ type: backupActions.BackupActionTypes.BACKUP_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.delete(`/api/backups/${backup._id}`);

      dispatch({
        type: backupActions.BackupActionTypes.BACKUP_DELETE_SUCCESS,
        payload: { backup: backup, message: res.data.message },
      });
      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: backupActions.BackupActionTypes.BACKUP_DELETE_FAIL,
        payload: err.response.data.message,
      });
      toast.error(err.response.data.message);
    }
  };
