import React, { useEffect, useState } from 'react';
import { FaCommentDots, FaHeart, FaPlus } from 'react-icons/fa';
import { BsFileDiff, BsFileEarmarkMinus } from 'react-icons/bs';
import ReactTimeAgo from 'react-time-ago';
// import comments from '../../comment-data';
import { ICommment } from '../../models/comment.model';
import { IFile } from '../../models/file.model';
import { createCommentsTree } from '../../utils/comments-tree';
import Comment from '../comment/Comment';
import InputComment from '../input-comment/InputComment';
import { useDispatch, useSelector } from 'react-redux';
import {
  commentCreators,
  fileCreators,
  tradeCreators,
} from '../../store/creators';
import { IUser } from '../../models/user.model';
import { Link, useNavigate } from 'react-router-dom';
import { AppState } from '../../store/app-state';
import { contains } from '../../utils/contains';
import {
  CommentsContainer,
  ContentInfoContainer,
  CreateCommentContainer,
  UserInfoContainer,
} from './comments-styles';
import FileEdit from '../file-edit/FileEdit';
import ConfirmPopup from '../confirm-popup/ConfirmPopup';
import { ITag } from '../../models/tag.model';
import TradeInput from '../trade-input/TradeInput';
import { MdOutlineEditLocation } from 'react-icons/md';
// import { ITrade } from '../../models/trade.model';
import TradeTable from '../trade-table/TradeTable';
import {
  // ITradeMethodology,
  TradeMethodologyJoin,
} from '../../models/trademedology.model';

type CommentsProps = {
  comments: ICommment[];
  fileInfo: IFile;
  user: IUser | null;
};

const Comments = ({ comments, fileInfo, user }: CommentsProps) => {
  const [textComment, setTextComment] = useState('');
  const [love, setLove] = useState(false);
  const [loveCount, setLoveCount] = useState(0);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const currentComment = useSelector(
    (state: AppState) => state.comments.current
  );
  const editComment = useSelector((state: AppState) => state.comments.edit);
  const parentComment = useSelector((state: AppState) => state.comments.parent);
  const editFile = useSelector((state: AppState) => state.files.edit);
  const images = useSelector((state: AppState) => state.files.images);
  const showTradeInput = useSelector(
    (state: AppState) => state.trades.showInput
  );

  useEffect(() => {
    if (editComment) {
      setTextComment(currentComment?.text as string);
    } else {
      setTextComment('');
    }

    setLove(contains(fileInfo.likes, user?._id));
    setLoveCount(fileInfo.likes.length);
  }, [
    currentComment?.text,
    editComment,
    fileInfo.description,
    fileInfo.likes,
    user?._id,
  ]);

  const commentTree = createCommentsTree(comments);

  const handleEnter = () => {
    if (parentComment && editComment) {
      dispatch(
        commentCreators.updateCommentFile({
          id: currentComment?._id as string,
          text: textComment,
        })
      );
      dispatch(commentCreators.clearEditCommentFile());
    } else if (parentComment && !editComment) {
      dispatch(
        commentCreators.createCommentFile({
          postId: null,
          fileId: fileInfo._id,
          text: textComment,
          parentId: parentComment._id,
        })
      );
      dispatch(commentCreators.clearReplyCommentFile());
    } else {
      dispatch(
        commentCreators.createCommentFile({
          postId: null,
          fileId: fileInfo._id,
          text: textComment,
          parentId: null,
        })
      );
    }
    // console.log('enter', text, fileInfo._id);
  };

  const handleLike = (file: IFile, user: IUser | null, status: boolean) => {
    setLove(!love);
    if (love) {
      setLoveCount(loveCount - 1);
    } else {
      setLoveCount(loveCount + 1);
    }
    dispatch(fileCreators.fileClickLikeAndUnlike(file, user, status));
  };

  const handleFocusInput = () => {
    dispatch(commentCreators.clickFileFocusInputComment());
  };

  const handleSetEdit = () => {
    dispatch(fileCreators.fileSetEdit(fileInfo));
  };

  const handleAddOrEditTrade = (trade?: TradeMethodologyJoin) => {
    dispatch(tradeCreators.tradeShowInput(true));
    if (trade) {
      dispatch(tradeCreators.tradeSetEdit(trade));
    }
  };

  const handleDelete = (file: IFile) => {
    // if (window.confirm('Are you sure you want to delete this item?')) {
    //   dispatch(fileCreators.fileDelete(file));
    // }
    setShowConfirmPopup(!showConfirmPopup);
  };

  const handleConfirmation = () => {
    dispatch(fileCreators.fileDelete(fileInfo));

    // Fixed error delete image last-child
    if (images[images.length - 1]._id === fileInfo._id) {
      navigate(`/images`);
      dispatch(fileCreators.loadFilesImages());
    }
  };

  const handleTag = (tag: ITag) => {
    // setActive(tag.name);
    dispatch(fileCreators.fileImagesFilter(tag, user));
  };

  return (
    <>
      {editFile ? (
        <FileEdit file={fileInfo} />
      ) : showTradeInput ? (
        <TradeInput file={fileInfo} />
      ) : (
        <>
          <ConfirmPopup
            showConfirmPopup={showConfirmPopup}
            setShowConfirmPopup={setShowConfirmPopup}
            handleConfirmation={handleConfirmation}
          />
          <UserInfoContainer>
            <div className="user">
              <Link to={`/users/${fileInfo.user.username}`}>
                <img src={fileInfo.user.avartar} alt="" />
              </Link>
              <div className="user-info">
                <Link to={`/users/${fileInfo.user.username}`}>
                  <h3>{fileInfo.user.username}</h3>
                </Link>
                <p>
                  <ReactTimeAgo
                    date={new Date(fileInfo.createdAt)}
                    locale="en-US"
                    timeStyle="round-minute"
                  />
                </p>
              </div>
              <div className="user-trade">
                <div
                  className={
                    fileInfo.tradeMethodology
                      ? 'create-trade edit'
                      : 'create-trade'
                  }
                  onClick={() =>
                    handleAddOrEditTrade(fileInfo.tradeMethodology)
                  }
                >
                  {fileInfo.tradeMethodology ? (
                    <MdOutlineEditLocation className="icon" />
                  ) : (
                    <FaPlus className="icon" />
                  )}
                </div>
              </div>
            </div>
          </UserInfoContainer>
          <ContentInfoContainer>
            <div className="description-container">
              {user?._id === fileInfo.userId && (
                <div className="file-action">
                  <div className="file-edit-icon" onClick={handleSetEdit}>
                    <BsFileDiff className="icon" />
                  </div>
                  <div
                    className="file-delete-icon"
                    onClick={() => handleDelete(fileInfo)}
                  >
                    <BsFileEarmarkMinus className="icon" />
                  </div>
                </div>
              )}
              {fileInfo.tradeMethodology && (
                <div className="table-trade">
                  <TradeTable trade={fileInfo.tradeMethodology} />
                </div>
              )}
              <div
                className="file-caption"
                style={
                  fileInfo.tradeMethodology &&
                  fileInfo.description &&
                  fileInfo.description.length > 0
                    ? { height: '50px', paddingTop: 0 }
                    : undefined
                }
              >
                <p>
                  {fileInfo.description !== undefined
                    ? fileInfo.description
                    : ''}
                </p>
              </div>
              <div className="file-tags">
                {fileInfo.tags.map((tag) => (
                  <Link
                    onClick={() => handleTag(tag)}
                    to={`/images?tags=${tag.slug}`}
                    key={tag._id}
                  >
                    <strong>#{tag.name}</strong>
                  </Link>
                ))}
              </div>
            </div>
            <div className="action-container">
              <div className="action-left">
                <div className="action-wrapper">
                  <span
                    className="icons"
                    onClick={() =>
                      handleLike(
                        fileInfo,
                        user,
                        contains(fileInfo.likes, user?._id)
                      )
                    }
                  >
                    <FaHeart color={love ? '#fe2c55' : '#000'} />
                  </span>
                  <strong>{loveCount > 0 ? loveCount : ''}</strong>
                </div>
                <div className="action-wrapper">
                  <span className="icons" onClick={handleFocusInput}>
                    <FaCommentDots />
                  </span>
                  <strong>{comments.length > 0 ? comments.length : ''}</strong>
                </div>
              </div>
            </div>
          </ContentInfoContainer>
          <CommentsContainer>
            <div className="comments">
              {commentTree.map((comments) => (
                <Comment
                  key={comments._id}
                  comments={comments}
                  parent={comments}
                  fileInfo={fileInfo}
                  user={user}
                />
              ))}
            </div>
          </CommentsContainer>
          <CreateCommentContainer>
            <div className="comment-create-inner-wrapper">
              <InputComment
                onEnter={handleEnter}
                onChange={setTextComment}
                value={textComment}
              />
            </div>
          </CreateCommentContainer>
        </>
      )}
    </>
  );
};

export default Comments;
