import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { AiOutlineHome } from 'react-icons/ai';
import { FaImages } from 'react-icons/fa';
import { LuFingerprint } from 'react-icons/lu';
import {
  BsBarChart,
  BsBookmarkCheck,
  BsCalculator,
  BsGear,
} from 'react-icons/bs';
import './trade-menu.scss';

const TradeMenu = () => {
  const navagate = useNavigate();

  return (
    <div className="trade-menu">
      <div className="logo-item">
        <div className="logo" onClick={() => navagate('/')}>
          <LuFingerprint className="icon" />
          <span>TradeS</span>
        </div>
      </div>
      <div className="menu-item">
        <NavLink to="/trades" end className="menu-item-link">
          <AiOutlineHome className="icon" />
          <span className="menu-item-title">Dashboard</span>
        </NavLink>
      </div>
      <div className="menu-item">
        <NavLink to="/trades/position-size" className="menu-item-link">
          <BsCalculator className="icon" />
          <span className="menu-item-title">Calulator</span>
        </NavLink>
      </div>
      <div className="menu-item">
        <NavLink to="/trades/list" className="menu-item-link">
          <BsBarChart className="icon" />
          <span className="menu-item-title">Reports</span>
        </NavLink>
      </div>
      <div className="menu-item">
        <NavLink to="/trades/books" className="menu-item-link">
          <BsBookmarkCheck className="icon" />
          <span className="menu-item-title">Books</span>
        </NavLink>
      </div>
      <div className="menu-item">
        <NavLink to="/trades/images" className="menu-item-link">
          <FaImages className="icon" />
          <span className="menu-item-title">Images</span>
        </NavLink>
      </div>
      <div className="menu-item">
        <NavLink to="/trades/settings" className="menu-item-link">
          <BsGear className="icon" />
          <span className="menu-item-title">Settings</span>
        </NavLink>
      </div>
    </div>
  );
};

export default TradeMenu;
