import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import './activity-embed.css';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export interface IActivityEmbed {
  id: string;
  activity: string;
  score: number;
}

type ActivityEmbedProps = {
  activities: IActivityEmbed[];
};

const ActivityEmbed = ({ activities }: ActivityEmbedProps) => {
  let keys: string[] = [];
  let values: number[] = [];
  let sumValue = 0;
  let fillBackgroundColor = 'rgba(0,0,200,0.1)';
  let fillBorderColor = 'rgba(0,0,200,0.6)';
  let fillPointBackgroundColor: string[] = [];
  let fillPointBorderColor: string[] = [];

  for (const a of activities) {
    keys.push(a.activity);
    values.push(a.score);
    sumValue = sumValue + a.score;

    if (a.score >= 50) {
      fillPointBackgroundColor.push('cornflowerblue');
      fillPointBorderColor.push('rgba(0,0,200,0.6)');
    } else {
      fillPointBackgroundColor.push('rgba(231,118,105,1)');
      fillPointBorderColor.push('rgba(255,0,0,0.6)');
    }

    if (sumValue >= (activities.length * 100) / 2) {
      fillBackgroundColor = 'rgba(0,0,200,0.1)';
      fillBorderColor = 'rgba(0,0,200,0.6)';
    } else {
      fillBackgroundColor = 'rgba(255,0,0,0.1)';
      fillBorderColor = 'rgba(255,0,0,0.6)';
    }
  }

  const data = {
    labels: keys,
    datasets: [
      {
        label: 'Activity',
        data: values,
        backgroundColor: fillBackgroundColor,
        borderColor: fillBorderColor,
        radius: 5,
        pointRadius: 5,
        pointBorderWidth: 3,
        pointBackgroundColor: fillPointBackgroundColor,
        pointBorderColor: fillPointBorderColor,
        pointHoverRadius: 7,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      r: {
        pointLabels: {
          font: {
            size: 16,
          },
        },
      },
    },
    scale: {
      ticks: {
        beginAtZero: true,
        min: 0,
        max: 100,
        stepSize: 20,
      },
      pointLabels: {
        fontSize: 20,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            return context.formattedValue;
          },
        },
      },
      legend: {
        display: false,
        labels: {
          font: {
            size: 16,
          },
        },
      },
    },
  };

  return (
    <>
      <div className="activity-center">
        <Radar data={data} options={options} />
      </div>
    </>
  );
};

export default ActivityEmbed;
