import React, { Dispatch, useRef } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/app-state';
import InputEmojiForwardRef from '../input-emoji-with-ref/InputEmojiWithRef';
import './imput-blog-comment.css';

type InputCommentProps = {
  value: string;
  onChange: Dispatch<React.SetStateAction<string>>;
  onEnter: () => void;
  postId?: string | number;
};

const InputBlogComment = ({
  value,
  onChange,
  onEnter,
  postId,
}: InputCommentProps) => {
  const input = useRef() as React.MutableRefObject<HTMLInputElement>;

  const focusId = useSelector((state: AppState) => state.posts.commentFocusId);

  if (focusId === postId) {
    setTimeout(() => {
      input.current?.focus();
    }, 200);
  }

  return (
    <div className="input-blog-comment-container">
      <InputEmojiForwardRef
        ref={input}
        value={value}
        setValue={onChange}
        onEnter={onEnter}
        cleanOnEnter
        closeOnClickOutside
      />
    </div>
  );
};

export default InputBlogComment;
