import React, { FC, ReactNode, useEffect, useRef } from 'react';
import './popup.css';

type PopupProps = {
  children: ReactNode;
  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose?: () => void;
};

const Popup: FC<PopupProps> = ({
  children,
  showPopup,
  setShowPopup,
  handleClose,
}) => {
  const popupRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Element)
      ) {
        setShowPopup(!showPopup);
        handleClose && handleClose();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showPopup, setShowPopup, handleClose]);

  return (
    <>
      {showPopup && (
        <>
          <div
            ref={popupRef}
            className={`popup center ${showPopup ? 'active' : ''}`}
          >
            {children}
          </div>
          <div className={`overlay ${showPopup ? 'active' : ''}`} />
        </>
      )}
    </>
  );
};

export default Popup;
