import React, { useEffect, useState } from 'react';
import {
  FaDesktop,
  FaKey,
  FaMobileAlt,
  FaShieldAlt,
  FaUser,
  FaUserMinus,
} from 'react-icons/fa';
import { MdDeviceUnknown } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';
import DropzonImagePreview from '../../components/dropzon-image-preview/DropzonImagePreview';
import { AppState } from '../../store/app-state';
import { otpCreators, sessionCreators } from '../../store/creators';
import AppleAppStore from '../../images/apple-app-store-badge.svg';
import GooglePlayStore from '../../images/google-play-badge.png';
import './profile.css';
import ConfirmPopup from '../../components/confirm-popup/ConfirmPopup';
import { useMediaQuery } from '../../hooks/useMediaQuery';

const Profile = () => {
  const [tab, setTab] = useState({ active: 'account' });
  const [otpInput, setOtpInput] = useState('');
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const isMobile = useMediaQuery('(max-width: 500px)');

  const user = useSelector((state: AppState) => state.auth.user);

  const otp = useSelector((state: AppState) => state.otps);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sessionCreators.loadSessions());
    dispatch(sessionCreators.loadCurrentSession());
    // if (user) {
    //   setTwoFAEnable(user.twoFAEnable);
    // }
  }, [dispatch]);

  const currentSession = useSelector(
    (state: AppState) => state.sessions.current
  );
  const sessions = useSelector((state: AppState) => state.sessions.sessions);

  const handleProfile = (file: File) => {
    console.log(file);
  };

  const terminetSession = () => {
    dispatch(sessionCreators.terminateSessions());
  };

  const handleConfirmation = () => {
    dispatch(otpCreators.disableOtp());
  };

  const handle2FAEnable = () => {
    if (user?.is2FAEnabled) {
      // Disable Otp
      setShowConfirmPopup(!showConfirmPopup);
    } else {
      // Enable Otp
      dispatch(otpCreators.enableOtp());
    }
  };

  const handleOtpInput = (otp: string) => {
    setOtpInput(otp);
  };

  const handleOtpSubmit = () => {
    dispatch(otpCreators.verifyOtp(otpInput));
    setOtpInput('');
  };

  let recoveryKey: string = '';

  otp.otp?.recoveryCodes.forEach((key: string, i: number) => {
    if (isMobile) {
      if (i + 1 === otp.otp?.recoveryCodes.length) {
        recoveryKey += key;
      } else {
        recoveryKey += key + '\r\n';
      }
    } else {
      if ((i + 1) % 2 === 1) {
        recoveryKey += key + ' ';
      } else {
        if (i + 1 === otp.otp?.recoveryCodes.length) {
          recoveryKey += key;
        } else {
          recoveryKey += key + '\r\n';
        }
      }
    }
  });

  return (
    <>
      <ConfirmPopup
        showConfirmPopup={showConfirmPopup}
        setShowConfirmPopup={setShowConfirmPopup}
        handleConfirmation={handleConfirmation}
        description="This reduces the security level of your account and is not recommended."
        submitBtnName="Yes, I disable it!"
      />
      <div className="profile-page">
        <div className="profile-page-container">
          <div className="profile-page-wrapper">
            <div className="profile-page-content">
              <div className="profile-page-content-container">
                <div className="profile-page-content-left">
                  <div className="profile">
                    <img src={user?.avartar} alt="user" width={100} />
                    <h4>{user?.username}</h4>
                  </div>
                  <div className="profile-menu">
                    <div
                      className={`tab ${
                        tab.active === 'account' ? 'active' : ''
                      }`}
                      onClick={() => setTab({ active: 'account' })}
                    >
                      <FaUser className="icon" />
                      <span>Account</span>
                    </div>
                    <div
                      className={`tab ${
                        tab.active === 'security' ? 'active' : ''
                      }`}
                      onClick={() => setTab({ active: 'security' })}
                    >
                      <FaKey className="icon" />
                      <span>Security</span>
                    </div>
                    <div
                      className={`tab ${
                        tab.active === 'application' ? 'active' : ''
                      }`}
                      onClick={() => setTab({ active: 'application' })}
                    >
                      <FaShieldAlt className="icon" />
                      <span>Application</span>
                    </div>
                    <div
                      className={`tab ${
                        tab.active === 'sessions' ? 'active' : ''
                      }`}
                      onClick={() => setTab({ active: 'sessions' })}
                    >
                      <FaDesktop className="icon icon-ex" />
                      <span>Browser Sessions</span>
                    </div>
                    <div
                      className={`tab ${
                        tab.active === 'delete-account' ? 'active' : ''
                      }`}
                      onClick={() => setTab({ active: 'delete-account' })}
                    >
                      <FaUserMinus className="icon icon-ex" />
                      <span>Delete Accont</span>
                    </div>
                  </div>
                </div>
                <div className="profile-page-content-right">
                  {tab.active === 'account' && (
                    <div className="profile-tab">
                      <h3 className="title">Account</h3>
                      <p className="description">
                        Update your account's profile information and email
                        address.
                      </p>
                      <div className="content">
                        <div className="account">
                          <DropzonImagePreview
                            file={handleProfile}
                            dropClassName="profile-upload"
                            iconWrapperClassName="icon-wrapper"
                            iconClassName="icon"
                            placeholderClassName="profile-upload-text"
                            placeholder="Select a new photo"
                            imagePreviewClassName="profile-upload-preview"
                          />
                          <form className="form-user-account">
                            <div className="form-control">
                              <label htmlFor="displayName">Display Name</label>
                              <input
                                type="text"
                                name="displayName"
                                id="displayName"
                              />
                            </div>
                            <div className="form-control">
                              <label htmlFor="username">Username</label>
                              <input
                                type="text"
                                name="username"
                                id="username"
                                value={user?.username || ''}
                                disabled
                              />
                            </div>
                            <div className="form-control">
                              <label htmlFor="email">Email</label>
                              <input
                                type="text"
                                name="email"
                                id="email"
                                value={user?.email || ''}
                                disabled
                              />
                            </div>
                            <div className="account-bottom">
                              <button type="submit" className="account-btn">
                                Save
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                  {tab.active === 'security' && (
                    <div className="profile-tab">
                      <h3 className="title">Security</h3>
                      <p className="description">
                        Ensure your account is using a long, random password to
                        stay secure.
                      </p>
                      <div className="content">
                        <div className="security">
                          <button className="security-btn">
                            Change Password
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {tab.active === 'application' && (
                    <div className="profile-tab">
                      <h3 className="title">Application</h3>
                      <p className="description">
                        Add additional security to your account using two factor
                        authentication.
                      </p>
                      <div className="content">
                        <p className="bold">
                          You have {user?.is2FAEnabled ? '' : 'not'} enabled two
                          factor authentication.
                        </p>
                        <p>
                          When two factor authentication is enabled, you will be
                          prompted for a secure, random token during
                          authentication. You may retrieve this token from your
                          phone's Google Authenticator application
                        </p>
                        <span className="app-google-authenticator">
                          <a
                            href="https://apps.apple.com/th/app/google-authenticator/id388497605"
                            target="_bank"
                          >
                            <img src={AppleAppStore} alt="" />
                          </a>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=th&gl=US"
                            target="_bank"
                          >
                            <img src={GooglePlayStore} alt="" />
                          </a>
                        </span>
                        <div className="application">
                          {!otp.isOtpRequired && (
                            <button
                              className={
                                user?.is2FAEnabled
                                  ? 'application-btn disable'
                                  : 'application-btn'
                              }
                              onClick={handle2FAEnable}
                            >
                              {user?.is2FAEnabled
                                ? 'Disable 2FA'
                                : 'Enable 2FA'}
                            </button>
                          )}
                          {otp.isOtpRequired && (
                            <div className="otp-container">
                              {otp.qrcode && (
                                <img
                                  src={otp.qrcode}
                                  alt=""
                                  className="qrcode"
                                />
                              )}
                              <div className="otp-form-control">
                                <p className="otp-title">2FA Secret Key</p>
                                <span className="otp-notice">
                                  Please copy "secret key" and store to secure
                                  location
                                </span>
                                <textarea
                                  readOnly
                                  value={otp.otp?.secret}
                                  className="otp-secret"
                                  cols={1}
                                  rows={isMobile ? 2 : 1}
                                  wrap="soft"
                                />
                              </div>
                              <div className="otp-form-control">
                                <p className="otp-title">2FA Recovery Key</p>
                                <span className="otp-notice">
                                  Please copy "recovery key" and store to secure
                                  location
                                </span>
                                <textarea
                                  readOnly
                                  value={recoveryKey}
                                  className="otp-recovery"
                                  cols={1}
                                  rows={isMobile ? 12 : 6}
                                  wrap="soft"
                                />
                              </div>
                              <div className="otp-input">
                                <OtpInput
                                  numInputs={6}
                                  value={otpInput}
                                  onChange={handleOtpInput}
                                  renderSeparator={
                                    <span>
                                      <strong>&middot;</strong>
                                    </span>
                                  }
                                  renderInput={(props) => <input {...props} />}
                                  inputStyle={styles.container(isMobile)}
                                />
                              </div>
                              <div className="otp-submit">
                                <button
                                  className="otp-btn"
                                  onClick={handleOtpSubmit}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {tab.active === 'sessions' && (
                    <div className="profile-tab">
                      <h3 className="title">Browser Sessions</h3>
                      <p className="description">
                        Manage and logout your active sessions on other browsers
                        and devices.
                      </p>
                      <div className="content">
                        <p>
                          If necessary, you may logout of all of your other
                          browser sessions across all of your devices. If you
                          feel your account has been compromised, you should
                          also update your password.
                        </p>
                        <div className="sessions">
                          <div className="session">
                            <div className="session-top">
                              {currentSession?.userAgent.isDesktop ? (
                                <FaDesktop className="icon" />
                              ) : currentSession?.userAgent.isMobile ? (
                                <FaMobileAlt className="icon" />
                              ) : (
                                <MdDeviceUnknown className="icon" />
                              )}
                              <div className="session-detail">
                                {currentSession?.userAgent.os}{' '}
                                <span>
                                  ({currentSession?.userAgent.browser})
                                </span>
                                <div className="session-ip">
                                  {currentSession?.userAgent.ip},
                                  <span className="session-current">
                                    This device
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="session-bottom">
                              <button
                                className="session-btn"
                                onClick={terminetSession}
                                disabled={sessions.length < 2 ? true : false}
                              >
                                Logout Other Browser Sessions
                              </button>
                            </div>
                          </div>
                          {sessions
                            .filter(
                              (session) => session._id !== currentSession?._id
                            )
                            .map((session) => (
                              <div className="session" key={session._id}>
                                <div className="session-top">
                                  {session.userAgent.isDesktop ? (
                                    <FaDesktop className="icon" />
                                  ) : session.userAgent.isMobile ? (
                                    <FaMobileAlt className="icon" />
                                  ) : (
                                    <MdDeviceUnknown className="icon" />
                                  )}
                                  <div className="session-detail">
                                    <p>
                                      {session.userAgent.os}{' '}
                                      <span>({session.userAgent.browser})</span>
                                    </p>
                                    <div className="session-ip">
                                      {session.userAgent.ip}
                                      <span className="session-current">
                                        Active
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {tab.active === 'delete-account' && (
                    <div className="profile-tab">
                      <h3 className="title">Delete Accont</h3>
                      <p className="description">
                        Permanently delete your account.
                      </p>
                      <div className="content">
                        <p>
                          Once your account is deleted, all of its resources and
                          data will be permanently deleted. Before deleting your
                          account, please download any data or information that
                          you wish to retain.
                        </p>
                        <div className="delete-account">
                          <button className="delete-account-btn">
                            Delete Account
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: (isMobile: boolean) => ({
    width: isMobile ? '2rem' : '2.5rem',
    height: isMobile ? '2rem' : '2.5rem',
    margin: isMobile ? '0 0.1rem' : '0 0.5rem',
    fontSize: isMobile ? '1rem' : '1.5rem',
    borderRadius: isMobile ? 2 : 4,
    border: '1px solid rgba(0,0,0,0.3)',
  }),
};

export default Profile;
