export enum RouteActionTypes {
  ROUTE_GO_BACK_SET = 'ROUTE_GO_BACK_SET',
  ROUTE_GO_BACK_REMOVE = 'ROUTE_GO_BACK_REMOVE',
}

export interface RouteGoBackSetAction {
  readonly type: RouteActionTypes.ROUTE_GO_BACK_SET;
  payload: string;
}

export interface RouteGoBackRemoveAction {
  readonly type: RouteActionTypes.ROUTE_GO_BACK_REMOVE;
}

export type Action = RouteGoBackSetAction | RouteGoBackRemoveAction;
