import { IUser } from '../../models/user.model';
import { customeLocalStorage } from '../../utils/local-storage';
import { authActions, otpActions } from '../actions';

export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  isAuthenticated: boolean | null;
  isLoading: boolean;
  user: IUser | null;
  pendingId: string | null;
  subject: string | null;
  message: string | null;
}

const initialState: AuthState = {
  accessToken: customeLocalStorage.get('accessToken'),
  refreshToken: customeLocalStorage.get('refreshToken'),
  isAuthenticated: customeLocalStorage.get('isLoggedin') || false,
  isLoading: false,
  user: null,
  pendingId: customeLocalStorage.get('pendingId') || null,
  subject: customeLocalStorage.get('subject') || null,
  message: null,
};

export const authReducer = (
  state: AuthState = initialState,
  action: authActions.Action | otpActions.Action
): AuthState => {
  switch (action.type) {
    case authActions.AuthActionTypes.USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case authActions.AuthActionTypes.LOGIN_SUCCESS:
    case authActions.AuthActionTypes.LOGIN_VERIFY_SUCCESS:
    case authActions.AuthActionTypes.LOGIN_USE_RECOVERY_CODE_SUCCESS:
      customeLocalStorage.set('accessToken', action.payload.accessToken);
      customeLocalStorage.set('refreshToken', action.payload.refreshToken);
      customeLocalStorage.set('isLoggedin', true);
      customeLocalStorage.set('pendingId', action.payload.pendingId);
      customeLocalStorage.set('subject', action.payload.subject);
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        pendingId: action.payload.pendingId,
        subject: action.payload.subject,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        user: action.payload.user,
        message: action.payload.message,
      };
    case authActions.AuthActionTypes.REGISTER_SUCCESS:
    case authActions.AuthActionTypes.REGISTER_VERIFY_SUCCESS:
    case authActions.AuthActionTypes.LOGIN_REQUEST_OTP:
      customeLocalStorage.set('pendingId', action.payload.pendingId);
      customeLocalStorage.set('subject', action.payload.subject);
      return {
        ...state,
        isLoading: false,
        pendingId: action.payload.pendingId,
        subject: action.payload.subject,
        message: action.payload.message,
      };
    case authActions.AuthActionTypes.USER_LOADED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: action.payload,
      };
    case otpActions.OtpActionTypes.OTO_VERIFY_SUCCESS:
    case otpActions.OtpActionTypes.OTO_DISABLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload.user,
      };
    case authActions.AuthActionTypes.AUTH_ERROR:
      customeLocalStorage.remove('accessToken');
      customeLocalStorage.remove('refreshToken');
      customeLocalStorage.remove('isLoggedin');
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        accessToken: null,
        refreshToken: null,
        user: null,
        message: action.payload.message,
      };
    case authActions.AuthActionTypes.LOGOUT_SUCCESS:
    case authActions.AuthActionTypes.LOGOUT_FAIL:
      customeLocalStorage.clear();
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        accessToken: null,
        refreshToken: null,
        user: null,
        message: action.payload.message,
      };
    case authActions.AuthActionTypes.REGISTER_FAIL:
    case authActions.AuthActionTypes.REGISTER_VERIFY_FAIL:
    case authActions.AuthActionTypes.LOGIN_FAIL:
    case authActions.AuthActionTypes.LOGIN_VERIFY_FAIL:
    case authActions.AuthActionTypes.LOGIN_USE_RECOVERY_CODE_FAIL:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        accessToken: null,
        refreshToken: null,
        user: null,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
