import { IErrorMessage } from '../../models/errormessage.model';
import { ITag } from '../../models/tag.model';

export enum TagActionTypes {
  TAG_LOADING = 'TAG_LOADING',
  TAG_POSTS_LOADED_SUCCESS = 'TAG_POSTS_LOADED_SUCCESS',
  TAG_POSTS_LOADED_FAIL = 'TAG_POSTS_LOADED_FAIL',
  TAG_FILES_LOADED_SUCCESS = 'TAG_FILES_LOADED_SUCCESS',
  TAG_FILES_LOADED_FAIL = 'TAG_FILES_LOADED_FAIL',
  TAG_TRADE_INSTRUMENT_LOADED_SUCCESS = 'TAG_TRADE_INSTRUMENT_LOADED_SUCCESS',
  TAG_TRADE_INSTRUMENT_LOADED_FAIL = 'TAG_TRADE_INSTRUMENT_LOADED_FAIL',
  TAG_TRADE_SETUP_LOADED_SUCCESS = 'TAG_TRADE_SETUP_LOADED_SUCCESS',
  TAG_TRADE_SETUP_LOADED_FAIL = 'TAG_TRADE_SETUP_LOADED_FAIL',
}

export interface TagLoadingAction {
  readonly type: TagActionTypes.TAG_LOADING;
}

export interface TagPostsLoadedSuccessAction {
  readonly type: TagActionTypes.TAG_POSTS_LOADED_SUCCESS;
  payload: ITag[];
}

export interface TagPostsLoadedFailAction {
  readonly type: TagActionTypes.TAG_POSTS_LOADED_FAIL;
  payload: IErrorMessage;
}

export interface TagFilesLoadedSuccessAction {
  readonly type: TagActionTypes.TAG_FILES_LOADED_SUCCESS;
  payload: ITag[];
}

export interface TagFilesLoadedFailAction {
  readonly type: TagActionTypes.TAG_FILES_LOADED_FAIL;
  payload: IErrorMessage;
}

export type Action =
  | TagLoadingAction
  | TagPostsLoadedSuccessAction
  | TagPostsLoadedFailAction
  | TagFilesLoadedSuccessAction
  | TagFilesLoadedFailAction;
