import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IPost } from '../../models/post.model';
import { IUser } from '../../models/user.model';
import { commentCreators } from '../../store/creators';
import './blog-comment-reply.css';
import InputBlogCommentReply from '../input-blog-comment-reply/InputBlogCommentReply';
import { AppState } from '../../store/app-state';

type ReplyProps = {
  user: IUser | null;
  post: IPost;
  parentId: string | number | null;
  commentId: string | number;
};

const BlogCommentReply = ({ user, post, parentId, commentId }: ReplyProps) => {
  const [text, setText] = useState('');

  const dispatch = useDispatch();

  const commentReplyId = useSelector(
    (state: AppState) => state.posts.commentReplyId
  );

  const handleEnter = () => {
    dispatch(
      commentCreators.createCommentPost({
        postId: post._id,
        fileId: null,
        text: text,
        parentId: parentId,
      })
    );
    dispatch(commentCreators.clearReplyCommentPost());
  };

  return (
    <>
      {commentReplyId === commentId && (
        <div className="blog-comments-child blog-comment-reply">
          {user && (
            <div className="user-avartar">
              <img src={user.avartar} alt="" />
            </div>
          )}
          <InputBlogCommentReply
            value={text}
            onChange={setText}
            onEnter={handleEnter}
            focus={commentReplyId === commentId}
          />
        </div>
      )}
    </>
  );
};

export default BlogCommentReply;
