import React from 'react';
import { Link } from 'react-router-dom';
import './blog-info.css';

const BlogInfo = () => {
  return (
    <div className="info">
      <div className="info-detail">
        <Link to="about">About</Link>
        <Link to="about">Contact</Link>
        <Link to="about">Terms</Link>
        <Link to="about">Privacy</Link>
      </div>
      <span className="copy-right">© 2022 TradeS</span>
    </div>
  );
};

export default BlogInfo;
