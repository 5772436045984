import React, { ChangeEvent, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/app-state';
import { postCreators } from '../../store/creators';
import { validUrl, validVideoUrl } from '../../utils/video';
import VideoEmbed, { IVideoEmbed } from '../video-embed/VideoEmbed';
import './video-embed-preview.css';

type VideoEmbedPreviewProps = {
  showVideo: boolean;
  setShowVideo: React.Dispatch<React.SetStateAction<boolean>>;
  video: IVideoEmbed;
  setVideo: React.Dispatch<React.SetStateAction<IVideoEmbed>>;
};

const VideoEmbedPreview = ({
  showVideo,
  setShowVideo,
  video,
  setVideo,
}: VideoEmbedPreviewProps) => {
  const [error, setError] = useState(false);

  const dispatch = useDispatch();

  const postInStore = useSelector((state: AppState) => state.posts);

  useEffect(() => {
    if (validVideoUrl(video.url) || validUrl(video.url) || video.url === '') {
      setError(false);
    } else {
      setError(true);
    }
  }, [video]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVideo({ ...video, url: e.target.value });
  };

  const handleChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setVideo({ ...video, isPortrait: e.target.checked });
  };

  const handleClose = () => {
    dispatch(postCreators.showVideo(!postInStore.showVideo));
    setVideo({ url: '', isPortrait: false });
    setError(false);
  };

  return (
    <>
      {showVideo && (
        <div className="video-embed-preview">
          <div className="video-embed-preview__close" onClick={handleClose}>
            <MdClose />
          </div>
          <div className="video-embed-preview__input">
            <div>
              <input
                type="text"
                value={video.url}
                onChange={handleChange}
                placeholder="URL: Youtube, Tiktok, Facebook or Dailymotion..."
              />
              <label>
                <input
                  type="checkbox"
                  checked={video.isPortrait}
                  onChange={handleChangeCheckbox}
                />
                Portrait
              </label>
            </div>
            {error && <span>Url is invalid</span>}
          </div>
          {!error && (
            <div className="video-embed-preview__video">
              <VideoEmbed video={video} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default VideoEmbedPreview;
