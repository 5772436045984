export function debounce(func: Function, wait: number, immediate: boolean) {
  let timeout: NodeJS.Timeout | null;

  return function (this: any, ...args: any[]) {
    const context = this;

    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
}

// https://rajc1729.medium.com/create-a-production-ready-infinite-scrolling-in-react-js-using-power-of-debounce-throttle-and-38afa645ef4d
