import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { AiOutlineTag } from 'react-icons/ai';
import { BsImageFill } from 'react-icons/bs';
import {
  FaArrowLeft,
  FaCloudUploadAlt,
  FaGlobeAsia,
  FaLock,
  FaRegSmile,
  FaRegUser,
  FaUserFriends,
} from 'react-icons/fa';
import {
  MdCenterFocusWeak,
  MdClose,
  MdOutlineAddToPhotos,
  MdRadar,
  MdVideoLibrary,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Descendant } from 'slate';
import { config } from '../../config';
import { IFile } from '../../models/file.model';
import { ITag, TagOption } from '../../models/tag.model';
import { AppState } from '../../store/app-state';
import { postCreators, scrollbarCreators } from '../../store/creators';
import { createFileFromMockFile } from '../../utils/file.utils';
import ImageGridPreview from '../image-grid-preview/ImageGridPreview';
import SelectAndCreateTags, {
  DiffHandleProps,
} from '../select-and-create-tags/SelectAndCreateTags';
import TextCardPreview from '../text-card-preview/TextCardPreview';
import { ITextCard } from '../text-card/TextCard';
import TextEditor from '../text-editor/TextEditor';
import VideoEmbedPreview from '../video-embed-preview/VideoEmbedPreview';
import { IVideoEmbed } from '../video-embed/VideoEmbed';
import './blog-modal.css';
import { IActivityEmbed } from '../activity-embed/ActivityEmbed';
import ActivityEmbedPreview from '../activity-embed-preview/ActivityEmbedPreview';

const BlogModal = () => {
  let initialValue: Descendant[] = [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ];

  const [value, setValue] = useState(initialValue);
  const [showAudience, setShowAudience] = useState(false);
  const [audience, setAudience] = useState('Only Me');
  const [showTags, setShowTags] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const [showVideo, setShowVideo] = useState(false);
  const [video, setVideo] = useState<IVideoEmbed>({
    url: '',
    isPortrait: false,
  });
  const [showActivity, setShowActivity] = useState(false);
  const [activites, setActivites] = useState<IActivityEmbed[]>([
    {
      id: uuid(),
      activity: '',
      score: 0,
    },
  ]);
  const [showTextCard, setShowTextCard] = useState(false);
  const [textCard, setTextCard] = useState<ITextCard>({
    text: '',
    color: '#cfffe3',
  });
  const [tags, setTags] = useState<ITag[]>([]);
  const [textDiff, setTextDiff] = useState<Object[] | []>([]);
  const [selectTags, setSelectTags] = useState<readonly TagOption[]>([]);

  const selectRef = useRef<DiffHandleProps>(null);

  const dispatch = useDispatch();
  const systemTags = useSelector((state: AppState) => state.tags.postTags);

  const uploadRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const modal = useSelector((state: AppState) => state.posts.modal);
  const token = useSelector((state: AppState) => state.auth.accessToken);
  const postInStore = useSelector((state: AppState) => state.posts);
  const scrollPosition = useSelector((state: AppState) => state.srollbar);

  // useEffect(() => {
  //   if (modal) {
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.overflow = 'unset';
  //   }
  // }, [modal]);

  useEffect(() => {
    if (postInStore.edit) {
      dispatch(postCreators.toggleModal());
    }
  }, [dispatch, postInStore.edit]);

  useEffect(() => {
    if (postInStore.current && postInStore.current.tags.length > 0) {
      setTags(postInStore.current.tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postInStore.edit]);

  useEffect(() => {
    const imageSrc = (image: string) =>
      `${config.serverUrl}/api/files/${image}?token=${token}`;

    const getImages = (files: IFile[]) => {
      let arr: string[] = [];
      Array.from(files).forEach((file) => {
        arr.push(imageSrc(file._id));
      });
      return arr;
    };

    const demoFiles = (files: IFile[]) => {
      let arr: File[] = [];
      Array.from(files).forEach(() => {
        let file = { name: 'demo', body: 'demo', mimeType: 'image/jpeg' };
        arr.push(createFileFromMockFile(file));
      });
      return arr;
    };

    if (postInStore.current && postInStore.edit) {
      setImages(getImages(postInStore.current.files));
      setSelectedFiles(demoFiles(postInStore.current.files));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postInStore.edit, token]);

  useEffect(() => {
    if (postInStore.current) {
      setValue(JSON.parse(postInStore.current.content));
      setAudience(postInStore.current.audience);
    }
  }, [dispatch, postInStore, token]);

  useEffect(() => {
    if (
      (postInStore.current && postInStore.current.files.length > 0) ||
      postInStore.showUpload
    ) {
      setShowUpload(true);
    } else {
      setShowUpload(false);
    }
  }, [dispatch, postInStore]);

  useEffect(() => {
    if (postInStore.showVideo) {
      setShowVideo(true);
    } else {
      setShowVideo(false);
    }
  }, [dispatch, postInStore.showVideo]);

  useEffect(() => {
    if (postInStore.showActivity) {
      setShowActivity(true);
    } else {
      setShowActivity(false);
    }
  }, [dispatch, postInStore.showActivity]);

  useEffect(() => {
    if (postInStore.current) {
      // Video
      postInStore.current.video.length > 29 &&
        setVideo(JSON.parse(postInStore.current.video));
      if (postInStore.current.video.length > 29) {
        setShowVideo(true);
      } else {
        setShowVideo(false);
      }

      // Activity
      postInStore.current.activities &&
        postInStore.current.activities.length > 71 &&
        setActivites(JSON.parse(postInStore.current.activities));
      if (
        postInStore.current.activities &&
        postInStore.current.activities.length > 71
      ) {
        setShowActivity(true);
      } else {
        setShowActivity(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, postInStore.edit]);

  useEffect(() => {
    if (showVideo) {
      dispatch(postCreators.showVideo(true));
    }
  }, [dispatch, showVideo]);

  useEffect(() => {
    if (showActivity) {
      dispatch(postCreators.showActivity(true));
    }
  }, [dispatch, showActivity]);

  useEffect(() => {
    if (postInStore.current) {
      const highlight: ITextCard =
        postInStore.current.highlight &&
        JSON.parse(postInStore.current.highlight);
      if (postInStore.current.highlight && highlight.text.length > 0) {
        setTextCard(highlight);
        setShowTextCard(true);
      } else {
        setShowTextCard(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postInStore.edit]);

  // useEffect(() => {
  //   if (!showTextCard) {
  //     setTextCard({ text: '', color: '#cfffe3' });
  //   }
  // }, [showTextCard]);

  useEffect(() => {
    selectRef?.current?.start();
  }, [selectTags]);

  const handleDiff = (data: Object[] | []) => {
    setTextDiff(data);
  };

  // save conten to database
  const content = JSON.stringify(value);

  const handleCloseModal = () => {
    dispatch(postCreators.toggleModal());
    dispatch(
      scrollbarCreators.setPositionScollbar(
        scrollPosition.positionXC,
        scrollPosition.positionYC
      )
    );

    setValue(initialValue);
    setAudience('Only Me');
    dispatch(postCreators.showUpload(false));
    dispatch(postCreators.showVideo(false));
    dispatch(postCreators.showActivity(false));
    setVideo({ url: '', isPortrait: false });
    setShowTextCard(false);
    setTextCard({ text: '', color: '#cfffe3' });
    setSelectTags([]);
    setTags([]);
    setShowTags(false);
    resetUpload();

    if (postInStore.edit) {
      dispatch(postCreators.clearEditPost());
    }
  };

  const handleAudience = (a: string) => {
    setAudience(a);
    setShowAudience(!showAudience);
  };

  const handleVideo = () => {
    dispatch(postCreators.showVideo(!postInStore.showVideo));
    setVideo({ url: '', isPortrait: false });
  };

  const handleActivity = () => {
    dispatch(postCreators.showActivity(!postInStore.showActivity));
    setActivites([
      {
        id: uuid(),
        activity: '',
        score: 0,
      },
    ]);
  };

  const handleTextCard = () => {
    setShowTextCard(!showTextCard);
  };

  const handleUpload = () => {
    dispatch(postCreators.showUpload(!postInStore.showUpload));
  };

  const handleCloseUpload = () => {
    dispatch(postCreators.showUpload(false));
    resetUpload();
  };

  const handleUploadClick = () => {
    uploadRef.current.click();
  };

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      Array.from(files).forEach((file) => {
        // Accept image only and image size less than 1 MB
        if (file.type.match('image.*') && file.size <= 2 * 1024 * 1024) {
          setSelectedFiles((prevArray) => [...prevArray, file]);

          let reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onloadend = function () {
            setImages((oldArray) => [...oldArray, reader.result as string]);
          };
        }
      });
    }
  };

  const handleUploadChange = (event: ChangeEvent<HTMLInputElement>) => {
    let files = event.target.files;
    files &&
      Array.from(files).forEach((file) => {
        // Accept image only and image size less than 1 MB
        if (file.type.match('image.*') && file.size <= 2 * 1024 * 1024) {
          setSelectedFiles((prevArray) => [...prevArray, file]);

          let reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onloadend = function () {
            setImages((oldArray) => [...oldArray, reader.result as string]);
          };
        }
      });
  };

  const resetUpload = () => {
    if (uploadRef.current) uploadRef.current.value = '';
    setImages([]);
    setSelectedFiles([]);
  };

  const onDeleteEmpty = () => {
    resetUpload();
  };

  const onCloseLightbox = () => {
    uploadRef.current.value = '';
  };

  const handleSubmit = () => {
    const formData = new FormData();

    formData.append('content', content);

    formData.append('audience', audience);

    formData.append('highlight', JSON.stringify(textCard));

    formData.append('video', JSON.stringify(video));

    formData.append('activities', JSON.stringify(activites));

    formData.append('tags', JSON.stringify(textDiff));

    if (postInStore.edit) {
      Array.from(selectedFiles).forEach((file) => {
        if (file.name !== 'demo') {
          formData.append('files', file);
        }
      });

      if (postInStore.current) {
        dispatch(postCreators.updatePost(postInStore.current?._id, formData));
        dispatch(
          scrollbarCreators.setPositionScollbar(
            scrollPosition.positionXC,
            scrollPosition.positionYC
          )
        );
      }
    } else {
      // Upload file
      Array.from(selectedFiles).forEach((file) => {
        formData.append('files', file);
      });

      dispatch(postCreators.createPost(formData));
    }

    // Clear data
    dispatch(postCreators.toggleModal());
    dispatch(postCreators.showUpload(false));
    setSelectedFiles([]);
    setImages([]);
    setValue(initialValue);
    setSelectTags([]);
    setShowTags(false);
    setTags([]);
    setAudience('Only Me');
    dispatch(postCreators.showVideo(false));
    setVideo({ url: '', isPortrait: false });
    dispatch(postCreators.showActivity(false));
    setActivites([
      {
        id: uuid(),
        activity: '',
        score: 0,
      },
    ]);
    setShowTextCard(false);
    setTextCard({ text: '', color: '#cfffe3' });
  };

  return (
    <>
      {modal && (
        <>
          <div className={`blog-modal center ${modal ? 'active' : ''}`}>
            <div className="blog-modal-close" onClick={handleCloseModal}>
              <MdClose />
            </div>
            <div className="input-box">
              <div className="tweet-area">
                <TextEditor
                  value={value}
                  setValue={setValue}
                  toolbarStyle="both"
                />
              </div>
              <TextCardPreview
                textCard={textCard}
                setTextCard={setTextCard}
                showTextCard={showTextCard}
                setShowTextCard={setShowTextCard}
              />
              <VideoEmbedPreview
                showVideo={showVideo}
                setShowVideo={setShowVideo}
                video={video}
                setVideo={setVideo}
              />
              <ActivityEmbedPreview
                showActivity={showActivity}
                setShowActivity={setShowActivity}
                activites={activites}
                setActivites={setActivites}
              />
              {images.length > 0 && (
                <div className="image-grid-preview">
                  <div className="image-preview-close" onClick={resetUpload}>
                    <MdClose />
                  </div>
                  {images.length > 0 && (
                    <div
                      className="image-preview-add-more"
                      onClick={handleUploadClick}
                    >
                      <MdOutlineAddToPhotos /> <span>Add Photos</span>
                      <input
                        type="file"
                        ref={uploadRef}
                        onChange={handleUploadChange}
                        style={{ display: 'none' }}
                        accept="image/*"
                        multiple
                      />
                    </div>
                  )}
                  <ImageGridPreview
                    images={images}
                    files={selectedFiles}
                    countFrom={5}
                    hideOverlay={true}
                    onDeleteEmpty={onDeleteEmpty}
                    onCloseLightbox={onCloseLightbox}
                    edit={postInStore.edit}
                  />
                </div>
              )}
              {showUpload && images.length === 0 && (
                <div className="blog-upload">
                  <div
                    className="blog-upload__close"
                    onClick={handleCloseUpload}
                  >
                    <MdClose />
                  </div>
                  <div
                    className="drop-container"
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                    onClick={handleUploadClick}
                  >
                    <div className="drop-message">
                      <div className="upload-icon">
                        <FaCloudUploadAlt className="icon" />
                      </div>
                      Drag & Drop files here or click to upload
                    </div>
                    <input
                      type="file"
                      ref={uploadRef}
                      onChange={handleUploadChange}
                      style={{ display: 'none' }}
                      accept="image/*"
                      multiple
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="blog-modal-action">
              <div
                className="privacy"
                onClick={() => setShowAudience(!showAudience)}
              >
                {audience === 'Public' && (
                  <>
                    <FaGlobeAsia />
                    <span>{audience}</span>
                  </>
                )}
                {audience === 'Friends' && (
                  <>
                    <FaUserFriends />
                    <span>{audience}</span>
                  </>
                )}
                {audience === 'Only Me' && (
                  <>
                    <FaLock />
                    <span>{audience}</span>
                  </>
                )}
              </div>
              <div className="blog-tags" onClick={() => setShowTags(!showTags)}>
                {selectTags.length > 0 ? (
                  selectTags.map((tag, i) => (
                    <div className="blog-tags-item" key={i}>
                      <AiOutlineTag />
                      <span>{tag.value}</span>
                    </div>
                  ))
                ) : tags.length > 0 ? (
                  tags.map((tag, i) => (
                    <div className="blog-tags-item" key={i}>
                      <AiOutlineTag />
                      <span>{tag.name}</span>
                    </div>
                  ))
                ) : (
                  <div className="blog-tags-item">
                    <AiOutlineTag />
                    <span>Tags</span>
                  </div>
                )}
              </div>
            </div>
            {showAudience && (
              <section className="audience">
                <header>
                  <div
                    className="arrow-back"
                    onClick={() => setShowAudience(!showAudience)}
                  >
                    <FaArrowLeft />
                  </div>
                  <p>Select Audience</p>
                </header>
                <div className="content">
                  <p>Who can see your post?</p>
                  <span>
                    Your post will show up in News Feed, on your profile and in
                    search results.
                  </span>
                </div>
                <ul className="list">
                  <li
                    className={audience === 'Public' ? 'active' : ''}
                    onClick={() => handleAudience('Public')}
                  >
                    <div className="column">
                      <div className="icon">
                        <FaGlobeAsia />
                      </div>
                      <div className="details">
                        <p>Public</p>
                        <span>Anyone on or off TradeS</span>
                      </div>
                    </div>
                    <div className="radio" />
                  </li>
                  <li
                    className={audience === 'Friends' ? 'active' : ''}
                    onClick={() => handleAudience('Friends')}
                  >
                    <div className="column">
                      <div className="icon">
                        <FaUserFriends />
                      </div>
                      <div className="details">
                        <p>Friends</p>
                        <span>Your friends on TradeS</span>
                      </div>
                    </div>
                    <div className="radio" />
                  </li>
                  <li
                    className={audience === 'Only Me' ? 'active' : ''}
                    onClick={() => handleAudience('Only Me')}
                  >
                    <div className="column">
                      <div className="icon">
                        <FaLock />
                      </div>
                      <div className="details">
                        <p>Only me</p>
                        <span>Only you can see your post</span>
                      </div>
                    </div>
                    <div className="radio" />
                  </li>
                </ul>
              </section>
            )}
            {showTags && (
              <section className="blog-tags-select">
                <header>
                  <div
                    className="arrow-back"
                    onClick={() => setShowTags(!showTags)}
                  >
                    <FaArrowLeft />
                  </div>
                  <p>Select Tags</p>
                </header>
                <div className="content">
                  <SelectAndCreateTags
                    systemTags={systemTags}
                    tags={tags}
                    postId={null}
                    fileId={null}
                    diff={handleDiff}
                    ref={selectRef}
                    selectTags={selectTags}
                    setSelectTags={setSelectTags}
                  />
                </div>
              </section>
            )}
            <div className="bottom-line"></div>
            <div className="bottom">
              <ul className="icons">
                <li onClick={handleTextCard}>
                  <MdCenterFocusWeak />
                </li>
                <li onClick={handleUpload}>
                  <BsImageFill />
                </li>
                <li onClick={handleVideo}>
                  <MdVideoLibrary />
                </li>
                <li onClick={handleActivity}>
                  <MdRadar />
                </li>
                <li>
                  <FaRegSmile />
                </li>
                <li>
                  <FaRegUser />
                </li>
              </ul>
              <div className="content">
                <button
                  className={`${
                    content.length > 50 ||
                    images.length > 0 ||
                    video.url.length > 0 ||
                    activites.length > 1 ||
                    (textCard.text.length > 0 && textCard.text.length <= 100)
                      ? 'active'
                      : ''
                  }`}
                  onClick={handleSubmit}
                >
                  {postInStore.edit ? 'Update' : 'Post'}
                </button>
              </div>
            </div>
          </div>
          <div className={`blog-modal-overlay ${modal ? 'active' : ''}`} />
        </>
      )}
    </>
  );
};

export default BlogModal;
