import { ICommments } from '../../models/comment.model';
import { IPost } from '../../models/post.model';
import { pinedPostAtFirst } from '../../utils/pin';
import { commentActions, postActions } from '../actions';

export interface PostState {
  isLoading: boolean;
  all: IPost[] | [];
  posts: IPost[] | [];
  available: number;
  filter: string;
  modal: boolean;
  showUpload: boolean;
  showVideo: boolean;
  showActivity: boolean;
  edit: boolean;
  current: IPost | null;
  commenIsLoading: boolean;
  commentReplyId: string | number | null;
  commentFocusId: string | number | null;
  commentPostId: string | number | null;
  commentEdit: boolean;
  commentParent: ICommments | null;
  commentCurrent: ICommments | null;
  message: string | null;
}

const initialState: PostState = {
  isLoading: false,
  all: [],
  posts: [],
  available: 0,
  filter: 'All',
  modal: false,
  showUpload: false,
  showVideo: false,
  showActivity: false,
  edit: false,
  current: null,
  commenIsLoading: false,
  commentReplyId: null,
  commentFocusId: null,
  commentPostId: null,
  commentEdit: false,
  commentParent: null,
  commentCurrent: null,
  message: null,
};

export const postReducer = (
  state: PostState = initialState,
  action: postActions.Action | commentActions.Action
): PostState => {
  switch (action.type) {
    case postActions.PostActionTypes.POST_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case commentActions.CommentActionTypes.COMMENT_POST_LOADING:
      return {
        ...state,
        commenIsLoading: true,
      };

    case postActions.PostActionTypes.POST_LOADED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        all: action.payload.posts,
        posts: action.payload.posts,
        available: action.payload.available,
      };

    case postActions.PostActionTypes.POST_MODAL_TOGGLE:
      return {
        ...state,
        modal: !state.modal,
      };

    case postActions.PostActionTypes.POST_UPLOAD_SHOW_OR_HIDE:
      return {
        ...state,
        showUpload: action.payload,
      };

    case postActions.PostActionTypes.POST_VIDEO_SHOW_OR_HIDE:
      return {
        ...state,
        showVideo: action.payload,
      };

    case postActions.PostActionTypes.POST_ACTIVITY_SHOW_OR_HIDE:
      return {
        ...state,
        showActivity: action.payload,
      };

    case postActions.PostActionTypes.POST_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        all: [action.payload.post, ...state.posts],
        posts: [action.payload.post, ...state.posts],
        message: action.payload.message,
        available: state.available + 1,
      };

    case postActions.PostActionTypes.POST_SET_EDIT:
      return {
        ...state,
        edit: action.payload.edit,
        current: action.payload.current,
      };

    case postActions.PostActionTypes.POST_CLEAR_EDIT:
      return {
        ...state,
        edit: false,
        current: null,
      };

    case postActions.PostActionTypes.POST_UPDATE_SUCCESS:
      return {
        ...state,
        all: state.all.map((post) => {
          if (post._id === action.payload.post._id) {
            return (post = action.payload.post);
          }
          return post;
        }),
        posts: state.posts.map((post) => {
          if (post._id === action.payload.post._id) {
            return (post = action.payload.post);
          }
          return post;
        }),
        message: action.payload.message,
        edit: false,
        current: null,
      };

    case postActions.PostActionTypes.POST_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        all: state.all.filter((post) => post._id !== action.payload.post._id),
        posts: state.posts.filter(
          (post) => post._id !== action.payload.post._id
        ),
        message: action.payload.message,
        available: state.available - 1,
      };

    case postActions.PostActionTypes.POST_DELETE_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        all: state.all.map((post) => {
          if (post._id === action.payload.post._id) {
            let newFiles = post.files.filter(
              (file) => file._id !== action.payload.fileId
            );
            post.files = newFiles;
          }
          return post;
        }),
        posts: state.posts.map((post) => {
          if (post._id === action.payload.post._id) {
            let newFiles = post.files.filter(
              (file) => file._id !== action.payload.fileId
            );
            post.files = newFiles;
          }
          return post;
        }),
        message: action.payload.message,
      };

    case postActions.PostActionTypes.POST_FILTER:
      return {
        ...state,
        isLoading: false,
        posts:
          action.payload.tag === 'All'
            ? state.all
            : action.payload.tag === 'Love'
            ? state.all.filter((post) => {
                if (
                  post.likes.filter(
                    (like) => like.userId === action.payload.user?._id
                  ).length > 0
                ) {
                  return true;
                }
                return false;
              })
            : state.all.filter((post) => {
                if (
                  post.tags.filter((tag) => tag.name === action.payload.tag)
                    .length > 0
                ) {
                  return true;
                }
                return false;
              }),
        filter: action.payload.tag,
      };

    case postActions.PostActionTypes.POST_CLICK_LIKE:
      return {
        ...state,
        isLoading: false,
        posts: state.posts.map((post) => {
          if (post._id === action.payload.post._id) {
            const postLike = post.likes as any;
            postLike.push(action.payload.like);
            return post;
          }
          return post;
        }),
        message: action.payload.message,
      };

    case postActions.PostActionTypes.POST_CLICK_UNLIKE:
      return {
        ...state,
        isLoading: false,
        posts: state.posts.filter((post) => {
          if (post._id === action.payload.post._id) {
            let userLike: any;
            userLike = post.likes.filter(
              (like) => like.userId !== action.payload.unlike.userId
            );
            return (post.likes = userLike);
          }
          return post;
        }),
        message: action.payload.message,
      };

    case postActions.PostActionTypes.POST_CLICK_PINED_OR_UNPINED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        posts: pinedPostAtFirst(
          state.posts.map((post) => {
            if (post._id === action.payload.post._id) {
              return (post = action.payload.post);
            }
            return post;
          })
        ),
        message: action.payload.message,
      };

    case commentActions.CommentActionTypes.COMMENT_POST_CLICK_FOCUS_INPUT:
      return {
        ...state,
        commentReplyId: null,
        commentFocusId: action.payload,
      };

    case commentActions.CommentActionTypes.COMMENT_POST_SET_REPLY:
      return {
        ...state,
        commentReplyId: action.payload.commentId,
        commentFocusId: null,
      };

    case commentActions.CommentActionTypes.COMMENT_POST_CLEAR_CLICK_FOCUS_INPUT:
      return {
        ...state,
        commentFocusId: null,
      };

    case commentActions.CommentActionTypes.COMMENT_POST_CLEAR_REPLY:
      return {
        ...state,
        commentReplyId: null,
      };

    case commentActions.CommentActionTypes.COMMENT_POST_CREATE_SUCCESS:
      return {
        ...state,
        commenIsLoading: false,
        commentFocusId: null,
        posts: state.posts.map((post) => {
          if (post._id === action.payload.postId) {
            let postComments = post.comments as any;
            postComments.push(action.payload.comment);
            return post;
          }
          return post;
        }),
      };

    case commentActions.CommentActionTypes.COMMENT_POST_CLICK_LIKE:
      return {
        ...state,
        commenIsLoading: false,
        posts: state.posts.map((post) => {
          if (post._id === action.payload.postId) {
            post.comments.map((comment) => {
              if (comment._id === action.payload.commentId) {
                let commentLikes = comment.likes as any;
                commentLikes.push(action.payload.like);
                return comment;
              }
              return comment;
            });
            return post;
          }
          return post;
        }),
        message: action.payload.message,
      };

    case commentActions.CommentActionTypes.COMMENT_POST_CLICK_UNLIKE:
      return {
        ...state,
        commenIsLoading: false,
        posts: state.posts.map((post) => {
          if (post._id === action.payload.postId) {
            post.comments.filter((comment) => {
              if (comment._id === action.payload.commentId) {
                let userLike: any;
                userLike = comment.likes.filter(
                  (like) => like.userId !== action.payload.unlike.userId
                );
                return (comment.likes = userLike);
              }
              return comment;
            });
            return post;
          }
          return post;
        }),
        message: action.payload.message,
      };

    case commentActions.CommentActionTypes.COMMENT_POST_DELETE_SUCCESS:
      return {
        ...state,
        commenIsLoading: false,
        posts: state.posts.map((post) => {
          if (post._id === action.payload.post._id) {
            let postComments = post.comments.filter((comment) => {
              if (
                comment._id === action.payload.commentId ||
                comment.parentId === action.payload.commentId
              ) {
                return false;
              }

              return true;
            });
            post.comments = postComments;
          }
          return post;
        }),
        message: action.payload.message,
      };

    case commentActions.CommentActionTypes.COMMENT_POST_SET_EDIT:
      return {
        ...state,
        commentReplyId: null,
        commentFocusId: null,
        commentPostId: action.payload.postId,
        commentEdit: action.payload.edit,
        commentCurrent: action.payload.current,
        commentParent: action.payload.parent,
      };

    case commentActions.CommentActionTypes.COMMENT_POST_CLEAR_EDIT:
      return {
        ...state,
        commentPostId: null,
        commentEdit: false,
        commentCurrent: null,
        commentParent: null,
      };

    case commentActions.CommentActionTypes.COMMENT_POST_UPDATE_SUCCESS:
      return {
        ...state,
        commenIsLoading: false,
        posts: state.posts.map((post) => {
          if (post._id === action.payload.postId) {
            let postComments: any;
            postComments = post.comments.map((comment) =>
              comment._id === action.payload.comment._id
                ? action.payload.comment
                : comment
            );
            post.comments = postComments;

            return post;
          }
          return post;
        }),
        commentPostId: null,
        commentEdit: false,
        commentCurrent: null,
        commentParent: null,
        message: action.payload.message,
      };

    case postActions.PostActionTypes.POST_LOADED_FAIL:
    case postActions.PostActionTypes.POST_CREATE_FAIL:
    case postActions.PostActionTypes.POST_UPDATE_FAIL:
    case postActions.PostActionTypes.POST_DELETE_FAIL:
    case postActions.PostActionTypes.POST_DELETE_FILE_FAIL:
    case postActions.PostActionTypes.POST_CLICK_LIKE_OR_UNLIKE_ERROR:
    case postActions.PostActionTypes.POST_CLICK_PINED_OR_UNPINED_FAIL:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };

    case commentActions.CommentActionTypes.COMMENT_POST_CREATE_FAIL:
    case commentActions.CommentActionTypes
      .COMMENT_POST_CLICK_LIKE_OR_UNLIKE_FAIL:
    case commentActions.CommentActionTypes.COMMENT_POST_DELETE_FAIL:
    case commentActions.CommentActionTypes.COMMENT_POST_UPDATE_FAIL:
      return {
        ...state,
        commenIsLoading: false,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
