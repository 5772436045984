import React, { useRef, useState } from 'react';
import {
  BsBookmark,
  BsFillBookmarkCheckFill,
  BsThreeDots,
} from 'react-icons/bs';
import { FaHeart, FaRegCommentDots, FaRegHeart, FaStar } from 'react-icons/fa';
import { BiEditAlt } from 'react-icons/bi';
import { RiShareForwardLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';
import { IPost } from '../../models/post.model';
import { AppState } from '../../store/app-state';
import BlogComments from '../blog-comments/BlogComments';
import ImageGrid from '../image-grid/ImageGrid';
import InputBlogComment from '../input-blog-comment/InputBlogComment';
import './blog-post.css';
import { TiDocumentDelete } from 'react-icons/ti';
import ConfirmPopup from '../confirm-popup/ConfirmPopup';
import {
  commentCreators,
  postCreators,
  scrollbarCreators,
} from '../../store/creators';
import TextEditorDisplay from '../text-editor/TextEditorDisplay';
import { AiOutlineTag } from 'react-icons/ai';
import { useOnClickOutside } from '../../hooks';
import { contains } from '../../utils/contains';
import { IUser } from '../../models/user.model';
import VideoEmbed from '../video-embed/VideoEmbed';
import TextCard from '../text-card/TextCard';
import ActivityEmbed from '../activity-embed/ActivityEmbed';

type PostProps = {
  post: IPost;
};

const BlogPost = ({ post }: PostProps) => {
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [blogActions, setBlogActions] = useState(false);
  const [textComment, setTextComment] = useState('');

  const dispatch = useDispatch();

  const btnBlogActionsRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const blogActionsRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const textCard = post.highlight && JSON.parse(post.highlight);
  const initialValue = JSON.parse(post.content);

  const user = useSelector((state: AppState) => state.auth.user);
  const active = useSelector((state: AppState) => state.posts.filter);
  const scrollPosition = useSelector((state: AppState) => state.srollbar);
  const commentReplyId = useSelector(
    (state: AppState) => state.posts.commentReplyId
  );
  const focusId = useSelector((state: AppState) => state.posts.commentFocusId);

  const handleEdit = () => {
    dispatch(
      scrollbarCreators.trackingScollbarCustom(
        scrollPosition.positionX,
        scrollPosition.positionY
      )
    );
    dispatch(postCreators.setEditPost(post, true));
  };

  const handleDelete = () => {
    setShowConfirmPopup(true);
  };

  const handleConfirmation = () => {
    dispatch(postCreators.deletePost(post));
  };

  const handleTagName = (tag: string) => {
    dispatch(postCreators.postFilter(tag, user));
  };

  const clickOutsideHandler = (event: Event) => {
    if (!btnBlogActionsRef.current?.contains(event?.target as Node)) {
      setBlogActions(false);
    }
  };

  useOnClickOutside(blogActionsRef, clickOutsideHandler);

  const handleLike = (post: IPost, user: IUser | null, status: boolean) => {
    dispatch(postCreators.postClickLikeOrUnlike(post, user, status));
  };

  const handlePinPost = (post: IPost) => {
    dispatch(postCreators.postClickPinedOrUnPind(post));
  };

  const handleFocusInput = (postId: string | number) => {
    if (commentReplyId) {
      dispatch(commentCreators.clickPostFocusInputComment(postId));
    } else if (focusId) {
      dispatch(commentCreators.clearClickPostFocusInputComment());
    } else {
      dispatch(commentCreators.clickPostFocusInputComment(postId));
    }
  };

  const handleEnter = () => {
    dispatch(
      commentCreators.createCommentPost({
        postId: post._id,
        fileId: null,
        text: textComment,
        parentId: null,
      })
    );
  };

  return (
    <>
      <ConfirmPopup
        showConfirmPopup={showConfirmPopup}
        setShowConfirmPopup={setShowConfirmPopup}
        handleConfirmation={handleConfirmation}
      />
      <div className="post">
        <div className="post-header">
          <div className="post-header-left">
            <img src={post.user.avartar} alt="" />
            <div className="post-user-info">
              <div className="post-username">
                <Link to={`/${post.user.username}`}>{post.user.username}</Link>{' '}
                &bull; <Link to={`/${post.user.username}`}>Follow</Link>
              </div>
              <div className="post-date">
                <Link to={`/${post.user.username}`}>
                  <ReactTimeAgo
                    date={new Date(post.createdAt)}
                    locale="en-US"
                    timeStyle="round-minute"
                  />
                </Link>
                <FaStar className="icon" />
              </div>
            </div>
          </div>
          <div className="post-header-right">
            <div className="right-icon" onClick={() => handlePinPost(post)}>
              {post.pin ? (
                <BsFillBookmarkCheckFill className="icon pin" />
              ) : (
                <BsBookmark className="icon" />
              )}
            </div>
            <div
              ref={btnBlogActionsRef}
              className="right-icon"
              onClick={() => setBlogActions(!blogActions)}
            >
              <BsThreeDots className="icon" />
            </div>
            {blogActions && (
              <div className="blog-actions" ref={blogActionsRef}>
                <div className="blog-actions-list">
                  <div className="blog-actions-list-item" onClick={handleEdit}>
                    <BiEditAlt className="icon" /> Edit
                  </div>
                  <div
                    className="blog-actions-list-item"
                    onClick={handleDelete}
                  >
                    <TiDocumentDelete className="icon" /> Delete
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="post-content">
          {post.highlight && textCard.text !== '' && (
            <TextCard textCard={textCard} />
          )}
          {post.content.length > 47 && (
            <TextEditorDisplay value={initialValue} />
          )}
          {post.activities && post.activities.length > 71 && (
            <ActivityEmbed activities={JSON.parse(post.activities)} />
          )}
          {post.video.length > 29 && (
            <VideoEmbed video={JSON.parse(post.video)} />
          )}
          <ImageGrid
            images={post.files}
            post={post}
            countFrom={5}
            hideOverlay={true}
          />
          {post.tags.length > 0 && (
            <div className="blog-tags">
              {post.tags.length > 0 &&
                post.tags.map((tag, i) => (
                  <div
                    key={tag._id}
                    className={`blog-tags-item ${
                      active === tag.name ? 'active' : ''
                    }`}
                    onClick={() => handleTagName(tag.name)}
                  >
                    <AiOutlineTag />
                    <span>{tag.name}</span>
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className="post-bottom">
          <div className="post-bottom-left">
            <div
              className={`post-bottom-icon ${
                contains(post.likes, post.user._id) ? 'active' : ''
              }`}
              onClick={() =>
                handleLike(post, user, contains(post.likes, user?._id))
              }
            >
              {contains(post.likes, post.user._id) ? (
                <FaHeart className="icon" />
              ) : (
                <FaRegHeart className="icon" />
              )}{' '}
              {post.likes.length > 0 && <span>{post.likes.length}</span>}
            </div>
            <div
              className="post-bottom-icon"
              onClick={() => handleFocusInput(post._id)}
            >
              <FaRegCommentDots className="icon" />{' '}
              {post.comments.length > 0 && <span>{post.comments.length}</span>}
            </div>
          </div>
          <div className="post-bottom-right">
            <RiShareForwardLine className="icon" />
          </div>
        </div>
        <div className="post-comments">
          <BlogComments
            comments={post.comments}
            postInfo={post}
            currentUser={user}
          />
          <InputBlogComment
            onEnter={handleEnter}
            onChange={setTextComment}
            value={textComment}
            postId={post._id}
          />
        </div>
      </div>
    </>
  );
};

export default BlogPost;
