import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Yup from '../../utils/yup-extended';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaHome } from 'react-icons/fa';
import { MdOutlineLock } from 'react-icons/md';
import image from '../../images/undraw_authentication_re_svpt.svg';
import './reset-password.css';
import { passwordpCreators } from '../../store/creators';
import { AppState } from '../../store/app-state';

const initialValues = {
  password: '',
  confirmPassword: '',
};

const ResetFormSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/^\S*$/, 'Whitespace is not allowed')
    .strongPassword(),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .test('passwords-match', 'Password does not match', function (value) {
      return this.parent.password === value;
    }),
});

const ResetPassword = () => {
  const [redirect, setRedirect] = useState(false);
  const { token } = useParams();
  const dispatch = useDispatch();

  const password = useSelector((state: AppState) => state.passwors);

  useEffect(() => {
    if (password.isRedirect) {
      setRedirect(password.isRedirect);
    }
  }, [password.isRedirect]);

  const handleSubmit = (fields: {
    password: string;
    confirmPassword: string;
  }) => {
    dispatch(
      passwordpCreators.resetPassword({
        passwordResetToken: token as string,
        password: fields.password,
        confirmPassword: fields.confirmPassword,
      })
    );
  };

  if (redirect) {
    return <Navigate to="/welcome" />;
  }

  return (
    <div className="reset-password">
      <div className="reset-password__container">
        <Link to="/" className="reset-password__home">
          <FaHome className="icon" />
        </Link>
        <div className="reset-password__image">
          <img src={image} alt="" />
        </div>
        <div className="reset-password__content">
          <div className="reset-password__content-container">
            <h4>New Password</h4>
            <p>Please enter a new password. Then you will be able to login.</p>
            <Formik
              initialValues={initialValues}
              validationSchema={ResetFormSchema}
              onSubmit={(fields, { resetForm }) => {
                resetForm();
                handleSubmit(fields);
              }}
            >
              {({ errors, status, touched, isValid, dirty }) => (
                <Form className="reset-password__form">
                  <div className="form-control">
                    <label htmlFor="password">
                      Password<span>*</span>
                    </label>
                    <div className="form-icon">
                      <MdOutlineLock className="icon" />
                      <Field
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Enter Your New Password"
                        className={
                          errors.password && touched.password
                            ? 'is-invalid'
                            : touched.password
                            ? 'is-valid'
                            : ''
                        }
                      />
                    </div>
                    <ErrorMessage
                      name="password"
                      component="span"
                      className="reset-invalid-feedback"
                    />
                  </div>
                  <div className="form-control">
                    <label htmlFor="confirmPassword">
                      Confirm Password<span>*</span>
                    </label>
                    <div className="form-icon">
                      <MdOutlineLock className="icon" />
                      <Field
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder="Enter Your Confirm Password"
                        className={
                          errors.confirmPassword && touched.confirmPassword
                            ? 'is-invalid'
                            : touched.confirmPassword
                            ? 'is-valid'
                            : ''
                        }
                      />
                    </div>
                    <ErrorMessage
                      name="confirmPassword"
                      component="span"
                      className="reset-invalid-feedback"
                    />
                  </div>
                  <div className="reset-password__action">
                    <button type="submit" disabled={!(isValid && dirty)}>
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
