import React from 'react';
import { AiOutlineFire } from 'react-icons/ai';
import { IoImagesOutline, IoVideocamOutline } from 'react-icons/io5';
import { Link, NavLink } from 'react-router-dom';
import './blog-menu.css';

const BlogMenu = () => {
  return (
    <div className="blog-content__left-container">
      <h3>Menu</h3>
      <ul className="menu-list">
        <li className="menu-list-item">
          <NavLink to="/blog">
            <div className="item-wrapper">
              <AiOutlineFire className="icon" />
              <span>Blog</span>
            </div>
          </NavLink>
        </li>
        <li className="menu-list-item">
          <Link to="/images">
            <div className="item-wrapper">
              <IoImagesOutline className="icon" />
              <span>Images</span>
            </div>
          </Link>
        </li>
        <li className="menu-list-item">
          <Link to="/videos">
            <div className="item-wrapper">
              <IoVideocamOutline className="icon" />
              <span>Videos</span>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default BlogMenu;
