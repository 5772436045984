import React, { useEffect, useState } from 'react';
import { httpClient } from '../../utils/http-client';
import Spinner from '../spinner/Spinner';
import './link-preview.css';

interface LinkPreviewResult {
  description: string;
  domain: string;
  favicon: string;
  image: string;
  ogUrl: string;
  sitename: string;
  title: string;
  type: string;
}

type LinkPreviewProps = {
  url: string;
  largeSize?: boolean;
};

const LinkPreview = ({ url, largeSize }: LinkPreviewProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<LinkPreviewResult>();

  async function getLinkPreviewData(url: string) {
    setLoading(true);
    try {
      const res = await httpClient.get(`/api/links?url=${url}`);

      setData(res.data);
    } catch (err: any) {
      if (err.response?.data?.message) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong');
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (url) {
      getLinkPreviewData(url);
    }
  }, [url]);

  return (
    <a
      className={`link-preview ${largeSize ? 'large' : ''}`}
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <div className={`link-preview__media ${largeSize ? 'large' : ''}`}>
        {loading ? <Spinner /> : <img src={data?.image} alt="" />}
      </div>
      <div className={`link-preview__content ${largeSize ? 'large' : ''}`}>
        <div className="link-preview__content-header">
          <p>
            {error
              ? error
              : data?.title && data?.title.length > 80
              ? `${data?.title.substring(0, 80)}...`
              : data?.title}
          </p>
        </div>
        <div className="link-preview__content-content">
          <p>{url}</p>
        </div>
        <div className="link-preview__content-footer">
          <p>{data?.domain}</p>
        </div>
      </div>
    </a>
  );
};

export default LinkPreview;

// inspire by
// https://www.npmjs.com/package/get-link-preview
// https://winhtaikaung.github.io/react-tiny-link/
