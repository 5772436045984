import { ICommments } from '../models/comment.model';

interface IdMap {
  [key: string | number]: number;
}

export function createCommentsTree(list: ICommments[]) {
  let map: IdMap = {},
    node: ICommments,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i]._id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentId) {
      // if you have dangling branches check that map[node.parentId] exists
      // @ts-ignore
      list[map[node.parentId]].children?.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}
