import React, {
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import imageExtensions from 'image-extensions';
import isUrl from 'is-url';
import isHotkey, { KeyboardEventLike } from 'is-hotkey';
import {
  Editable,
  withReact,
  useSlate,
  Slate,
  RenderLeafProps,
  RenderElementProps,
  useFocused,
  useSlateStatic,
  useSelected,
  ReactEditor,
} from 'slate-react';
import {
  Editor,
  Transforms,
  createEditor,
  Descendant,
  Element as SlateElement,
  Range,
} from 'slate';
import { withHistory } from 'slate-history';
// emoji-mart v3
// import { Picker } from 'emoji-mart';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import {
  Button,
  Menu,
  Portal,
  ReactIcon,
  Toolbar,
} from './TextEditorComponents';
import {
  CustomEditor,
  ImageElement,
  LinkElement,
  ParagraphElement,
} from '../../types/slate';
import { css } from '@emotion/css';
import { useOnClickOutside } from '../../hooks';
import './text-editor.css';
import { httpClient } from '../../utils/http-client';
import {
  BsBrush,
  BsCodeSlash,
  BsEmojiSmile,
  BsListOl,
  BsListUl,
  BsTextCenter,
  BsTextLeft,
  BsTextParagraph,
  BsTextRight,
  BsTrash,
  BsTypeBold,
  BsTypeH1,
  BsTypeH2,
  BsTypeItalic,
  BsTypeUnderline,
} from 'react-icons/bs';
import { BiCloudUpload, BiImageAdd } from 'react-icons/bi';
import { RiDoubleQuotesR } from 'react-icons/ri';
import { MdAddLink, MdLinkOff } from 'react-icons/md';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

type TextEditorProps = {
  value: Descendant[];
  setValue: React.Dispatch<React.SetStateAction<Descendant[]>>;
  toolbarStyle?: 'normal' | 'hover' | 'both';
};

const TextEditor = ({
  value,
  setValue,
  toolbarStyle = 'normal',
}: TextEditorProps) => {
  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  );
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );
  const editor = useMemo(
    () => withPlugins(withHistory(withReact(createEditor()))),
    []
  );

  return (
    <div className="editor">
      <Slate
        editor={editor}
        value={value}
        onChange={(value) => setValue(value)}
      >
        {(toolbarStyle === 'normal' || toolbarStyle === 'both') && (
          <Toolbar>
            <MarkButton format="bold">
              <BsTypeBold />
            </MarkButton>
            <MarkButton format="italic">
              <BsTypeItalic />
            </MarkButton>
            <MarkButton format="underline">
              <BsTypeUnderline />
            </MarkButton>
            <BlockButton format="heading-one">
              <BsTypeH1 />
            </BlockButton>
            <BlockButton format="heading-two">
              <BsTypeH2 />
            </BlockButton>
            <BlockButton format="block-quote">
              <RiDoubleQuotesR />
            </BlockButton>
            <MarkButton format="code">
              <BsCodeSlash />
            </MarkButton>
            <MarkButton format="highlighted">
              <BsBrush />
            </MarkButton>
            <BlockButton format="numbered-list">
              <BsListOl />
            </BlockButton>
            <BlockButton format="bulleted-list">
              <BsListUl />
            </BlockButton>
            <AddLinkButton>
              <MdAddLink />
            </AddLinkButton>
            <RemoveLinkButton>
              <MdLinkOff />
            </RemoveLinkButton>
            <InsertImageButton>
              <BiImageAdd />
            </InsertImageButton>
            <InsertImageUploadButton>
              <BiCloudUpload />
            </InsertImageUploadButton>
            <EmojiButton>
              <BsEmojiSmile />
            </EmojiButton>
          </Toolbar>
        )}
        {(toolbarStyle === 'hover' || toolbarStyle === 'both') && (
          <HoveringToolbar>
            <BlockButton hoverToolbar format="left">
              <BsTextLeft />
            </BlockButton>
            <BlockButton hoverToolbar format="center">
              <BsTextCenter />
            </BlockButton>
            <BlockButton hoverToolbar format="right">
              <BsTextRight />
            </BlockButton>
            <BlockButton hoverToolbar format="justify">
              <BsTextParagraph />
            </BlockButton>
          </HoveringToolbar>
        )}
        <div style={{ marginTop: '50px' }}></div>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some rich text…"
          spellCheck
          autoFocus
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event as KeyboardEventLike)) {
                event.preventDefault();
                // @ts-ignore
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </Slate>
    </div>
  );
};

const HoveringToolbar = ({ children }: { children: ReactNode }) => {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement | null>;
  const editor = useSlate();
  const inFocus = useFocused();

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style');
      return;
    }

    const domSelection = window.getSelection();
    const domRange = domSelection!.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();
    el.style.opacity = '1';
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
    el.style.left = `${
      rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
    }px`;
  });

  return (
    <Portal>
      <Menu
        ref={ref}
        className={css`
          padding: 8px 7px 6px;
          position: absolute;
          z-index: 3000;
          top: -10000px;
          left: -10000px;
          margin-top: -6px;
          opacity: 0;
          background-color: #222;
          border-radius: 4px;
          transition: opacity 0.75s;
        `}
        onMouseDown={(e: MouseEvent) => {
          // prevent toolbar from taking focus away from editor
          e.preventDefault();
        }}
      >
        {children}
      </Menu>
    </Portal>
  );
};

const toggleBlock = (editor: CustomEditor, format: string) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: Partial<SlateElement>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      // @ts-ignore
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    // @ts-ignore
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: CustomEditor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (
  editor: CustomEditor,
  format: string,
  blockType = 'type'
) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        // @ts-ignore
        n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor: CustomEditor, format: string) => {
  const marks = Editor.marks(editor);
  // @ts-ignore
  return marks ? marks[format] === true : false;
};

export const Element = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  const selected = useSelected();
  // @ts-ignore
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case 'image':
      return (
        <Image attributes={attributes} children={children} element={element} />
      );
    case 'link':
      return (
        <a
          {...attributes}
          href={element.url}
          className={
            selected
              ? css`
                  box-shadow: 0 0 0 3px #ddd;
                `
              : ''
          }
        >
          <InlineChromiumBugfix />
          {children}
          <InlineChromiumBugfix />
        </a>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

export const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.highlighted) {
    children = <span style={{ background: '#cfffe3' }}>{children}</span>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({
  children,
  format,
  hoverToolbar = false,
}: {
  children: ReactNode;
  format: string;
  hoverToolbar?: boolean;
}) => {
  const editor = useSlate();
  return (
    <Button
      isOnHoverToolbar={hoverToolbar}
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <ReactIcon>{children}</ReactIcon>
    </Button>
  );
};

const MarkButton = ({
  children,
  format,
  hoverToolbar = false,
}: {
  children: ReactNode;
  format: string;
  hoverToolbar?: boolean;
}) => {
  const editor = useSlate();
  return (
    <Button
      isOnHoverToolbar={hoverToolbar}
      active={isMarkActive(editor, format)}
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <ReactIcon>{children}</ReactIcon>
    </Button>
  );
};

const EmojiButton = ({ children }: { children: ReactNode }) => {
  const [showEmojis, setShowEmojis] = useState(false);

  const btnRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const emojiRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const editor = useSlate();

  const onEmojiClick = (e: any) => {
    let sym = e.unified.split('-');
    let codesArray: any[] = [];
    sym.forEach((el: any) => codesArray.push('0x' + el));
    let emoji = String.fromCodePoint(...codesArray);

    editor.insertText(emoji);

    // setShowEmojis(!showEmojis);
  };

  const handleClick = () => {
    setShowEmojis(!showEmojis);
  };

  const clickOutsideHandler = (event: Event) => {
    if (!btnRef.current?.contains(event?.target as Node)) {
      setShowEmojis(false);
    }
  };

  useOnClickOutside(emojiRef, clickOutsideHandler);

  return (
    <div className="emoji-container">
      <Button active={showEmojis} ref={btnRef} onClick={handleClick}>
        <ReactIcon>{children}</ReactIcon>
      </Button>
      {showEmojis && (
        <div className="emoji-piker" ref={emojiRef}>
          <Picker
            data={data}
            onEmojiSelect={onEmojiClick}
            maxFrequentRows={1}
            previewPosition="none"
          />
        </div>
      )}
    </div>
  );
};

export const withPlugins = (editor: CustomEditor) => {
  const { insertData, isVoid, insertText, isInline } = editor;

  editor.isVoid = (element) => {
    switch (element.type) {
      case 'image':
        return true;

      default:
        return isVoid(element);
    }
  };

  editor.isInline = (element) => {
    return ['link'].includes(element.type) || isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');
    const { files } = data;

    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        const [mime] = files[i].type.split('/');

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result as string;
            insertImage(editor, url);
          });

          reader.readAsDataURL(files[i]);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const insertImage = (editor: CustomEditor, url: string) => {
  const text = { text: '' };
  const image: ImageElement = { type: 'image', url, children: [text] };
  Transforms.insertNodes(editor, image);
  // insert paragraph after image
  const paragraph: ParagraphElement = { type: 'paragraph', children: [text] };
  Transforms.insertNodes(editor, paragraph);
};

const Image = ({ attributes, children, element }: RenderElementProps) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  const selected = useSelected();
  const focused = useFocused();

  const handleDeleteImage = () => {
    // @ts-ignore
    const { hostname } = new URL(element.url);

    const server = new URL(process.env.REACT_APP_SERVER_URL as string);

    if (hostname === server.hostname) {
      // @ts-ignore
      const str = element.url.split('/static/');
      const url = `${process.env.REACT_APP_SERVER_URL}/api/files/static/${str[1]}`;

      httpClient
        .delete(url)
        .then(() => {
          Transforms.removeNodes(editor, { at: path });
        })
        .catch(() => alert('Cannot delete image from server'));
    } else {
      Transforms.removeNodes(editor, { at: path });
    }
  };

  return (
    <div {...attributes}>
      {children}
      <div
        contentEditable={false}
        className={css`
          position: relative;
        `}
      >
        <img
          // @ts-ignore
          src={element.url}
          alt=""
          className={css`
            display: block;
            max-width: 100%;
            max-height: 20em;
            box-shadow: ${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'};
          `}
        />
        <Button
          active
          onClick={handleDeleteImage}
          className={css`
            display: ${selected && focused ? 'inline' : 'none'};
            position: absolute;
            top: 0.5em;
            left: 0.5em;
            margin-top: 0px;
            background-color: white;
          `}
        >
          <ReactIcon>
            <BsTrash />
          </ReactIcon>
        </Button>
      </div>
    </div>
  );
};

const InsertImageButton = ({ children }: { children: ReactNode }) => {
  const editor = useSlateStatic();
  return (
    <Button
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        const url = window.prompt('Enter the URL of the image:');
        if (url && !isImageUrl(url)) {
          alert('URL is not an image');
          return;
        }
        if (url) {
          insertImage(editor, url!);
        }
      }}
    >
      <ReactIcon>{children}</ReactIcon>
    </Button>
  );
};

const InsertImageUploadButton = ({ children }: { children: ReactNode }) => {
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const editor = useSlateStatic();

  const onImageSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const files = event.target.files;

    if (files && files.length === 0) {
      return;
    }

    const file = files && files[0];

    const formData = new FormData();

    formData.append('file', file!);

    httpClient
      .post(`${process.env.REACT_APP_SERVER_URL}/api/files/static`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 1000 * 3, // Wait for 3 seconds
      })
      .then((response) => {
        const url = response.data.image;

        if (!url) {
          alert('Somthing went wrong');
          return;
        }
        if (url) {
          insertImage(editor, url!);
        }
      })
      .catch((error) => {
        // console.log(error);
        alert('Somthing went wrong');
        return;
      });
  };

  return (
    <Button htmlFor="image-upload" onClick={() => inputRef.current.click()}>
      <ReactIcon>{children}</ReactIcon>
      <input
        ref={inputRef}
        type="file"
        id="image-upload"
        className="image-upload-input"
        accept="image.*"
        onChange={onImageSelected}
      />
    </Button>
  );
};

const isImageUrl = (url: string) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split('.').pop();
  return imageExtensions.includes(ext!);
};

const InlineChromiumBugfix = () => (
  <span
    contentEditable={false}
    className={css`
      font-size: 0;
    `}
  >
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

const insertLink = (editor: CustomEditor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const isLinkActive = (editor: CustomEditor) => {
  // @ts-ignore
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
  return !!link;
};

const wrapLink = (editor: CustomEditor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const unwrapLink = (editor: CustomEditor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};

const AddLinkButton = ({ children }: { children: ReactNode }) => {
  const editor = useSlate();
  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        const url = window.prompt('Enter the URL of the link:');
        if (!url) return;
        insertLink(editor, url);
      }}
    >
      <ReactIcon>{children}</ReactIcon>
    </Button>
  );
};

const RemoveLinkButton = ({ children }: { children: ReactNode }) => {
  const editor = useSlate();

  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={(event: MouseEvent) => {
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        }
      }}
    >
      <ReactIcon>{children}</ReactIcon>
    </Button>
  );
};

export default TextEditor;
