import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MdOutlineMailOutline } from 'react-icons/md';
import image from '../../images/undraw_forgot_password_re_hxwm.svg';
import './forgot-password.css';
import { FaHome } from 'react-icons/fa';
import { passwordpCreators } from '../../store/creators';

const initialValues = {
  email: '',
};

const ForgotFormSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required('Email is required'),
});

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const handleSubmit = (fields: { email: string }) => {
    dispatch(passwordpCreators.forgotPassword(fields));
  };

  return (
    <div className="forgot-password">
      <div className="forgot-password__container">
        <Link to="/" className="forgot-password__home">
          <FaHome className="icon" />
        </Link>
        <div className="forgot-password__image">
          <img src={image} alt="" />
        </div>
        <div className="forgot-password__content">
          <div className="forgot-password__content-container">
            <h4>Recover Account</h4>
            <p>
              Please enter your email address. You will receive a link to create
              a new password via email.
            </p>
            <Formik
              initialValues={initialValues}
              validationSchema={ForgotFormSchema}
              onSubmit={(fields, { resetForm }) => {
                resetForm();
                handleSubmit(fields);
              }}
            >
              {({ errors, status, touched, isValid, dirty }) => (
                <Form className="forgot-password__form">
                  <div className="form-control">
                    <label htmlFor="email">
                      Email Address<span>*</span>
                    </label>
                    <div className="form-icon">
                      <MdOutlineMailOutline className="icon" />
                      <Field
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter Your Email Address"
                        className={
                          errors.email && touched.email
                            ? 'is-invalid'
                            : touched.email
                            ? 'is-valid'
                            : ''
                        }
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="span"
                      className="forgot-invalid-feedback"
                    />
                  </div>
                  <div className="forgot-password__action">
                    <button type="submit" disabled={!(isValid && dirty)}>
                      Send
                    </button>
                  </div>
                  <div className="forgot-password__bottom">
                    <p>
                      Remember your password? <Link to="/welcome">Sign in</Link>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
