import React from 'react';
import DropZone from '../../components/dropzone/DropZone';
import './upload.css';

const Upload = () => {
  return (
    <>
      <div className="upload">
        <div className="upload-container">
          <div className="upload-wrapper">
            <div className="upload-content">
              <h2>Upload images</h2>
              <h4>Post a image to your account</h4>
              <div className="content">
                <DropZone />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Upload;
