import { useEffect, useState } from 'react';
import { debounce } from '../utils/debounce';

export const useWindowScrollPositions = (wait = 100) => {
  const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 });

  useEffect(() => {
    function updatePosition() {
      setPosition({ scrollX: window.scrollX, scrollY: window.scrollY });
    }

    function debounceScroll() {
      return debounce(updatePosition, wait, false);
    }

    window.addEventListener('scroll', debounceScroll());
    updatePosition();

    return () => window.removeEventListener('scroll', debounceScroll());
  }, [wait]);

  return scrollPosition;
};

// https://stackoverflow.com/a/70734224
