import { IFile } from '../models/file.model';

export const filterImageAndLike = (file: IFile) =>
  file.filetype === 'image' && file.like === true;

interface MockFile {
  name: string;
  body: string;
  mimeType: string;
}
interface iBlob extends Blob, File {
  name: string;
  lastModifiedDate: Date;
  lastModified: number;
  webkitRelativePathts: string;
}

export const createFileFromMockFile = (file: MockFile): File => {
  const blob: Partial<iBlob> = new Blob([file.body], { type: file.mimeType });
  blob.lastModifiedDate = new Date();
  blob.name = file.name;
  blob.lastModified = Date.now();
  return blob as File;
};
