import { IChat } from '../../models/chat.model';
import { chatActions, messageActions } from '../actions';

export interface ChatState {
  isLoading: boolean;
  chats: IChat[] | [];
  currentChat: IChat | null;
  previousChat: IChat | null;
  message: string | null;
}

const initialState: ChatState = {
  isLoading: false,
  chats: [],
  currentChat: null,
  previousChat: null,
  message: null,
};

export const chatReducer = (
  state: ChatState = initialState,
  action: chatActions.Action | messageActions.Action
): ChatState => {
  switch (action.type) {
    case chatActions.ChatActionTypes.CHAT_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case chatActions.ChatActionTypes.CHAT_LOADED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chats: action.payload,
      };

    case chatActions.ChatActionTypes.CHAT_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chats: [action.payload.chat, ...state.chats],
        message: action.payload.message,
      };

    case chatActions.ChatActionTypes.CHAT_SET_CURRENT:
      return {
        ...state,
        isLoading: false,
        currentChat: action.payload.currentChat,
        previousChat: action.payload.previousChat,
      };

    case messageActions.MessageActionTypes.MESSAGE_CREATE_SUCCESS:
      return {
        ...state,
        chats: state.chats.map((chat) => {
          if (chat._id === action.payload.newMessage.chatId) {
            if (chat.latestMessage === undefined) {
              chat['latestMessage'] = action.payload.newMessage;
            } else {
              chat.latestMessage = action.payload.newMessage;
            }
            return chat;
          }
          return chat;
        }),
      };

    case chatActions.ChatActionTypes.CHAT_MESSAGE_RECEIVED_LATEST:
      return {
        ...state,
        chats: state.chats.map((chat) => {
          if (chat._id === action.payload.chatId) {
            if (chat.latestMessage === undefined) {
              chat['latestMessage'] = action.payload;
            } else {
              chat.latestMessage = action.payload;
            }
            return chat;
          }
          return chat;
        }),
      };

    case chatActions.ChatActionTypes.CHAT_LOADED_FAIL:
    case chatActions.ChatActionTypes.CHAT_CREATE_FAIL:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
