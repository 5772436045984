import { IToast } from '../../models/toast.model';

export enum ToastActionTypes {
  TOAST_ADD = 'TOAST_ADD',
  TOAST_DELETE = 'TOAST_DELETE',
}

export interface ToastAddAction {
  readonly type: ToastActionTypes.TOAST_ADD;
  payload: IToast;
}

export interface ToastDeleteAction {
  readonly type: ToastActionTypes.TOAST_DELETE;
  payload: { id: string };
}

export type Action = ToastAddAction | ToastDeleteAction;
