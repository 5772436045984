import React, { useCallback, useState } from 'react';
import { FaAngleLeft, FaAngleRight, FaTrash } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/app-state';
import { postCreators } from '../../store/creators';
import './image-grid-preview.css';

type ImageGridPreviewProps = {
  images: string[];
  files: File[];
  hideOverlay?: boolean;
  renderOverlay?: () => string;
  overlayBackgroundColor?: string;
  onClickEach?: { (image: string, index: number): void } | null;
  countFrom?: number;
  onDeleteEmpty?: () => void;
  onCloseLightbox?: () => void;
  edit?: boolean;
};

const ImageGridPreview = ({
  images = [],
  files,
  hideOverlay = false,
  renderOverlay = () => 'Preview Image',
  overlayBackgroundColor = 'rgba(0, 0, 0, 0.333)',
  onClickEach = null,
  countFrom = 5,
  onDeleteEmpty,
  onCloseLightbox,
  edit = false,
}: ImageGridPreviewProps) => {
  const [modal, setModal] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [isCountFrom] = useState(
    countFrom > 0 && countFrom < 5 ? countFrom : 5
  );

  let [conditionalRender] = useState(false);

  const dispatch = useDispatch();

  const postInStore = useSelector((state: AppState) => state.posts);

  const openModal = (index: number) => {
    if (onClickEach) {
      return onClickEach(images[index], index);
    }

    setModal(true);
    setImageIndex(index);
  };

  const renderCountOverlay = (more: boolean) => {
    const extra =
      images.length - (isCountFrom && isCountFrom > 5 ? 5 : isCountFrom);

    return [
      more && <div key="count" className="image-grid__cover"></div>,
      more && (
        <div key="count-sub" className="image-grid__cover-text">
          <p>+{extra}</p>
        </div>
      ),
    ];
  };

  const renderOne = () => {
    const overlay =
      images.length > isCountFrom && isCountFrom === 1
        ? renderCountOverlay(true)
        : renderOverlayId();

    return (
      <div className="image-grid">
        <div className="image-grid__row">
          <div className="image-grid__col-12">
            <div
              className={`image-grid__img-container`}
              onClick={() => openModal(0)}
              style={
                [1].includes(imagesToShow.length)
                  ? { paddingTop: '85%' }
                  : [3].includes(imagesToShow.length)
                  ? { paddingTop: '50%' }
                  : [4].includes(imagesToShow.length)
                  ? { paddingTop: '50%' }
                  : {}
              }
            >
              <img
                src={images[0]}
                alt=""
                className="image-grid__img-background"
              />
              {overlay}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTwo = () => {
    const overlay =
      images.length > isCountFrom && [2, 3].includes(+isCountFrom)
        ? renderCountOverlay(true)
        : renderOverlayId();
    conditionalRender =
      [3, 4].includes(images.length) ||
      (images.length > +isCountFrom && [3, 4].includes(+isCountFrom));

    return (
      <div className="image-grid">
        <div className="image-grid__row">
          <div className="image-grid__col-6">
            <div
              className="image-grid__img-container"
              onClick={() => openModal(conditionalRender ? 1 : 0)}
              style={
                [2].includes(imagesToShow.length)
                  ? { paddingTop: '170%' }
                  : [3].includes(imagesToShow.length)
                  ? { paddingTop: '65%' }
                  : {}
              }
            >
              <img
                src={conditionalRender ? images[1] : images[0]}
                alt=""
                className="image-grid__img-background"
              />
              {renderOverlayId()}
            </div>
          </div>
          <div className="image-grid__col-6">
            <div
              className="image-grid__img-container"
              onClick={() => openModal(conditionalRender ? 2 : 1)}
              style={
                [2].includes(imagesToShow.length)
                  ? { paddingTop: '170%' }
                  : [3].includes(imagesToShow.length)
                  ? { paddingTop: '65%' }
                  : {}
              }
            >
              <img
                src={conditionalRender ? images[2] : images[1]}
                alt=""
                className="image-grid__img-background"
              />
              {overlay}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderOverlayId = (id = 0) => {
    if (hideOverlay) {
      return false;
    }

    return [
      <div
        key={`cover-${id}`}
        className="image-grid__cover image-grid__slide"
        style={{ backgroundColor: overlayBackgroundColor }}
      ></div>,
      <div
        key={`cover-text-${id}`}
        className="image-grid__cover-text image-grid__slide image-grid__animate-text"
      >
        {renderOverlay()}
      </div>,
    ];
  };

  const renderThree = () => {
    const overlay =
      !isCountFrom ||
      isCountFrom > 5 ||
      (images.length > isCountFrom && [4, 5].includes(+isCountFrom))
        ? renderCountOverlay(true)
        : renderOverlayId(conditionalRender ? 3 : 4);
    conditionalRender =
      images.length === 4 ||
      (images.length > +isCountFrom && +isCountFrom === 4);

    return (
      <div className="image-grid">
        <div className="image-grid__row">
          <div className="image-grid__col-4">
            <div
              className="image-grid__img-container"
              onClick={() => openModal(conditionalRender ? 1 : 2)}
            >
              <img
                src={conditionalRender ? images[1] : images[2]}
                alt=""
                className="image-grid__img-background"
              />
              {renderOverlayId(conditionalRender ? 1 : 2)}
            </div>
          </div>
          <div className="image-grid__col-4">
            <div
              className="image-grid__img-container"
              onClick={() => openModal(conditionalRender ? 2 : 3)}
            >
              <img
                src={conditionalRender ? images[2] : images[3]}
                alt=""
                className="image-grid__img-background"
              />
              {renderOverlayId(conditionalRender ? 2 : 3)}
            </div>
          </div>
          <div className="image-grid__col-4">
            <div
              className="image-grid__img-container"
              onClick={() => openModal(conditionalRender ? 3 : 4)}
            >
              <img
                src={conditionalRender ? images[3] : images[4]}
                alt=""
                className="image-grid__img-background"
              />
              {overlay}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const imagesToShow = [...images];

  if (isCountFrom && images.length > isCountFrom) {
    imagesToShow.length = isCountFrom;
  }

  const prevIndex = (imageIndex + images.length - 1) % images.length;
  const nextIndex = (imageIndex + images.length + 1) % images.length;

  let mainSrc = `${images[imageIndex]}`;

  const onMovePrevRequest = useCallback(() => {
    setImageIndex(prevIndex);
  }, [prevIndex]);

  const onMoveNextRequest = useCallback(() => {
    setImageIndex(nextIndex);
  }, [nextIndex]);

  const requestMove = useCallback(
    (direction: string) => {
      if (direction === 'prev') {
        onMovePrevRequest();
      } else {
        onMoveNextRequest();
      }
    },
    [onMoveNextRequest, onMovePrevRequest]
  );

  // Request to transition to the next image
  const requestMoveNext = () => {
    requestMove('next');
  };

  // Request to transition to the previous image
  const requestMovePrev = () => {
    requestMove('prev');
  };

  const handleCloseLightbox = () => {
    setModal(false);
    onCloseLightbox && onCloseLightbox();
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure to delete this item?')) {
      if (edit) {
        const { hostname, pathname } = new URL(images[imageIndex]);

        const server = new URL(process.env.REACT_APP_SERVER_URL as string);

        if (hostname === server.hostname) {
          const fileId = pathname.split('/files/')[1];
          if (postInStore.current)
            dispatch(
              postCreators.deleteFileFromPost(postInStore.current, fileId)
            );
        }
      }

      images.splice(imageIndex, 1);
      files.splice(imageIndex, 1);
      requestMoveNext();

      if (images.length === 0) {
        setModal(false);
        onDeleteEmpty && onDeleteEmpty();
      }
    }
  };

  return (
    <>
      <div className="image-grid__container">
        {[1, 3, 4].includes(imagesToShow.length) && renderOne()}
        {imagesToShow.length >= 2 && imagesToShow.length !== 4 && renderTwo()}
        {imagesToShow.length >= 4 && renderThree()}

        {modal && (
          <>
            <div className="image-lightbox">
              <div className="image-lightbox__container">
                <img src={mainSrc} alt="" />
              </div>
            </div>
            <div
              className="image-lightbox__close"
              onClick={handleCloseLightbox}
            >
              <MdClose />
            </div>
            <div
              className="image-lightbox__next-button"
              onClick={requestMoveNext}
            >
              <FaAngleRight className="icon" />
            </div>
            <div
              className="image-lightbox__prev-button"
              onClick={requestMovePrev}
            >
              <FaAngleLeft className="icon" />
            </div>
            <div className="image-lightbox__action">
              <p>
                {imageIndex + 1}/{images.length}
              </p>
              <FaTrash className="icon" onClick={handleDelete} />
            </div>
            <div
              className={`image-lightbox__overlay ${modal ? 'active' : ''}`}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ImageGridPreview;
