import { customeLocalStorage } from '../../utils/local-storage';
import { routeActions } from '../actions';

export interface RouteState {
  routeGoBack: string;
}

const initialState: RouteState = {
  routeGoBack: customeLocalStorage.get('routeGoBack') || '/',
};

export const routeReducer = (
  state: RouteState = initialState,
  action: routeActions.Action
): RouteState => {
  switch (action.type) {
    case routeActions.RouteActionTypes.ROUTE_GO_BACK_SET:
      customeLocalStorage.set('routeGoBack', action.payload);
      return {
        ...state,
        routeGoBack: action.payload,
      };
    case routeActions.RouteActionTypes.ROUTE_GO_BACK_REMOVE:
      customeLocalStorage.set('routeGoBack', '/');
      return {
        ...state,
        routeGoBack: '/',
      };

    default:
      return state;
  }
};
