import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Order, getComparator, stableSort } from '../../../utils/table';
import EnhancedTableHead, {
  DataHeader,
  HeadCell,
} from '../../../components/enhanced-table-head/EnhancedTableHead';
import EnhancedTableToolbar from '../../../components/enhanced-table-toolbar/EnhancedTableToolbar';
import './trade-list.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/app-state';
import { ITrade } from '../../../models/trade.model';
import TradePopup from '../../../components/trade-popup/TradePopup';
import { tradeCreators } from '../../../store/creators';

export interface Data {
  id: number;
  username: string;
  avatar: string;
  email: string;
  status: string;
  fullname: string;
  career: string;
  phone: string;
  city: string;
  country: string;
}

const headCells: HeadCell[] = [
  { id: 'id', numeric: false, disablePadding: true, label: 'ID' },
  {
    id: 'instrument',
    numeric: false,
    disablePadding: false,
    label: 'Instrument',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'entryDate',
    numeric: false,
    disablePadding: false,
    label: 'Entry Date',
  },
  {
    id: 'entryPrice',
    numeric: true,
    disablePadding: false,
    label: 'Entry Price',
  },
  {
    id: 'volume',
    numeric: true,
    disablePadding: false,
    label: 'Volume',
  },
  {
    id: 'exitDate',
    numeric: false,
    disablePadding: false,
    label: 'Exit Date',
  },
  {
    id: 'exitPrice',
    numeric: true,
    disablePadding: false,
    label: 'Exit Price',
  },
  {
    id: 'granLost',
    numeric: true,
    disablePadding: false,
    label: 'Gain/Loss',
  },
  {
    id: 'setup',
    numeric: false,
    disablePadding: false,
    label: 'Setup',
  },
  {
    id: 'direction',
    numeric: false,
    disablePadding: false,
    label: 'Direction',
  },
  {
    id: 'entryEmotion',
    numeric: false,
    disablePadding: false,
    label: 'Entry Emotion',
  },
  {
    id: 'entryComment',
    numeric: false,
    disablePadding: false,
    label: 'Entry Comment',
  },
  {
    id: 'exitEmotion',
    numeric: false,
    disablePadding: false,
    label: 'Exit Emotion',
  },
  {
    id: 'exitComment',
    numeric: false,
    disablePadding: false,
    label: 'Exit Comment',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  // { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  // { id: 'action', numeric: false, disablePadding: false, label: 'Action' },
];

const TradeList = () => {
  const [showTradePopup, setShowTradePopup] = useState(false);
  const [rows, setRows] = useState<ITrade[]>([]);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof DataHeader>('id');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.auth.user);
  const current = useSelector((state: AppState) => state.trades.current);
  const trades = useSelector((state: AppState) => state.trades.trades);

  useEffect(() => {
    if (trades.length > 0) {
      setRows(trades);
    }
  }, [trades]);

  useEffect(() => {
    if (current) {
      setShowTradePopup(true);
    } else {
      setShowTradePopup(false);
    }
  }, [current]);

  useEffect(() => {
    if (showTradePopup) {
      setRowsPerPage(5);
    } else {
      setRowsPerPage(10);
    }
  }, [showTradePopup]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DataHeader
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id: string
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const handleOpenPopupTrade = () => {
    setShowTradePopup(true);
  };

  const handlePopupTradeClose = () => {
    setShowTradePopup(false);
  };

  const handleEditSelectedItem = () => {
    dispatch(
      tradeCreators.tradeSetEdit(
        trades.filter((t) => t._id === selected[0])[0].tradeMethodology
      )
    );
  };

  const handleDeleteSelectedItem = () => {
    if (window.confirm('Are you sure to delete this record')) {
      setRows(rows.filter((item) => !selected.includes(item._id)));
      setSelected([]);
    }
  };

  const visibleRows = React.useMemo(
    () =>
      // @ts-ignore
      stableSort<ITrade>(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rows, rowsPerPage]
  );

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className="trade-list">
      <TradePopup
        user={user}
        showTradePopup={showTradePopup}
        setShowTradePopup={setShowTradePopup}
        handleClose={handlePopupTradeClose}
      />
      <Paper sx={{ width: '100%', mb: 2, borderRadius: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleAdd={handleOpenPopupTrade}
          handleEdit={handleEditSelectedItem}
          handleDelete={handleDeleteSelectedItem}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row._id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.order}
                    </TableCell>
                    <TableCell>
                      {row.tradeMethodology.instrument.name}
                    </TableCell>
                    <TableCell>
                      {row.completed === true ? 'Completed' : 'Draft'}
                    </TableCell>
                    <TableCell>
                      {new Date(row.entryDate).toLocaleString('en-GB')}
                    </TableCell>
                    <TableCell align="right">{row.entryPrice}</TableCell>
                    <TableCell align="right">{row.volume}</TableCell>
                    <TableCell>
                      {row.exitDate
                        ? new Date(row.exitDate).toLocaleString('en-GB')
                        : ''}
                    </TableCell>
                    <TableCell align="right">{row.exitPrice}</TableCell>
                    <TableCell
                      sx={{
                        color:
                          row.result > 0
                            ? '#3bb077'
                            : row.result < 0
                            ? 'red'
                            : undefined,
                      }}
                      align="right"
                    >
                      {row.result}
                    </TableCell>
                    <TableCell>{row.tradeMethodology.setup.name}</TableCell>
                    <TableCell>{row.tradeMethodology.direction.name}</TableCell>
                    <TableCell
                      sx={{
                        color:
                          row.tradeMethodology.entryEmotion.score &&
                          row.tradeMethodology.entryEmotion.score > 0
                            ? '#3bb077'
                            : row.tradeMethodology.entryEmotion.score &&
                              row.tradeMethodology.entryEmotion.score < 0
                            ? 'red'
                            : undefined,
                      }}
                    >
                      {row.tradeMethodology.entryEmotion.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        color:
                          row.tradeMethodology.entryComment.score &&
                          row.tradeMethodology.entryComment.score > 0
                            ? '#3bb077'
                            : row.tradeMethodology.entryComment.score &&
                              row.tradeMethodology.entryComment.score < 0
                            ? 'red'
                            : undefined,
                      }}
                    >
                      {row.tradeMethodology.entryComment.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        color:
                          row.tradeMethodology.exitEmotion &&
                          row.tradeMethodology.exitEmotion.score > 0
                            ? '#3bb077'
                            : row.tradeMethodology.exitEmotion &&
                              row.tradeMethodology.exitEmotion.score < 0
                            ? 'red'
                            : undefined,
                      }}
                    >
                      {row.tradeMethodology.exitEmotion &&
                        row.tradeMethodology.exitEmotion.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        color:
                          row.tradeMethodology.exitComment &&
                          row.tradeMethodology.exitComment.score > 0
                            ? '#3bb077'
                            : row.tradeMethodology.exitComment &&
                              row.tradeMethodology.exitComment.score < 0
                            ? 'red'
                            : undefined,
                      }}
                    >
                      {row.tradeMethodology.exitComment &&
                        row.tradeMethodology.exitComment.name}
                    </TableCell>
                    <TableCell>{row.description}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
};

export default TradeList;

// https://mui.com/material-ui/react-table/#sorting-amp-selecting
