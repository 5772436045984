import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  ScriptableLineSegmentContext,
  BarElement,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import './trade-home.scss';
import { AppState } from '../../../store/app-state';
import { useSelector } from 'react-redux';
import { ITrade } from '../../../models/trade.model';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const TradeHome = () => {
  const [values, setValues] = useState<ITrade[]>([]);

  const trades = useSelector((state: AppState) => state.trades);

  useEffect(() => {
    setValues(trades.trades);
  }, [trades.trades]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'right',
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Total Invested Value',
      },
    },
  };

  const skipped = (ctx: ScriptableLineSegmentContext, value: any) =>
    ctx.p0.skip || ctx.p1.skip ? value : undefined;

  // const down = (ctx: ScriptableLineSegmentContext, value: any) =>
  //   ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

  const belowZero = (ctx: ScriptableLineSegmentContext, value: any) =>
    ctx.p1.parsed.y < 0 ? value : undefined;

  const data = {
    labels: values.map((i) => i.id),
    datasets: [
      {
        type: 'bar' as const,
        label: 'Realize',
        backgroundColor: values.map((i) =>
          i.portGain > 0 ? 'rgba(0, 255, 0, .5)' : 'rgba(255, 0, 0, .5)'
        ),
        data: values.map((i) => i.portGain),
        order: 1,
      },
      {
        type: 'line' as const,
        label: 'Summation',
        data: values.map((i) => i.portRealize),
        fill: {
          target: 'origin',
          above: 'rgba(0, 255, 0, .1)',
          below: 'rgba(255, 0, 0, .1)',
        },
        borderColor: 'rgba(53,199,89,1)',
        segment: {
          borderColor: (ctx: any) =>
            skipped(ctx, 'rgb(0,0,0,0.2)') || belowZero(ctx, 'rgb(192,75,75)'),
          borderDash: (ctx: any) => skipped(ctx, [6, 6]),
        },
        order: 0,
      },
    ],
  };

  return (
    <div className="trade-home">
      <div className="chart">
        <div className="header">
          <div className="left">
            <h5>Total Invested Value</h5>
            <p>
              {trades.statistic?.balance}{' '}
              <span
                className={
                  trades.statistic && trades.statistic.netProfitPercent < 0
                    ? 'negative'
                    : ''
                }
              >
                {trades.statistic && trades.statistic.netProfitPercent > 0
                  ? '+'
                  : ''}
                {trades.statistic?.netProfitPercent}%
              </span>
            </p>
          </div>
          <div className="right">
            <button type="button">1M</button>
            <button type="button">3M</button>
            <button type="button">6M</button>
            <button type="button" className="active">
              1Y
            </button>
            <button type="button">3Y</button>
            <button type="button">All</button>
          </div>
        </div>
        <div className="chart-js">
          {/* @ts-expect-error */}
          <Chart type="line" data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default TradeHome;
