import React, { useCallback, useEffect, useMemo } from 'react';
import Cards from '../../components/cards/Cards';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/app-state';
import {
  fileCreators,
  routeCreators,
  scrollbarCreators,
  tagCreators,
} from '../../store/creators';
import { useOnceDispatchEffect } from '../../hooks';
import { useWindowScrollPositions } from '../../hooks';
import Tagsbar from '../../components/tags-bar/Tagsbar';
import InfiniteLoading from '../../components/infinite-loading/InfiniteLoading';
import './images.css';

const Images = () => {
  const dispatch = useDispatch();

  const files = useSelector((state: AppState) => state.files);
  const images = useSelector((state: AppState) => state.files.images);
  const isLoading = useSelector((state: AppState) => state.files.isLoading);
  const user = useSelector((state: AppState) => state.auth.user);
  const routeGoBack = useSelector(
    (state: AppState) => state.routes.routeGoBack
  );
  const page = useSelector((state: AppState) => state.srollbar.pageImages);
  const scrollPosition = useSelector((state: AppState) => state.srollbar);

  // useEffect(() => {
  //   dispatch(fileCreators.loadImages());
  //   dispatch(routeCreators.routeSet('/images'));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useOnceDispatchEffect(fileCreators.loadImages());
  // useOnceDispatchEffect(fileCreators.loadFiles(), images);
  useOnceDispatchEffect(fileCreators.loadFilesImages());
  useOnceDispatchEffect(tagCreators.loadFilesTags());
  useOnceDispatchEffect(routeCreators.routeSet('/images'));
  useOnceDispatchEffect(
    scrollbarCreators.setPositionScollbar(
      scrollPosition.positionX,
      scrollPosition.positionY
    )
  );

  useEffect(() => {
    dispatch(fileCreators.fileImagesFilter(files.filter, user));
  }, [dispatch, files.filter, files.files, user]);

  const { scrollX, scrollY } = useWindowScrollPositions();

  useEffect(() => {
    dispatch(scrollbarCreators.trackingScollbar(scrollX, scrollY));
  }, [dispatch, scrollX, scrollY]);

  useEffect(() => {
    if (page === 1 && routeGoBack !== '/images') {
      dispatch(scrollbarCreators.setPositionScollbar(0, 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop <
        Math.floor(document.documentElement.offsetHeight * 0.8) ||
      page * 12 > images.length ||
      isLoading
    ) {
      return;
    }
    dispatch(scrollbarCreators.setPage('images', page + 1));
  }, [dispatch, images.length, isLoading, page]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const visibleImages = useMemo(
    () => images.slice(0, page * 12),
    [page, images]
  );

  return (
    <div className="images">
      <Tagsbar />
      {isLoading && images.length === 0 && page === 1 ? (
        <InfiniteLoading absolute />
      ) : (
        <Cards images={visibleImages} />
      )}
      {isLoading && page !== 1 && <InfiniteLoading />}
    </div>
  );
};

export default Images;
