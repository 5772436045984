import { IWallet } from '../models/wallet.model';

export const getBalance = (wallets: IWallet[]) => {
  let result = 0;

  wallets.forEach((w) => {
    result = result + w.amount;
  });

  return result;
};
