import React, { ChangeEvent, useEffect, useState } from 'react';
import './trade-position-size.scss';

const TradePositionSize = () => {
  const [accountSize, setAccountSize] = useState(1000000);
  const [entryPrice, setEntryPrice] = useState(10);
  const [stoplossPrice, setStoplossPrice] = useState(9.5);
  const [targetPrice, setTargetPrice] = useState(11.5);
  const [accountRisk, setAccountRisk] = useState(1);

  const [positionSize, setPositionSize] = useState(
    (0.01 * 1000000) / (10 - 9.5)
  );
  const [amountOfPosition, setAmountOfPosition] = useState(
    (0.01 * 1000000 * 10) / (10 - 9.5)
  );
  const [riskSize, setRiskSize] = useState(0.01 * 1000000);
  const [rrr, setRRR] = useState((11.5 - 10) / (10 - 9.5));

  useEffect(() => {
    setRiskSize((accountRisk / 100) * accountSize);

    setPositionSize(
      Math.floor(riskSize / (entryPrice - stoplossPrice) / 100) * 100
    );

    setAmountOfPosition(positionSize * entryPrice);

    setRRR((targetPrice - entryPrice) / (entryPrice - stoplossPrice));
  }, [
    accountRisk,
    accountSize,
    entryPrice,
    positionSize,
    riskSize,
    stoplossPrice,
    targetPrice,
  ]);

  const onAccountSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAccountSize(parseFloat(event.target.value));
  };

  const onEntryPriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEntryPrice(parseFloat(event.target.value));
  };

  const onStoplossPriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStoplossPrice(parseFloat(event.target.value));
  };

  const onTargetPriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTargetPrice(parseFloat(event.target.value));
  };

  const onAccountRiskChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAccountRisk(parseFloat(event.target.value));
  };

  return (
    <div className="trade-position-size">
      <div className="content">
        <div className="header">
          <h2>Position Size</h2>
        </div>
        <div className="calculation">
          <div className="left">
            <div className="form-control">
              <label htmlFor="accountSize">Account Size</label>
              <input
                type="number"
                name="accountSize"
                id="accountSize"
                value={accountSize}
                onChange={onAccountSizeChange}
              />
            </div>
            <div className="form-control">
              <label htmlFor="entryPrice">Entry Price</label>
              <input
                type="number"
                name="entryPrice"
                id="entryPrice"
                value={entryPrice}
                onChange={onEntryPriceChange}
              />
            </div>
            <div className="form-control">
              <label htmlFor="stoplossPrice">Stoploss Price</label>
              <input
                type="number"
                name="stoplossPrice"
                id="stoplossPrice"
                value={stoplossPrice}
                onChange={onStoplossPriceChange}
              />
            </div>
            <div className="form-control">
              <label htmlFor="targetPrice">Target Price</label>
              <input
                type="number"
                name="targetPrice"
                id="targetPrice"
                value={targetPrice}
                onChange={onTargetPriceChange}
              />
            </div>
            <div className="form-control">
              <label htmlFor="accountRisk">Account Risk (%)</label>
              <input
                type="number"
                name="accountRisk"
                id="accountRisk"
                max={10}
                min={0.1}
                value={accountRisk}
                onChange={onAccountRiskChange}
              />
            </div>
          </div>
          <div className="right">
            <div className="result">
              <p>Position Size</p>
              <h3>{Number(positionSize.toFixed(1)).toLocaleString()}</h3>
            </div>
            <div className="result">
              <p>Amount of Position</p>
              <h3>{Number(amountOfPosition.toFixed(1)).toLocaleString()}</h3>
            </div>
            <div className="result">
              <p>Risk Size</p>
              <h3>{Number(riskSize.toFixed(1)).toLocaleString()}</h3>
            </div>
            <div className="result">
              <p>Risk Reward Ratio</p>
              <h3>{Number(rrr.toFixed(2)).toLocaleString()}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradePositionSize;
