import React, { useEffect, useRef, useState } from 'react';
import Popup from '../popup/Popup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import InputEmojiForwardRef from '../input-emoji-with-ref/InputEmojiWithRef';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/app-state';
import { walletCreators } from '../../store/creators';
import { IWalletData } from '../../models/wallet.model';
import DatePicker from 'react-datepicker';
import './wallet-popup.css';

type WalletPopupProps = {
  showWalletPopup: boolean;
  setShowWalletPopup: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose?: () => void;
};

const WalletPopup = ({
  showWalletPopup,
  setShowWalletPopup,
  handleClose,
}: WalletPopupProps) => {
  const [data, setData] = useState<IWalletData>({
    amount: '',
    actionDate: new Date(),
    description: '',
  });

  const walletInputSchema = Yup.object().shape({
    amount: Yup.number().required('Amount is Required'),
    actionDate: Yup.date().required('Action date is Required'),
    description: Yup.string().notRequired(),
  });

  const descriptionRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const dispatch = useDispatch();

  const editWallet = useSelector((state: AppState) => state.wallets.edit);
  const currentWallet = useSelector((state: AppState) => state.wallets.current);

  useEffect(() => {
    if (currentWallet) {
      setData({
        amount: currentWallet.amount,
        actionDate: new Date(currentWallet.actionDate),
        description: currentWallet.description,
      });
    } else {
      setData({
        amount: '',
        actionDate: new Date(),
        description: '',
      });
    }
  }, [currentWallet]);

  const handleCancel = () => {
    setShowWalletPopup(false);
    if (currentWallet) {
      dispatch(walletCreators.walletSetEditClear());
    }
  };

  return (
    <Popup
      showPopup={showWalletPopup}
      setShowPopup={setShowWalletPopup}
      handleClose={handleClose}
    >
      <div className="wallet-popup">
        <Formik
          enableReinitialize={true}
          validateOnMount={true}
          initialValues={data}
          validationSchema={walletInputSchema}
          onSubmit={async (fields, { resetForm }) => {
            if (currentWallet) {
              dispatch(walletCreators.updateWallet(currentWallet, fields));
              setShowWalletPopup(false);
            } else {
              dispatch(walletCreators.createWallet(fields));
              setShowWalletPopup(false);
            }

            resetForm();
          }}
        >
          {({
            handleChange,
            handleBlur,
            values,
            errors,
            status,
            touched,
            isValid,
            dirty,
            resetForm,
          }) => (
            <Form className="content">
              <div className="input-container">
                <div className="input-wrapper">
                  <Field
                    name="amount"
                    type="number"
                    step="0"
                    value={values.amount}
                    placeholder="Amount"
                  />
                  <ErrorMessage
                    name="amount"
                    component="span"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="datetime-container">
                <div className="input-wrapper">
                  <DatePicker
                    wrapperClassName="input-wrapper"
                    name="actionDate"
                    dateFormat="dd/MM/yyyy HH:mm"
                    selected={values.actionDate}
                    onBlur={() => {
                      handleBlur({ target: { name: 'actionDate' } });
                    }}
                    onChange={(value) => {
                      handleChange({
                        target: { name: 'actionDate', value: value },
                      });
                    }}
                    timeInputLabel="Time:"
                    showTimeInput
                    shouldCloseOnSelect={false}
                  />
                  <ErrorMessage
                    name="actionDate"
                    component="span"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="input-container">
                <InputEmojiForwardRef
                  ref={descriptionRef}
                  value={values.description}
                  setValue={(value) => {
                    handleChange({
                      target: { name: 'description', value: value },
                    });
                  }}
                  closeOnClickOutside
                />
              </div>
              <div className="btn-container">
                <button
                  type="button"
                  className="btn-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={editWallet ? 'btn-update' : 'btn-create'}
                  disabled={!isValid && !dirty}
                >
                  {editWallet ? 'Update' : 'Create'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Popup>
  );
};

export default WalletPopup;
