import { otpActions } from '../actions';
import { IOtp } from '../../models/otp.model';

export interface OtpState {
  otp: IOtp | null;
  isLoading: boolean;
  isOtpRequired: boolean;
  qrcode: string | null;
  message: string | null;
}

const initialState: OtpState = {
  otp: null,
  isLoading: false,
  isOtpRequired: false,
  qrcode: null,
  message: null,
};

export const otpReducer = (
  state: OtpState = initialState,
  action: otpActions.Action
): OtpState => {
  switch (action.type) {
    case otpActions.OtpActionTypes.OTO_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case otpActions.OtpActionTypes.OTO_ENABLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isOtpRequired: true,
        otp: action.payload.otp,
        qrcode: action.payload.qrcode,
        message: action.payload.message,
      };

    case otpActions.OtpActionTypes.OTO_VERIFY_SUCCESS:
    case otpActions.OtpActionTypes.OTO_DISABLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isOtpRequired: false,
        message: action.payload.message,
      };

    case otpActions.OtpActionTypes.OTO_ENABLE_FAIL:
    case otpActions.OtpActionTypes.OTO_VERIFY_FAIL:
    case otpActions.OtpActionTypes.OTO_DISABLE_FAIL:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
