import React, { useEffect, useRef, useState } from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { AiOutlineFile, AiOutlineDelete } from 'react-icons/ai';
import './dropzone.css';
import { httpClient } from '../../utils/http-client';

interface CustomeFile extends File {
  invalid: boolean;
}

const DropZone = () => {
  const [selectedFiles, setSelectedFiles] = useState<CustomeFile[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [validFiles, setValidFiles] = useState<CustomeFile[]>([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState<CustomeFile[]>([]);
  const modalImageRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const modalRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const uploadModalRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const uploadRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const progressRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    let filteredArray = selectedFiles.reduce((file, current) => {
      const x = file.find((item: File) => item.name === current.name);
      if (!x) {
        // @ts-ignore
        return file.concat([current]);
      } else {
        return file;
      }
    }, []);
    setValidFiles([...filteredArray]);
  }, [selectedFiles]);

  useEffect(() => {
    const close = (e: any) => {
      if (e.key === 'Escape') {
        closeModal();
        closeUploadModal();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const handleFiles = (files: FileList) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        // add to an array so we can display the name of file
        // @ts-ignore
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
      } else {
        // add a new property called invalid
        // @ts-ignore
        files[i]['invalid'] = true;
        // add to the same array so we can display the name of the file
        // @ts-ignore
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
        // set error message
        setErrorMessage('File type not permitted');
        // @ts-ignore
        setUnsupportedFiles((prevArray) => [...prevArray, files[i]]);
      }
    }
  };

  const fileSize = (size: number) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const fileType = (fileName: string) => {
    return (
      fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
      fileName
    );
  };

  const validateFile = (file: File) => {
    const validTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/x-icon',
      'image/bmp',
      'image/webp',
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const removeFile = (name: string) => {
    // find the index of the item
    // remove the item from array

    const validFileIndex = validFiles.findIndex((e) => e.name === name);
    validFiles.splice(validFileIndex, 1);
    // update validFiles array
    setValidFiles([...validFiles]);
    const selectedFileIndex = selectedFiles.findIndex((e) => e.name === name);
    selectedFiles.splice(selectedFileIndex, 1);
    // update selectedFiles array
    setSelectedFiles([...selectedFiles]);

    const unsupportedFileIndex = unsupportedFiles.findIndex(
      (e) => e.name === name
    );
    if (unsupportedFileIndex !== -1) {
      unsupportedFiles.splice(unsupportedFileIndex, 1);
      // update unsupportedFiles array
      setUnsupportedFiles([...unsupportedFiles]);
    }
  };

  const openImageModal = (file: File) => {
    const reader = new FileReader();
    modalRef.current.style.display = 'block';
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      modalImageRef.current.style.backgroundImage = `url(${e.target?.result})`;
    };
  };

  const closeModal = () => {
    modalRef.current.style.display = 'none';
    modalImageRef.current.style.backgroundImage = 'none';
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const filesSelected = () => {
    if (fileInputRef.current.files?.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const closeUploadModal = () => {
    uploadModalRef.current.style.display = 'none';
    uploadRef.current.classList.remove('error');
    // window.location.reload();
  };

  const uploadFiles = () => {
    uploadModalRef.current.style.display = 'block';
    uploadRef.current.innerHTML = 'File(s) Uploading...';
    for (let i = 0; i < validFiles.length; i++) {
      const formData = new FormData();
      formData.append('file', validFiles[i]);

      httpClient
        .post('/api/files', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: (progressEvent) => {
            const uploadPercentage = Math.floor(
              (progressEvent.loaded /
                (progressEvent?.total ? progressEvent.total : 1)) *
                100
            );
            progressRef.current.innerHTML = `${uploadPercentage}%`;
            progressRef.current.style.width = `${uploadPercentage}%`;
            if (uploadPercentage === 100) {
              // console.log(progressEvent);
              uploadRef.current.innerHTML = 'File(s) Uploaded';
              // uploadRef.current.classList.remove('error');
              progressRef.current.style.backgroundColor = '#4aa1f3';
              validFiles.length = 0;
              setValidFiles([...validFiles]);
              setSelectedFiles([...validFiles]);
              setUnsupportedFiles([...validFiles]);
              // setTimeout(() => {
              //   window.location.reload();
              // }, 3000);
            }
          },
        })
        .catch((err) => {
          // If error, display a message on the upload modal
          uploadRef.current.innerHTML =
            err.response.data.message || 'Error Uploading File(s)';
          // and add the "error" class
          uploadRef.current.classList.add('error');
          // set progress bar background color to red
          progressRef.current.style.backgroundColor = 'red';
        });
    }
  };

  return (
    <>
      <div className="dropzon-container">
        {unsupportedFiles.length === 0 && validFiles.length ? (
          <button className="file-upload-btn" onClick={() => uploadFiles()}>
            Upload Files
          </button>
        ) : (
          ''
        )}
        {unsupportedFiles.length ? (
          <p>Please remove all unsupported files.</p>
        ) : (
          ''
        )}
        <div
          className="drop-container"
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          onClick={fileInputClicked}
        >
          <div className="drop-message">
            <div className="upload-icon">
              <FaCloudUploadAlt className="icon" />
            </div>
            Drag & Drop files here or click to upload
          </div>
          <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            multiple
            onChange={filesSelected}
          />
        </div>
        <div className="file-display-container">
          {validFiles.map((data, i) => (
            <div className="file-status-bar" key={i}>
              <div
                onClick={
                  !data.invalid
                    ? () => openImageModal(data)
                    : () => removeFile(data.name)
                }
              >
                <div className="file-type-logo">
                  <AiOutlineFile className="icon" />
                </div>
                <div className="file-type">{fileType(data.name)}</div>
                <span
                  className={`file-name ${data.invalid ? 'file-error' : ''}`}
                >
                  {data.name}
                </span>
                <span className="file-size">({fileSize(data.size)})</span>{' '}
                {data.invalid && (
                  <span className="file-error-message">({errorMessage})</span>
                )}
              </div>
              <div
                className="file-remove"
                onClick={() => removeFile(data.name)}
              >
                <AiOutlineDelete />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="modal" ref={modalRef}>
        <div className="overlay"></div>
        <span className="close" onClick={() => closeModal()}>
          &times;
        </span>
        <div className="modal-image" ref={modalImageRef}></div>
      </div>
      <div className="upload-modal" ref={uploadModalRef}>
        <div className="overlay"></div>
        <div className="close" onClick={() => closeUploadModal()}>
          &times;
        </div>
        <div className="progress-container">
          <span className="upload-messages" ref={uploadRef}></span>
          <div className="progress">
            <div className="progress-bar" ref={progressRef}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DropZone;
