import axios from 'axios';
import { customeLocalStorage } from './local-storage';

// Default config options
const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// Create instance
let instance = axios.create(defaultOptions);

// Set Auth Token for any request
instance.interceptors.request.use(
  (config) => {
    if (customeLocalStorage.get('accessToken')) {
      const accessToken = customeLocalStorage.get('accessToken');
      if (config.headers)
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    } else {
      if (config.headers) delete config.headers['Authorization'];
    }

    return config;
  },
  (err) => Promise.reject(err)
);

// Re-new Access Token for any response status 401
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    return new Promise((resolve, reject) => {
      const originalReq = err.config;
      if (
        err.response.status === 401 &&
        err.config &&
        !err.config.__isRetryRequest
      ) {
        originalReq._retry = true;

        let res = fetch('/api/tokens', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
          },
          redirect: 'follow',
          referrer: 'no-referrer',
          body: JSON.stringify({
            refreshToken: customeLocalStorage.get('refreshToken'),
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (
              res.accessToken === undefined ||
              res.refreshToken === undefined
            ) {
              return axios(originalReq);
            } else {
              // console.log('GET NEW ACCESS TOKEN', res);
              customeLocalStorage.set('accessToken', res.accessToken);
              customeLocalStorage.set('refreshToken', res.refreshToken);
              originalReq.headers[
                'Authorization'
              ] = `Bearer ${res.accessToken}`;

              return axios(originalReq);
            }
          });

        resolve(res);
      }
      reject(err);
    });
  }
);

export const httpClient = instance;
