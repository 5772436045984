import { Dispatch } from 'redux';
import { ICommments } from '../../models/comment.model';
import { IPost } from '../../models/post.model';
import { httpClient } from '../../utils/http-client';
import { commentActions } from '../actions';

export const loadCommentByFileId =
  (id: string) => async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({ type: commentActions.CommentActionTypes.COMMENT_FILE_LOADING });

    try {
      const res = await httpClient.get(`/api/comments/files/${id}`);

      dispatch({
        type: commentActions.CommentActionTypes.COMMENT_FILE_LOADED_SUCCESS,
        payload: res.data.comments,
      });
    } catch (err: any) {
      dispatch({
        type: commentActions.CommentActionTypes.COMMENT_FILE_LOADED_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const clickFileFocusInputComment =
  () => async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({
      type: commentActions.CommentActionTypes.COMMENT_FILE_CLICK_FOCUS_INPUT,
    });
  };

export const clickPostFocusInputComment =
  (id: string | number) =>
  async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({
      type: commentActions.CommentActionTypes.COMMENT_POST_CLICK_FOCUS_INPUT,
      payload: id,
    });
  };

export const clearClickPostFocusInputComment =
  () => async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({
      type: commentActions.CommentActionTypes
        .COMMENT_POST_CLEAR_CLICK_FOCUS_INPUT,
    });
  };

export const createCommentPost =
  (data: {
    postId: string | number;
    fileId: string | number | null;
    text: string;
    parentId: string | number | null;
  }) =>
  async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({ type: commentActions.CommentActionTypes.COMMENT_POST_LOADING });

    try {
      const res = await httpClient.post('/api/comments', data);

      dispatch({
        type: commentActions.CommentActionTypes.COMMENT_POST_CREATE_SUCCESS,
        payload: {
          postId: data.postId,
          comment: res.data.comment,
          message: res.data.message,
        },
      });
    } catch (err: any) {
      dispatch({
        type: commentActions.CommentActionTypes.COMMENT_POST_CREATE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const createCommentFile =
  (data: {
    postId: string | number | null;
    fileId: string | number | null;
    text: string;
    parentId: string | number | null;
  }) =>
  async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({ type: commentActions.CommentActionTypes.COMMENT_FILE_LOADING });

    try {
      const res = await httpClient.post('/api/comments', data);

      dispatch({
        type: commentActions.CommentActionTypes.COMMENT_FILE_CREATE_SUCCESS,
        payload: { comment: res.data.comment, message: res.data.message },
      });
    } catch (err: any) {
      dispatch({
        type: commentActions.CommentActionTypes.COMMENT_FILE_CREATE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const deleteCommentPostById =
  (post: IPost, commentId: string | number) =>
  async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({ type: commentActions.CommentActionTypes.COMMENT_POST_LOADING });

    try {
      const res = await httpClient.delete(`/api/comments/${commentId}`);

      dispatch({
        type: commentActions.CommentActionTypes.COMMENT_POST_DELETE_SUCCESS,
        payload: { post, commentId, message: res.data.message },
      });
    } catch (err: any) {
      dispatch({
        type: commentActions.CommentActionTypes.COMMENT_POST_DELETE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const deleteCommentFileById =
  (id: string | number) =>
  async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({ type: commentActions.CommentActionTypes.COMMENT_FILE_LOADING });

    try {
      const res = await httpClient.delete(`/api/comments/${id}`);

      dispatch({
        type: commentActions.CommentActionTypes.COMMENT_FILE_DELETE_SUCCESS,
        payload: { id: id, message: res.data.message },
      });
    } catch (err: any) {
      dispatch({
        type: commentActions.CommentActionTypes.COMMENT_FILE_DELETE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const setReplyCommentPost =
  (commentId: string | number) =>
  async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({
      type: commentActions.CommentActionTypes.COMMENT_POST_SET_REPLY,
      payload: { commentId },
    });
  };

export const setReplyCommentFile =
  (parent: ICommments, current: ICommments) =>
  async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({
      type: commentActions.CommentActionTypes.COMMENT_FILE_SET_REPLY,
      payload: { parent: parent, current: current },
    });
  };

export const setEditCommentPost =
  (
    postId: string | number,
    parent: ICommments,
    current: ICommments,
    edit: boolean = true
  ) =>
  async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({
      type: commentActions.CommentActionTypes.COMMENT_POST_SET_EDIT,
      payload: { postId: postId, parent: parent, current: current, edit: edit },
    });
  };

export const setEditCommentFile =
  (parent: ICommments, current: ICommments, edit: boolean = true) =>
  async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({
      type: commentActions.CommentActionTypes.COMMENT_FILE_SET_EDIT,
      payload: { parent: parent, current: current, edit: edit },
    });
  };

export const updateCommentPost =
  (
    postId: string | number,
    commentId: string | number,
    data: { text: string }
  ) =>
  async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({ type: commentActions.CommentActionTypes.COMMENT_POST_LOADING });

    try {
      const res = await httpClient.put(`/api/comments/${commentId}`, data);

      dispatch({
        type: commentActions.CommentActionTypes.COMMENT_POST_UPDATE_SUCCESS,
        payload: {
          postId: postId,
          comment: res.data.comment,
          message: res.data.message,
        },
      });
    } catch (err: any) {
      dispatch({
        type: commentActions.CommentActionTypes.COMMENT_POST_UPDATE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const updateCommentFile =
  (data: { id: string | number; text: string }) =>
  async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({ type: commentActions.CommentActionTypes.COMMENT_FILE_LOADING });

    try {
      const res = await httpClient.put(`/api/comments/${data.id}`, data);

      dispatch({
        type: commentActions.CommentActionTypes.COMMENT_FILE_UPDATE_SUCCESS,
        payload: { comment: res.data.comment, message: res.data.message },
      });
    } catch (err: any) {
      dispatch({
        type: commentActions.CommentActionTypes.COMMENT_FILE_UPDATE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const clearEditCommentPost =
  () => async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({
      type: commentActions.CommentActionTypes.COMMENT_POST_CLEAR_EDIT,
    });
  };

export const clearEditCommentFile =
  () => async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({
      type: commentActions.CommentActionTypes.COMMENT_FILE_CLEAR_EDIT,
    });
  };

export const clearReplyCommentPost =
  () => async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({
      type: commentActions.CommentActionTypes.COMMENT_POST_CLEAR_REPLY,
    });
  };

export const clearReplyCommentFile =
  () => async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({
      type: commentActions.CommentActionTypes.COMMENT_FILE_CLEAR_REPLY,
    });
  };

export const commentPostClickLikeOrUnlike =
  (postId: string | number, commentId: string | number, status: boolean) =>
  async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({
      type: commentActions.CommentActionTypes.COMMENT_POST_LOADING,
    });

    try {
      const res = await httpClient.get(`/api/comments/${commentId}/like`);

      if (status) {
        dispatch({
          type: commentActions.CommentActionTypes.COMMENT_POST_CLICK_UNLIKE,
          payload: {
            postId: postId,
            commentId: commentId,
            unlike: res.data.unlike,
            message: res.data.message,
          },
        });
      } else {
        dispatch({
          type: commentActions.CommentActionTypes.COMMENT_POST_CLICK_LIKE,
          payload: {
            postId: postId,
            commentId: commentId,
            like: res.data.like,
            message: res.data.message,
          },
        });
      }
    } catch (err: any) {
      dispatch({
        type: commentActions.CommentActionTypes
          .COMMENT_POST_CLICK_LIKE_OR_UNLIKE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const commentFileClickLikeOrUnlike =
  (commentId: string | number, status: boolean) =>
  async (dispatch: Dispatch<commentActions.Action>) => {
    dispatch({
      type: commentActions.CommentActionTypes.COMMENT_FILE_LOADING,
    });

    try {
      const res = await httpClient.get(`/api/comments/${commentId}/like`);

      if (status) {
        dispatch({
          type: commentActions.CommentActionTypes.COMMENT_FILE_CLICK_UNLIKE,
          payload: {
            id: commentId,
            unlike: res.data.unlike,
            message: res.data.message,
          },
        });
      } else {
        dispatch({
          type: commentActions.CommentActionTypes.COMMENT_FILE_CLICK_LIKE,
          payload: {
            id: commentId,
            like: res.data.like,
            message: res.data.message,
          },
        });
      }
    } catch (err: any) {
      dispatch({
        type: commentActions.CommentActionTypes
          .COMMENT_FILE_CLICK_LIKE_OR_UNLIKE_FAIL,
        payload: err.response.data.message,
      });
    }
  };
