import { IBackup } from '../../models/backup.model';
import { backupActions } from '../actions';

export interface BackupState {
  isLoading: boolean;
  backups: IBackup[] | [];
  message: string | null;
}

const initialState: BackupState = {
  isLoading: false,
  backups: [],
  message: null,
};

export const backupReducer = (
  state: BackupState = initialState,
  action: backupActions.Action
): BackupState => {
  switch (action.type) {
    case backupActions.BackupActionTypes.BACKUP_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case backupActions.BackupActionTypes.BACKUP_LOADED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        backups: action.payload,
      };

    case backupActions.BackupActionTypes.BACKUP_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        backups: [...state.backups, action.payload.backup],
        message: action.payload.message,
      };

    case backupActions.BackupActionTypes.BACKUP_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        backups: state.backups.filter(
          (backup) => backup._id !== action.payload.backup._id
        ),
        message: action.payload.message,
      };

    case backupActions.BackupActionTypes.BACKUP_LOADED_FAIL:
    case backupActions.BackupActionTypes.BACKUP_CREATE_FAIL:
    case backupActions.BackupActionTypes.BACKUP_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
