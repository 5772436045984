import { IErrorMessage } from '../../models/errormessage.model';
import { IToken } from '../../models/token.model';
import { IUser } from '../../models/user.model';

export enum AuthActionTypes {
  USER_LOADING = 'USER_LOADING',
  USER_LOADED = 'USER_LOADED',
  AUTH_ERROR = 'AUTH_ERROR',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAIL = 'LOGIN_FAIL',
  LOGIN_REQUEST_OTP = 'LOGIN_REQUEST_OTP',
  LOGIN_VERIFY_SUCCESS = 'LOGIN_VERIFY_SUCCESS',
  LOGIN_VERIFY_FAIL = 'LOGIN_VERIFY_FAIL',
  LOGIN_USE_RECOVERY_CODE_SUCCESS = 'LOGIN_USE_RECOVERY_CODE_SUCCESS',
  LOGIN_USE_RECOVERY_CODE_FAIL = 'LOGIN_USE_RECOVERY_CODE_FAIL',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAIL = 'LOGOUT_FAIL',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  REGISTER_FAIL = 'REGISTER_FAIL',
  REGISTER_VERIFY_SUCCESS = 'REGISTER_VERIFY_SUCCESS',
  REGISTER_VERIFY_FAIL = 'REGISTER_VERIFY_FAIL',
}

export interface UserLoadingAction {
  readonly type: AuthActionTypes.USER_LOADING;
}

export interface UserLoadedAction {
  readonly type: AuthActionTypes.USER_LOADED;
  payload: IUser;
}

export interface AuthErrorAction {
  readonly type: AuthActionTypes.AUTH_ERROR;
  payload: IErrorMessage;
}

export interface LoginSuccessAction {
  readonly type: AuthActionTypes.LOGIN_SUCCESS;
  payload: IToken;
}

export interface LoginFailAction {
  readonly type: AuthActionTypes.LOGIN_FAIL;
  payload: IErrorMessage;
}

export interface LoginRquestOtpAction {
  readonly type: AuthActionTypes.LOGIN_REQUEST_OTP;
  payload: { pendingId: string; subject: string; message: string };
}

export interface LoginVerifySuccessAction {
  readonly type: AuthActionTypes.LOGIN_VERIFY_SUCCESS;
  payload: IToken;
}

export interface LoginVerifyFailAction {
  readonly type: AuthActionTypes.LOGIN_VERIFY_FAIL;
  payload: IErrorMessage;
}

export interface LoginUseRecoveryCodeSuccessAction {
  readonly type: AuthActionTypes.LOGIN_USE_RECOVERY_CODE_SUCCESS;
  payload: IToken;
}

export interface LoginUseRecoveryCodeFailAction {
  readonly type: AuthActionTypes.LOGIN_USE_RECOVERY_CODE_FAIL;
  payload: IErrorMessage;
}

export interface LogoutSuccessAction {
  readonly type: AuthActionTypes.LOGOUT_SUCCESS;
  payload: IErrorMessage;
}

export interface LogoutFailAction {
  readonly type: AuthActionTypes.LOGOUT_FAIL;
  payload: IErrorMessage;
}

export interface RegisterSuccessAction {
  readonly type: AuthActionTypes.REGISTER_SUCCESS;
  payload: { pendingId: string; subject: string; message: string };
}

export interface RegisterFailAction {
  readonly type: AuthActionTypes.REGISTER_FAIL;
  payload: IErrorMessage;
}

export interface RegisterVerifySuccessAction {
  readonly type: AuthActionTypes.REGISTER_VERIFY_SUCCESS;
  payload: { pendingId: string; subject: string; message: string };
}

export interface RegisterVerifyFailAction {
  readonly type: AuthActionTypes.REGISTER_VERIFY_FAIL;
  payload: IErrorMessage;
}

export type Action =
  | AuthErrorAction
  | LoginSuccessAction
  | LoginFailAction
  | LoginRquestOtpAction
  | LoginVerifySuccessAction
  | LoginVerifyFailAction
  | LoginUseRecoveryCodeSuccessAction
  | LoginUseRecoveryCodeFailAction
  | LogoutSuccessAction
  | LogoutFailAction
  | RegisterSuccessAction
  | RegisterFailAction
  | RegisterVerifySuccessAction
  | RegisterVerifyFailAction
  | UserLoadingAction
  | UserLoadedAction;
