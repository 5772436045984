import React, { Dispatch, useRef } from 'react';
// import InputEmoji from 'react-input-emoji';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppState } from '../../store/app-state';
import InputEmojiForwardRef from '../input-emoji-with-ref/InputEmojiWithRef';

const InputCommentContainer = styled.div`
  display: 'flex';
  flex: 1;
  position: 'relative';

  .react-emoji-picker--wrapper {
    width: 360px;
  }
`;

type InputCommentProps = {
  value: string;
  onChange: Dispatch<React.SetStateAction<string>>;
  onEnter: () => void;
};

const InputComment = ({ value, onChange, onEnter }: InputCommentProps) => {
  // const [borderColor, SetBorderColor] = useState('#EAEAEA');
  const input = useRef() as React.MutableRefObject<HTMLInputElement>;

  // const handleFocus = () => {
  //   SetBorderColor('#aaa');
  // };

  // const handleMouseLeave = () => {
  //   SetBorderColor('#EAEAEA');
  // };

  const focus = useSelector((state: AppState) => state.comments.focus);
  const current = useSelector((state: AppState) => state.comments.current);

  if (current || focus) {
    input.current?.focus();
  }

  return (
    <InputCommentContainer /*onMouseLeave={handleMouseLeave}*/>
      {/* <InputEmoji
        value={value}
        onChange={onChange}
        cleanOnEnter
        onFocus={handleFocus}
        borderColor={borderColor}
        onEnter={onEnter}
        placeholder="Write a comment..."
      /> */}
      <InputEmojiForwardRef
        ref={input}
        value={value}
        setValue={onChange}
        onEnter={onEnter}
        cleanOnEnter
        closeOnClickOutside
      />
    </InputCommentContainer>
  );
};

export default InputComment;
