import { TagOption } from '../models/tag.model';
import { ITradeMethodology } from '../models/trademedology.model';
import { numToColor } from './color';

export const tradeMethodologyToSingleSelect = (tag: ITradeMethodology) => {
  return {
    value: tag.name,
    label: tag.name,
    score: tag.score,
    color: numToColor(0),
  };
};

export const tradeMethodoloiesToSingleSelect = (tags: ITradeMethodology[]) => {
  return {
    value: tags[0].name,
    label: tags[0].name,
    score: tags[0].score,
    color: numToColor(0),
  };
};

export const tradeMethodologiesToMultiSelect = (tags: ITradeMethodology[]) => {
  const tagOptions: readonly TagOption[] = tags.map(function (
    tag: ITradeMethodology,
    i: number
  ) {
    return {
      value: tag.name,
      label: tag.name,
      color: numToColor(i),
    };
  });

  return tagOptions;
};
