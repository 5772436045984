export const validYoutube = (url: string): [boolean, string] => {
  let re =
    /(https?:\/\/)?(((m|www)\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;

  // https://stackoverflow.com/a/51870158

  const match = url.match(re);

  const list = ['youtube', 'youtube-nocookie'];

  if (match && (list.includes(match[5]) || match[2] === 'youtu.be/')) {
    return [true, match[8]];
  }

  return [false, ''];
};

export const validDailymotion = (url: string): [boolean, string] => {
  let re =
    /^(?:(?:http|https):\/\/)?(?:www.)?(dailymotion\.com|dai\.ly)\/((video\/([^_]+))|(hub\/([^_]+)|([^\\/_]+)))$/;

  // https://stackoverflow.com/a/50644701

  const match = url.match(re);

  const list = ['dailymotion.com', 'dai.ly'];

  if (match && list.includes(match[1])) {
    return match[4] ? [true, match[4]] : [true, match[5]];
  }

  return [false, ''];
};

export const validTiktok = (url: string): [boolean, string] => {
  let re =
    /(https?:\/\/)?(((m|www|yt)\.)?(tiktok)\.com.*(v\/|(@[a-zA-z0-9]*|.*)(\/.*\/|trending.?shareId=)|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|tiktok\.com\/)([_0-9a-z-]+)/i;

  // https://stackoverflow.com/a/51870158
  // https://regex101.com/library/PVeIMO
  // https://stackoverflow.com/a/70193436

  const match = url.match(re);

  const list = ['tiktok'];

  if (match && (list.includes(match[5]) || match[2] === 'tiktok.com/')) {
    return [true, match[9]];
  }

  return [false, ''];
};

export const validFacebook = (url: string): [boolean, string] => {
  let re =
    /(?:https?:\/\/)?(?:www.|web.|m.)?(facebook|fb).(com|watch)\/(?:video.php\?v=\d+|(\S+)|photo.php\?v=\d+|\?v=\d+)|\S+\/videos\/((\S+)\/(\d+)|(\d+))\/?/;

  // https://stackoverflow.com/a/65484777

  const match = url.match(re);

  const list = ['facebook'];

  if (match && list.includes(match[1])) {
    return [true, match[0]];
  }

  return [false, ''];
};

export const validIframe = (
  iframe: string
): [
  math: boolean,
  iframe: string,
  src: string,
  width: string,
  height: string
] => {
  let re = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g;
  let regEx = /(src|width|height)=[\S+]([^"']*)["']/gi;

  // https://www.regextester.com/99829
  // https://stackoverflow.com/a/13380395
  // https://stackoverflow.com/a/19793385

  const match = iframe.match(re);

  if (match) {
    const el = iframe.match(regEx);

    let arr: string[] = [];

    el &&
      Array.from(el).forEach((v) => {
        let item = v.match(/"((?:\\.|[^"\\])*)"/);
        let result = item && item[1];
        arr.push(result || '');
      });
    // [math,iframe,src,width,height]
    return [true, match[0], arr[0] || '', arr[1] || '', arr[2] || ''];
  }

  // [math,iframe,src,width,height]
  return [false, '', '', '', ''];
};

export function validVideoUrl(url: string) {
  const [matchYoutube] = validYoutube(url);
  const [matchDailymotion] = validDailymotion(url);
  const [matchTiktok] = validTiktok(url);
  const [matchFacebook] = validFacebook(url);
  const [matchIframe] = validIframe(url);

  if (
    matchIframe ||
    matchYoutube ||
    matchDailymotion ||
    matchTiktok ||
    matchFacebook
  ) {
    return true;
  }

  return false;
}

export const validUrl = (url: string): boolean => {
  let re =
    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\\/]))?/;

  // https://stackoverflow.com/a/8667172

  return re.test(url);
};
