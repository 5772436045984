import { IPost } from '../models/post.model';
import { sort } from './sort';

export const pinedPostAtFirst = (posts: IPost[]) => {
  const pinnedPosts = posts.filter((p) => p.pin === true);
  const unpinnedPosts = posts.filter((p) => p.pin === false);

  if (pinnedPosts.length === 0) {
    return sort(posts, '-createdAt');
  } else {
    posts = [...pinnedPosts, ...unpinnedPosts];
  }

  return posts;
};
