import React, { useEffect, useState } from 'react';
import { ICommment } from '../../models/comment.model';
import { IPost } from '../../models/post.model';
import { IUser } from '../../models/user.model';
import { createCommentsTree } from '../../utils/comments-tree';
import BlogComment from '../blog-comment/BlogComment';
import './blog-comments.css';

type BlogCommentsProps = {
  comments: ICommment[];
  postInfo: IPost;
  currentUser: IUser | null;
};

const BlogComments = ({
  comments,
  postInfo,
  currentUser,
}: BlogCommentsProps) => {
  const [hidden, setHidden] = useState(false);
  const commentTree = createCommentsTree(comments);

  useEffect(() => {
    if (commentTree.length > 2) {
      setHidden(true);
    }
  }, [commentTree.length]);

  return (
    <ul className="blog-comments">
      {commentTree.map((comments, i) => (
        <React.Fragment key={comments._id}>
          {hidden && i === commentTree.length - 3 ? (
            <div
              className="view-previous-comments"
              onClick={() => setHidden(false)}
            >
              <p>
                View{' '}
                {commentTree.length - 2 <= 0
                  ? ''
                  : commentTree.length - 2 + ' '}
                previous comments
              </p>
            </div>
          ) : hidden && i < commentTree.length - 3 ? (
            <></>
          ) : (
            <BlogComment
              key={comments._id}
              comments={comments}
              parent={comments}
              postInfo={postInfo}
              currentUser={currentUser}
            />
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default BlogComments;
