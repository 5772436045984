import { IErrorMessage } from '../../models/errormessage.model';

export enum PasswordActionTypes {
  PASSWORD_LOADING = 'PASSWORD_LOADING',
  PASSWORD_FORGOT_SUCCESS = 'PASSWORD_FORGOT_SUCCESS',
  PASSWORD_FORGOT_FAIL = 'PASSWORD_FORGOT_FAIL',
  PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL',
}

export interface PasswordLoadingAction {
  readonly type: PasswordActionTypes.PASSWORD_LOADING;
}

export interface PasswordForgotSuccessAction {
  readonly type: PasswordActionTypes.PASSWORD_FORGOT_SUCCESS;
  payload: IErrorMessage;
}

export interface PasswordForgotFailAction {
  readonly type: PasswordActionTypes.PASSWORD_FORGOT_FAIL;
  payload: IErrorMessage;
}

export interface PasswordResetSuccessAction {
  readonly type: PasswordActionTypes.PASSWORD_RESET_SUCCESS;
  payload: IErrorMessage;
}

export interface PasswordResetFailAction {
  readonly type: PasswordActionTypes.PASSWORD_RESET_FAIL;
  payload: IErrorMessage;
}

export type Action =
  | PasswordLoadingAction
  | PasswordForgotSuccessAction
  | PasswordForgotFailAction
  | PasswordResetSuccessAction
  | PasswordResetFailAction;
