import { IRole } from '../../models/role.model';
import { roleActions } from '../actions';

export interface RoleState {
  isLoading: boolean;
  roles: IRole[] | [];
  message: string | null;
}

const initialState: RoleState = {
  isLoading: false,
  roles: [],
  message: null,
};

export const roleReducer = (
  state: RoleState = initialState,
  action: roleActions.Action
): RoleState => {
  switch (action.type) {
    case roleActions.RoleActionTypes.ROLE_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case roleActions.RoleActionTypes.ROLE_LOADED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        roles: action.payload,
      };

    case roleActions.RoleActionTypes.ROLE_LOADED_FAIL:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
