import styled from 'styled-components';

export const EditContainer = styled.div`
  padding: 0px 32px 0px;
  position: relative;

  .content {
    padding: 22px 0px 15px;

    .input-container {
      width: 100%;
      height: 50px;
      padding-top: 200px;
    }

    .select-container {
      width: 100%;
      height: 50px;
      padding-top: 50px;
    }

    .btn-update-container {
      width: 100%;
      height: 20px;
      padding-top: 50px;

      .btn-update,
      .btn-cancel {
        padding: 10px 20px;
        float: right;
        margin-left: 5px;
      }
    }
  }
`;

export const UserInfoContainer = styled.div`
  padding: 0px 32px 0px;
  border-bottom: 1px solid #eee;

  .user {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: center;
    padding: 22px 0px 15px;

    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }

    .user-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 1 auto;
      overflow: hidden;
      margin-left: 10px;

      a {
        text-decoration: none;
        color: #333;
      }
    }

    .user-trade {
      display: flex;
      align-content: center;

      .create-trade {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: #48c78e;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.edit {
          color: #555;
          background-color: rgb(255, 224, 138);

          &:hover {
            background-color: rgb(255, 220, 125);
            transition: 0.5s ease-in-out;
          }
        }

        &:hover {
          background-color: #3ec487;
          transition: 0.5s ease-in-out;
        }

        .icon {
          font-size: 1.5rem;
        }
      }
    }
  }
`;

export const ContentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 32px;

  .description-container {
    min-height: 120px;
    position: relative;

    .file-action {
      position: absolute;
      top: 0;
      right: 0;
      padding-top: 5px;
      display: flex;
      flex-direction: row;
      font-size: 20px;

      .trade-add-icon {
        cursor: pointer;
        margin-right: 8px;

        .icon {
          color: #28a745;

          &:hover {
            color: #218838;
            transition: color 0.3s ease-in-out;
          }
        }
      }

      .file-edit-icon {
        cursor: pointer;
        margin-right: 8px;

        .icon {
          color: #ffc107;

          &:hover {
            color: #e0a800;
            transition: color 0.3s ease-in-out;
          }
        }
      }

      .file-delete-icon {
        cursor: pointer;

        .icon {
          color: #dc3545;

          &:hover {
            color: #c82333;
            transition: color 0.3s ease-in-out;
          }
        }
      }
    }

    .table-trade {
      margin: 32px 0 16px;
    }

    .file-caption {
      color: rgb(22, 24, 35);
      box-sizing: border-box;
      padding-top: 10px;
      font-size: 18px;
      line-height: 22px;

      p {
        display: block;
        padding-right: 8px;
      }
    }

    .file-tags {
      position: absolute;
      bottom: 0;

      a {
        display: inline-block;
        text-decoration: none;
        white-space: nowrap;
        padding-right: 8px;
        color: #555;

        strong {
          font-size: 16px;
          font-weight: 600;
        }

        &:hover {
          text-decoration: underline;
          color: rgb(22, 24, 35);
          transition: text-decoration 0.3s ease-in-out;
        }
      }
    }
  }

  .action-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px;
    position: relative;
    flex: 0 0 auto;
  }
  .action-left {
    display: flex;
    flex: 0 0 auto;
    font-weight: 600;
    font-size: 16px;
  }
  .action-wrapper {
    margin-right: 20px;
    font-size: 13px;
  }
  .icons {
    display: inline-block;
    font-size: 20px;
    text-align: center;
    vertical-align: middle;
    margin-right: 6px;
    padding-top: 6px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-size: 20px 20px;
    background-color: rgba(22, 24, 35, 0.06);
    background-position: center center;
    cursor: pointer;

    &:hover {
      background: rgba(22, 24, 35, 0.1);
      transition: all 0.3s ease-out;
    }
  }
`;

export const CommentsContainer = styled.div`
  width: 100%;
  flex: 1 1 auto;
  position: relative;
  display: flex;
  background-color: rgb(248, 248, 248);
  padding: 0px 32px;
  border-top: 0.5px solid rgba(18, 18, 18, 0.12);
  border-bottom: 0.5px solid rgba(18, 18, 18, 0.12);
  box-sizing: border-box;
  overflow-y: overlay;

  .comments {
    padding-top: 16px;
    width: 100%;
  }

  .comment-item {
    margin-bottom: 16px;
    width: 100%;
  }

  .comment-content {
    display: flex;
    position: relative;
    flex-direction: row;
    padding: 0px;
    margin-bottom: 16px;
  }
  .user-avatar {
    color: inherits;
    text-align: center;
    vertical-align: middle;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border-width: 0.5px;
      border-color: rgba(22, 24, 35, 0.12);
    }
  }
  .comment-content-container {
    user-select: none;
    padding: 0px 16px;
    display: block;
  }
  .user-name {
    font-size: 18px;
    line-height: 25px;
    text-decoration: none;
    color: rgb(22, 24, 35);

    &:hover {
      text-decoration: underline;
    }
  }
  .comment-text {
    font-size: 1rem;
    line-height: 22px;
    color: rgb(18, 18, 18);
    padding-top: 4px;
    padding-right: 40px;
    font-weight: 400;
    word-break: break-word;
    white-space: pre-line;
  }
  .comment-buttom-container {
    display: flex;
  }
  .comment-date {
    display: block;
    font-size: 0.875rem;
    line-height: 20px;
    margin: 6px 0px 0px;
    color: rgba(18, 18, 18, 0.5);
  }
  .comment-reply,
  .comment-edit {
    font-weight: 600;
    color: rgba(22, 24, 35, 0.5);
    font-size: 14px;
    line-height: 20px;
    margin: 6px 0px 0px 16px;
    cursor: pointer;

    &:hover {
      color: rgba(22, 24, 35, 0.6);
      transition: all 0.3s ease-out;
    }

    &.active {
      color: rgba(22, 24, 35, 0.8);
    }
  }
  .comment-cancel,
  .comment-edit-cancel {
    font-weight: 600;
    color: rgba(255, 137, 59, 1);
    font-size: 14px;
    line-height: 20px;
    margin: 6px 0px 0px 16px;
    cursor: pointer;

    &:hover {
      color: rgba(22, 24, 35, 0.6);
      transition: all 0.3s ease-out;
    }
  }
  .comment-delete {
    font-weight: 600;
    color: rgba(22, 24, 35, 0.5);
    font-size: 14px;
    line-height: 20px;
    margin: 6px 0px 0px 16px;
    cursor: pointer;

    &:hover {
      color: rgba(219, 26, 26, 0.8);
      transition: all 0.3s ease-out;
    }
  }
  .like-container {
    position: absolute;
    top: 24px;
    right: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20px;

    .love-comment-icon {
      color: #aaa;
      cursor: pointer;
    }
  }
  .count {
    display: block;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 17px;
    text-align: center;
    color: rgba(22, 24, 35, 0.5);
  }
  .more-comments {
    padding-left: 52px;
    margin-bottom: 20px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.15);
  }

  ::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
  }
`;

export const CreateCommentContainer = styled.div`
  /* margin: 0px 20px; */
  padding: 21px 20px;
  background-color: rgb(255, 255, 255);

  .comment-create-inner-wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: flex-end;
    margin-bottom: -5px;
  }
  .comment-input-outside-container {
    flex: 1 1 0%;
  }
  .comment-input-inner-wrapper {
    position: relative;
    box-sizing: border-box;
    background: rgba(22, 24, 35, 0.06);
    border: 1px solid transparent;
    border-radius: 8px;

    input {
      width: 100%;
      height: 30px;
      border: 1px solid #ccc;
      border-radius: 2px;
      box-shadow: none;
      padding-left: 5px;
      font-size: 0.825rem;

      &:focus {
        border: 1px solid #eee;
        box-shadow: none;
        outline: none;
        font-size: 1rem;
        transition: all 0.1s ease-out;
      }
    }
  }
  .post-container {
    font-weight: 600;
    margin: 0px 4px 10px 16px;
    font-size: 0.825rem;
    line-height: 14px;

    button {
      background: inherit;
      border: inherit;
      cursor: pointer;
      color: rgba(22, 24, 35, 0.5);

      &:hover {
        color: rgba(22, 24, 35, 0.3);
        transition: all 0.3s ease-out;
      }
    }
  }
`;
