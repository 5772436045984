import React from 'react';
import { Link } from 'react-router-dom';
import { FaGoogle, FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

const SignWithSocials = () => {
  return (
    <div className="social-media">
      <Link to="#" className="social-icon">
        <FaFacebookF />
      </Link>
      <Link to="#" className="social-icon">
        <FaTwitter />
      </Link>
      <Link to="#" className="social-icon">
        <FaGoogle />
      </Link>
      <Link to="#" className="social-icon">
        <FaLinkedinIn />
      </Link>
    </div>
  );
};

export default SignWithSocials;
