import { ICommment, ICommments } from '../../models/comment.model';
import { ILike } from '../../models/like.model';
import { IErrorMessage } from '../../models/errormessage.model';
import { IPost } from '../../models/post.model';

export enum CommentActionTypes {
  COMMENT_FILE_LOADING = 'COMMENT_FILE_LOADING',
  COMMENT_FILE_LOADED_SUCCESS = 'COMMENT_FILE_LOADED_SUCCESS',
  COMMENT_FILE_LOADED_FAIL = 'COMMENT_FILE_LOADED_FAIL',
  COMMENT_FILE_CREATE_SUCCESS = 'COMMENT_FILE_CREATE_SUCCESS',
  COMMENT_FILE_CREATE_FAIL = 'COMMENT_FILE_CREATE_FAIL',
  COMMENT_FILE_DELETE_SUCCESS = 'COMMENT_FILE_DELETE_SUCCESS',
  COMMENT_FILE_DELETE_FAIL = 'COMMENT_FILE_DELETE_FAIL',
  COMMENT_FILE_SET_REPLY = 'COMMENT_FILE_SET_REPLY',
  COMMENT_FILE_CLEAR_REPLY = 'COMMENT_FILE_CLEAR_REPLY',
  COMMENT_FILE_SET_EDIT = 'COMMENT_FILE_SET_EDIT',
  COMMENT_FILE_CLEAR_EDIT = 'COMMENT_FILE_CLEAR_EDIT',
  COMMENT_FILE_UPDATE_SUCCESS = 'COMMENT_FILE_UPDATE_SUCCESS',
  COMMENT_FILE_UPDATE_FAIL = 'COMMENT_FILE_UPDATE_FAIL',
  COMMENT_FILE_CLICK_LIKE = 'COMMENT_FILE_CLICK_LIKE',
  COMMENT_FILE_CLICK_UNLIKE = 'COMMENT_FILE_CLICK_UNLIKE',
  COMMENT_FILE_CLICK_LIKE_OR_UNLIKE_FAIL = 'COMMENT_FILE_CLICK_LIKE_OR_UNLIKE_FAIL',
  COMMENT_FILE_CLICK_FOCUS_INPUT = 'COMMENT_FILE_CLICK_FOCUS_INPUT',
  COMMENT_POST_LOADING = 'COMMENT_POST_LOADING',
  COMMENT_POST_CLICK_FOCUS_INPUT = 'COMMENT_POST_CLICK_FOCUS_INPUT',
  COMMENT_POST_CLEAR_CLICK_FOCUS_INPUT = 'COMMENT_CLEAR_POST_CLICK_FOCUS_INPUT',
  COMMENT_POST_CREATE_SUCCESS = 'COMMENT_POST_CREATE_SUCCESS',
  COMMENT_POST_CREATE_FAIL = 'COMMENT_POST_CREATE_FAIL',
  COMMENT_POST_DELETE_SUCCESS = 'COMMENT_POST_DELETE_SUCCESS',
  COMMENT_POST_DELETE_FAIL = 'COMMENT_POST_DELETE_FAIL',
  COMMENT_POST_SET_REPLY = 'COMMENT_POST_SET_REPLY',
  COMMENT_POST_CLEAR_REPLY = 'COMMENT_POST_CLEAR_REPLY',
  COMMENT_POST_SET_EDIT = 'COMMENT_POST_SET_EDIT',
  COMMENT_POST_CLEAR_EDIT = 'COMMENT_POST_CLEAR_EDIT',
  COMMENT_POST_UPDATE_SUCCESS = 'COMMENT_POST_UPDATE_SUCCESS',
  COMMENT_POST_UPDATE_FAIL = 'COMMENT_POST_UPDATE_FAIL',
  COMMENT_POST_CLICK_LIKE = 'COMMENT_POST_CLICK_LIKE',
  COMMENT_POST_CLICK_UNLIKE = 'COMMENT_POST_CLICK_UNLIKE',
  COMMENT_POST_CLICK_LIKE_OR_UNLIKE_FAIL = 'COMMENT_POST_CLICK_LIKE_OR_UNLIKE_FAIL',
}

export interface CommentFileLoadingAction {
  readonly type: CommentActionTypes.COMMENT_FILE_LOADING;
}

export interface CommentFileLoadedSuccessAction {
  readonly type: CommentActionTypes.COMMENT_FILE_LOADED_SUCCESS;
  payload: ICommment[];
}

export interface CommentFileLoadedFailAction {
  readonly type: CommentActionTypes.COMMENT_FILE_LOADED_FAIL;
  payload: IErrorMessage;
}

export interface CommentFileClickFocusInputAction {
  readonly type: CommentActionTypes.COMMENT_FILE_CLICK_FOCUS_INPUT;
}

export interface CommentFileCreateSuccessAction {
  readonly type: CommentActionTypes.COMMENT_FILE_CREATE_SUCCESS;
  payload: { comment: ICommment; message: IErrorMessage };
}

export interface CommentFileCreateFailAction {
  readonly type: CommentActionTypes.COMMENT_FILE_CREATE_FAIL;
  payload: IErrorMessage;
}

export interface CommentFileDeleteSuccessAction {
  readonly type: CommentActionTypes.COMMENT_FILE_DELETE_SUCCESS;
  payload: { id: string | number; message: IErrorMessage };
}

export interface CommentFileDeleteFailAction {
  readonly type: CommentActionTypes.COMMENT_FILE_DELETE_FAIL;
  payload: IErrorMessage;
}

export interface CommentFileSetReplyAction {
  readonly type: CommentActionTypes.COMMENT_FILE_SET_REPLY;
  payload: { parent: ICommments; current: ICommments };
}

export interface CommentFileClearReplyAction {
  readonly type: CommentActionTypes.COMMENT_FILE_CLEAR_REPLY;
}

export interface CommentFileSetEditAction {
  readonly type: CommentActionTypes.COMMENT_FILE_SET_EDIT;
  payload: {
    parent: ICommments;
    current: ICommments;
    edit: boolean;
  };
}

export interface CommentFileClearEditAction {
  readonly type: CommentActionTypes.COMMENT_FILE_CLEAR_EDIT;
}

export interface CommentFileUpdateSuccessAction {
  readonly type: CommentActionTypes.COMMENT_FILE_UPDATE_SUCCESS;
  payload: { comment: ICommment; message: IErrorMessage };
}

export interface CommentFileUpdateFailAction {
  readonly type: CommentActionTypes.COMMENT_FILE_UPDATE_FAIL;
  payload: IErrorMessage;
}

export interface CommentFileClickLikeAction {
  readonly type: CommentActionTypes.COMMENT_FILE_CLICK_LIKE;
  payload: { id: string | number; like: ILike; message: string };
}

export interface CommentFileClickUnlikeAction {
  readonly type: CommentActionTypes.COMMENT_FILE_CLICK_UNLIKE;
  payload: { id: string | number; unlike: ILike; message: string };
}

export interface CommentFileClickLikeORUnlikeFailAction {
  readonly type: CommentActionTypes.COMMENT_FILE_CLICK_LIKE_OR_UNLIKE_FAIL;
  payload: IErrorMessage;
}

export interface CommentPostLoadingAction {
  readonly type: CommentActionTypes.COMMENT_POST_LOADING;
}

export interface CommentPostClickFocusInputAction {
  readonly type: CommentActionTypes.COMMENT_POST_CLICK_FOCUS_INPUT;
  payload: string | number;
}

export interface CommentPostClearClickFocusInputAction {
  readonly type: CommentActionTypes.COMMENT_POST_CLEAR_CLICK_FOCUS_INPUT;
}

export interface CommentPostCreateSuccessAction {
  readonly type: CommentActionTypes.COMMENT_POST_CREATE_SUCCESS;
  payload: {
    postId: string | number;
    comment: ICommment;
    message: IErrorMessage;
  };
}

export interface CommentPostCreateFailAction {
  readonly type: CommentActionTypes.COMMENT_POST_CREATE_FAIL;
  payload: IErrorMessage;
}

export interface CommentPostDeleteSuccessAction {
  readonly type: CommentActionTypes.COMMENT_POST_DELETE_SUCCESS;
  payload: { post: IPost; commentId: string | number; message: string };
}

export interface CommentPostDeleteFailAction {
  readonly type: CommentActionTypes.COMMENT_POST_DELETE_FAIL;
  payload: IErrorMessage;
}

export interface CommentPostSetReplyAction {
  readonly type: CommentActionTypes.COMMENT_POST_SET_REPLY;
  payload: { commentId: string | number };
}

export interface CommentPostClearReplyAction {
  readonly type: CommentActionTypes.COMMENT_POST_CLEAR_REPLY;
}

export interface CommentPostSetEditAction {
  readonly type: CommentActionTypes.COMMENT_POST_SET_EDIT;
  payload: {
    postId: string | number;
    parent: ICommments;
    current: ICommments;
    edit: boolean;
  };
}

export interface CommentPostClearEditAction {
  readonly type: CommentActionTypes.COMMENT_POST_CLEAR_EDIT;
}

export interface CommentPostUpdateSuccessAction {
  readonly type: CommentActionTypes.COMMENT_POST_UPDATE_SUCCESS;
  payload: { postId: string | number; comment: ICommment; message: string };
}

export interface CommentPostUpdateFailAction {
  readonly type: CommentActionTypes.COMMENT_POST_UPDATE_FAIL;
  payload: IErrorMessage;
}

export interface CommentPostClickLikeAction {
  readonly type: CommentActionTypes.COMMENT_POST_CLICK_LIKE;
  payload: {
    postId: string | number;
    commentId: string | number;
    like: ILike;
    message: string;
  };
}

export interface CommentPostClickUnlikeAction {
  readonly type: CommentActionTypes.COMMENT_POST_CLICK_UNLIKE;
  payload: {
    postId: string | number;
    commentId: string | number;
    unlike: ILike;
    message: string;
  };
}

export interface CommentPostClickLikeOrUnlikeFailAction {
  readonly type: CommentActionTypes.COMMENT_POST_CLICK_LIKE_OR_UNLIKE_FAIL;
  payload: IErrorMessage;
}

export type Action =
  | CommentFileLoadingAction
  | CommentFileLoadedSuccessAction
  | CommentFileLoadedFailAction
  | CommentFileClickFocusInputAction
  | CommentFileCreateSuccessAction
  | CommentFileCreateFailAction
  | CommentFileDeleteSuccessAction
  | CommentFileDeleteFailAction
  | CommentFileSetReplyAction
  | CommentFileClearReplyAction
  | CommentFileSetEditAction
  | CommentFileClearEditAction
  | CommentFileUpdateSuccessAction
  | CommentFileUpdateFailAction
  | CommentFileClickLikeAction
  | CommentFileClickUnlikeAction
  | CommentFileClickLikeORUnlikeFailAction
  | CommentPostLoadingAction
  | CommentPostClickFocusInputAction
  | CommentPostClearClickFocusInputAction
  | CommentPostCreateSuccessAction
  | CommentPostCreateFailAction
  | CommentPostDeleteSuccessAction
  | CommentPostDeleteFailAction
  | CommentPostSetReplyAction
  | CommentPostClearReplyAction
  | CommentPostSetEditAction
  | CommentPostClearEditAction
  | CommentPostUpdateSuccessAction
  | CommentPostUpdateFailAction
  | CommentPostClickLikeAction
  | CommentPostClickUnlikeAction
  | CommentPostClickLikeOrUnlikeFailAction;
