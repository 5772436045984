import { IErrorMessage } from '../../models/errormessage.model';
import { ITrade } from '../../models/trade.model';
import { TradeMethodologyJoin } from '../../models/trademedology.model';
import { IWallet } from '../../models/wallet.model';

export enum TradeActionTypes {
  TRADE_LOADING = 'TRADE_LOADING',
  TRADE_SHOW_INPUT = 'TRADE_SHOW_INPUT',
  TRADE_LOADED_SUCCESS = 'TRADE_LOADED_SUCCESS',
  TRADE_LOADED_FAIL = 'TRADE_LOADED_FAIL',
  TRADE_GET_SUCCESS = 'TRADE_GET_SUCCESS',
  TRADE_GET_FAIL = 'TRADE_GET_FAIL',
  TRADE_CREATE_SUCCESS = 'TRADE_CREATE_SUCCESS',
  TRADE_CREATE_FAIL = 'TRADE_CREATE_FAIL',
  TRADE_SET_EDIT = 'TRADE_SET_EDIT',
  TRADE_SET_EDIT_CLEAR = 'TRADE_SET_EDIT_CLEAR',
  TRADE_UPDATE_SUCCESS = 'TRADE_UPDATE_SUCCESS',
  TRADE_UPDATE_FAIL = 'TRADE_UPDATE_FAIL',
  TRADE_DELETE_SUCCESS = 'TRADE_DELETE_SUCCESS',
  TRADE_DELETE_FAIL = 'TRADE_DELETE_FAIL',
}

export interface TradeLoadingAction {
  readonly type: TradeActionTypes.TRADE_LOADING;
}

export interface TradeShowInputAction {
  readonly type: TradeActionTypes.TRADE_SHOW_INPUT;
  payload: boolean;
}

export interface TradeLoadedSuccessAction {
  readonly type: TradeActionTypes.TRADE_LOADED_SUCCESS;
  payload: { trades: ITrade[]; wallets: IWallet[] };
}

export interface TradeLoadedFailAction {
  readonly type: TradeActionTypes.TRADE_LOADED_FAIL;
  payload: IErrorMessage;
}

export interface TradeGetSuccessAction {
  readonly type: TradeActionTypes.TRADE_GET_SUCCESS;
  payload: { trade: ITrade; message: string };
}

export interface TradeGetFailAction {
  readonly type: TradeActionTypes.TRADE_GET_FAIL;
  payload: IErrorMessage;
}

export interface TradeCreateSuccessAction {
  readonly type: TradeActionTypes.TRADE_CREATE_SUCCESS;
  payload: { trade: ITrade; message: string };
}

export interface TradeCreateFailAction {
  readonly type: TradeActionTypes.TRADE_CREATE_FAIL;
  payload: IErrorMessage;
}

export interface TradeSetEditAction {
  readonly type: TradeActionTypes.TRADE_SET_EDIT;
  payload: TradeMethodologyJoin;
}

export interface TradeSetEditClearAction {
  readonly type: TradeActionTypes.TRADE_SET_EDIT_CLEAR;
}

export interface TradeUpdateSuccessAction {
  readonly type: TradeActionTypes.TRADE_UPDATE_SUCCESS;
  payload: { trade: ITrade; message: string };
}

export interface TradeUpdateFailAction {
  readonly type: TradeActionTypes.TRADE_UPDATE_FAIL;
  payload: IErrorMessage;
}

export interface TradeDeleteSuccessAction {
  readonly type: TradeActionTypes.TRADE_DELETE_SUCCESS;
  payload: { trade: ITrade; message: string };
}

export interface TradeDeleteFailAction {
  readonly type: TradeActionTypes.TRADE_DELETE_FAIL;
  payload: IErrorMessage;
}

export type Action =
  | TradeLoadingAction
  | TradeShowInputAction
  | TradeLoadedSuccessAction
  | TradeLoadedFailAction
  | TradeGetSuccessAction
  | TradeGetFailAction
  | TradeCreateSuccessAction
  | TradeCreateFailAction
  | TradeSetEditAction
  | TradeSetEditClearAction
  | TradeUpdateSuccessAction
  | TradeUpdateFailAction
  | TradeDeleteSuccessAction
  | TradeDeleteFailAction;
