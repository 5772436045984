import React from 'react';
import './spinner.css';

const Spinner = () => {
  return (
    <svg className="spinner-circle">
      <circle cx="20" cy="20" r="18"></circle>
    </svg>
  );
};

export default Spinner;

// https://medium.com/@clg/animated-svg-spinner-8dff32d310fc
