import React from 'react';
import { BsBarChart } from 'react-icons/bs';
import { IoImageOutline, IoVideocamOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppState } from '../../store/app-state';
import { postCreators } from '../../store/creators';
import BlogModal from '../blog-modal/BlogModal';
import './blog-create-post.css';

const BlogCreatePost = () => {
  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.auth.user);

  return (
    <>
      <BlogModal />
      <div className="create-post">
        <div className="create-post-top">
          <Link to="/users">
            <img src={user?.avartar} alt="" />
          </Link>
          <div
            className="mock-input"
            onClick={() => dispatch(postCreators.toggleModal())}
          >
            Write something...
          </div>
        </div>
        <div className="create-post-bottom">
          <div
            className="create-post-icon"
            onClick={() => {
              dispatch(postCreators.toggleModal());
              dispatch(postCreators.showVideo(true));
            }}
          >
            <IoVideocamOutline className="icon icon-video" />
            <span>Videos</span>
          </div>
          <div
            className="create-post-icon"
            onClick={() => {
              dispatch(postCreators.toggleModal());
              dispatch(postCreators.showUpload(true));
            }}
          >
            <IoImageOutline className="icon icon-photo" />
            <span>Photos</span>
          </div>
          <div
            className="create-post-icon"
            onClick={() => {
              dispatch(postCreators.toggleModal());
              dispatch(postCreators.showActivity(true));
            }}
          >
            <BsBarChart className="icon icon-poll" />
            <span>Activities</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCreatePost;
