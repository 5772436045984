import { Dispatch } from 'redux';
import { httpClient } from '../../utils/http-client';
import { fileActions, tradeActions } from '../actions';
import { ITrade, ITradeData } from '../../models/trade.model';
import { useToastInCreator } from '../../hooks';
import { TradeMethodologyJoin } from '../../models/trademedology.model';

export const tradeShowInput =
  (state: boolean) => async (dispatch: Dispatch<tradeActions.Action>) => {
    dispatch({
      type: tradeActions.TradeActionTypes.TRADE_SHOW_INPUT,
      payload: state,
    });
  };

export const loadTrades =
  () => async (dispatch: Dispatch<tradeActions.Action>) => {
    dispatch({ type: tradeActions.TradeActionTypes.TRADE_LOADING });

    try {
      const resT = await httpClient.get(`/api/trades`);

      const resW = await httpClient.get(`/api/wallets`);

      dispatch({
        type: tradeActions.TradeActionTypes.TRADE_LOADED_SUCCESS,
        payload: {
          trades: resT.data.trades,
          wallets: resW.data.wallets,
        },
      });
    } catch (err: any) {
      dispatch({
        type: tradeActions.TradeActionTypes.TRADE_LOADED_FAIL,
        payload: err.response.data,
      });
    }
  };

export const tradeCreate =
  (data: ITradeData) =>
  async (dispatch: Dispatch<tradeActions.Action | fileActions.Action>) => {
    dispatch({ type: tradeActions.TradeActionTypes.TRADE_LOADING });
    const formData = new FormData();

    const toast = useToastInCreator(dispatch);

    formData.append('file', data.file as any);

    formData.append('data', JSON.stringify(data));

    try {
      const res = await httpClient.post(`/api/trades`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({
        type: tradeActions.TradeActionTypes.TRADE_CREATE_SUCCESS,
        payload: {
          trade: res.data.trade,
          message: res.data.message,
        },
      });
      dispatch({
        type: fileActions.FileActionTypes.FILE_ADSIGN_TRADE,
        payload: res.data.trade,
      });
      toast.success(res.data.message);
    } catch (err: any) {
      console.log(err);
      dispatch({
        type: tradeActions.TradeActionTypes.TRADE_CREATE_FAIL,
        payload: err.response.data,
      });
      toast.error(err.response.data.message);
    }
  };

export const tradeImageCreate =
  (data: ITradeData) =>
  async (dispatch: Dispatch<tradeActions.Action | fileActions.Action>) => {
    dispatch({ type: tradeActions.TradeActionTypes.TRADE_LOADING });
    const formData = new FormData();

    const toast = useToastInCreator(dispatch);

    formData.append('file', data.file as any);

    formData.append('data', JSON.stringify(data));

    try {
      const res = await httpClient.post(`/api/trades/images`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({
        type: tradeActions.TradeActionTypes.TRADE_CREATE_SUCCESS,
        payload: {
          trade: res.data.trade,
          message: res.data.message,
        },
      });
      dispatch({
        type: fileActions.FileActionTypes.FILE_ADSIGN_TRADE,
        payload: res.data.trade,
      });
      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: tradeActions.TradeActionTypes.TRADE_CREATE_FAIL,
        payload: err.response.data,
      });
      toast.error(err.response.data.message);
    }
  };

export const tradeSetEdit =
  (trade: TradeMethodologyJoin) =>
  async (dispatch: Dispatch<tradeActions.Action>) => {
    dispatch({
      type: tradeActions.TradeActionTypes.TRADE_SET_EDIT,
      payload: trade,
    });
  };

export const tradeSetEditClear =
  () => async (dispatch: Dispatch<tradeActions.Action>) => {
    dispatch({
      type: tradeActions.TradeActionTypes.TRADE_SET_EDIT_CLEAR,
    });
  };

export const tradeUpdate =
  (trade: ITrade, data: ITradeData) =>
  async (dispatch: Dispatch<tradeActions.Action | fileActions.Action>) => {
    dispatch({ type: tradeActions.TradeActionTypes.TRADE_LOADING });

    const formData = new FormData();

    const toast = useToastInCreator(dispatch);

    formData.append('file', data.file as any);

    formData.append('data', JSON.stringify(data));

    try {
      const res = await httpClient.put(`/api/trades/${trade._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch({
        type: tradeActions.TradeActionTypes.TRADE_UPDATE_SUCCESS,
        payload: {
          trade: res.data.trade,
          message: res.data.message,
        },
      });
      dispatch({
        type: fileActions.FileActionTypes.FILE_ADSIGN_TRADE,
        payload: res.data.trade,
      });
      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: tradeActions.TradeActionTypes.TRADE_UPDATE_FAIL,
        payload: err.response.data,
      });
      toast.error(err.response.data.message);
    }
  };

export const tradeImageUpdate =
  (trade: ITrade, data: ITradeData) =>
  async (dispatch: Dispatch<tradeActions.Action | fileActions.Action>) => {
    dispatch({ type: tradeActions.TradeActionTypes.TRADE_LOADING });

    const formData = new FormData();

    const toast = useToastInCreator(dispatch);

    formData.append('file', data.file as any);

    formData.append('data', JSON.stringify(data));

    try {
      const res = await httpClient.put(
        `/api/trades/images/${trade._id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      dispatch({
        type: tradeActions.TradeActionTypes.TRADE_UPDATE_SUCCESS,
        payload: {
          trade: res.data.trade,
          message: res.data.message,
        },
      });
      dispatch({
        type: fileActions.FileActionTypes.FILE_ADSIGN_TRADE,
        payload: res.data.trade,
      });
      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: tradeActions.TradeActionTypes.TRADE_UPDATE_FAIL,
        payload: err.response.data,
      });
      toast.error(err.response.data.message);
    }
  };

export const tradeDelete =
  (data: ITrade) => async (dispatch: Dispatch<tradeActions.Action>) => {
    dispatch({ type: tradeActions.TradeActionTypes.TRADE_LOADING });

    try {
      const res = await httpClient.delete(`/api/trades/${data._id}`);

      dispatch({
        type: tradeActions.TradeActionTypes.TRADE_DELETE_SUCCESS,
        payload: {
          trade: data,
          message: res.data.message,
        },
      });
    } catch (err: any) {
      dispatch({
        type: tradeActions.TradeActionTypes.TRADE_DELETE_FAIL,
        payload: err.response.data,
      });
    }
  };
