import React, { FC } from 'react';
import {
  BsBell,
  BsChevronDown,
  BsFullscreen,
  BsFullscreenExit,
  BsGrid,
  BsSearch,
} from 'react-icons/bs';
import { FullScreenHandle } from 'react-full-screen';
import { useNavigate } from 'react-router-dom';
import './trade-navbar.scss';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/app-state';

type TradeNavbarProps = {
  fullscreen: FullScreenHandle;
};

const TradeNavbar: FC<TradeNavbarProps> = ({ fullscreen }) => {
  const navagate = useNavigate();

  const user = useSelector((state: AppState) => state.auth.user);

  return (
    <div className="trade-navbar">
      <h3>Dashboard</h3>
      <div className="icons">
        <div className="icon-wrapper">
          <BsSearch className="icon" />
        </div>
        <div className="icon-wrapper" onClick={() => navagate('/trade')}>
          <BsGrid className="icon" />
        </div>
        {fullscreen.active ? (
          <div className="icon-wrapper" onClick={() => fullscreen.exit()}>
            <BsFullscreenExit className="icon" />
          </div>
        ) : (
          <div className="icon-wrapper" onClick={() => fullscreen.enter()}>
            <BsFullscreen className="icon" />
          </div>
        )}
        <div className="icon-wrapper">
          <div className="notification">
            <BsBell className="icon" />
            <span>1</span>
          </div>
        </div>
        <div className="user">
          <img src={user?.avartar} alt="" />
          <span>{user?.username}</span>
          <BsChevronDown className="icon" />
        </div>
      </div>
    </div>
  );
};

export default TradeNavbar;
