import { Dispatch } from 'redux';
import { useToastInCreator } from '../../hooks';
import { httpClient } from '../../utils/http-client';
import { passwordActions } from '../actions';

export const forgotPassword =
  (data: { email: string }) =>
  async (dispatch: Dispatch<passwordActions.Action>) => {
    dispatch({ type: passwordActions.PasswordActionTypes.PASSWORD_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.post('/api/passwords/forgot', data);

      dispatch({
        type: passwordActions.PasswordActionTypes.PASSWORD_FORGOT_SUCCESS,
        payload: {
          message: res.data.message,
        },
      });
      toast.info(res.data.message, 5);
    } catch (err: any) {
      dispatch({
        type: passwordActions.PasswordActionTypes.PASSWORD_FORGOT_FAIL,
        payload: { message: err.response.data.message },
      });
      toast.error(err.response.data.message, 5);
    }
  };

export const resetPassword =
  (data: {
    passwordResetToken: string;
    password: string;
    confirmPassword: string;
  }) =>
  async (dispatch: Dispatch<passwordActions.Action>) => {
    dispatch({ type: passwordActions.PasswordActionTypes.PASSWORD_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.post('/api/passwords/reset', data);

      dispatch({
        type: passwordActions.PasswordActionTypes.PASSWORD_RESET_SUCCESS,
        payload: {
          message: res.data.message,
        },
      });
      toast.success(res.data.message, 5);
    } catch (err: any) {
      dispatch({
        type: passwordActions.PasswordActionTypes.PASSWORD_RESET_FAIL,
        payload: { message: err.response.data.message },
      });
      toast.error(err.response.data.message, 5);
    }
  };
