import { modalActions } from '../actions';

export interface ModalState {
  modalImageOpen: boolean;
}

const initialState: ModalState = {
  modalImageOpen: false,
};

export const modalReducer = (
  state: ModalState = initialState,
  action: modalActions.Action
): ModalState => {
  switch (action.type) {
    case modalActions.ModalActionTypes.MODAL_IMAGE_OPEN:
      return {
        ...state,
        modalImageOpen: action.payload,
      };
    case modalActions.ModalActionTypes.MODAL_IMAGE_CLOSE:
      return {
        ...state,
        modalImageOpen: false,
      };

    default:
      return state;
  }
};
