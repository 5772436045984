import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';
import './dropzon-image-preview.css';

type DropzonImagePreviewProps = {
  file: (image: File) => void;
  defaultPreview?: File | string | null;
  dropClassName?: string;
  iconWrapperClassName?: string;
  iconClassName?: string;
  placeholderClassName?: string;
  placeholder?: string;
  imagePreviewClassName?: string;
};

const DropzonImagePreview = ({
  file,
  defaultPreview,
  dropClassName = 'image-drop-container',
  iconWrapperClassName = 'image-upload-icon',
  iconClassName = 'icon',
  placeholderClassName = 'image-drop-message-text',
  placeholder = 'Drag & Drop files here or click to upload',
  imagePreviewClassName = 'image-preview',
}: DropzonImagePreviewProps) => {
  const [image, setImage] = useState<File | null>();
  const [preview, setPreview] = useState<string | null>();
  const fileInputRef = useRef() as MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(image);
      file(image);
    } else {
      setPreview(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  useEffect(() => {
    if (defaultPreview && defaultPreview instanceof File) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(defaultPreview);
    } else if (defaultPreview) {
      setPreview(defaultPreview);
    }
    // eslint-disable-nxt-line react-hooks/exhaustive-deps
  }, [defaultPreview]);

  const buttonHandle = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const imageHandle = () => {
    setImage(null);
  };

  const inputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file: false | File | null = files && files!.length > 0 && files[0];

    if (file && file.type.substring(0, 5) === 'image') {
      setImage(file);
    } else {
      setImage(null);
    }
  };

  const dragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const dragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const dragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const fileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const file: false | File | null = files && files!.length > 0 && files[0];

    if (file && file.type.substring(0, 5) === 'image') {
      setImage(file);
    } else {
      setImage(null);
    }
  };

  return (
    <>
      <div
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        className={dropClassName}
      >
        {preview ? (
          <img
            src={preview}
            alt=""
            onClick={imageHandle}
            className={imagePreviewClassName}
          />
        ) : (
          <>
            <div className="image-drop-message" onClick={buttonHandle}>
              <div className={iconWrapperClassName}>
                <FaCloudUploadAlt className={iconClassName} />
              </div>
              <span className={placeholderClassName}>{placeholder}</span>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              accept="image/*"
              className="image-file-input"
              onChange={inputOnChange}
            />
          </>
        )}
      </div>
    </>
  );
};

export default DropzonImagePreview;
