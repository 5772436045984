import { IErrorMessage } from '../../models/errormessage.model';
import { IOtp } from '../../models/otp.model';
import { IUser } from '../../models/user.model';

export enum OtpActionTypes {
  OTO_LOADING = 'OTO_LOADING',
  OTO_ENABLE_SUCCESS = 'OTO_ENABLE_SUCCESS',
  OTO_ENABLE_FAIL = 'OTO_ENABLE_FAIL',
  OTO_VERIFY_SUCCESS = 'OTO_VERIFY_SUCCESS',
  OTO_VERIFY_FAIL = 'OTO_VERIFY_FAIL',
  OTO_DISABLE_SUCCESS = 'OTO_DISABLE_SUCCESS',
  OTO_DISABLE_FAIL = 'OTO_DISABLE_FAIL',
}

export interface OtpLoadingAction {
  readonly type: OtpActionTypes.OTO_LOADING;
}

export interface OtpEnableSuccessAction {
  readonly type: OtpActionTypes.OTO_ENABLE_SUCCESS;
  payload: { otp: IOtp; qrcode: string; message: string };
}

export interface OtpEnableFailAction {
  readonly type: OtpActionTypes.OTO_ENABLE_FAIL;
  payload: IErrorMessage;
}

export interface OtpVerifySuccessAction {
  readonly type: OtpActionTypes.OTO_VERIFY_SUCCESS;
  payload: { user: IUser; message: string };
}

export interface OtpVerifyFailAction {
  readonly type: OtpActionTypes.OTO_VERIFY_FAIL;
  payload: IErrorMessage;
}

export interface OtpDisableSuccessAction {
  readonly type: OtpActionTypes.OTO_DISABLE_SUCCESS;
  payload: { user: IUser; message: string };
}

export interface OtpDisableFailAction {
  readonly type: OtpActionTypes.OTO_DISABLE_FAIL;
  payload: IErrorMessage;
}

export type Action =
  | OtpLoadingAction
  | OtpEnableSuccessAction
  | OtpEnableFailAction
  | OtpVerifySuccessAction
  | OtpVerifyFailAction
  | OtpDisableSuccessAction
  | OtpDisableFailAction;
