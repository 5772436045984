import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FaEnvelope, FaLock } from 'react-icons/fa';
import SignWithSocials from '../sign-with-socials/SignWithSocials';
import { Link } from 'react-router-dom';

const initialValues = {
  email: '',
  password: '',
};

const SigninFormSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

type Props = {
  formData: (data: { email: string; password: string }) => void;
};

const SigninForm = ({ formData }: Props) => {
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={SigninFormSchema}
        onSubmit={(fields, { resetForm }) => {
          resetForm();
          formData(fields);
        }}
      >
        {({ errors, status, touched, isValid, dirty }) => (
          <Form className="sign-in-form">
            <h2 className="title">Sign in</h2>
            <div className="input-field">
              <label htmlFor="signin-email" className="icon-wrapper">
                <FaEnvelope />
              </label>
              <Field
                name="email"
                id="signin-email"
                placeholder="Email"
                type="email"
                className={
                  errors.email && touched.email
                    ? 'is-invalid'
                    : touched.email
                    ? 'is-valid'
                    : ''
                }
              />
              <ErrorMessage
                name="email"
                component="span"
                className="invalid-feedback"
              />
            </div>
            <div className="input-field" style={{ marginBottom: '20px' }}>
              <label htmlFor="signin-password" className="icon-wrapper">
                <FaLock />
              </label>
              <Field
                name="password"
                id="signin-password"
                placeholder="Password"
                type="password"
                className={
                  errors.password && touched.password
                    ? 'is-invalid'
                    : touched.password
                    ? 'is-valid'
                    : ''
                }
              />
              <ErrorMessage
                name="password"
                component="span"
                className="invalid-feedback"
              />
              {!errors.password && (
                <Link to="/forgot-password" className="forgot-password-action">
                  Forgot your password?
                </Link>
              )}
            </div>
            <input
              type="submit"
              className="btn solid"
              defaultValue="Login"
              disabled={!(isValid && dirty)}
            />
            <p className="social-text">Or Sign in with social platforms</p>
            <SignWithSocials />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SigninForm;
