import { IErrorMessage } from '../../models/errormessage.model';
import { ISession } from '../../models/session.model';

export enum SessionActionTypes {
  SESSION_LOADING = 'SESSION_LOADING',
  SESSION_LOAD_SESSIONS_SUCCESS = 'SESSION_LOAD_SESSIONS_SUCCESS',
  SESSION_LOAD_SESSIONS_FAIL = 'SESSION_LOAD_SESSIONS_FAIL',
  SESSION_LOAD_CURRENT_SESSION_SUCCESS = 'SESSION_LOAD_CURRENT_SESSION_SUCCESS',
  SESSION_LOAD_CURRENT_SESSION_FAIL = 'SESSION_LOAD_CURRENT_SESSION_FAIL',
  SESSION_TERMINATE_SESSIONS_SUCCESS = 'SESSION_TERMINATE_SESSIONS_SUCCESS',
  SESSION_TERMINATE_SESSIONS_FAIL = 'SESSION_TERMINATE_SESSIONS_FAIL',
}

export interface SessionLoadingAction {
  readonly type: SessionActionTypes.SESSION_LOADING;
}

export interface SessionLoadSessionsSuccessAction {
  readonly type: SessionActionTypes.SESSION_LOAD_SESSIONS_SUCCESS;
  payload: ISession[];
}

export interface SessionLoadSessionsFailAction {
  readonly type: SessionActionTypes.SESSION_LOAD_SESSIONS_FAIL;
  payload: IErrorMessage;
}

export interface SessionLoadCurrentSessionSuccessAction {
  readonly type: SessionActionTypes.SESSION_LOAD_CURRENT_SESSION_SUCCESS;
  payload: ISession;
}

export interface SessionLoadCurrentSessionFailAction {
  readonly type: SessionActionTypes.SESSION_LOAD_CURRENT_SESSION_FAIL;
  payload: IErrorMessage;
}

export interface SessionTerminateSessionsSuccessAction {
  readonly type: SessionActionTypes.SESSION_TERMINATE_SESSIONS_SUCCESS;
  payload: IErrorMessage;
}

export interface SessionTerminateSessionsFailAction {
  readonly type: SessionActionTypes.SESSION_TERMINATE_SESSIONS_FAIL;
  payload: IErrorMessage;
}

export type Action =
  | SessionLoadingAction
  | SessionLoadSessionsSuccessAction
  | SessionLoadSessionsFailAction
  | SessionLoadCurrentSessionSuccessAction
  | SessionLoadCurrentSessionFailAction
  | SessionTerminateSessionsSuccessAction
  | SessionTerminateSessionsFailAction;
