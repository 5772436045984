import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FcLock } from 'react-icons/fc';
import { FaKey } from 'react-icons/fa';
import './recovery-code.css';

const initialValues = {
  recoveryCode: '',
};

const RecoveryCodeFormSchema = Yup.object().shape({
  recoveryCode: Yup.string().required('Recovery code is required'),
});

type RecoveryCodeProps = {
  showRecoveryCode: boolean;
  setShowRecoveryCode: React.Dispatch<React.SetStateAction<boolean>>;
  formData: (data: { recoveryCode: string }) => void;
};

const RecoveryCode = ({
  showRecoveryCode,
  setShowRecoveryCode,
  formData,
}: RecoveryCodeProps) => {
  return (
    <>
      {showRecoveryCode && (
        <Formik
          initialValues={initialValues}
          validationSchema={RecoveryCodeFormSchema}
          onSubmit={(fields, { resetForm }) => {
            resetForm();
            formData(fields);
          }}
        >
          {({ errors, status, touched, isValid, dirty }) => (
            <Form className="sign-in-form">
              <div className="otp-input-icon">
                <FcLock />
              </div>
              <h2 className="title">Use a Recovery Code</h2>
              <h4 className="description" style={{ paddingBottom: '10px' }}>
                Enter one of your recovery codes to verify your indentify.
              </h4>
              <div className="input-field">
                <label htmlFor="recoveryCode" className="icon-wrapper">
                  <FaKey />
                </label>
                <Field
                  name="recoveryCode"
                  id="recoveryCode"
                  placeholder="Recovery Code"
                  type="text"
                  className={
                    errors.recoveryCode && touched.recoveryCode
                      ? 'is-invalid'
                      : touched.recoveryCode
                      ? 'is-valid'
                      : ''
                  }
                />
                <ErrorMessage
                  name="recoveryCode"
                  component="span"
                  className="invalid-feedback"
                />
              </div>
              <div className="btns">
                <button
                  type="submit"
                  className="btn-recovery-submit"
                  disabled={!(isValid && dirty)}
                >
                  Verify
                </button>
                <button
                  type="button"
                  className="btn-recovery-cancel"
                  onClick={() => {
                    setShowRecoveryCode(!showRecoveryCode);
                  }}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default RecoveryCode;
