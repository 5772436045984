import { IFile } from './file.model';
import { ITrade } from './trade.model';

export enum TradeMethodologyGroup {
  methodology = 'methodology',
  normal = 'normal',
}

export enum TradeMethodologyType {
  instrument = 'instrument',
  setup = 'setup',
  direction = 'direction',
  entryemotion = 'entryemotion',
  entrycomment = 'entrycomment',
  exitemotion = 'exitemotion',
  exitcomment = 'exitcomment',
  trademanagement = 'trademanagement',
}

export interface ITradeMethodology {
  _id: string | number;
  name: string;
  slug: string;
  userId: string;
  fileId: string | null;
  tradeId: string | null;
  score: number;
  group: TradeMethodologyGroup;
  type: TradeMethodologyType;
  description: string;
  order: number;
  createdAt: string;
  updatedAt: string;
}

export interface TradeMethodologyJoin {
  tradeId: string;
  fileId: string | null;
  instrumentId: string;
  entryEmotionId: string;
  entryCommentId: string;
  setupId: string;
  directionId: string;
  exitEmotionId: string | null;
  exitCommentId: string | null;
  tradeManagementId: string | null;
  createdAt: string;
  updatedAt: string;
  trade: ITrade;
  file: IFile | null;
  instrument: ITradeMethodology;
  entryEmotion: ITradeMethodology;
  entryComment: ITradeMethodology;
  setup: ITradeMethodology;
  direction: ITradeMethodology;
  exitEmotion: ITradeMethodology | null;
  exitComment: ITradeMethodology | null;
  tradeManagement: ITradeMethodology | null;
}
