export enum ScrollbarActionTypes {
  SCROLLBAR_TRACKING = 'SCROLLBAR_TRACKING',
  SCROLLBAR_TRACKING_CUSTOM = 'SCROLLBAR_TRACKING_CUSTOM',
  SCROLLBAR_SET_POSITION = 'SCROLLBAR_SET_POSITION',
  SCROLLBAR_SET_PREVIOUS_POSITION = 'SCROLLBAR_SET_PREVIOUS_POSITION',
  SCROLLBAR_SET_PAGE = 'SCROLLBAR_SET_PAGE',
}

export interface ScrollbarTrackingCustomAction {
  readonly type: ScrollbarActionTypes.SCROLLBAR_TRACKING_CUSTOM;
  payload: { positionX: number; positionY: number };
}

export interface ScrollbarTrackingAction {
  readonly type: ScrollbarActionTypes.SCROLLBAR_TRACKING;
  payload: { positionX: number; positionY: number };
}

export interface ScrollbarSetPosionAction {
  readonly type: ScrollbarActionTypes.SCROLLBAR_SET_POSITION;
  payload: { positionX: number; positionY: number };
}

export interface ScrollbarSetPreViousPosionAction {
  readonly type: ScrollbarActionTypes.SCROLLBAR_SET_PREVIOUS_POSITION;
  payload: { positionX: number; positionY: number };
}

export interface ScrollbarSetPageAction {
  readonly type: ScrollbarActionTypes.SCROLLBAR_SET_PAGE;
  payload: { type: 'home' | 'blog' | 'images' | 're-order'; page: number };
}

export type Action =
  | ScrollbarTrackingAction
  | ScrollbarTrackingCustomAction
  | ScrollbarSetPosionAction
  | ScrollbarSetPreViousPosionAction
  | ScrollbarSetPageAction;
