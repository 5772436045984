import { Dispatch } from 'redux';
import { useToastInCreator } from '../../hooks';
import { httpClient } from '../../utils/http-client';
import { otpActions } from '../actions';

export const enableOtp =
  () => async (dispatch: Dispatch<otpActions.Action>) => {
    dispatch({ type: otpActions.OtpActionTypes.OTO_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.get('/api/otps/enable');

      dispatch({
        type: otpActions.OtpActionTypes.OTO_ENABLE_SUCCESS,
        payload: {
          otp: res.data.otp,
          qrcode: res.data.qrcode,
          message: res.data.message,
        },
      });
      toast.info(res.data.message);
    } catch (err: any) {
      dispatch({
        type: otpActions.OtpActionTypes.OTO_ENABLE_FAIL,
        payload: { message: err.response.data.message },
      });
      toast.error(err.response.data.message);
    }
  };

export const verifyOtp =
  (token: string) => async (dispatch: Dispatch<otpActions.Action>) => {
    dispatch({ type: otpActions.OtpActionTypes.OTO_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.post('/api/otps/verify', { token });

      dispatch({
        type: otpActions.OtpActionTypes.OTO_VERIFY_SUCCESS,
        payload: {
          user: res.data.user,
          message: res.data.message,
        },
      });
      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: otpActions.OtpActionTypes.OTO_VERIFY_FAIL,
        payload: { message: err.response.data.message },
      });
      toast.error(err.response.data.message);
    }
  };

export const disableOtp =
  () => async (dispatch: Dispatch<otpActions.Action>) => {
    dispatch({ type: otpActions.OtpActionTypes.OTO_LOADING });

    const toast = useToastInCreator(dispatch);

    try {
      const res = await httpClient.get('/api/otps/disable');

      dispatch({
        type: otpActions.OtpActionTypes.OTO_DISABLE_SUCCESS,
        payload: {
          user: res.data.user,
          message: res.data.message,
        },
      });
      toast.success(res.data.message);
    } catch (err: any) {
      dispatch({
        type: otpActions.OtpActionTypes.OTO_DISABLE_FAIL,
        payload: { message: err.response.data.message },
      });
      toast.error(err.response.data.message);
    }
  };
