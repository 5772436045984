import { Dispatch } from 'redux';
import { scrollbarActions } from '../actions';

export const trackingScollbar =
  (positionX: number, positionY: number) =>
  async (dispatch: Dispatch<scrollbarActions.Action>) => {
    dispatch({
      type: scrollbarActions.ScrollbarActionTypes.SCROLLBAR_TRACKING,
      payload: { positionX, positionY },
    });
  };

export const trackingScollbarCustom =
  (positionX: number, positionY: number) =>
  async (dispatch: Dispatch<scrollbarActions.Action>) => {
    dispatch({
      type: scrollbarActions.ScrollbarActionTypes.SCROLLBAR_TRACKING_CUSTOM,
      payload: { positionX, positionY },
    });
  };

export const setPositionScollbar =
  (positionX: number, positionY: number) =>
  async (dispatch: Dispatch<scrollbarActions.Action>) => {
    dispatch({
      type: scrollbarActions.ScrollbarActionTypes.SCROLLBAR_SET_POSITION,
      payload: { positionX, positionY },
    });
  };

export const setPreviousPositionScollbar =
  (positionX: number, positionY: number) =>
  async (dispatch: Dispatch<scrollbarActions.Action>) => {
    dispatch({
      type: scrollbarActions.ScrollbarActionTypes
        .SCROLLBAR_SET_PREVIOUS_POSITION,
      payload: { positionX, positionY },
    });
  };

export const setPage =
  (type: 'home' | 'blog' | 'images' | 're-order', page: number) =>
  async (dispatch: Dispatch<scrollbarActions.Action>) => {
    dispatch({
      type: scrollbarActions.ScrollbarActionTypes.SCROLLBAR_SET_PAGE,
      payload: { type, page },
    });
  };
