import { IUser } from '../../models/user.model';
import { userActions } from '../actions';

export interface UserState {
  isLoading: boolean;
  users: IUser[] | [];
  edit: boolean;
  current: IUser | null;
  message: string | null;
}

const initialState: UserState = {
  isLoading: false,
  users: [],
  edit: false,
  current: null,
  message: null,
};

export const userReducer = (
  state: UserState = initialState,
  action: userActions.Action
): UserState => {
  switch (action.type) {
    case userActions.UserActionTypes.USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case userActions.UserActionTypes.USER_LOADED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.payload,
      };

    case userActions.UserActionTypes.USER_SET_EDIT:
      return {
        ...state,
        edit: action.payload.edit,
        current: action.payload.current,
      };

    case userActions.UserActionTypes.USER_CLEAR_EDIT:
      return {
        ...state,
        edit: false,
        current: null,
      };

    case userActions.UserActionTypes.USER_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: state.users.map((user) => {
          if (user._id === action.payload.user._id) {
            user = action.payload.user;
          }
          return user;
        }),
        edit: false,
        current: null,
      };

    case userActions.UserActionTypes.USER_LOADED_FAIL:
    case userActions.UserActionTypes.USER_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
