import { IErrorMessage } from '../../models/errormessage.model';
import { IWallet } from '../../models/wallet.model';

export enum WalletActionTypes {
  WALLET_LOADING = 'WALLET_LOADING',
  WALLET_LOADED_SUCCESS = 'WALLET_LOADED_SUCCESS',
  WALLET_LOADED_FAIL = 'WALLET_LOADED_FAIL',
  WALLET_CREATE_SUCCESS = 'WALLET_CREATE_SUCCESS',
  WALLET_CREATE_FAIL = 'WALLET_CREATE_FAIL',
  WALLET_SET_EDIT = 'WALLET_SET_EDIT',
  WALLET_SET_EDIT_CLEAR = 'WALLET_SET_EDIT_CLEAR',
  WALLET_UPDATE_SUCCESS = 'WALLET_UPDATE_SUCCESS',
  WALLET_UPDATE_FAIL = 'WALLET_UPDATE_FAIL',
  WALLET_DELETE_SUCCESS = 'WALLET_DELETE_SUCCESS',
  WALLET_DELETE_FAIL = 'WALLET_DELETE_FAIL',
}

export interface WalletActionLoadingAction {
  readonly type: WalletActionTypes.WALLET_LOADING;
}

export interface WalletLoadedSuccessAction {
  readonly type: WalletActionTypes.WALLET_LOADED_SUCCESS;
  payload: IWallet[];
}

export interface WalletLoadedFailAction {
  readonly type: WalletActionTypes.WALLET_LOADED_FAIL;
  payload: IErrorMessage;
}

export interface WalletCreateSuccessAction {
  readonly type: WalletActionTypes.WALLET_CREATE_SUCCESS;
  payload: { wallet: IWallet; message: string };
}

export interface WalletCreateFailAction {
  readonly type: WalletActionTypes.WALLET_CREATE_FAIL;
  payload: IErrorMessage;
}

export interface WalletSetEditAction {
  readonly type: WalletActionTypes.WALLET_SET_EDIT;
  payload: IWallet;
}

export interface WalletSetEditClearAction {
  readonly type: WalletActionTypes.WALLET_SET_EDIT_CLEAR;
}

export interface WalletUpdateSuccessAction {
  readonly type: WalletActionTypes.WALLET_UPDATE_SUCCESS;
  payload: { wallet: IWallet; message: string };
}

export interface WalletUpdateFailAction {
  readonly type: WalletActionTypes.WALLET_UPDATE_FAIL;
  payload: IErrorMessage;
}

export interface WalletDeleteSuccessAction {
  readonly type: WalletActionTypes.WALLET_DELETE_SUCCESS;
  payload: { wallet: IWallet; message: string };
}

export interface WalletDeleteFailAction {
  readonly type: WalletActionTypes.WALLET_DELETE_FAIL;
  payload: IErrorMessage;
}

export type Action =
  | WalletActionLoadingAction
  | WalletLoadedSuccessAction
  | WalletLoadedFailAction
  | WalletCreateSuccessAction
  | WalletCreateFailAction
  | WalletSetEditAction
  | WalletSetEditClearAction
  | WalletUpdateSuccessAction
  | WalletUpdateFailAction
  | WalletDeleteSuccessAction
  | WalletDeleteFailAction;
