import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { config } from '../../config';
import { IFile } from '../../models/file.model';
import { IPost } from '../../models/post.model';
import { AppState } from '../../store/app-state';
import { fileCreators } from '../../store/creators';
// import CustomModal from '../modal/CustomModal';
import './image-grid.css';

type ImageGridProps = {
  images: IFile[];
  post: IPost;
  hideOverlay?: boolean;
  renderOverlay?: () => string;
  overlayBackgroundColor?: string;
  onClickEach?: { (image: IFile, index: number): void } | null;
  countFrom?: number;
};

const ImageGrid = ({
  images = [],
  post,
  hideOverlay = false,
  renderOverlay = () => 'Preview Image',
  overlayBackgroundColor = 'rgba(0, 0, 0, 0.333)',
  onClickEach = null,
  countFrom = 5,
}: ImageGridProps) => {
  // const [modal, setModal] = useState(false);
  // const [imageIndex, setImageIndex] = useState(0);
  const [isCountFrom] = useState(
    countFrom > 0 && countFrom < 5 ? countFrom : 5
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const token = useSelector((state: AppState) => state.auth.accessToken);

  const imageSrc = (image: string) =>
    `${config.serverUrl}/api/files/${image}?token=${token}`;

  let [conditionalRender] = useState(false);

  // const openModal = (index: number) => {
  //   if (onClickEach) {
  //     return onClickEach(images[index], index);
  //   }

  //   setModal(true);
  //   setImageIndex(index);
  // };

  const navigateTo = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    event.preventDefault();

    if (onClickEach) {
      return onClickEach(images[index], index);
    }

    dispatch(fileCreators.loadFilesImagesFromPost(post._id));

    // https://stackoverflow.com/a/73206229
    navigate(
      {
        pathname: `/images/${images[index]._id}`,
        search: `?${createSearchParams({
          post: post._id.toString(),
        })}`,
      },
      { replace: true }
    );
  };

  const renderCountOverlay = (more: boolean) => {
    const extra =
      images.length - (isCountFrom && isCountFrom > 5 ? 5 : isCountFrom);

    return [
      more && <div key="count" className="image-grid__cover"></div>,
      more && (
        <div key="count-sub" className="image-grid__cover-text">
          <p>+{extra}</p>
        </div>
      ),
    ];
  };

  const renderOne = () => {
    const overlay =
      images.length > isCountFrom && isCountFrom === 1
        ? renderCountOverlay(true)
        : renderOverlayId();

    return (
      <div className="image-grid">
        <div className="image-grid__row">
          <div className="image-grid__col-12">
            <div
              className={`image-grid__img-container`}
              // onClick={() => openModal(0)}
              onClick={(e) => navigateTo(e, 0)}
              style={
                [1].includes(imagesToShow.length)
                  ? { paddingTop: '56.25%' } // 9:16
                  : [3].includes(imagesToShow.length)
                  ? { paddingTop: '56.25%' } // 9:16
                  : [4].includes(imagesToShow.length)
                  ? { paddingTop: '56.25%' } // 9:16
                  : {}
              }
            >
              <img
                src={imageSrc(images[0]._id)}
                alt=""
                style={imagesToShow.length === 1 ? { height: 'auto' } : {}}
                className="image-grid__img-background"
              />
              {overlay}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTwo = () => {
    const overlay =
      images.length > isCountFrom && [2, 3].includes(+isCountFrom)
        ? renderCountOverlay(true)
        : renderOverlayId();
    conditionalRender =
      [3, 4].includes(images.length) ||
      (images.length > +isCountFrom && [3, 4].includes(+isCountFrom));

    return (
      <div className="image-grid">
        <div className="image-grid__row">
          <div className="image-grid__col-6">
            <div
              className="image-grid__img-container"
              // onClick={() => openModal(conditionalRender ? 1 : 0)}
              onClick={(e) => navigateTo(e, conditionalRender ? 1 : 0)}
              style={
                [2].includes(imagesToShow.length)
                  ? { paddingTop: '170%' }
                  : [3].includes(imagesToShow.length)
                  ? { paddingTop: '65%' }
                  : {}
              }
            >
              <img
                src={imageSrc(
                  conditionalRender ? images[1]._id : images[0]._id
                )}
                alt=""
                className="image-grid__img-background"
              />
              {renderOverlayId()}
            </div>
          </div>
          <div className="image-grid__col-6">
            <div
              className="image-grid__img-container"
              // onClick={() => openModal(conditionalRender ? 2 : 1)}
              onClick={(e) => navigateTo(e, conditionalRender ? 2 : 1)}
              style={
                [2].includes(imagesToShow.length)
                  ? { paddingTop: '170%' }
                  : [3].includes(imagesToShow.length)
                  ? { paddingTop: '65%' }
                  : {}
              }
            >
              <img
                src={imageSrc(
                  conditionalRender ? images[2]._id : images[1]._id
                )}
                alt=""
                className="image-grid__img-background"
              />
              {overlay}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderOverlayId = (id = 0) => {
    if (hideOverlay) {
      return false;
    }

    return [
      <div
        key={`cover-${id}`}
        className="image-grid__cover image-grid__slide"
        style={{ backgroundColor: overlayBackgroundColor }}
      ></div>,
      <div
        key={`cover-text-${id}`}
        className="image-grid__cover-text image-grid__slide image-grid__animate-text"
      >
        {renderOverlay()}
      </div>,
    ];
  };

  const renderThree = () => {
    const overlay =
      !isCountFrom ||
      isCountFrom > 5 ||
      (images.length > isCountFrom && [4, 5].includes(+isCountFrom))
        ? renderCountOverlay(true)
        : renderOverlayId(conditionalRender ? 3 : 4);
    conditionalRender =
      images.length === 4 ||
      (images.length > +isCountFrom && +isCountFrom === 4);

    return (
      <div className="image-grid">
        <div className="image-grid__row">
          <div className="image-grid__col-4">
            <div
              className="image-grid__img-container"
              // onClick={() => openModal(conditionalRender ? 1 : 2)}
              onClick={(e) => navigateTo(e, conditionalRender ? 1 : 2)}
            >
              <img
                src={imageSrc(
                  conditionalRender ? images[1]._id : images[2]._id
                )}
                alt=""
                className="image-grid__img-background"
              />
              {renderOverlayId(conditionalRender ? 1 : 2)}
            </div>
          </div>
          <div className="image-grid__col-4">
            <div
              className="image-grid__img-container"
              // onClick={() => openModal(conditionalRender ? 2 : 3)}
              onClick={(e) => navigateTo(e, conditionalRender ? 2 : 3)}
            >
              <img
                src={imageSrc(
                  conditionalRender ? images[2]._id : images[3]._id
                )}
                alt=""
                className="image-grid__img-background"
              />
              {renderOverlayId(conditionalRender ? 2 : 3)}
            </div>
          </div>
          <div className="image-grid__col-4">
            <div
              className="image-grid__img-container"
              // onClick={() => openModal(conditionalRender ? 3 : 4)}
              onClick={(e) => navigateTo(e, conditionalRender ? 3 : 4)}
            >
              <img
                src={imageSrc(
                  conditionalRender ? images[3]._id : images[4]._id
                )}
                alt=""
                className="image-grid__img-background"
              />
              {overlay}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const imagesToShow = [...images];

  if (isCountFrom && images.length > isCountFrom) {
    imagesToShow.length = isCountFrom;
  }

  // const prevIndex = (imageIndex + images.length - 1) % images.length;
  // const nextIndex = (imageIndex + images.length + 1) % images.length;

  // const mainSrc = images[imageIndex] && imageSrc(images[imageIndex]._id);
  // const nextSrc = images[imageIndex] && imageSrc(images[imageIndex]._id);
  // const prevSrc = images[imageIndex] && imageSrc(images[imageIndex]._id);

  return (
    <>
      {[1, 3, 4].includes(imagesToShow.length) && renderOne()}
      {imagesToShow.length >= 2 && imagesToShow.length !== 4 && renderTwo()}
      {imagesToShow.length >= 4 && renderThree()}

      {/* <CustomModal
        showModal={modal}
        setShowModal={setModal}
        mainSrc={mainSrc || ''}
        nextSrc={nextSrc || ''}
        prevSrc={prevSrc || ''}
        onMovePrevRequest={() => setImageIndex(prevIndex)}
        onMoveNextRequest={() => setImageIndex(nextIndex)}
        closeModalAndRedirectTo="/blog"
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h1>Comment</h1>
        </div>
      </CustomModal> */}
    </>
  );
};

export default ImageGrid;
