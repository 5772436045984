import { scrollbarActions } from '../actions';

export interface ScrollbarState {
  positionX: number;
  positionY: number;
  positionXC: number;
  positionYC: number;
  positionPX: number;
  positionPY: number;
  pageHome: number;
  pageBlog: number;
  pageImages: number;
  pageReOrder: number;
}

const initialState: ScrollbarState = {
  positionX: 0,
  positionY: 0,
  positionXC: 0,
  positionYC: 0,
  positionPX: 0,
  positionPY: 0,
  pageHome: 1,
  pageBlog: 1,
  pageImages: 1,
  pageReOrder: 1,
};

export const scrollbarReducer = (
  state: ScrollbarState = initialState,
  action: scrollbarActions.Action
): ScrollbarState => {
  switch (action.type) {
    case scrollbarActions.ScrollbarActionTypes.SCROLLBAR_TRACKING:
      return {
        ...state,
        positionX: action.payload.positionX,
        positionY: action.payload.positionY,
      };

    case scrollbarActions.ScrollbarActionTypes.SCROLLBAR_TRACKING_CUSTOM:
      return {
        ...state,
        positionXC: action.payload.positionX,
        positionYC: action.payload.positionY,
      };

    case scrollbarActions.ScrollbarActionTypes.SCROLLBAR_SET_PREVIOUS_POSITION:
      return {
        ...state,
        positionPX: action.payload.positionX,
        positionPY: action.payload.positionY,
      };

    case scrollbarActions.ScrollbarActionTypes.SCROLLBAR_SET_POSITION:
      window.scrollTo(action.payload.positionX, action.payload.positionY);
      return {
        ...state,
        positionX: action.payload.positionX,
        positionY: action.payload.positionY,
        positionXC: 0,
        positionYC: 0,
      };

    case scrollbarActions.ScrollbarActionTypes.SCROLLBAR_SET_PAGE:
      return {
        ...state,
        pageHome: action.payload.type === 'home' ? action.payload.page : 1,
        pageBlog: action.payload.type === 'blog' ? action.payload.page : 1,
        pageImages: action.payload.type === 'images' ? action.payload.page : 1,
        pageReOrder:
          action.payload.type === 're-order' ? action.payload.page : 1,
      };

    default:
      return state;
  }
};
