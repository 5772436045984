import React, { Dispatch, useRef } from 'react';
import InputEmojiForwardRef from '../input-emoji-with-ref/InputEmojiWithRef';
import './imput-blog-comment.css';

type InputCommentProps = {
  value: string;
  onChange: Dispatch<React.SetStateAction<string>>;
  onEnter: () => void;
  focus?: boolean;
  style?: React.CSSProperties | undefined;
};

const InputBlogCommentReply = ({
  value,
  onChange,
  onEnter,
  focus,
  style,
}: InputCommentProps) => {
  const input = useRef() as React.MutableRefObject<HTMLInputElement>;

  if (focus) {
    setTimeout(() => {
      input.current?.focus();
    }, 200);
  }

  return (
    <div className="input-blog-comment-container" style={style}>
      <InputEmojiForwardRef
        ref={input}
        value={value}
        setValue={onChange}
        onEnter={onEnter}
        cleanOnEnter
        closeOnClickOutside
      />
    </div>
  );
};

export default InputBlogCommentReply;
