import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Yup from '../../utils/yup-extended';
import { FaEnvelope, FaLock, FaUser } from 'react-icons/fa';
import SignWithSocials from '../sign-with-socials/SignWithSocials';

const initialValues = {
  username: '',
  email: '',
  password: '',
  confirmPassword: '',
};

const SignupFormSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, 'Username must be at least 3 characters')
    .required('Username is required')
    .matches(/^\S*$/, 'Whitespace is not allowed'),
  email: Yup.string().email('Email is invalid').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/^\S*$/, 'Whitespace is not allowed')
    .strongPassword(),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .test('passwords-match', 'Password does not match', function (value) {
      return this.parent.password === value;
    }),
});

type Props = {
  formData: (data: {
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
  }) => void;
};

const SignupForm = ({ formData }: Props) => {
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={SignupFormSchema}
        onSubmit={(fields, { resetForm }) => {
          resetForm();
          formData(fields);
        }}
      >
        {({ errors, status, touched, isValid, dirty }) => (
          <Form className="sign-up-form">
            <h2 className="title">Sign up</h2>
            <div className="input-field">
              <label htmlFor="username" className="icon-wrapper">
                <FaUser />
              </label>
              <Field
                name="username"
                id="username"
                placeholder="Username"
                autoComplete="off"
                type="text"
                className={
                  errors.username && touched.username
                    ? 'is-invalid'
                    : touched.username
                    ? 'is-valid'
                    : ''
                }
              />
              <ErrorMessage
                name="username"
                component="span"
                className="invalid-feedback"
              />
            </div>
            <div className="input-field">
              <label htmlFor="signup-email" className="icon-wrapper">
                <FaEnvelope />
              </label>
              <Field
                name="email"
                id="signup-email"
                placeholder="Email"
                autoComplete="off"
                type="email"
                className={
                  errors.email && touched.email
                    ? 'is-invalid'
                    : touched.email
                    ? 'is-valid'
                    : ''
                }
              />
              <ErrorMessage
                name="email"
                component="span"
                className="invalid-feedback"
              />
            </div>
            <div className="input-field">
              <label htmlFor="signup-password" className="icon-wrapper">
                <FaLock />
              </label>
              <Field
                name="password"
                id="signup-password"
                placeholder="Password"
                autoComplete="off"
                type="password"
                className={
                  errors.password && touched.password
                    ? 'is-invalid'
                    : touched.password
                    ? 'is-valid'
                    : ''
                }
              />
              <ErrorMessage
                name="password"
                component="span"
                className="invalid-feedback"
              />
            </div>
            <div className="input-field">
              <label htmlFor="confirmPassword" className="icon-wrapper">
                <FaLock />
              </label>
              <Field
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm Password"
                autoComplete="off"
                type="password"
                className={
                  errors.confirmPassword && touched.confirmPassword
                    ? 'is-invalid'
                    : touched.confirmPassword
                    ? 'is-valid'
                    : ''
                }
              />
              <ErrorMessage
                name="confirmPassword"
                component="span"
                className="invalid-feedback"
              />
            </div>
            <input
              type="submit"
              className="btn"
              defaultValue="Sign up"
              disabled={!(isValid && dirty)}
            />
            <p className="social-text">Or Sign up with social platforms</p>
            <SignWithSocials />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SignupForm;
