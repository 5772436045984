import { IWallet } from '../../models/wallet.model';
import { getBalance } from '../../utils/get-ballance';
import { walletActions } from '../actions';

export interface WalletState {
  isLoading: boolean;
  wallets: IWallet[] | [];
  balance: number;
  edit: boolean;
  current: IWallet | null;
  message: string | null;
}

const initialState: WalletState = {
  isLoading: false,
  wallets: [],
  balance: 0,
  edit: false,
  current: null,
  message: null,
};

export const walletReducer = (
  state: WalletState = initialState,
  action: walletActions.Action
): WalletState => {
  switch (action.type) {
    case walletActions.WalletActionTypes.WALLET_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case walletActions.WalletActionTypes.WALLET_LOADED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wallets: action.payload,
        balance: getBalance(action.payload),
      };

    case walletActions.WalletActionTypes.WALLET_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wallets: [...state.wallets, action.payload.wallet],
        balance: getBalance([...state.wallets, action.payload.wallet]),
        message: action.payload.message,
      };

    case walletActions.WalletActionTypes.WALLET_SET_EDIT:
      return {
        ...state,
        isLoading: false,
        edit: true,
        current: action.payload,
      };

    case walletActions.WalletActionTypes.WALLET_SET_EDIT_CLEAR:
      return {
        ...state,
        isLoading: false,
        edit: false,
        current: null,
      };

    case walletActions.WalletActionTypes.WALLET_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wallets: state.wallets.map((wallet) => {
          if (wallet._id === action.payload.wallet._id) {
            let newWallet = action.payload.wallet;
            return (wallet = newWallet);
          }
          return wallet;
        }),
        edit: false,
        current: null,
        message: action.payload.message,
      };

    case walletActions.WalletActionTypes.WALLET_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wallets: state.wallets.filter(
          (wallet) => wallet._id !== action.payload.wallet._id
        ),
        balance: getBalance(
          state.wallets.filter(
            (wallet) => wallet._id !== action.payload.wallet._id
          )
        ),
        message: action.payload.message,
      };

    case walletActions.WalletActionTypes.WALLET_LOADED_FAIL:
    case walletActions.WalletActionTypes.WALLET_CREATE_FAIL:
    case walletActions.WalletActionTypes.WALLET_UPDATE_FAIL:
    case walletActions.WalletActionTypes.WALLET_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };

    default:
      return state;
  }
};
