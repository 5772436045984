import { Dispatch } from 'redux';
import { routeActions } from '../actions';

export const routeSet =
  (route: string) => async (dispatch: Dispatch<routeActions.Action>) => {
    dispatch({
      type: routeActions.RouteActionTypes.ROUTE_GO_BACK_SET,
      payload: route,
    });
  };

export const routeRemove =
  () => async (dispatch: Dispatch<routeActions.Action>) => {
    dispatch({ type: routeActions.RouteActionTypes.ROUTE_GO_BACK_REMOVE });
  };
