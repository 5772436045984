import React, { useEffect, useRef, useState } from 'react';
import Popup from '../popup/Popup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import InputEmojiForwardRef from '../input-emoji-with-ref/InputEmojiWithRef';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/app-state';
import { tradeCreators, tradeMethodologyCreators } from '../../store/creators';
import DatePicker, { ReactDatePicker } from 'react-datepicker';
import { SelectInstance } from 'react-select';
import { TagOption } from '../../models/tag.model';
import { tradeMethodologyToSingleSelect } from '../../utils/trade-methodology-to-select';
import { ITradeData } from '../../models/trade.model';
import { IUser } from '../../models/user.model';
import DropzonImagePreview from '../dropzon-image-preview/DropzonImagePreview';
import SelectAndCreateTag from '../select-and-create-tag/SelectAndCreateTag';
import './trade-popup.css';
import {
  TradeMethodologyGroup,
  TradeMethodologyType,
} from '../../models/trademedology.model';

type TradePopupProps = {
  user: IUser | null;
  showTradePopup: boolean;
  setShowTradePopup: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose?: () => void;
};

const TradePopup = ({
  user,
  showTradePopup,
  setShowTradePopup,
  handleClose,
}: TradePopupProps) => {
  const [data, setData] = useState<ITradeData>({
    userId: user ? user._id : null,
    fileId: null,
    entryPrice: '',
    entryEmotion: null,
    entryComment: null,
    exitPrice: '',
    exitEmotion: null,
    exitComment: null,
    volume: '',
    highPrice: '',
    lowPrice: '',
    entryDate: new Date(),
    exitDate: new Date(),
    gainLost: '',
    instrument: null,
    setup: null,
    direction: null,
    tradeManagement: null,
    description: '',
    file: null,
  });

  const tradeInputSchema = Yup.object().shape({
    instrument: Yup.object()
      .required('Trade instrument is required')
      .nullable(),
    entryDate: Yup.date().required('Entry date is required'),
    entryPrice: Yup.number().required('Entry price is required'),
    entryEmotion: Yup.object().required('Entry emotion is required').nullable(),
    entryComment: Yup.object().required('Entry comment is required').nullable(),
    volume: Yup.number().required('Volume is required'),
    direction: Yup.object().required('Trade direction is required').nullable(),
    setup: Yup.object().required('Trade setup is required').nullable(),
    gainLost: Yup.number().notRequired(),
    exitDate: Yup.date().when('gainLost', (gainLost, field) => {
      if (gainLost && gainLost.toString().length > 0)
        return field.required('Exit date is required');
      return field.notRequired();
    }),
    exitPrice: Yup.number().when('gainLost', (gainLost, field) => {
      if (gainLost && gainLost.toString().length > 0)
        return field.required('Exit price is required');
      return field.notRequired();
    }),
    exitEmotion: Yup.object().when('gainLost', (gainLost, field) => {
      if (gainLost && gainLost.toString().length > 0)
        return field.required('Exit emotion is required').nullable();
      return field.nullable();
    }),
    exitComment: Yup.object().when('gainLost', (gainLost, field) => {
      if (gainLost && gainLost.toString().length > 0)
        return field.required('Exit comment is required').nullable();
      return field.nullable();
    }),
    highPrice: Yup.number().when('gainLost', (gainLost, field) => {
      if (gainLost && gainLost.toString().length > 0)
        return field.required('High price is required');
      return field.notRequired();
    }),
    lowPrice: Yup.number().when('gainLost', (gainLost, field) => {
      if (gainLost && gainLost.toString().length > 0)
        return field.required('Low price is required');
      return field.notRequired();
    }),
    tradeManagement: Yup.object().when('gainLost', (gainLost, field) => {
      if (gainLost && gainLost.toString().length > 0)
        return field.required('Trade management is required').nullable();
      return field.nullable();
    }),
    description: Yup.string().notRequired(),
    file: Yup.mixed().notRequired().nullable(),
  });

  const instrumentRef = useRef() as React.MutableRefObject<
    SelectInstance<TagOption, false>
  >;
  const entryDateRef = useRef() as React.MutableRefObject<
    ReactDatePicker<never, undefined>
  >;
  const entryEmotionRef = useRef() as React.MutableRefObject<
    SelectInstance<TagOption, false>
  >;
  const entryCommentRef = useRef() as React.MutableRefObject<
    SelectInstance<TagOption, false>
  >;
  const exitDateRef = useRef() as React.MutableRefObject<
    ReactDatePicker<never, undefined>
  >;
  const exitEmotionRef = useRef() as React.MutableRefObject<
    SelectInstance<TagOption, false>
  >;
  const exitCommentRef = useRef() as React.MutableRefObject<
    SelectInstance<TagOption, false>
  >;
  const setupRef = useRef() as React.MutableRefObject<
    SelectInstance<TagOption, false>
  >;
  const directionRef = useRef() as React.MutableRefObject<
    SelectInstance<TagOption, false>
  >;
  const tradeManagementRef = useRef() as React.MutableRefObject<
    SelectInstance<TagOption, false>
  >;
  const descriptionRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const dispatch = useDispatch();

  const editTrade = useSelector((state: AppState) => state.trades.edit);
  const currentTrade = useSelector((state: AppState) => state.trades.current);

  useEffect(() => {
    if (showTradePopup) {
      dispatch(
        tradeMethodologyCreators.loadTradeMethodologiesGroup(
          TradeMethodologyGroup.methodology
        )
      );
      dispatch(
        tradeMethodologyCreators.loadTradeMethodologiesTypeInstrumentUnique()
      );
      dispatch(
        tradeMethodologyCreators.loadTradeMethodologiesTypeSetupUnique()
      );
    }
  }, [dispatch, showTradePopup]);

  const systemTradeGroups = useSelector(
    (state: AppState) => state.tradeMethodologies.groups
  );

  const systemTradeInstrumentTags = useSelector(
    (state: AppState) => state.tradeMethodologies.instruments
  );

  const systemTradeSetupTags = useSelector(
    (state: AppState) => state.tradeMethodologies.setups
  );

  // const imageSrc = (imageId: string) =>
  //   `${config.serverUrl}/api/files/${imageId}?token=${auth.accessToken}`;

  const systemTradeDirectionTags = systemTradeGroups.filter(
    (m) => m.type === TradeMethodologyType.direction
  );

  const systemTradeEntryEmotionTags = systemTradeGroups.filter(
    (m) => m.type === TradeMethodologyType.entryemotion
  );

  const systemTradeEntryCommentTags = systemTradeGroups.filter(
    (m) => m.type === TradeMethodologyType.entrycomment
  );

  const systemTradeExitEmotionTags = systemTradeGroups.filter(
    (m) => m.type === TradeMethodologyType.exitemotion
  );

  const systemTradeExitCommentTags = systemTradeGroups.filter(
    (m) => m.type === TradeMethodologyType.exitcomment
  );

  const systemTradeManagementTags = systemTradeGroups.filter(
    (m) => m.type === TradeMethodologyType.trademanagement
  );

  useEffect(() => {
    if (currentTrade) {
      setData({
        userId: currentTrade.trade.userId,
        fileId: currentTrade.fileId,
        entryDate: new Date(currentTrade.trade.entryDate),
        entryPrice: currentTrade.trade.entryPrice,
        entryEmotion: tradeMethodologyToSingleSelect(currentTrade.entryEmotion),
        entryComment: tradeMethodologyToSingleSelect(currentTrade.entryComment),
        exitDate: currentTrade.trade.exitDate
          ? new Date(currentTrade.trade.exitDate)
          : new Date(),
        exitPrice: currentTrade.trade.exitPrice
          ? currentTrade.trade.exitPrice
          : '',
        exitEmotion: currentTrade.exitEmotion
          ? tradeMethodologyToSingleSelect(currentTrade.exitEmotion)
          : null,
        exitComment: currentTrade.exitComment
          ? tradeMethodologyToSingleSelect(currentTrade.exitComment)
          : null,
        volume: currentTrade.trade.volume,
        highPrice: currentTrade.trade.highPrice
          ? currentTrade.trade.highPrice
          : '',
        lowPrice: currentTrade.trade.lowPrice
          ? currentTrade.trade.lowPrice
          : '',
        gainLost: currentTrade.trade.result ? currentTrade.trade.result : '',
        instrument: tradeMethodologyToSingleSelect(currentTrade.instrument),
        setup: tradeMethodologyToSingleSelect(currentTrade.setup),
        direction: tradeMethodologyToSingleSelect(currentTrade.direction),
        tradeManagement: currentTrade.tradeManagement
          ? tradeMethodologyToSingleSelect(currentTrade.tradeManagement)
          : null,
        description: currentTrade.trade.description,
        file: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrade]);

  const handleCancel = () => {
    setShowTradePopup(false);
    if (currentTrade) {
      dispatch(tradeCreators.tradeSetEditClear());
    }
  };

  return (
    <div className="trade-popup">
      <Popup
        showPopup={showTradePopup}
        setShowPopup={setShowTradePopup}
        handleClose={handleClose}
      >
        <Formik
          enableReinitialize={true}
          validateOnMount={true}
          validateOnBlur={true}
          validateOnChange={true}
          initialValues={data}
          validationSchema={tradeInputSchema}
          onSubmit={(fields, { resetForm }) => {
            if (currentTrade) {
              dispatch(tradeCreators.tradeUpdate(currentTrade.trade, fields));
            } else {
              dispatch(tradeCreators.tradeCreate(fields));
            }

            resetForm();
          }}
        >
          {({
            handleChange,
            handleBlur,
            values,
            errors,
            status,
            touched,
            isValid,
            dirty,
          }) => (
            <div className="content-wrapper">
              <Form className="content">
                <div className="trade">
                  <div className="trade-entry">
                    <div className="select-container">
                      <div className="input-wrapper">
                        <label
                          htmlFor="instrument"
                          onClick={() => instrumentRef?.current?.focus()}
                        >
                          Instrument
                        </label>
                        <SelectAndCreateTag
                          name="instrument"
                          id="instrument"
                          ref={instrumentRef}
                          options={systemTradeInstrumentTags}
                          defaultValue={[]}
                          value={values.instrument}
                          onBlur={() => {
                            handleBlur({ target: { name: 'instrument' } });
                          }}
                          onChange={(selectedOption) => {
                            handleChange({
                              target: {
                                name: 'instrument',
                                value: selectedOption,
                              },
                            });
                          }}
                          placeholder="Select instrument or type something..."
                          noOptionsMessage={() => 'No other instrument :('}
                        />
                        <ErrorMessage
                          name="instrument"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="input-group">
                      <div className="input-wrapper">
                        <label
                          htmlFor="entryDate"
                          onClick={() => entryDateRef?.current?.setFocus()}
                        >
                          Entry Date
                        </label>
                        <DatePicker
                          ref={entryDateRef}
                          wrapperClassName="input-wrapper"
                          name="entryDate"
                          id="entryDate"
                          dateFormat="dd/MM/yyyy HH:mm"
                          selected={values.entryDate}
                          onBlur={() => {
                            handleBlur({ target: { name: 'entryDate' } });
                          }}
                          onChange={(value) => {
                            handleChange({
                              target: { name: 'entryDate', value: value },
                            });
                          }}
                          timeInputLabel="Time:"
                          showTimeInput
                          shouldCloseOnSelect={false}
                        />
                        <ErrorMessage
                          name="entryDate"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="input-wrapper mt-custom">
                        <label htmlFor="entryPrice">Entry Price</label>
                        <Field
                          name="entryPrice"
                          id="entryPrice"
                          type="number"
                          step="0.01"
                          value={values.entryPrice}
                          placeholder="Entry price"
                        />
                        <ErrorMessage
                          name="entryPrice"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="select-group">
                      <div className="input-wrapper">
                        <label
                          htmlFor="entryEmotion"
                          onClick={() => entryEmotionRef?.current?.focus()}
                        >
                          Entry Emotion
                        </label>
                        <SelectAndCreateTag
                          name="entryEmotion"
                          id="entryEmotion"
                          ref={entryEmotionRef}
                          options={systemTradeEntryEmotionTags}
                          defaultValue={[]}
                          value={values.entryEmotion}
                          onBlur={() => {
                            handleBlur({ target: { name: 'entryEmotion' } });
                          }}
                          onChange={(selectedOption) => {
                            handleChange({
                              target: {
                                name: 'entryEmotion',
                                value: selectedOption,
                              },
                            });
                          }}
                          placeholder="Select entry emotion"
                          noOptionsMessage={() => 'No other emotions:('}
                          selectOnly
                        />
                        <ErrorMessage
                          name="entryEmotion"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="input-wrapper mt-custom">
                        <label
                          htmlFor="entryComment"
                          onClick={() => entryCommentRef?.current?.focus()}
                        >
                          Entry Comment
                        </label>
                        <SelectAndCreateTag
                          name="entryComment"
                          id="entryComment"
                          ref={entryCommentRef}
                          options={systemTradeEntryCommentTags}
                          defaultValue={[]}
                          value={values.entryComment}
                          onBlur={() => {
                            handleBlur({ target: { name: 'entryComment' } });
                          }}
                          onChange={(selectedOption) => {
                            handleChange({
                              target: {
                                name: 'entryComment',
                                value: selectedOption,
                              },
                            });
                          }}
                          placeholder="Select entry comment"
                          noOptionsMessage={() => 'No other comments :('}
                          selectOnly
                        />
                        <ErrorMessage
                          name="entryComment"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="input-group">
                      <div className="input-wrapper">
                        <label htmlFor="volume">Volume</label>
                        <Field
                          name="volume"
                          id="volume"
                          type="number"
                          step="0"
                          value={values.volume}
                          placeholder="Volume"
                        />
                        <ErrorMessage
                          name="volume"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="select-container">
                      <div className="input-wrapper">
                        <label
                          htmlFor="direction"
                          onClick={() => directionRef?.current?.focus()}
                        >
                          Trade Direction
                        </label>
                        <SelectAndCreateTag
                          name="direction"
                          id="direction"
                          ref={directionRef}
                          options={systemTradeDirectionTags}
                          defaultValue={[]}
                          onBlur={() => {
                            handleBlur({ target: { name: 'direction' } });
                          }}
                          value={values.direction}
                          onChange={(selectedOption) => {
                            handleChange({
                              target: {
                                name: 'direction',
                                value: selectedOption,
                              },
                            });
                          }}
                          placeholder="Select direction Long or Short"
                          noOptionsMessage={() => 'No other direction :('}
                          selectOnly
                        />
                        <ErrorMessage
                          name="direction"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="select-container">
                      <div className="input-wrapper">
                        <label
                          htmlFor="setup"
                          onClick={() => setupRef?.current?.focus()}
                        >
                          Setup Method
                        </label>
                        <SelectAndCreateTag
                          name="setup"
                          id="setup"
                          ref={setupRef}
                          options={systemTradeSetupTags}
                          defaultValue={[]}
                          value={values.setup}
                          onBlur={() => {
                            handleBlur({ target: { name: 'setup' } });
                          }}
                          onChange={(selectedOption) => {
                            handleChange({
                              target: { name: 'setup', value: selectedOption },
                            });
                          }}
                          placeholder="Select setup or type something..."
                          noOptionsMessage={() => 'No other setups :('}
                        />
                        <ErrorMessage
                          name="setup"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="trade-exit">
                    <div className="input-group">
                      <div className="input-wrapper">
                        <label
                          htmlFor="exitDate"
                          onClick={() => exitDateRef?.current?.setFocus()}
                        >
                          Exit Date
                        </label>
                        <DatePicker
                          ref={exitDateRef}
                          wrapperClassName="input-wrapper"
                          name="exitDate"
                          id="exitDate"
                          dateFormat="dd/MM/yyyy HH:mm"
                          selected={values.exitDate}
                          onBlur={() => {
                            handleBlur({ target: { name: 'exitDate' } });
                          }}
                          onChange={(value) => {
                            handleChange({
                              target: { name: 'exitDate', value: value },
                            });
                          }}
                          timeInputLabel="Time:"
                          showTimeInput
                          shouldCloseOnSelect={false}
                        />
                        <ErrorMessage
                          name="exitDate"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="input-wrapper mt-custom">
                        <label htmlFor="exitPrice">Exit Price</label>
                        <Field
                          name="exitPrice"
                          id="exitPrice"
                          type="number"
                          step="0.01"
                          value={values.exitPrice}
                          placeholder="Exit price"
                        />
                        <ErrorMessage
                          name="exitPrice"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="select-group">
                      <div className="input-wrapper">
                        <label
                          htmlFor="exitEmotion"
                          onClick={() => exitEmotionRef?.current?.focus()}
                        >
                          Exit Emotion
                        </label>
                        <SelectAndCreateTag
                          name="exitEmotion"
                          id="exitEmotion"
                          ref={exitEmotionRef}
                          options={systemTradeExitEmotionTags}
                          defaultValue={[]}
                          value={values.exitEmotion}
                          onBlur={() => {
                            handleBlur({ target: { name: 'exitEmotion' } });
                          }}
                          onChange={(selectedOption) => {
                            handleChange({
                              target: {
                                name: 'exitEmotion',
                                value: selectedOption,
                              },
                            });
                          }}
                          placeholder="Select exit emotion"
                          noOptionsMessage={() => 'No other emotions :('}
                          selectOnly
                        />
                        <ErrorMessage
                          name="exitEmotion"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="input-wrapper mt-custom">
                        <label
                          htmlFor="exitComment"
                          onClick={() => exitCommentRef?.current?.focus()}
                        >
                          Exit Comment
                        </label>
                        <SelectAndCreateTag
                          name="exitComment"
                          id="exitComment"
                          ref={exitCommentRef}
                          options={systemTradeExitCommentTags}
                          defaultValue={[]}
                          value={values.exitComment}
                          onBlur={() => {
                            handleBlur({ target: { name: 'exitComment' } });
                          }}
                          onChange={(selectedOption) => {
                            handleChange({
                              target: {
                                name: 'exitComment',
                                value: selectedOption,
                              },
                            });
                          }}
                          placeholder="Select exit comment"
                          noOptionsMessage={() => 'No other comments :('}
                          selectOnly
                        />
                        <ErrorMessage
                          name="exitComment"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="input-group">
                      <div className="input-wrapper">
                        <label htmlFor="highPrice">Hight Price</label>
                        <Field
                          name="highPrice"
                          id="highPrice"
                          type="number"
                          step="0.01"
                          value={values.highPrice}
                          placeholder="High price"
                        />
                        <ErrorMessage
                          name="highPrice"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="input-group">
                      <div className="input-wrapper">
                        <label htmlFor="lowPrice">Low Price</label>
                        <Field
                          name="lowPrice"
                          id="lowPrice"
                          type="number"
                          step="0.01"
                          value={values.lowPrice}
                          placeholder="Low price"
                        />
                        <ErrorMessage
                          name="lowPrice"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="input-group">
                      <div className="input-wrapper">
                        <label htmlFor="gainLost">Gain/Loss</label>
                        <Field
                          name="gainLost"
                          id="gainLost"
                          type="number"
                          step="0.01"
                          value={values.gainLost}
                          placeholder="Gain/Loss"
                        />
                        <ErrorMessage
                          name="gainLost"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="select-container">
                      <div className="input-wrapper">
                        <label
                          htmlFor="tradeManagement"
                          onClick={() => tradeManagementRef?.current?.focus()}
                        >
                          Trade Management
                        </label>
                        <SelectAndCreateTag
                          name="tradeManagement"
                          id="tradeManagement"
                          ref={tradeManagementRef}
                          options={systemTradeManagementTags}
                          defaultValue={[]}
                          onBlur={() => {
                            handleBlur({ target: { name: 'tradeManagement' } });
                          }}
                          value={values.tradeManagement}
                          onChange={(selectedOption) => {
                            handleChange({
                              target: {
                                name: 'tradeManagement',
                                value: selectedOption,
                              },
                            });
                          }}
                          placeholder="Select trade management"
                          noOptionsMessage={() => 'No other management :('}
                          selectOnly
                        />
                        <ErrorMessage
                          name="tradeManagement"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-container">
                  <div className="input-wrapper">
                    <label
                      htmlFor="description"
                      onClick={() => descriptionRef?.current?.focus()}
                    >
                      Trade Description
                    </label>
                    <InputEmojiForwardRef
                      ref={descriptionRef}
                      value={values.description}
                      setValue={(value) => {
                        handleChange({
                          target: { name: 'description', value: value },
                        });
                      }}
                      closeOnClickOutside
                    />
                  </div>
                </div>
                <div className="image-upload-container">
                  <DropzonImagePreview
                    file={(file) => {
                      handleChange({
                        target: { name: 'file', value: file },
                      });
                    }}
                    // defaultPreview={imageSrc(file._id)}
                  />
                </div>
                <div className="btn-update-container">
                  <button
                    type="button"
                    className="btn-cancel"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={editTrade ? 'btn-update' : 'btn-create'}
                    disabled={!isValid || Array.isArray(errors)}
                  >
                    {editTrade ? 'Update' : 'Create'}
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </Popup>
    </div>
  );
};

export default TradePopup;
